// eslint-disable-next-line
export enum Status {
    error = 'error',
    idle = 'idle',
    loading = 'loading',
    ready = 'ready',
}

export type StatusState = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    status: Status;
};

export enum ExchangeDirection {
    claimAirdrop = 'claimAirdrop',
    claimAllowance = 'claimAllowance',
    claimBonus = 'claimBonus',
    claimReward = 'claimReward',
    claimUst = 'claimUst',
    depositGro = 'depositGro',
    depositUsdc = 'depositUsdc',
    exit = 'exit',
    extend = 'extend',
    invest = 'invest',
    investAvax = 'investAvax',
    investMigrate = 'investMigrate',
    lockMoreGro = 'lockMoreGro',
    migrate = 'migrate',
    migratePool = 'migratePool',
    redeemDura = 'redeemDura',
    redeemGro = 'redeemGro',
    revest = 'revest',
    stake = 'stake',
    teamClaim = 'teamClaim',
    unstake = 'unstake',
    withdraw = 'withdraw',
    withdrawAvax = 'withdrawAvax',
    withdrawGro = 'withdrawGro',
    withdrawMigrate = 'withdrawMigrate',
    withdrawUsdc = 'withdrawUsdc',
}
