import React from 'react';
import { css } from '@emotion/react';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { GroInfoIcon, TextOrSkeleton } from '../../../components';
import { ToolTipText } from '../../../components/typography/ToolTipText';
import { VaultAvaxNames } from '../../../constants';
import { getTokenInfo } from '../../../lib/contract-info';
import { useAppSelector } from '../../app/hooks';
import { selectGroStatsError, selectIsLoadingGroMcStats } from '../../stats/store/stats.selectors';
import { formatAsCurrency } from '../../utils/currency.helpers';
import { formatPct } from '../../utils/percentage.helpers';
import { getLabsToken } from '../store/labs.helper';
import {
    selectLast3dApy,
    selectStatsAvaxExposure,
    selectStatsReservesSpecific,
    selectStrategyTVLCap,
    selectTVLSpecific,
    selectVaultVersion,
} from '../store/labs.selectors';

export function LabsDetails({ name }: { name: VaultAvaxNames }): React.ReactElement {
    const theme = useTheme();
    const last3dAPY = useAppSelector(selectLast3dApy(name));
    const statsTVLspecific = useAppSelector(selectTVLSpecific(name));
    const statsReserveSpecific = useAppSelector(selectStatsReservesSpecific(name));
    const statsAVAXExposure = useAppSelector(selectStatsAvaxExposure(name));
    const loading = useAppSelector(selectIsLoadingGroMcStats);
    const depositLimit = useAppSelector(selectStrategyTVLCap(name));
    const groError = useAppSelector(selectGroStatsError);

    const { displayName } = getTokenInfo(getLabsToken(name));

    const version = selectVaultVersion(name);

    return (
        <Box>
            <Box alignItems="baseline" display="flex" mt={0.5}>
                <Typography variant="h2">{displayName} Leveraged Yield</Typography>
                <Typography color="text.tertiary" ml={0.5} variant="h3">
                    {version}
                </Typography>
            </Box>
            <Tooltip
                disableInteractive
                title={
                    <ToolTipText>
                        Effective annual yield based on performance from last{' '}
                        {name.includes('1_7') ? '12 hours' : '3 days'}
                    </ToolTipText>
                }
            >
                <Typography
                    color="secondary"
                    css={css`
                        font-size: 30px;
                    `}
                    mt={1}
                    variant="h1"
                >
                    {last3dAPY.isLessThan(0) && '-'}
                    {formatPct(last3dAPY)} APY
                    <GroInfoIcon
                        css={css`
                            margin-left: 8px;
                        `}
                        height={16}
                        stroke={theme.palette.text.tertiary}
                        width={16}
                    />
                </Typography>
            </Tooltip>
            <Typography sx={{ opacity: 0.7 }} variant="nav">
                PURE STABLECOIN YIELDS
            </Typography>
            <Box mt={1}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">TVL</Typography>
                    <Typography variant="body2">
                        {!groError ? (
                            <TextOrSkeleton loading={loading}>--</TextOrSkeleton>
                        ) : (
                            <TextOrSkeleton loading={loading}>n/a</TextOrSkeleton>
                        )}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={0.5}>
                    <Tooltip
                        disableInteractive
                        title={
                            <Box>
                                <ToolTipText>
                                    Assets that are not currently deployed in the Strategy
                                    including those held back to enable easier user withdrawals.
                                </ToolTipText>
                            </Box>
                        }
                    >
                        <Typography variant="body2">
                            Reserve
                            <GroInfoIcon
                                css={css`
                                    margin-left: 5px;
                                    vertical-align: middle;
                                `}
                                height={16}
                                stroke={theme.palette.text.tertiary}
                                width={16}
                            />
                        </Typography>
                    </Tooltip>
                    <Typography variant="body2">
                        {!groError ? (
                            <TextOrSkeleton loading={loading}>
                                {formatAsCurrency(statsReserveSpecific, 0)}
                            </TextOrSkeleton>
                        ) : (
                            <TextOrSkeleton loading={loading}>n/a</TextOrSkeleton>
                        )}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={0.5}>
                    <Tooltip
                        disableInteractive
                        title={
                            <Box>
                                <ToolTipText>
                                    This is the net % of assets that&rsquo;s currently exposed to
                                    AVAX either short or long
                                </ToolTipText>
                            </Box>
                        }
                    >
                        <Typography variant="body2">
                            AVAX exposure
                            <GroInfoIcon
                                css={css`
                                    margin-left: 5px;
                                    vertical-align: middle;
                                `}
                                height={16}
                                stroke={theme.palette.text.tertiary}
                                width={16}
                            />
                        </Typography>
                    </Tooltip>
                    <Typography variant="body2">
                        {!groError ? (
                            <TextOrSkeleton loading={loading}>
                                {statsAVAXExposure.isLessThan(0) && '-'}
                                {formatPct(statsAVAXExposure)}
                                {statsAVAXExposure.isLessThan(0) && ' (short)'}
                                {statsAVAXExposure.isGreaterThan(0) && ' (long)'}
                            </TextOrSkeleton>
                        ) : (
                            <TextOrSkeleton loading={loading}>n/a</TextOrSkeleton>
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
