/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Dispatch } from 'react';
import { NonPayableTransactionObject, NonPayableTx } from '../../lib/abis/types/types';
import { web3Instance } from '../app/services';
import { RootState } from '../app/store';
import {
    TX_INVALID_ERROR_MESSAGE,
    TX_TIMED_OUT_ERROR_MESSAGE,
} from '../exchange/exchange.constants';
import { fetchGasAPI } from '../exchange/services/gas.service';
import {
    setWalletTransactionHash,
    setWalletTransactionStatus,
} from '../transaction/store/transactions.reducer';
import { TransactionStatus } from '../transaction/store/transactions.store';
import { selectIsAVAXNetwork, selectWalletAccount } from '../wallet/store/wallet.selectors';

type GasReturn = {
    gasCurrent: {
        from: string;
        gas: number;
        maxFeePerGas?: number | null;
        maxPriorityFeePerGas?: number | null;
    };
    gasLegacy: {
        from: string;
        gas: number;
        gasPrice: number;
    };
};

export async function setUpGas(
    method: NonPayableTransactionObject<any>,
    state: () => RootState,
): Promise<GasReturn> {
    const wallet = selectWalletAccount(state());
    const isAvax = selectIsAVAXNetwork(state());
    const gasPrice = Math.ceil(+(await web3Instance().eth.getGasPrice()));

    const estimatedGas = Math.ceil(
        await method.estimateGas({
            from: wallet,
        }),
    );

    const gas = Math.ceil(estimatedGas);

    const { maxFeePerGas, maxPriorityFeePerGas } = await fetchGasAPI();

    const gasCurrent = {
        from: wallet,
        gas,
        maxFeePerGas: isAvax ? null : maxFeePerGas,
        maxPriorityFeePerGas: isAvax ? null : maxPriorityFeePerGas,
    };

    return {
        gasCurrent,
        gasLegacy: {
            from: wallet,
            gas,
            gasPrice,
        },
    };
}

export async function sendTransaction(
    method: NonPayableTransactionObject<any>,
    dispatch: Dispatch<any>,
    mmTid: string,
    tid: string,
    state: () => RootState,
    onDone: () => void,
): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const sendTx = (props: NonPayableTx) =>
        method
            .send(props)
            .once('sending', () => {
                dispatch(
                    setWalletTransactionStatus({
                        mmTid,
                        status: TransactionStatus.pendingMmApproval,
                        tid,
                    }),
                );
            })
            .once('transactionHash', (hash: string) => {
                dispatch(setWalletTransactionHash({ hash, mmTid, tid }));
                dispatch(
                    setWalletTransactionStatus({
                        mmTid,
                        status: TransactionStatus.pendingConfirmation,
                        tid,
                    }),
                );
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .once('receipt', (_receipt: any) => {
                dispatch(
                    setWalletTransactionStatus({
                        mmTid,
                        status: TransactionStatus.confirmed,
                        tid,
                    }),
                );
                void onDone();
            });

    const handleTxError = (error: any): void => {
        const message = error.message.split(':')[0];
        const isTimingOut = message.includes(TX_TIMED_OUT_ERROR_MESSAGE);
        dispatch(
            setWalletTransactionStatus({
                error: message,
                mmTid,
                status: isTimingOut ? TransactionStatus.timeout : TransactionStatus.error,
                tid,
            }),
        );
    };

    const { gasCurrent, gasLegacy } = await setUpGas(method, state);

    // @ts-ignore
    void sendTx(gasCurrent) // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .on('error', (error: any) => {
            if (error.message.toLowerCase().includes(TX_INVALID_ERROR_MESSAGE)) {
                // retry with legacy params
                void sendTx(gasLegacy).on('error', handleTxError);
            } else {
                handleTxError(error);
            }
        });
}
