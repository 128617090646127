/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { cusdc } from '../contract-info';
import { Cusdc } from './types/cusdc';

let instance: Cusdc;

export function cusdcContract(): Cusdc {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(cusdc.abi, cusdc.address) as any as Cusdc;
    return instance;
}
