import React, { useState } from 'react';
import { css } from '@emotion/react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TOKENS } from '../constants';
import { ExchangeDirection } from '../modules/app/app.types';
import { useAppSelector } from '../modules/app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../modules/exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../modules/exchange/store/thunks/createNewTransactionThunk';
import { selectOpenMigrationModal } from '../modules/pools/store/pools.selectors';
import { ConfirmationModal } from '../modules/transaction/components/ConfirmationModal';
import { GroModal, GroModalHeader } from './GroModal';

export function MigratePools(): React.ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isOpen = useAppSelector(selectOpenMigrationModal);
    const [startTxn, setStartTxn] = useState(false);

    const styles = {
        button: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        iconWrapper: css`
            background: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            height: 32px;
            width: 32px;
        `,
        subText: css`
            margin-top: -2px;
        `,
        wrapper: css`
            background: linear-gradient(
                180deg,
                rgba(69, 132, 255, 0.3) 0%,
                rgba(0, 112, 175, 0.3) 100%
            );
            border: 1px solid ${theme.palette.info.light};
            border-radius: 8px;
        `,
    };

    function onClose(): void {}

    function onMigrate(): void {
        dispatch(resetExchange());
        dispatch(setExchangeDirection(ExchangeDirection.migratePool));
        dispatch(setGrwthToken(TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [TOKENS.GRO]: new BigNumber(0),
            }),
        );
        dispatch(
            addTokenRhs({
                [TOKENS.GRO]: new BigNumber(0),
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    return (
        <GroModal isOpen={isOpen} width="444px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={5} px={5}>
                    <GroModalHeader hideClose onClose={onClose}>
                        <Typography variant="h1">
                            <span aria-label="party" role="img">
                                🎉
                            </span>{' '}
                            Pools & Rewards upgrade
                        </Typography>
                    </GroModalHeader>
                    <Typography color="text.secondary" mt={3} variant="body2Small">
                        The Gro DAO has voted for new tokenomics.
                    </Typography>
                    <Typography color="text.secondary" my={2} variant="body2Small">
                        You can now:
                    </Typography>
                    <ul
                        css={css`
                            padding-left: 30px;
                        `}
                    >
                        <li>
                            <Typography color="text.secondary" variant="body2Small">
                                Claim multiple Pool rewards at once.
                            </Typography>
                        </li>
                        <li>
                            <Typography color="text.secondary" variant="body2Small">
                                Partially exit your Vesting Contract.
                            </Typography>
                        </li>
                        <li>
                            <Typography color="text.secondary" variant="body2Small">
                                Lock more GRO into your Vesting Contract.
                            </Typography>
                        </li>
                        <li>
                            <Typography color="text.secondary" variant="body2Small">
                                Claim 30% of rewards immediately or vest 100%.
                            </Typography>
                        </li>
                    </ul>
                    <a
                        css={css`
                            text-decoration: none;
                        `}
                        href="https://vote.gro.xyz/#/proposal/0x8e0fb22cc79de63ee7f63b90f2625de20fa26b5db0414df1eefe2ba925c9b155"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                    >
                        <Typography
                            alignItems="center"
                            color="text.tertiary"
                            display="flex"
                            mt={2}
                            variant="body2Small"
                        >
                            And more! See all upgrades
                            <OpenInNewRoundedIcon
                                css={css`
                                    margin-left: 4px;
                                    font-size: 1rem;
                                `}
                            />
                        </Typography>
                    </a>
                    <Box css={styles.wrapper} mt={3} px={2} py={2.5}>
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.button}
                            variant="contained"
                            onClick={onMigrate}
                        >
                            Activate address in new Pools
                        </Button>
                        <Box mt={3}>
                            <Typography color="text.tertiary" variant="body2Small">
                                Don&apos;t want to activate right now? No problem; your funds are
                                safe and already accruing rewards in the new upgraded Pools
                                contracts
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={(): void => {}}
                />
            )}
        </GroModal>
    );
}

export const MigratePoolsModal = React.memo(MigratePools);
