/* eslint-disable no-nested-ternary */
import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import { GroPendingSpinnerIcon } from '../../../../components';
import { GroModalHeader } from '../../../../components/GroModal';
import { GrowthToken, PoolToken, StableTokenAvax, Token, TOKENS } from '../../../../constants';
import { getTokenInfo } from '../../../../lib/contract-info';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { PROVIDERS } from '../../../app/providers.constants';
import { isGrwthToken, isPoolToken } from '../../../utils/token.helpers';
import { selectProviderName } from '../../../wallet/store/wallet.selectors';
import { getModalTitle } from '../../transaction.helpers';

export type LoadingModalProps = {
    direction?: ExchangeDirection;
    onClose: () => void;
    token?: GrowthToken | PoolToken | StableTokenAvax | TOKENS.CUSDC | TOKENS.DURA | TOKENS.USDC;
};

export function LoadingTransactionSteps({
    direction,
    onClose,
    token,
}: LoadingModalProps): React.ReactElement {
    const providerName = useAppSelector(selectProviderName);
    const theme = useTheme();

    const styles = {
        loadingText: css`
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
        `,
    };
    return (
        <Box>
            <GroModalHeader onClose={onClose}>
                <Box
                    sx={{
                        p: theme.spacing(0, 4.5, 3.5),
                    }}
                >
                    <Typography pb={1} variant="h1">
                        {getModalTitle(token as Token, direction as ExchangeDirection)}
                    </Typography>
                    {isGrwthToken(token as Token) && (
                        <Typography color="text.secondary" pb={1} variant="body2">
                            {providerName === PROVIDERS.METAMASK
                                ? 'Confirm transaction steps in your MetaMask'
                                : 'Confirm transaction steps in your WalletConnect'}
                        </Typography>
                    )}
                    {isPoolToken(token as Token) && (
                        <Typography color="text.secondary" pb={1} variant="body2">
                            {getTokenInfo(token as Token).displayName}
                        </Typography>
                    )}
                </Box>
            </GroModalHeader>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',

                    justifyContent: 'flex-start',
                    marginBottom: '130px',
                    marginTop: '94px',
                    position: 'relative',
                }}
            >
                <Box sx={{ marginBottom: '44px' }}>
                    <GroPendingSpinnerIcon height={72} width={72} />
                </Box>

                <Typography css={styles.loadingText} variant="body2">
                    Loading transaction...
                </Typography>
            </Box>
        </Box>
    );
}
