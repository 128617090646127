import { gql } from '@apollo/client';

export const GET_BALANCER_POOL_INFO = gql`
    query GetBalancerPoolInfo {
        pools(
            where: { id: "0x702605f43471183158938c1a3e5f5a359d7b31ba00020000000000000000009f" }
        ) {
            id
            address
            poolType
            factory
            name
            symbol
            swapFee
            swapsCount
            totalLiquidity
            totalShares
            totalSwapVolume
            totalSwapFee
            createTime
        }
        poolShares(
            where: {
                poolId: "0x702605f43471183158938c1a3e5f5a359d7b31ba00020000000000000000009f"
                userAddress: "0x2E32bAd45a1C29c1EA27cf4dD588DF9e68ED376C"
            }
        ) {
            balance
            userAddress {
                id
            }
        }
    }
`;
