import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectLastTransaction } from '../../store/transactions.selectors';
import { TransactionStatus } from '../../store/transactions.store';
import { ConfirmationSteps } from './ConfirmationSteps';
import { LoadingTransactionSteps } from './LoadingModal';

type TpConfirmationModal = {
    isOpen: boolean;
    onBack: () => void;
    onClose: () => void;
};

export function ConfirmationModal({
    isOpen,
    onBack,
    onClose,
}: TpConfirmationModal): React.ReactElement | null {
    const transaction = useAppSelector(selectLastTransaction);
    const direction = transaction?.direction;
    const token = transaction?.token;

    return (
        (isOpen &&
            (transaction && transaction.status === TransactionStatus.ready ? (
                <ConfirmationSteps tid={transaction.id} onBack={onBack} onClose={onClose} />
            ) : (
                <LoadingTransactionSteps direction={direction} token={token} onClose={onClose} />
            ))) ||
        null
    );
}
