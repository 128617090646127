/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { depositUSDC, redeemDura, withdrawUSDC } from '../../../../lib/web3/redeemAlloy';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { updateGROValuesThunk } from '../../../rewards/store/thunks/updateGROValuesThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import {
    selectStableTokenBalance,
    selectWalletAccount,
} from '../../../wallet/store/wallet.selectors';
import { updateRedeemValuesThunk } from './updateRedeemValuesThunk';

export const executeRedeemAlloyTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());

        const transaction = selectCurrentSessionTransaction(tid)(getState());

        const mmTransaction = transaction.queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const sourceTokenAmount =
            removeTokenDecimals(
                TOKENS.GRO,
                Object.values(mmTransaction.meta.from)[0] as BigNumber,
            ) || new BigNumber(0);

        let method: NonPayableTransactionObject<any>;

        if (transaction.direction === ExchangeDirection.redeemDura) {
            method = redeemDura(sourceTokenAmount);
        } else if (transaction.direction === ExchangeDirection.withdrawUsdc) {
            method = withdrawUSDC(sourceTokenAmount);
        } else {
            const groBalance = selectStableTokenBalance(TOKENS.GRO)(getState());
            const balanceWithDecimals =
                removeTokenDecimals(TOKENS.GRO, groBalance) || new BigNumber(0);
            method = depositUSDC(
                sourceTokenAmount.isGreaterThan(balanceWithDecimals)
                    ? balanceWithDecimals
                    : sourceTokenAmount,
            );
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
            void dispatch(updateRedeemValuesThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
