/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { VaultAvaxNames } from '../../constants';
import { web3Instance } from '../../modules/app/services';
import { getVaultAdaptor } from '../contract-info';
import { VaultAdaptor } from './types/vaultAdaptor';

export function vaultAdaptorContract(token: VaultAvaxNames): VaultAdaptor {
    const vaultAdaptorInfo = getVaultAdaptor(token);
    const { Contract } = web3Instance().eth;
    return new Contract(vaultAdaptorInfo.abi, vaultAdaptorInfo.address) as any as VaultAdaptor;
}
