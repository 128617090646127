/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useDispatch } from 'react-redux';
import { GroIcon, GroInfoIcon } from '../../../components';
import { GroModalHeader, GroModalTxn } from '../../../components/GroModal';
import { ToolTipText } from '../../../components/typography/ToolTipText';
import { getTokenInfo } from '../../../lib/contract-info';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { formatAsCurrency, formatNumber } from '../../utils/currency.helpers';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../assets/unlock.svg';
import { getLabsToken } from '../store/labs.helper';
import { setLabsTransaction, setOpenAllowanceModal } from '../store/labs.reducer';
import {
    selectClaimableAllowance,
    selectGROAvailable,
    selectGRORequired,
    selectIsAllowanceClaimable,
    selectIsLabsLoading,
    selectLabsToken,
    selectOpenAllowanceModal,
    selectSnapshotDate,
    selectTotalClaimableAllowance,
} from '../store/labs.selectors';

export function GroAllowance(): React.ReactElement {
    const theme = useTheme();
    const dispatch = useDispatch();
    const loading = useAppSelector(selectIsLabsLoading);
    const name = useAppSelector(selectLabsToken);
    const token = getLabsToken(name);
    const canClaim = useAppSelector(selectIsAllowanceClaimable(name));
    // const canClaim = false;
    const availableGRO = useAppSelector(selectGROAvailable);
    const requiredGRO = useAppSelector(selectGRORequired);
    const isOpen = useAppSelector(selectOpenAllowanceModal);
    const claimableAllowance = useAppSelector(selectClaimableAllowance);
    const date = useAppSelector(selectSnapshotDate);
    const refAnimationInstance = useRef<any>(null);
    const { displayName } = getTokenInfo(token);
    const [startTxn, setStartTxn] = useState(false);

    const totalclaimableAllowance = useAppSelector(selectTotalClaimableAllowance(name));

    const getInstance = useCallback((instance: any) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio: any, opts: any) => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current({
                ...opts,
                colors: ['#fff', '#CD94EB', '#7C588F'],
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio),
            });
        }
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        makeShot(0.2, {
            spread: 60,
        });

        makeShot(0.35, {
            decay: 0.91,
            scalar: 0.8,
            spread: 100,
        });

        makeShot(0.1, {
            decay: 0.92,
            scalar: 1.2,
            spread: 120,
            startVelocity: 25,
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }, [makeShot]);

    function onClose(): void {
        setStartTxn(false);
        dispatch(setOpenAllowanceModal(false));
        dispatch(setLabsTransaction(ExchangeDirection.investAvax));
    }

    const styles = {
        allowanceWrapper: css`
            background: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
        `,
        button: css`
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        canvasStyles: css`
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            pointer-events: none;
        `,
        groIcon: css`
            width: 11px;
            margin-right: ${theme.spacing(0.25)};
        `,
        iconWrapper: css`
            background: rgba(255, 255, 255, 0.15);
            border-radius: 4px;
            height: 32px;
            min-width: 32px;
        `,
        infoWrapper: css`
            border-radius: 8px;
            border: 1px solid ${theme.palette.gro.light};
            background: linear-gradient(
                180deg,
                rgba(157, 59, 232, 0.3) 0%,
                rgba(101, 28, 156, 0.3) 100%
            );
        `,
        lockIcon: css`
            margin-right: ${theme.spacing(loading ? 10 : 9)};
            vertical-align: middle;
            display: inline;
        `,
    };

    function onRequest(): void {
        dispatch(resetExchange());
        dispatch(setExchangeDirection(ExchangeDirection.claimAllowance));
        dispatch(setGrwthToken(token));
        dispatch(
            addTokenLhs({
                [token]: claimableAllowance,
            }),
        );
        dispatch(
            addTokenRhs({
                [token]: claimableAllowance,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    useEffect(() => {
        if (isOpen && canClaim) {
            setTimeout(() => {
                fire();
            }, 200);
        }
    }, [isOpen, canClaim, fire]);

    const icon = canClaim ? <CancelOutlinedIcon /> : <CheckCircleOutlineIcon />;

    return (
        <GroModalTxn isOpen={isOpen} width="432px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={4} px={3}>
                    <GroModalHeader allowBack onBack={onClose} onClose={onClose}>
                        <Typography variant="h1">Labs allowance</Typography>
                    </GroModalHeader>
                    <Box mt={1}>
                        <Typography color="text.secondary" variant="body2">
                            {displayName} Leveraged Yield v1.8
                        </Typography>
                    </Box>

                    <Box css={styles.infoWrapper} mt={2.5} p={2}>
                        <Typography variant="body2">Unlock your lab allowance</Typography>
                        {!canClaim && (
                            <Box display="flex" mt={2}>
                                <Box
                                    alignItems="center"
                                    css={styles.iconWrapper}
                                    display="flex"
                                    justifyContent="center"
                                    mr={1.5}
                                >
                                    {loading ? (
                                        <CircularProgress color="secondary" size={20} />
                                    ) : (
                                        <InfoOutlinedIcon />
                                    )}
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Typography
                                        color={loading ? 'text.tertiary' : 'text.primary'}
                                        variant="h3"
                                    >
                                        You are not eligible
                                    </Typography>
                                    <Typography color="text.tertiary" variant="nav">
                                        Allowance increase eligibility
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        {!canClaim ? (
                            <Box css={styles.allowanceWrapper} my={2} px={1.5} py={1}>
                                {' '}
                                <Typography variant="nav">
                                    You’ll need to have a balance of{' '}
                                </Typography>
                                <Tooltip
                                    disableInteractive
                                    title={
                                        <Box>
                                            <ToolTipText>
                                                Combined GRO is the sum of your GRO balances on the
                                                Ethereum mainnet at the time an allowance snapshot
                                                is taken.
                                            </ToolTipText>
                                            <br />
                                            <ToolTipText>Combined GRO includes:</ToolTipText>
                                            <ToolTipText>- GRO in your wallet</ToolTipText>
                                            <ToolTipText>- Your staked GRO</ToolTipText>
                                            <ToolTipText>- Your locked GRO</ToolTipText>
                                            <ToolTipText>- Your unlocked GRO</ToolTipText>
                                        </Box>
                                    }
                                >
                                    <span
                                        css={css`
                                            color: #deb8fc;
                                            margin: 0;
                                            font-size: 0.875rem;
                                            font-weight: 400;
                                            letter-spacing: 0.5px;
                                            line-height: 1.1875;
                                        `}
                                    >
                                        500 Combined GRO
                                        <GroInfoIcon
                                            css={css`
                                                margin-left: 4px;
                                                margin-bottom: -2px;
                                            `}
                                            height={14}
                                            stroke="#DEB8FC"
                                            width={14}
                                        />{' '}
                                    </span>
                                </Tooltip>
                                <Typography variant="nav">
                                    , or more, on Ethereum Mainnet at the next snapshot to unlock a
                                    higher allowance.
                                </Typography>
                                <Box display="flex" justifyContent="space-between" mt={1.5}>
                                    <Typography variant="nav">Your combined balance:</Typography>
                                    <Tooltip
                                        disableInteractive
                                        title={
                                            <Box>
                                                <ToolTipText>
                                                    This is your current combined GRO balance,
                                                    including:
                                                </ToolTipText>
                                                <ToolTipText>- GRO in your wallet</ToolTipText>
                                                <ToolTipText>- Your staked GRO</ToolTipText>
                                                <ToolTipText>- Your locked GRO</ToolTipText>
                                                <ToolTipText>- Your unlocked GRO</ToolTipText>
                                            </Box>
                                        }
                                    >
                                        <Typography variant="nav">
                                            <GroIcon css={styles.groIcon} fill="currentColor" />
                                            {formatNumber(availableGRO, 0)}
                                            <GroInfoIcon
                                                css={css`
                                                    margin-left: 4px;
                                                    margin-bottom: -2px;
                                                `}
                                                height={14}
                                                stroke={theme.palette.text.tertiary}
                                                width={14}
                                            />
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={0.5}>
                                    <Typography variant="nav">Required balance:</Typography>
                                    <Tooltip
                                        disableInteractive
                                        title={
                                            <Box>
                                                <ToolTipText>
                                                    This is the amount of combined GRO you need to
                                                    have at the time of the last snapshot to unlock
                                                    the allowance.
                                                </ToolTipText>
                                            </Box>
                                        }
                                    >
                                        <Typography variant="nav">
                                            <GroIcon css={styles.groIcon} fill="currentColor" />
                                            {formatNumber(requiredGRO, 0)}
                                            <GroInfoIcon
                                                css={css`
                                                    margin-left: 4px;
                                                    margin-bottom: -2px;
                                                `}
                                                height={14}
                                                stroke={theme.palette.text.tertiary}
                                                width={14}
                                            />
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={0.5}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="nav">
                                            Last snapshot was taken:
                                        </Typography>
                                        <Typography
                                            color="white"
                                            css={css`
                                                opacity: 0.7;
                                            `}
                                            fontSize="12px"
                                            variant="nav"
                                        >
                                            (Updated frequently)
                                        </Typography>
                                    </Box>
                                    <Typography variant="nav">{date}</Typography>
                                </Box>
                                <Box display="flex" mt={2}>
                                    <a
                                        css={css`
                                            text-decoration: none;
                                        `}
                                        href="https://app.uniswap.org/#/swap?outputCurrency=0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        <Typography color="text.primary" variant="nav">
                                            Get more GRO DAO tokens{' '}
                                            <OpenInNewRoundedIcon
                                                css={css`
                                                    font-size: 14px;
                                                    margin-bottom: -2px;
                                                `}
                                            />
                                        </Typography>
                                    </a>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" my={2}>
                                <Box
                                    alignItems="center"
                                    css={styles.iconWrapper}
                                    display="flex"
                                    justifyContent="center"
                                    mr={1.5}
                                >
                                    {loading ? (
                                        <CircularProgress color="secondary" size={20} />
                                    ) : (
                                        icon
                                    )}
                                </Box>

                                <Box display="flex" flexDirection="column">
                                    <Typography
                                        color={
                                            loading || !canClaim ? 'text.tertiary' : 'text.primary'
                                        }
                                        variant="h3"
                                    >
                                        up to{' '}
                                        {loading
                                            ? '--'
                                            : formatAsCurrency(totalclaimableAllowance, 0)}
                                    </Typography>
                                    <Tooltip
                                        disableInteractive
                                        title={
                                            <ToolTipText>
                                                Your allowance is the maximum amount you can
                                                deposit into a strategy.
                                            </ToolTipText>
                                        }
                                    >
                                        <Typography color="text.tertiary" variant="nav">
                                            Your allowance{' '}
                                            <GroInfoIcon
                                                css={css`
                                                    margin-left: 4px;
                                                    margin-bottom: -2px;
                                                `}
                                                height={14}
                                                stroke={theme.palette.text.tertiary}
                                                width={14}
                                            />
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </Box>
                        )}
                        <ReactCanvasConfetti css={styles.canvasStyles} refConfetti={getInstance} />
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.button}
                            disabled={loading || !canClaim}
                            sx={{ justifyContent: 'flex-start', padding: '10px 13px' }}
                            variant="contained"
                            onClick={onRequest}
                        >
                            {loading || !canClaim ? (
                                <LockIcon css={styles.lockIcon} />
                            ) : (
                                <UnlockIcon css={styles.lockIcon} />
                            )}
                            Claim allowance
                        </Button>
                    </Box>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModalTxn>
    );
}
