import React, { useEffect } from 'react';
import { AlertProps as MuiAlertProps } from '@mui/material';
import { useHistory } from 'react-router';
import { PpWC } from '../../utilTypes';

type TpAlert = {
    AlertProps?: Omit<MuiAlertProps, 'onClose'>;
    Content: React.ReactNode;
    closeAll?: boolean;
    dismissable?: boolean;
    key: string;
    open: boolean;
    persistRouting?: boolean;
};

export type TpPushAlert = Omit<TpAlert, 'open'>;

/**
 * In this AlertPushContext context, we just provide the pushAlert function with pushes a alert on to the array.
 * This separation helps prevent unnecessary re-renders
 */
export type CxAlertPush = (params: TpPushAlert) => void;
export const AlertPushContext = React.createContext<undefined | CxAlertPush>(undefined);

/**
 * In this CxAlerts context, we provide all the notificatons and functions to remove / close them
 * This separation helps prevent unnecessary re-renders
 */
export type CxAlerts = {
    alerts: Array<TpAlert>;
    closeAlert: (key: string) => void;
    removeAlert: (key: string) => void;
};
export const AlertsContext = React.createContext<undefined | CxAlerts>(undefined);

/**
 * Note that we always need to create a new array when updating the state, otherwise no re-renders!
 */
export function AlertsProvider({ children }: PpWC): React.ReactElement {
    const [alerts, setAlerts] = React.useState<Array<TpAlert>>([]);

    /**
     * Add new alert to the alerts array and open immediately
     */
    const pushAlert = React.useCallback(
        ({
            AlertProps,
            closeAll = false,
            Content,
            dismissable = true,
            key,
            persistRouting = false,
        }: TpPushAlert): void => {
            setAlerts((prevAlerts) =>
                // Include the new alert, but filter by unique
                prevAlerts
                    .filter((alert) => alert.key !== key)
                    .concat([
                        {
                            AlertProps,
                            closeAll,
                            Content,
                            dismissable,
                            key,
                            open: true,
                            persistRouting,
                        },
                    ]),
            );
        },
        [],
    );

    /**
     * Close all Alerts on route changes, unless they have requested to be persisted
     */
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
            setAlerts((ds) => ds.map((d) => ({ ...d, open: d.persistRouting ? d.open : false })));
        });

        return (): void => unlisten();
    }, [history]);

    const memoedFunctions = React.useMemo(() => {
        /**
         * Create a new alerts array with open set to false on the target alert.
         * Don't remove the alert from the array here b/c we want the tranistions to complete.
         */
        function closeAlert(key: string): void {
            const targetAlert = alerts.find((alert) => alert.key === key);

            if (!targetAlert) {
                return;
            }

            const newAlerts = alerts.map((alert) => {
                /**
                 * If the target alert has requested to closeAll, set open to false in all the alerts
                 */
                if (targetAlert.closeAll) {
                    return { ...alert, open: false };
                }
                /**
                 * Otherwise, return the alert unchanged, except the target
                 */
                if (alert.key !== key) {
                    return alert;
                }
                return { ...targetAlert, open: false };
            });
            setAlerts(newAlerts);
        }

        /**
         * Called when the transitions have finished, we can safely remove from the array
         */
        function removeAlert(key: string): void {
            /**
             * If alert has request to closeAll, remove all the alerts
             */
            const targetAlert = alerts.find((alert) => alert.key === key);

            if (!targetAlert) {
                return;
            }

            if (targetAlert.closeAll) {
                setAlerts([]);
            }
            setAlerts(alerts.filter((alert) => alert.key !== key));
        }
        return {
            alerts,
            closeAlert,
            removeAlert,
        };
    }, [alerts]);

    return (
        <AlertPushContext.Provider value={pushAlert}>
            <AlertsContext.Provider value={memoedFunctions}>{children}</AlertsContext.Provider>
        </AlertPushContext.Provider>
    );
}
