import React from 'react';
import { TransactionCard } from '../../../../components';
import { getTokenInfo } from '../../../../lib/contract-info';
import { useAppSelector } from '../../../app/hooks';
import { getLabsToken } from '../../store/labs.helper';
import {
    selectLabsToken,
    selectStableAmountToWithdraw,
    selectWithdrawToDollars,
} from '../../store/labs.selectors';

export function ToCard(): React.ReactElement {
    const tokenName = useAppSelector(selectLabsToken);

    const token = getLabsToken(tokenName);
    const stableCoin = useAppSelector(selectStableAmountToWithdraw);
    const withdrawAmounToDollars = useAppSelector(selectWithdrawToDollars);
    const { displayName } = getTokenInfo(token);

    return (
        <TransactionCard
            selected
            showIcon
            amount={stableCoin}
            balance={stableCoin}
            balanceToDollars={withdrawAmounToDollars}
            text={displayName}
            token={token}
        />
    );
}
