import BigNumber from 'bignumber.js';
import { Status, StatusState } from '../../app/app.types';
import { AirdropItem } from '../airdrop.types';

export type GROState = {
    airdrop?: AirdropItem;
    canClaim: boolean;
    claimDelay: number;
    claimToWallet: boolean;
    claimedUST: BigNumber;
    endClaimDate: number;
    exitToWallet: number;
    hasClaimedPwrd: boolean;
    hasClaimedUst: boolean;
    isUstClaim: boolean;
    lockExpectation: BigNumber;
    lockMore: {
        extend: BigNumber;
        revest: BigNumber;
    };
    maxLockedPeriod: number;
    openClaim: boolean;
    openLockMoreGro: boolean;
    rewardsPid: string[];
    startClaimDate: number;
    totalLockedGRO: number;
    totalVestingBonus: number;
    unvestedGRO: number;
    userClaim: number;
    ustLocked: BigNumber;
    ustVested: BigNumber;
    vestedGRO: number;
    vestingBonus: number;
    vestingEndDateUST: number;
    vestingPercentage: number;
} & StatusState;

export const initialGROState: GROState = {
    canClaim: false,
    claimDelay: 0,
    claimedUST: new BigNumber(0),
    claimToWallet: true,
    endClaimDate: 0,
    error: undefined,
    exitToWallet: 0,
    hasClaimedPwrd: false,
    hasClaimedUst: false,
    isUstClaim: false,
    lockExpectation: new BigNumber(0),
    lockMore: {
        extend: new BigNumber(0),
        revest: new BigNumber(0),
    },
    maxLockedPeriod: 0,
    openClaim: false,
    openLockMoreGro: false,
    rewardsPid: [],
    startClaimDate: 0,
    status: Status.idle,
    totalLockedGRO: 0,
    totalVestingBonus: 0,
    unvestedGRO: 0,
    userClaim: 0,
    ustLocked: new BigNumber(0),
    ustVested: new BigNumber(0),
    vestedGRO: 0,
    vestingBonus: 0,
    vestingEndDateUST: 0,
    vestingPercentage: 0,
};
