/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { StableTokenAvax, VaultAvaxNames } from '../../../constants';
import { addTokenDecimals } from '../../../lib/web3/web3.helpers';
import { ExchangeDirection, Status } from '../../app/app.types';
import { initialLabsState } from './labs.store';

export type TpSetTokenValues = {
    assets: string;
    credit: string;
    currentAllowance: string;
    debt: string;
    depositLimit: string;
    deposited: string;
    name: VaultAvaxNames;
    price: string;
    token: StableTokenAvax;
    vaultBalance: string;
};

const labsSlice = createSlice({
    initialState: initialLabsState,
    name: 'labs',
    reducers: {
        resetLabs: (state) => {
            state = { ...initialLabsState };
            return state;
        },
        setDeprecatedScreen: (state, { payload }: PayloadAction<boolean>) => {
            state.deprecatedScreen = payload;
        },
        setLabsStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
        setLabsToken: (state, { payload }: PayloadAction<VaultAvaxNames>) => {
            state.token = payload;
        },
        setLabsTransaction: (state, { payload }: PayloadAction<ExchangeDirection | undefined>) => {
            state.direction = payload;
        },
        setOpenAllowanceModal: (state, { payload }: PayloadAction<boolean>) => {
            state.openAllowance = payload;
        },
        setOpenIncreasedAllowanceModal: (state, { payload }: PayloadAction<boolean>) => {
            state.openIncreasedAllowance = payload;
        },
        setTokenValues: (state, { payload }: PayloadAction<TpSetTokenValues>) => {
            state[payload.name] = {
                assets: addTokenDecimals(payload.token, new BigNumber(payload.assets)).toNumber(),
                credit: addTokenDecimals(payload.token, new BigNumber(payload.credit)).toNumber(),
                currentAllowance: addTokenDecimals(
                    payload.token,
                    new BigNumber(payload.currentAllowance),
                ).toNumber(),
                debt: addTokenDecimals(payload.token, new BigNumber(payload.debt)).toNumber(),
                deposited: addTokenDecimals(
                    payload.token,
                    new BigNumber(payload.deposited),
                ).toNumber(),
                depositLimit: addTokenDecimals(
                    payload.token,
                    new BigNumber(payload.depositLimit),
                ).toNumber(),
                pricePerShare: addTokenDecimals(
                    payload.token,
                    new BigNumber(payload.price),
                ).toNumber(),
                vaultBalance: addTokenDecimals(
                    payload.token,
                    new BigNumber(payload.vaultBalance),
                ).toNumber(),
            };
        },
        setWithdrawAmount: (state, { payload }: PayloadAction<BigNumber>) => {
            state.amount = payload;
        },
    },
});

export const {
    resetLabs,
    setDeprecatedScreen,
    setLabsStatus,
    setLabsToken,
    setLabsTransaction,
    setOpenAllowanceModal,
    setOpenIncreasedAllowanceModal,
    setTokenValues,
    setWithdrawAmount,
} = labsSlice.actions;

export const labsReducer = labsSlice.reducer;
