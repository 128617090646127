/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Collapse, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { StableTokenMainnet, Token, TOKENS_DEPOSIT } from '../../../../../constants';
import { useAppSelector } from '../../../../app/hooks';
import {
    selectWalletLoading,
    selectWalletTokens,
} from '../../../../wallet/store/wallet.selectors';
import { TokensExchange } from '../../../exchange.types';
import { updateTokenLhsInvest } from '../../../store/exchange.reducers';
import {
    selectExchangeLimits,
    selectExhangeVolatilityStatus,
    selectIsExchangeLoading,
    selectStablesInOrder,
} from '../../../store/exchange.selectors';
import { LoadingCard } from '../LoadingCard';
import { TokenCardWrapper } from '../TokenCardWrapper';

export function ExchangeDeposit(): React.ReactElement {
    const dispatch = useDispatch();
    const walletTokens = useAppSelector(selectWalletTokens);
    const stables = useAppSelector(selectStablesInOrder) as StableTokenMainnet[];
    const [tokens, setTokens] = useState<TokensExchange[]>([]);
    // const disableAdd = useMemo(() => STABLE_TOKENS.length === tokens.length, [tokens]);
    const exchangeLoading = useAppSelector(selectIsExchangeLoading);
    const walletLoading = useAppSelector(selectWalletLoading);
    const limits = useAppSelector(selectExchangeLimits);
    const volatile = useAppSelector(selectExhangeVolatilityStatus);

    const tokensNotAdded = useMemo(
        () => TOKENS_DEPOSIT.filter((token) => !tokens.includes(token)),
        [tokens],
    );

    const theme = useTheme();
    const styles = {
        wrapper: css`
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background: ${volatile
                ? theme.palette.info.dark
                : limits.limitReached
                ? theme.palette.info.superDark
                : 'transparent'};
        `,
    };

    function removeToken(token: Token): void {
        const tokensToSet = tokens.filter((elem) => elem !== token);
        setTokens(tokensToSet);
    }

    const onSelectToken =
        (index: number) =>
        (tk: TokensExchange): void => {
            const newTokenArr = tokens.map((elem, i) => (i === index ? tk : elem));
            dispatch(
                updateTokenLhsInvest({
                    token: tk,
                    value: walletTokens[tk] as BigNumber,
                }),
            );
            setTokens(newTokenArr);
        };

    useEffect(() => {
        if (!exchangeLoading && !walletLoading) {
            dispatch(
                updateTokenLhsInvest({
                    token: stables[0],
                    value: walletTokens[stables[0]] as BigNumber,
                }),
            );
            // onSelectToken(0)(stables[0])
            setTokens([stables[0]]); // this is so we only set one token to deposit at a time, set to setTokens(stables) if we want to allow several
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeLoading, walletLoading]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography color="text.tertiary">From</Typography>
            </Box>
            <TransitionGroup>
                {!exchangeLoading &&
                    tokens.map((token, index, arr) => (
                        <Collapse key={token}>
                            <Box
                                css={index === arr.length - 1 ? styles.wrapper : undefined}
                                mb={index === arr.length - 1 ? 0 : 1}
                            >
                                <TokenCardWrapper
                                    removeToken={removeToken}
                                    token={token}
                                    tokensNotAdded={tokensNotAdded}
                                    onSelectToken={onSelectToken(index)}
                                />
                            </Box>
                        </Collapse>
                    ))}
                {exchangeLoading && (
                    <Collapse>
                        <Box>
                            <LoadingCard />
                        </Box>
                    </Collapse>
                )}
            </TransitionGroup>
        </Box>
    );
}
