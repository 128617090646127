/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { TpGroStatsMc, TpUserStatsMc } from '../stats.types';
import { initialStatsState } from './stats.store';

const statsSlice = createSlice({
    initialState: initialStatsState,
    name: 'stats',
    reducers: {
        loadGroStatsMc: (state) => {
            state.loadingGroMcStats = true;
        },
        loadUserStatsMc: (state) => {
            state.loadingUserStatsMc = true;
        },
        resetStats: (state) => {
            state = { ...initialStatsState };
            return state;
        },
        setGroStatsError: (state, { payload }: PayloadAction<boolean>) => {
            state.groStatsError = payload;
        },
        setGroStatsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loadingGroMcStats = payload;
        },
        setGroStatsMc: (state, { payload }: PayloadAction<TpGroStatsMc>) => {
            state.loadingGroMcStats = false;
            state.groStatsMc = payload;
        },
        setGroStatsWarning: (state, { payload }: PayloadAction<boolean>) => {
            state.groStatsWarning = payload;
        },
        setLabsPersonalBalance: (state, { payload }: PayloadAction<BigNumber>) => {
            state.labsBalance = payload;
        },
        setUserStatsError: (state, { payload }: PayloadAction<boolean>) => {
            state.userStatsError = payload;
        },
        setUserStatsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loadingUserStatsMc = payload;
        },
        setUserStatsMc: (state, { payload }: PayloadAction<TpUserStatsMc>) => {
            state.loadingUserStatsMc = false;
            state.userStatsMc = payload;
        },
        setUserStatsWarning: (state, { payload }: PayloadAction<boolean>) => {
            state.userStatsWarning = payload;
        },
    },
});

export const {
    loadGroStatsMc,
    loadUserStatsMc,
    resetStats,
    setGroStatsError,
    setGroStatsLoading,
    setGroStatsMc,
    setGroStatsWarning,
    setLabsPersonalBalance,
    setUserStatsError,
    setUserStatsLoading,
    setUserStatsMc,
    setUserStatsWarning,
} = statsSlice.actions;

export const statsReducer = statsSlice.reducer;
