/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */

import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../constants';
import { useAppSelector } from '../../../modules/app/hooks';
import { selectTotalLockedAmount } from '../../../modules/rewards/store/rewards.selectors';
import {
    selectGroStatsError,
    selectTokenPricesInUsd,
} from '../../../modules/stats/store/stats.selectors';
import { formatAsCurrency } from '../../../modules/utils/currency.helpers';
import { GroIcon, GvtIcon, PwrdIcon } from '../../icons';
import { TextOrSkeleton } from '../../utils';
import { CardButtons } from './CardButtons';
import { CardDetails } from './CardDetails';
import { FailedAPY } from './FailedApy';
import { GroRewards } from './GroRewards';
import { ProductApy } from './ProductApy';
import { ProductInfo } from './ProductInfo';

type PpProductCard = {
    apr?: BigNumber;
    apy?: BigNumber;
    apyLoading?: boolean;
    balance: BigNumber;
    balanceLoading?: boolean;
    highestPoolAPY?: number;
    netRewards: BigNumber;
    returns: BigNumber;
    rewards?: BigNumber;
    token: TOKENS.GRO | TOKENS.PWRD | TOKENS.GVT;
    tvl?: BigNumber;
    unstaked?: BigNumber;
};

const bigNum = new BigNumber(0);

export function ProductCard({
    apr = bigNum,
    apy = bigNum,
    apyLoading = false,
    balance,
    balanceLoading = false,
    highestPoolAPY,
    netRewards,
    returns,
    rewards = bigNum,
    token,
    tvl = bigNum,
    unstaked = bigNum,
}: PpProductCard): React.ReactElement {
    const theme = useTheme();
    const [isPools, setIsPools] = useState(true);

    const totalLocked = useAppSelector(selectTotalLockedAmount);
    const prices = useAppSelector(selectTokenPricesInUsd);
    const groStatsError = useAppSelector(selectGroStatsError);

    const icon = {
        [TOKENS.GVT]: <GvtIcon fill={theme.palette.gvt.faded} width="24px" />,
        [TOKENS.PWRD]: <PwrdIcon fill={theme.palette.pwrd.faded} width="24px" />,
        [TOKENS.GRO]: <GroIcon fill={theme.palette.gro.faded} width="24px" />,
    };

    const styles = {
        active: css`
            background: rgba(196, 118, 255, 0.1);
            border-radius: 8px;
        `,
        bottomWrapper: css`
            border-radius: 16px;
            background: ${theme.palette.common.black};
        `,
        card: css`
            ${theme.cssMixins.colStretch};
            border-color: ${token === TOKENS.GRO
                ? 'rgba(222, 184, 252, 0.2);'
                : token === TOKENS.PWRD
                ? 'rgba(138, 213, 255, 0.2);'
                : 'rgba(254, 174, 168, 0.2);'};
            min-width: 231px;

            background: ${token === TOKENS.GRO
                ? 'linear-gradient(0deg, rgba(180, 82, 255, 0.1), rgba(180, 82, 255, 0.1));'
                : token === TOKENS.PWRD
                ? 'linear-gradient(0deg, rgba(150, 205, 255, 0.08), rgba(150, 205, 255, 0.08));'
                : 'linear-gradient(0deg, rgba(250, 119, 121, 0.12), rgba(250, 119, 121, 0.12));'};
            border-radius: 16px;
            justify-content: space-between;
            position: relative;
            min-height: 450px;
            flex: 1;
        `,
        claimBtn: css`
            width: 80px;
            height: 24px;
        `,
        stakeNotification: css`
            background: ${theme.palette.grey[800]};
            border-radius: 16px;
            position: absolute;
            width: 310px;
        `,
        toggle: css`
            border: 1px solid rgba(196, 118, 255, 0.1);
            border-radius: 8px;
            cursor: pointer;
        `,
        tvlTitle: css`
            font-weight: 500;
        `,
    };

    const formattedTvl = useMemo(() => {
        const lockedAmountUsd = totalLocked.multipliedBy(prices?.gro);
        const finalTVL = isPools ? tvl : lockedAmountUsd;
        return finalTVL.isGreaterThanOrEqualTo(1000000)
            ? `${formatAsCurrency(finalTVL.dividedBy(1000000), 2)}mm`
            : `${formatAsCurrency(finalTVL, 0)}`;
    }, [totalLocked, prices, isPools, tvl]);

    return (
        <Card component="article" css={styles.card} variant="outlined">
            <Box p={2} pb={token === TOKENS.GRO ? 1 : 2}>
                <Box alignItems="center" display="flex" justifyContent="space-between" mb={2.5}>
                    {icon[token]}
                    <Box alignItems="center" display="flex">
                        <Typography color={theme.palette[token].faded} mr={1} variant="h3">
                            {token === TOKENS.GRO && isPools ? 'Staked' : null}
                            {token === TOKENS.GRO && !isPools && 'Vesting GRO '}
                            TVL
                        </Typography>
                        <Typography color={theme.palette[token].faded} variant="h3">
                            <TextOrSkeleton loading={apyLoading}>{formattedTvl}</TextOrSkeleton>
                        </Typography>
                    </Box>
                </Box>
                {groStatsError ? (
                    <FailedAPY token={token} />
                ) : (
                    <ProductApy
                        apy={apy}
                        apyLoading={apyLoading}
                        balance={balance}
                        highestPoolAPY={highestPoolAPY}
                        isPools={isPools}
                        token={token}
                    />
                )}
                {token !== TOKENS.GRO && <GroRewards apy={apr} token={token} />}

                <ProductInfo isPools={isPools} token={token} />
                {token === TOKENS.GRO && (
                    <Box css={styles.toggle} display="flex" mt={2.25}>
                        <Box
                            css={isPools ? styles.active : undefined}
                            display="flex"
                            flex="1"
                            justifyContent="center"
                            py={0.5}
                            onClick={(): void => setIsPools(true)}
                        >
                            <Typography
                                color={isPools ? 'text.primary' : 'gro.faded'}
                                variant="h3"
                            >
                                Pools
                            </Typography>
                        </Box>
                        <Box
                            css={!isPools ? styles.active : undefined}
                            display="flex"
                            flex="1"
                            justifyContent="center"
                            py={0.5}
                            onClick={(): void => setIsPools(false)}
                        >
                            <Typography
                                color={!isPools ? 'text.primary' : 'gro.faded'}
                                variant="h3"
                            >
                                GRO Vesting
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box css={styles.bottomWrapper} pt={2.5} px={2} py={2}>
                <CardDetails
                    balance={balance}
                    balanceLoading={balanceLoading}
                    isPools={isPools}
                    netRewards={netRewards}
                    returns={returns}
                    rewards={rewards}
                    token={token}
                    unstaked={unstaked}
                />
                <CardButtons balance={balance} isPools={isPools} token={token} />
            </Box>
        </Card>
    );
}
