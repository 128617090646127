/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Token, TOKENS } from '../../../constants';
import { useAppSelector } from '../../../modules/app/hooks';
import { TokensExchange } from '../../../modules/exchange/exchange.types';
import { selectStablesLoading } from '../../../modules/exchange/store/exchange.selectors';
import { formatAsCurrency, formatNumber } from '../../../modules/utils/currency.helpers';
import { isPoolToken } from '../../../modules/utils/token.helpers';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { TokenIcon } from '../../icons';
import { TextOrSkeleton } from '../../utils';
import { TokenCardInput } from '../TokenCardInput';
import { DropdownSelector } from './DropdownSelector';
import { RemoveCard } from './RemoveCard';
import { SliderSelector } from './SliderSelector';

type TpTransactionCard = {
    amount: BigNumber;
    balance: BigNumber;
    balanceToDollars: BigNumber;
    bottomCard?: boolean;
    hideDollar?: boolean,
    isEditable?: boolean;
    onChange?: (val: BigNumber) => void;
    onClick?: () => void;
    onSelectToken?: (val: TokensExchange) => void;
    onSlide?: (event: Event, value: number | number[]) => void;
    removeToken?: (token: Token) => void;
    selected?: boolean;
    showIcon?: boolean;
    sliderValue?: number;
    subtitle?: string;
    text?: string;
    token?: Token;
    tokensNotAdded?: TokensExchange[];
    unstake?: boolean;
};

export function TransactionCard({
    amount,
    balance,
    balanceToDollars,
    bottomCard,
    hideDollar,
    isEditable,
    onChange = (_val: BigNumber): void => { },
    onClick,
    onSelectToken,
    onSlide,
    removeToken = (_val: Token): void => { },
    selected,
    showIcon,
    sliderValue = 0,
    subtitle,
    text,
    token,
    tokensNotAdded,
    unstake,
}: TpTransactionCard): React.ReactElement {
    const theme = useTheme();
    const formattedAmount = amount.isGreaterThanOrEqualTo(0.001) ? amount : new BigNumber(0);
    const isMax = formattedAmount.isEqualTo(balance || new BigNumber(0));
    const { isMobileDisplay } = useGetSizes(theme);
    const stablesLoading = useAppSelector(selectStablesLoading);

    const styles = {
        disableMax: css`
            background: ${theme.palette.grey[800]};
            color: ${theme.palette.grey[400]};
        `,
        max: css`
            background: ${theme.palette.grey[800]};
            color: ${theme.palette.info.light};
            font-size: ${isMobileDisplay ? '0.75rem' : '14px'};
            max-width: 44px;
            max-height: 20px;
            padding: 4px 8px;
            border-radius: 5px;
            margin-left: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        responsiveFont: css`
            font-size: ${isMobileDisplay ? '0.75rem' : '1rem'};
        `,
        vaultIcon: css`
            color: ${theme.palette.grey[200]};
            margin-right: 2px;
        `,
        wrapper: css`
            background: ${formattedAmount.isZero() || !selected || bottomCard
                ? theme.palette.grey[800]
                : theme.palette.common.black};
            border: 1px solid
                ${formattedAmount.isZero() || !selected || bottomCard
                ? theme.palette.grey[600]
                : theme.palette.grey[200]};
            border-radius: 12px;
            cursor: ${onClick ? 'pointer' : 'cursor'};
        `,
    };

    function handleAmountFractionChange(): void { }

    function onWrapperClick(): void {
        if (onClick) onClick();
    }

    const showCloseBtn = isEditable && tokensNotAdded && tokensNotAdded.length < 2;

    const isPool = isPoolToken(token || '');

    const name = useMemo(() => {
        switch (token) {
            case TOKENS.GVT_SINGLE_SIDED:
                return unstake ? 'Staked Vault tokens ' : 'Vault tokens ';
            case TOKENS.PWRD_SINGLE_SIDED:
                return unstake ? 'Staked PWRD tokens ' : 'PWRD tokens ';
            default:
                return unstake ? 'Staked Pool tokens ' : 'Pool tokens ';
        }
    }, [token, unstake]);

    return (
        <Box
            css={styles.wrapper}
            display="flex"
            justifyContent="space-between"
            onClick={onWrapperClick}
        >
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                pl={2}
                pr={showCloseBtn ? 1 : 2}
                py={1.5}
            >
                <Box display="flex" justifyContent="space-between" mb={0.75}>
                    <Box alignItems="center" display="flex">
                        {[TOKENS.GRO, TOKENS.GRO_SINGLE_SIDED].includes(token || TOKENS.DAI) && !hideDollar && (
                            <TokenIcon
                                css={css`
                                    margin-right: 4px;
                                    min-width: 16px;
                                    min-height: 16px;
                                    width: 16px;
                                    color: ${selected && !formattedAmount.isZero()
                                        ? theme.palette.common.white
                                        : theme.palette.grey[200]};
                                `}
                                fill={
                                    selected && !formattedAmount.isZero()
                                        ? theme.palette.common.white
                                        : theme.palette.grey[200]
                                }
                                token={TOKENS.GRO}
                            />
                        )}
                        <TokenCardInput
                            hidePercentage
                            isActive
                            isFocused
                            reverse
                            amount={formattedAmount}
                            decimals={2}
                            fraction={0}
                            isEditable={isEditable}
                            selected={selected}
                            token={token || TOKENS.GRO}
                            onChange={onChange}
                            onFractionChange={handleAmountFractionChange}
                        />
                    </Box>

                    {text && (
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="flex-end"
                            width="39%"
                        >
                            {showIcon && token && (
                                <TokenIcon
                                    colored
                                    css={css`
                                        margin-right: 4px;
                                        width: 16px;
                                        height: 16px;
                                    `}
                                    token={token}
                                />
                            )}
                            <Typography
                                color={
                                    selected && !formattedAmount.isZero()
                                        ? 'text.primary'
                                        : 'text.tertiary'
                                }
                                textAlign="right"
                                variant={isMobileDisplay ? 'h3' : 'h2'}
                            >
                                {text}
                            </Typography>
                        </Box>
                    )}
                    {tokensNotAdded && onSelectToken && token && (
                        <DropdownSelector
                            token={token}
                            tokensNotAdded={tokensNotAdded}
                            onSelectToken={onSelectToken}
                        />
                    )}
                </Box>
                {!onSlide ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                        mt={!isEditable ? 1 : 0}
                    >
                        <Box alignItems="center" display="flex">
                            <Box alignItems="center" display="flex">
                                {token === TOKENS.GVT && (
                                    <Typography
                                        color="text.tertiary"
                                        css={styles.responsiveFont}
                                        mr={0.5}
                                        variant="body2Small"
                                    >
                                        Vault Tokens
                                    </Typography>
                                )}

                                <Typography
                                    color="text.tertiary"
                                    css={styles.responsiveFont}
                                    variant="body2Small"
                                >
                                    <TextOrSkeleton loading={stablesLoading}>
                                        {isPool && token !== TOKENS.GRO_SINGLE_SIDED && name}
                                        {token === TOKENS.GVT ||
                                            hideDollar ||
                                            (isPool && token !== TOKENS.GRO_SINGLE_SIDED)
                                            ? formatNumber(balanceToDollars)
                                            : formatAsCurrency(balanceToDollars)}
                                    </TextOrSkeleton>
                                </Typography>
                            </Box>
                            {isEditable && (
                                <Box
                                    css={isMax ? [styles.max, styles.disableMax] : styles.max}
                                    onClick={(): void => onChange(balance)}
                                >
                                    <Typography variant="nav">MAX</Typography>
                                </Box>
                            )}
                        </Box>
                        {subtitle && (
                            <Typography
                                color="text.tertiary"
                                css={styles.responsiveFont}
                                variant="body2Small"
                            >
                                {subtitle}
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <SliderSelector sliderValue={sliderValue} onSlide={onSlide} />
                )}
            </Box>
            {showCloseBtn && <RemoveCard removeToken={removeToken} token={token} />}
        </Box>
    );
}
