/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */

import { fetchTvlStats } from '../../../../lib/web3/stats';
import { AppActionThunk } from '../../../app/store';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { setExchangeLimits } from '../exchange.reducers';

export const calculateExchangeLimitsThunk: AppActionThunk<Promise<boolean>> =
    (onChain = false) =>
    async (dispatch, getState) => {
        const groStats = selectGroStatsMc(getState());

        if (onChain) {
            // Fetch GVT and PWRD supply on-chain instead from the gro stats
            const { total, totalGVT, totalPWRD, utilRatio, utilRatioThreshold } =
                await fetchTvlStats();

            dispatch(
                setExchangeLimits({
                    ...groStats.mainnet?.tvl,
                    gvt: totalGVT.toString(),
                    pwrd: totalPWRD.toString(),
                    total: total.toString(),
                    util_ratio: utilRatio.toString(),
                    util_ratio_limit: utilRatioThreshold.toString(),
                }),
            );
        } else {
            dispatch(setExchangeLimits(groStats?.mainnet.tvl));
        }

        return true;
    };
