/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { bouncer } from '../contract-info';
import { Bouncer } from './types/bouncer';

let instance: Bouncer;

export function bouncerContract(): Bouncer {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(bouncer.abi, bouncer.address) as any as Bouncer;
    return instance;
}
