import React from 'react';
import { Box, Slider, Typography } from '@mui/material';

type TpSliderSelector = {
    onSlide: (event: Event, value: number | number[]) => void;
    sliderValue: number;
};

export function SliderSelector({ onSlide, sliderValue }: TpSliderSelector): React.ReactElement {
    return (
        <Box alignItems="center" display="flex" width="100%">
            <Typography mr={0.8} variant="nav">
                0%
            </Typography>
            <Slider
                aria-label="Small"
                color="secondary"
                max={100}
                min={0}
                size="small"
                value={sliderValue}
                onChange={onSlide}
            />
            <Typography ml={0.8} variant="nav">
                {Math.floor(sliderValue)}%
            </Typography>
        </Box>
    );
}
