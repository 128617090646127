/* eslint-disable no-nested-ternary */

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../../modules/app/hooks';
import { selectUserStatsError } from '../../../modules/stats/store/stats.selectors';
import { formatAsCurrency, formatNumber } from '../../../modules/utils/currency.helpers';
import {
    selectIsAvalancheNetwork,
    selectWalletAccount,
} from '../../../modules/wallet/store/wallet.selectors';
import { GroIcon, PwrdIcon } from '../../icons';
import { ToolTipText } from '../../typography/ToolTipText';

export function BalancePill({
    isGro,
    isPwrd,
    loading,
    returns,
    tooltipText = '',
}: {
    isGro?: boolean;
    isPwrd?: boolean;
    loading: boolean;
    returns: BigNumber;
    tooltipText: string;
}): React.ReactElement {
    const theme = useTheme();
    const isAvalanche = useAppSelector(selectIsAvalancheNetwork);
    const wallet = useAppSelector(selectWalletAccount);
    const location = useLocation();
    const userStatsError = useAppSelector(selectUserStatsError);

    const showValue = useMemo(
        () => (!isAvalanche || location.pathname === '/labs') && wallet,
        [location, isAvalanche, wallet],
    );

    const color =
        returns.isEqualTo(0) || !showValue
            ? theme.palette.grey[200]
            : returns.isGreaterThan(0)
            ? theme.palette.success.light
            : theme.palette.error.light;

    const styles = {
        balanceCounter: css`
            background: rgba(255, 255, 255, 0.1);
            border-radius: 20px;
            color: ${color};
        `,
    };

    const returnsFormatted = userStatsError
        ? 'n/a'
        : isGro || isPwrd
        ? formatNumber(returns, 0)
        : formatAsCurrency(returns, returns.isEqualTo(0) ? 0 : 2);

    const pill = (
        <Box
            alignItems="center"
            css={styles.balanceCounter}
            display="flex"
            justifyContent="center"
            ml={1}
            px={0.5}
        >
            {returns.isGreaterThan(0) && showValue && (
                <ArrowDropUpIcon sx={{ fontSize: '16px' }} />
            )}
            {returns.isLessThan(0) && showValue && <ArrowDropDownIcon sx={{ fontSize: '16px' }} />}
            <Typography mr={0.5} variant="nav">
                {isGro && (
                    <GroIcon
                        css={css`
                            margin-right: 3px;
                            margin-bottom: -0.5px;
                        `}
                        fill="currentColor"
                        height="10px"
                        width="10px"
                    />
                )}
                {isPwrd && (
                    <PwrdIcon
                        css={css`
                            margin-right: 3px;
                            margin-bottom: -0.5px;
                        `}
                        fill="currentColor"
                        height="10px"
                        width="10px"
                    />
                )}
                {!showValue ? `${!isGro && !isPwrd ? '$' : ''}--` : returnsFormatted}
            </Typography>
        </Box>
    );

    return loading ? (
        <Box ml={1}>
            <Skeleton height={19} variant="rectangular" width={45} />
        </Box>
    ) : tooltipText ? (
        <Tooltip disableInteractive title={<ToolTipText>{tooltipText}</ToolTipText>}>
            {pill}
        </Tooltip>
    ) : (
        pill
    );
}
