import React from 'react';
import { Box, Typography } from '@mui/material';
import { TextOrSkeleton } from '../../../../components';
import { useAppSelector } from '../../../app/hooks';
import {
    selectPersonalTotalLabsPosition,
    selectTotalBalance,
} from '../../../labs/store/labs.selectors';
import { formatAsCurrency } from '../../../utils/currency.helpers';
import {
    selectIsAVAXNetwork,
    selectIsCorrectNetwork,
    selectWalletAccount,
} from '../../../wallet/store/wallet.selectors';
import {
    selectIsLoadingUserStatsMc,
    selectLabsBalanceOnChain,
    selectUserStatsError,
} from '../../store/stats.selectors';

export function LabsBalancePosition(): React.ReactElement {
    const isCorrectNetwork = useAppSelector(selectIsCorrectNetwork);
    const loading = useAppSelector(selectIsLoadingUserStatsMc);
    const wallet = useAppSelector(selectWalletAccount);
    const balance = useAppSelector(selectTotalBalance);
    const groError = useAppSelector(selectUserStatsError);
    const isAvax = useAppSelector(selectIsAVAXNetwork);
    const labsBalanceOnChain = useAppSelector(selectLabsBalanceOnChain);

    return (
        <Box>
            <Box display="flex">
                <Typography color="text.tertiary" variant="h3">
                    Balance
                </Typography>
            </Box>
            <Box alignItems="center" display="flex" mt={1.5} position="relative">
                <Typography ml={0.5} variant="h3Large">
                    {!groError ? (
                        <TextOrSkeleton loading={false}>
                            {isCorrectNetwork ? formatAsCurrency(balance) : '$--'}
                        </TextOrSkeleton>
                    ) : (
                        <TextOrSkeleton loading={wallet ? loading : false}>
                            {isCorrectNetwork && isAvax
                                ? formatAsCurrency(labsBalanceOnChain)
                                : 'n/a'}
                        </TextOrSkeleton>
                    )}
                </Typography>
            </Box>
        </Box>
    );
}
