/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from 'react';
import { css } from '@emotion/react';
import { Box, Skeleton, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { VaultAvaxNames } from '../../../constants';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoadingGroMcStats } from '../../stats/store/stats.selectors';
import {
    dateNumToString,
    selectCurrentPosAPY,
    selectCurrentPosIsOpen,
    selectCurrentPosOpenAmount,
    selectCurrentPosReturn,
    selectCurrentPosTs,
    selectPastPosDetails,
} from '../store/labs.selectors';
import { PositionCard } from './PositionCard';

export function LabsPosition({
    name,
    smallDevices,
}: {
    name: VaultAvaxNames;
    smallDevices?: boolean;
}): React.ReactElement {
    const currentPosIsOpen = useAppSelector(selectCurrentPosIsOpen(name));
    const currentPosOpenAmount = useAppSelector(selectCurrentPosOpenAmount(name));
    const currentPosReturn = useAppSelector(selectCurrentPosReturn(name));
    const currentPosTS = useAppSelector(selectCurrentPosTs(name));
    const currentPosAPY = useAppSelector(selectCurrentPosAPY(name));
    const pastPosDetails = useAppSelector(selectPastPosDetails(name));
    const loading = useAppSelector(selectIsLoadingGroMcStats);

    return (
        <Box mr={smallDevices ? 0 : 3} mt={3}>
            <Box>
                <Box mb={1.5}>
                    <Typography color="#b7c0d5;" variant="nav">
                        Current strategy cycle
                    </Typography>
                </Box>
                {loading && <Skeleton height="45px" variant="rectangular" width="290px" />}
                {currentPosIsOpen && !loading && (
                    <PositionCard
                        amount={currentPosOpenAmount}
                        apy={currentPosAPY}
                        dateFrom={currentPosTS}
                        returnAmount={currentPosReturn}
                        smallDevices={smallDevices}
                        title="Open"
                    />
                )}
                {!currentPosIsOpen && !loading && (
                    <PositionCard
                        noOpen
                        amount={new BigNumber(0)}
                        apy={new BigNumber(0)}
                        dateFrom=""
                        returnAmount={new BigNumber(0)}
                        smallDevices={smallDevices}
                        title="No open positions"
                    />
                )}
            </Box>
            <Box>
                {(!!pastPosDetails?.length || loading) && (
                    <Box mt={3}>
                        <Typography
                            css={css`
                                color: #b7c0d5;
                                padding-bottom: 5px;
                            `}
                            variant="nav"
                        >
                            Past strategy cycles
                        </Typography>
                    </Box>
                )}
                {loading ? (
                    [...Array(5)].map((elem, i) => (
                        <Box key={i} pb="4px">
                            <Skeleton height="45px" variant="rectangular" width="290px" />
                        </Box>
                    ))
                ) : (
                    <Box>
                        {pastPosDetails?.map((pastPosItem) => {
                            const closeAmount = new BigNumber(pastPosItem.close_amount);
                            const openAmount = new BigNumber(pastPosItem.open_amount);
                            const totalReturn = closeAmount.minus(openAmount);

                            const openDate = dateNumToString(Number(pastPosItem.open_ts));
                            const closeDate = dateNumToString(Number(pastPosItem.close_ts));
                            const stratAPY = new BigNumber(pastPosItem.apy);
                            return (
                                <Box key={pastPosItem.open_ts} mb={1}>
                                    <PositionCard
                                        amount={closeAmount}
                                        apy={stratAPY}
                                        dateFrom={openDate}
                                        dateTo={closeDate}
                                        returnAmount={totalReturn}
                                        smallDevices={smallDevices}
                                        title="Closed"
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
