/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../../constants';
import {
    fetchClaimDelay,
    fetchTotalVestingBonus,
    fetchUserCanClaim,
    fetchVestingBonus,
} from '../../../../lib/web3/groBonus';
import {
    getLockedUst,
    getVestedAmount,
    getVestingEndDateUst,
    hasClaimed,
} from '../../../../lib/web3/ustClaim';
import {
    fetchTotalLockedAmount,
    fetchUnvestedGRO,
    fetchVestedGRO,
    getMaxLockedPeriod,
} from '../../../../lib/web3/vesting';
import { addTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import {
    setCanClaim,
    setClaimDate,
    setGROStatus,
    setHasClaimedUst,
    setMaxLockedPeriod,
    setTotalLockedAmountGRO,
    setTotalVestingBonus,
    setUnvested,
    setUstLocked,
    setUstVested,
    setVested,
    setVestingBonus,
    setVestingEndDateUst,
} from '../rewards.reducer';
import proofs from './proofs.json';

export const updateGROValuesThunk: AppActionThunk =
    (wallet?: string) => async (dispatch, getState) => {
        try {
            dispatch(setGROStatus({ status: Status.loading }));
            if (wallet) {
                const item = proofs.airdrops.find((elem) => elem.address === wallet);
                dispatch(setCanClaim(await fetchUserCanClaim(wallet)));
                dispatch(setUnvested({ amount: await fetchUnvestedGRO(wallet) }));
                dispatch(setVested({ amount: await fetchVestedGRO(wallet) }));
                // dispatch(setPercentage(await fetchVestingPercentage(wallet)));
                dispatch(setVestingBonus({ amount: await fetchVestingBonus(wallet) }));
                dispatch(setClaimDate(await fetchClaimDelay(wallet)));
                if (item && item.proofs.length) {
                    dispatch(
                        setUstVested(
                            await getVestedAmount({
                                proof: item.proofs,
                                totalAmount: new BigNumber(item.amount),
                                wallet,
                            }),
                        ),
                    );
                    dispatch(setHasClaimedUst(await hasClaimed(wallet)));
                    dispatch(
                        setUstLocked(
                            addTokenDecimals(
                                TOKENS.PWRD,
                                await getLockedUst(
                                    wallet,
                                    item.proofs,
                                    new BigNumber(item.amount),
                                ),
                            ),
                        ),
                    );
                }
            }
            dispatch(setTotalVestingBonus({ amount: await fetchTotalVestingBonus() }));
            dispatch(setVestingEndDateUst(await getVestingEndDateUst()));
            dispatch(setMaxLockedPeriod(await getMaxLockedPeriod()));
            dispatch(
                setTotalLockedAmountGRO({
                    amount: await fetchTotalLockedAmount(),
                }),
            );
            dispatch(setGROStatus({ status: Status.ready }));
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.warn('updateGROValuesThunk.error', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            dispatch(setGROStatus({ error: error.message, status: Status.error }));
        }
    };
