/* eslint-disable func-style */
import { BigNumber } from 'bignumber.js';
import { TOKENS } from '../../../constants';
import { tokenToUsd } from '../../../lib/web3/convert';
import { TpTvl } from '../../stats/stats.types';
import { TpLimitsExchange } from '../exchange.types';
import { ExchangeState } from '../store/exchange.store';

/*
    LIMIT DEPOSIT PWRD = ((util_ratio_limit_PD - FE buffer) * GVT_TVL)    -    PWRD_TVL
    LIMIT WITHDRAW GVT =  GVT_TVL   -   ( (1 / (util_ratio_limit_GW - FE buffer))   *   PWRD_TVL )
*/
export function calculateExchangeLimits(tvl: TpTvl): TpLimitsExchange {
    const gvt = new BigNumber(tvl.gvt);
    const pwrd = new BigNumber(tvl.pwrd);

    const utilRatio = new BigNumber(tvl.util_ratio);

    // Percentage of marging allocated for the FE
    const frontEndBuffer = 0.0001;

    // maximum allowable utilization ratio of pwrd to gvt for gvt withdrawals to be accepted [must be >= 0]
    const ratioWithdrawGVT = new BigNumber(tvl.util_ratio_limit).minus(frontEndBuffer);

    //  maximum allowable utilization ratio of pwrd to gvt for pwrd deposits to be accepted [must be >= 0 and <= 1]
    const ratioDepositPWRD = new BigNumber(tvl.util_ratio_limit).minus(frontEndBuffer);

    const limitDepositPWRD = gvt.multipliedBy(ratioDepositPWRD).minus(pwrd);
    const limitWithdrawGVT = gvt.minus(
        new BigNumber(1).dividedBy(ratioWithdrawGVT).multipliedBy(pwrd),
    );

    return {
        availableDepositPWRD: utilRatio >= ratioDepositPWRD ? new BigNumber(0) : limitDepositPWRD,
        availableWithdrawGVT: utilRatio >= ratioWithdrawGVT ? new BigNumber(0) : limitWithdrawGVT,
    };
}

export type LimitsReachedType = {
    difference: BigNumber;
    limit: BigNumber;
    limitReached: boolean;
};

export const getLimitsWithdraw = (state: ExchangeState): LimitsReachedType => {
    const { token } = state;
    const amount = new BigNumber(state.tokens.from[token] || 0);
    const amountInUsd =
        amount && token ? tokenToUsd(token, amount, state.conversions) : new BigNumber(0);
    const difference = amountInUsd
        .minus(new BigNumber(state.limits.availableWithdrawGVT))
        .integerValue();

    return {
        difference: BigNumber.maximum(1, difference),
        limit: new BigNumber(state.limits.availableWithdrawGVT),
        limitReached:
            token === TOKENS.GVT && amountInUsd.isGreaterThan(state.limits.availableWithdrawGVT),
    };
};

export const getLimitsInvest = (state: ExchangeState): LimitsReachedType => {
    const { token } = state;
    const amount = new BigNumber(state.tokens.to[token] || 0);
    const amountInUsd =
        amount && token ? tokenToUsd(token, amount, state.conversions) : new BigNumber(0);
    const difference = amountInUsd.minus(state.limits.availableDepositPWRD).integerValue();
    return {
        difference: BigNumber.maximum(1, difference),
        limit: new BigNumber(state.limits.availableDepositPWRD),
        limitReached:
            token === TOKENS.PWRD && amountInUsd.isGreaterThan(state.limits.availableDepositPWRD),
    };
};
