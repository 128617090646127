/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-style */
import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { POOL_TOKENS, Token, TokenObject, TOKENS } from '../../../constants';
import { Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { selectExchangeConversions } from '../../exchange/store/exchange.selectors';
import { selectPersonalTotalLabsPosition } from '../../labs/store/labs.selectors';
import {
    selectPendingGRO,
    selectUnvestedGRO,
    selectVestedGRO,
} from '../../rewards/store/rewards.selectors';
import { TpGroStatsMc } from '../../stats/stats.types';
import { selectGroStatsMc } from '../../stats/store/stats.selectors';
import {
    selectIsUS,
    selectStakedWalletTokens,
    selectWalletTokens,
} from '../../wallet/store/wallet.selectors';
import { PoolState } from './pools.store';

export function selectPoolsState(state: RootState): PoolState {
    return state.pools;
}

export const selectPoolsLoading = createSelector(
    selectPoolsState,
    (poolsState) => poolsState.status !== Status.ready && poolsState.status !== Status.error,
);

export const selectPools = createSelector(selectPoolsState, (poolState) => [...poolState.pools]);

export const selectSpecificPool = (token: Token) =>
    createSelector(selectPools, (pools) => {
        const pool = pools.find((elem) => elem.name === token);
        return pool;
    });

export const selectStakedTotalTVL = createSelector(selectGroStatsMc, (groStats: TpGroStatsMc) =>
    groStats.mainnet?.pools
        .filter((pool) => pool.tvl_staked !== 'NA')
        .map((pool) => new BigNumber(pool.tvl_staked || 0))
        .reduce((prev, next) => prev.plus(next), new BigNumber(0)),
);

export const selectAVGWeightedAPY = createSelector(
    [selectStakedWalletTokens, selectGroStatsMc],
    (stakedWalletTokens: TokenObject, groStats: TpGroStatsMc) => {
        const names: Token[] = [];
        Object.entries(stakedWalletTokens).forEach((elem) => {
            // eslint-disable-next-line
            const [name, value]: any = elem;
            if (!new BigNumber(value).isZero()) {
                names.push(name);
            }
        });
        const stakedTotal = POOL_TOKENS.filter((token) => token !== TOKENS.PWRD3CRV)
            .map((token) => {
                const poolStats = groStats.mainnet?.pools.find((p) => p.name === token);
                if (!poolStats) {
                    return new BigNumber(0);
                }
                if (poolStats.lp_usd_price === 'NA') {
                    return new BigNumber(0);
                }
                return (
                    // eslint-disable-next-line
                    stakedWalletTokens[token]?.multipliedBy(poolStats?.lp_usd_price) ||
                    new BigNumber(0)
                );
            })
            .reduce((prev, current) => prev.plus(current));
        const avgToSum = names
            .map((name) => {
                const pool = groStats.mainnet?.pools.find((elem) => elem.name === name);

                const poolAPY = new BigNumber(
                    groStats.mainnet?.pools.find((elem) => elem.name === name)?.apy.current
                        .total || 0,
                );
                let stakedInPool = new BigNumber(0);
                if (pool?.lp_usd_price !== 'NA') {
                    stakedInPool = new BigNumber(stakedWalletTokens[name] || 0).multipliedBy(
                        pool?.lp_usd_price || 0,
                    );
                }
                return stakedInPool.dividedBy(stakedTotal).multipliedBy(poolAPY);
            })
            .reduce((curr, next) => curr.plus(next), new BigNumber(0));
        return avgToSum;
    },
);

export const selectPoolAllocationUsd = createSelector(
    [selectWalletTokens, selectStakedWalletTokens, selectGroStatsMc],
    (tokens: TokenObject, stakedTokens: TokenObject, groStats: TpGroStatsMc) => {
        const unstaked = POOL_TOKENS.filter(
            (token) => token !== TOKENS.GVT_SINGLE_SIDED && token !== TOKENS.PWRD_SINGLE_SIDED,
        )
            .map((token) => {
                const poolStats = groStats.mainnet?.pools.find((p) => p.name === token);
                if (!poolStats) {
                    return new BigNumber(0);
                }
                if (poolStats.lp_usd_price === 'NA') {
                    return new BigNumber(0);
                }

                return (
                    // eslint-disable-next-line
                    tokens[token]?.multipliedBy(parseFloat(poolStats?.lp_usd_price!)) ||
                    new BigNumber(0)
                );
            })
            .reduce((prev, current) => prev.plus(current));
        const staked = POOL_TOKENS.map((token) => {
            const poolStats = groStats.mainnet?.pools.find((p) => p.name === token);
            if (!poolStats) {
                return new BigNumber(0);
            }
            if (poolStats.lp_usd_price === 'NA') {
                return new BigNumber(0);
            }

            return (
                // eslint-disable-next-line
                stakedTokens[token]?.multipliedBy(poolStats?.lp_usd_price!) || new BigNumber(0)
            );
        }).reduce((prev, current) => prev.plus(current));
        return { staked, unstaked };
    },
);

export const selectOpenMigrationModal = createSelector(
    selectPoolsState,
    (pools) => pools.openMigrationModal,
);

export const selectPidsAndStaked = createSelector(
    selectPools,
    selectStakedWalletTokens,
    (pools, tokens) =>
        pools.map(({ name, pid }) => {
            const staked = new BigNumber(tokens[name] || 0);
            return { pid, staked };
        }),
);

export const selectGvtPoolPrice = createSelector(
    selectPools,
    (pools) =>
        new BigNumber(pools.find((p) => p.name === TOKENS.GVT_SINGLE_SIDED)?.lp_usd_price || 0),
);

export const selectPwrdPoolPrice = createSelector(
    selectPools,
    (pools) =>
        new BigNumber(pools.find((p) => p.name === TOKENS.PWRD_SINGLE_SIDED)?.lp_usd_price || 0),
);

export const selectStakedGvtToUsd = createSelector(
    selectStakedWalletTokens,
    selectGvtPoolPrice,
    (stakedTokens, price) =>
        stakedTokens[TOKENS.GVT_SINGLE_SIDED]?.multipliedBy(price) || new BigNumber(0),
);

export const selectGVTPoolAPR = createSelector(selectGroStatsMc, (stats) => {
    const poolStats = stats.mainnet?.pools.find((p) => p.name === TOKENS.GVT_SINGLE_SIDED);
    return new BigNumber(poolStats?.apy.current.reward || 0);
});

export const selectPWRDPoolAPR = createSelector(selectGroStatsMc, (stats) => {
    const poolStats = stats.mainnet?.pools.find((p) => p.name === TOKENS.PWRD_SINGLE_SIDED);
    return new BigNumber(poolStats?.apy.current.reward || 0);
});

export const selectStakedPwrdToUsd = createSelector(
    selectStakedWalletTokens,
    selectPwrdPoolPrice,
    (stakedTokens, price) =>
        stakedTokens[TOKENS.PWRD_SINGLE_SIDED]?.multipliedBy(price) || new BigNumber(0),
);

export const selectStakedTokenToUsd = (token: Token) =>
    createSelector(selectStakedGvtToUsd, selectStakedPwrdToUsd, (gvt, pwrd) =>
        token === TOKENS.GVT ? gvt : pwrd,
    );

export const selectUnstakedGVTToUsd = createSelector(
    selectWalletTokens,
    selectExchangeConversions,
    (walletTokens, conversions) =>
        walletTokens[TOKENS.GVT_SINGLE_SIDED]?.multipliedBy(conversions.gvt || 0) ||
        new BigNumber(0),
);

export const selectUnstakedPWRDToUsd = createSelector(
    selectWalletTokens,
    selectExchangeConversions,
    (walletTokens, conversions) =>
        walletTokens[TOKENS.PWRD]?.multipliedBy(conversions.pwrd || 0) || new BigNumber(0),
);

export const selectUnstakedGROToUsd = createSelector(
    selectPools,
    selectWalletTokens,
    (pools, walletTokens) => {
        const pool = pools.find((elem) => elem.name === TOKENS.GRO_SINGLE_SIDED);
        return walletTokens[TOKENS.GRO]?.multipliedBy(pool?.lp_usd_price || 0) || new BigNumber(0);
    },
);

export const selectUnstakedPwrd3CrvToUsd = createSelector(
    selectPools,
    selectWalletTokens,
    (pools, walletTokens) => {
        const pool = pools.find((elem) => elem.name === TOKENS.PWRD3CRV);
        return (
            walletTokens[TOKENS.PWRD3CRV]?.multipliedBy(pool?.lp_usd_price || 0) ||
            new BigNumber(0)
        );
    },
);

export const selectStakedPools = createSelector(
    selectPools,
    selectStakedWalletTokens,
    (pools, stakedTokens) => {
        const staked = pools
            .map((elem) => ({
                ...elem,
                staked: stakedTokens[elem.name]?.toString() || '',
            }))
            .filter((pool) => pool.staked !== '0');
        return staked;
    },
);

export const selectActivePools = createSelector(selectPools, (pools) => pools);

export const selectDeprecatedPools = createSelector(
    selectPools,
    selectStakedWalletTokens,
    selectWalletTokens,
    (pools, staked, walletTokens) => [],
);

export const selectPoolsDeprecatedNotification = createSelector(
    selectDeprecatedPools,
    selectStakedWalletTokens,
    (pools, staked) => 0,
);

export const selectPoolsDeprecated = createSelector(selectPoolsState, (state) => state.deprecated);

export const selectPidsToMigrate = createSelector(
    selectPoolsState,
    (state) => state.pidsToMigrate,
);

export const selectHasPoolsBalance = createSelector(selectStakedPools, (staked) => {
    const balance = staked.reduce((curr, next) => curr.plus(next.staked), new BigNumber(0));
    return balance.isGreaterThan(0);
});

export const selectShouldBlock = createSelector(
    selectIsUS,
    selectHasPoolsBalance,
    selectUnstakedGVTToUsd,
    selectUnstakedPWRDToUsd,
    selectStakedGvtToUsd,
    selectStakedPwrdToUsd,
    selectPendingGRO,
    selectUnvestedGRO,
    selectVestedGRO,
    selectPersonalTotalLabsPosition,
    (
        isUs,
        poolsBalance,
        unstakedGvt,
        unstakedPwrd,
        stakedGvt,
        stakedPwrd,
        pending,
        unvested,
        vested,
        labs,
    ) => {
        const gvtBalance = unstakedGvt.plus(stakedGvt).isGreaterThan(0);
        const pwrdBalance = stakedPwrd.plus(unstakedPwrd).isGreaterThan(0);
        const rewardsBalance = pending.plus(vested).plus(unvested).isGreaterThan(0);
        const labsBalance = labs.isGreaterThan(0);
        return (
            isUs && !(poolsBalance || gvtBalance || pwrdBalance || rewardsBalance || labsBalance)
        );
    },
);

export const selectMigratedLoading = createSelector(
    selectPoolsState,
    (poolsState) => poolsState.migrated !== Status.ready && poolsState.migrated !== Status.error,
);
