import { Status, StatusState } from '../../app/app.types';
import { TpPool } from '../../stats/stats.types';

export type PoolState = {
    deprecated: boolean;
    migrated: Status;
    openMigrationModal: boolean;
    pidsToMigrate: string[];
    pools: TpPool[];
} & StatusState;

export const initialPoolState: PoolState = {
    deprecated: false,
    error: undefined,
    migrated: Status.idle,
    openMigrationModal: false,
    pidsToMigrate: [],
    pools: [],
    status: Status.ready,
};
