/* eslint-disable @typescript-eslint/no-loss-of-precision */
import BigNumber from 'bignumber.js';

// See: https://docs.metamask.io/guide/ethereum-provider.html#chain-ids
export enum EthereumNetworkCodes {
    avalanche = 43114,
    goerli = 5,
    kovan = 42,
    mainnet = 1,
    rinkeby = 4,
    ropsten = 3,
    localhost = 31337,
    avax = 43114,
}

export enum NETWORK {
    AVALANCHE = 'avalanche',
    LOCALHOST = 'localhost',
    MAINNET = 'mainnet',
}

export const LOCAL_FORK = false;

export const LOCAL_FORK_AVAX = false;

export const NETWORK_DISPLAY_NAMES = {
    [NETWORK.MAINNET]: 'Ethereum Mainnet',
};

export const ETHERSCAN_API_KEY = 'C8FNTC46DIGY4XZH9W4KS5XVXF71I19CHX';

export const ETHEREUM_NETWORK: TpMetaMaskSupportNetworks =
    process.env.REACT_APP_ETHEREUM_NETWORK || NETWORK.MAINNET;

export const AVALANCHE_NETWORK: TpMetaMaskSupportNetworks = LOCAL_FORK_AVAX
    ? NETWORK.LOCALHOST
    : process.env.REACT_APP_AVALANCHE_NETWORK || NETWORK.AVALANCHE;

export const ETHEREUM_NETWORK_CODE = EthereumNetworkCodes[ETHEREUM_NETWORK];

export const isMainnet = ETHEREUM_NETWORK === NETWORK.MAINNET;

export const DISCORD_LINK = '//discord.gg/G5UmeHgwvv';

export const INFURA_ID = process.env.REACT_APP_INFURA_ID || '9aa3d95b3bc440fa88ea12eaa4456161';

export function etherscanAddressUrl(address = '', isAvax = false): string {
    if (isAvax) return `//snowtrace.io/address/${address}`;
    return ETHEREUM_NETWORK === NETWORK.MAINNET
        ? `//etherscan.io/address/${address}`
        : `//${ETHEREUM_NETWORK}.etherscan.io/address/${address}`;
}

export function etherscanTxUrl(hash: string, isAvax: boolean): string {
    if (isAvax) {
        return `https://snowtrace.io/tx/${hash}`;
    }
    return ETHEREUM_NETWORK === NETWORK.MAINNET
        ? `https://etherscan.io/tx/${hash}`
        : `https://${ETHEREUM_NETWORK}.etherscan.io/tx/${hash}`;
}

// TODO: Find a better file organization for constants
export enum TOKENS {
    BAL_GRO_WETH = 'balancer_v2_8020_gro_weth_5',
    CRV = 'crv',
    CRV_META = 'curve_meta_pwrd_3crv_4',
    CUSDC = 'cusdc',
    DAI = 'dai',
    DAIE = 'daie',
    DURA = 'dura',
    GRO = 'gro',
    GRO_SINGLE_SIDED = 'single_staking_100_gro_0',
    GVT = 'gvt',
    GVT_SINGLE_SIDED = 'single_staking_100_gvt_3',
    PWRD = 'pwrd',
    PWRD3CRV = 'pwrd3crv',
    PWRD_SINGLE_SIDED = 'single_staking_100_pwrd_6',
    UNI_50_50 = 'uniswap_v2_5050_gro_gvt_1',
    UNI_USDC_50_50 = 'uniswap_v2_5050_gro_usdc_2',
    USD = 'usd',
    USDC = 'usdc',
    USDCE = 'usdce',
    USDT = 'usdt',
    USDTE = 'usdte',
    WETH = 'weth',
}

export const NO_SIGNATURE = [TOKENS.CRV, TOKENS.USDT];

// Get an iterable version of stable tokens and also an union type
export const STABLE_TOKENS_AVAX = [TOKENS.USDCE, TOKENS.USDTE, TOKENS.DAIE] as const;
export const STABLE_TOKENS = [TOKENS.DAI, TOKENS.USDC, TOKENS.USDT, TOKENS.CRV] as const;

export const TOKENS_DEPOSIT = [TOKENS.DAI, TOKENS.USDC, TOKENS.USDT, TOKENS.CRV] as const;

export type StableTokenAvax = (typeof STABLE_TOKENS_AVAX)[number];
export type StableToken = (typeof STABLE_TOKENS)[number] | StableTokenAvax;
export type StableTokenMainnet = (typeof STABLE_TOKENS)[number];

export const GROWTH_TOKENS = [TOKENS.GVT, TOKENS.PWRD] as const;
export type GrowthToken = (typeof GROWTH_TOKENS)[number];

export enum PoolProviders {
    BALANCER = 'balancer_v2',
    CURVE = 'curve_meta',
    GRO = 'ss_1',
    UNISWAP = 'uniswap_v2',
}

export const POOL_TOKENS = [
    TOKENS.BAL_GRO_WETH,
    TOKENS.GRO_SINGLE_SIDED,
    TOKENS.GVT_SINGLE_SIDED,
    TOKENS.UNI_50_50,
    TOKENS.UNI_USDC_50_50,
    TOKENS.CRV_META,
    TOKENS.PWRD3CRV,
    TOKENS.GRO,
    TOKENS.PWRD_SINGLE_SIDED,
] as const;
export type PoolToken = (typeof POOL_TOKENS)[number];

export type UniToken = TOKENS.UNI_USDC_50_50 | TOKENS.UNI_50_50;

export type Token =
    | GrowthToken
    | StableToken
    | PoolToken
    | TOKENS.USD
    | TOKENS.GRO
    | TOKENS.PWRD3CRV
    | TOKENS.WETH
    | TOKENS.CRV
    | TOKENS.CUSDC
    | TOKENS.DURA;

export type TokenObject = {
    [token in Token]?: BigNumber;
};

export enum PoolIncentiveToken {
    BAL = 'bal',
}

export type PoolIncentiveTokens = typeof PoolIncentiveToken;

export const GVT_BETA_CAP = isMainnet ? new BigNumber(10000000) : new BigNumber(50000000);
export const MANUAL_CAP_ENABLED = false;
export const MINIMUM_INVESTING_VISIBLE_AMOUNT = 0.01;
export const MINIMUM_INVESTING_TOTAL_AMOUNT = 0.5;

export const MAINTENANCE_STATUS = false;

// TODO: Check what is the error when rejected on walletconnect
export const METAMASK_ERROR_REJECTED_SIGNATURE = 'MetaMask Tx Signature';

/* eslint-disable no-loss-of-precision */
export const MAX_APPROVAL = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const AVAX_NETWORK = {
    blockExplorerUrls: ['https://snowtrace.io/'],
    chainId: '0xa86a',
    chainName: 'Avalanche Network',
    nativeCurrency: {
        decimals: 18,
        name: 'Avalanche',
        symbol: 'AVAX',
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
};

export const LOCALHOST_NETWORK = {
    chainId: '0x7A69',
    rpcUrls: [''],
};

export const ETH_NETWORK = {
    blockExplorerUrls: ['https://etherscan.io'],
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
        decimals: 18,
        name: 'Ethereum',
        symbol: 'ETH',
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
};

export enum VaultAvaxNames {
    'groDAI.e_vault' = 'groDAI.e_vault',
    'groDAI.e_vault_v1_5' = 'groDAI.e_vault_v1_5',
    'groDAI.e_vault_v1_5_1' = 'groDAI.e_vault_v1_5_1',
    'groDAI.e_vault_v1_6' = 'groDAI.e_vault_v1_6',
    'groDAI.e_vault_v1_7' = 'groDAI.e_vault_v1_7',
    'groUSDC.e_vault' = 'groUSDC.e_vault',
    'groUSDC.e_vault_v1_5' = 'groUSDC.e_vault_v1_5',
    'groUSDC.e_vault_v1_5_1' = 'groUSDC.e_vault_v1_5_1',
    'groUSDC.e_vault_v1_6' = 'groUSDC.e_vault_v1_6',
    'groUSDC.e_vault_v1_7' = 'groUSDC.e_vault_v1_7',
    'groUSDT.e_vault' = 'groUSDT.e_vault',
    'groUSDT.e_vault_v1_5' = 'groUSDT.e_vault_v1_5',
    'groUSDT.e_vault_v1_5_1' = 'groUSDT.e_vault_v1_5_1',
    'groUSDT.e_vault_v1_6' = 'groUSDT.e_vault_v1_6',
    'groUSDT.e_vault_v1_7' = 'groUSDT.e_vault_v1_7',
}
