export const coreThemeConstants = {
    borderRadius: {
        md: 5,
    },
    fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    letterSpacing: {
        lg: '2px',
        md: '1px',
        sm: '0.5px',
        xs: '0.25px',
    },
    lineHeight: {
        lg: 1.4, // 22.4px on 16
        md: 1.1875, // 19px on 16, 47.5 on 40
        xl: 1.5, // 24px on 16
    },
    spacingBase: 8, // 8px grid
};
