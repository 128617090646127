/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { usdc, usdcProxi } from '../contract-info';
import { FiatToken } from './types/fiatToken';
import { Usdc } from './types/usdc';

let instance: Usdc;

let instanceProxi: FiatToken;

export function usdcContract(): Usdc {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(usdc.abi, usdc.address) as any as Usdc;
    return instance;
}

export function usdcProxiContract(): FiatToken {
    if (instance) return instanceProxi;
    const { Contract } = web3Instance().eth;
    instanceProxi = new Contract(usdcProxi.abi, usdcProxi.address) as any as FiatToken;
    return instanceProxi;
}
