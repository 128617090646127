/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import BigNumber from 'bignumber.js';
import { MAX_APPROVAL, Token, VaultAvaxNames } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import {
    burner,
    getVaultAdaptor,
    lpTokenStakerHandler,
    redeem,
    redeemAlloy,
} from '../../../../lib/contract-info';
import { generateApproveMethod } from '../../../../lib/web3/approve';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { selectLabsToken } from '../../../labs/store/labs.selectors';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectTransactionsState } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { refreshBalancesThunk } from '../../../wallet/store/thunks/refreshBalancesThunk';
import { selectIsAVAXNetwork } from '../../../wallet/store/wallet.selectors';

function getApprovalAddress(
    name: VaultAvaxNames,
    direction?: ExchangeDirection,
    isAvax?: boolean,
): string | undefined {
    if (isAvax) {
        const vaultInfo = getVaultAdaptor(name);
        return vaultInfo.address;
    }
    switch (direction) {
        case ExchangeDirection.stake:
        case ExchangeDirection.unstake:
            return lpTokenStakerHandler.address;
        case ExchangeDirection.lockMoreGro:
        case ExchangeDirection.revest:
            return burner.address;
        case ExchangeDirection.depositGro:
            return redeem.address;
        case ExchangeDirection.depositUsdc:
            return redeemAlloy.address;
        default:
            return undefined;
    }
}

export const executeApproveTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) =>
    async (dispatch, getState): Promise<void> => {
        // const wallet = selectWalletAccount(getState());

        const transactions = selectTransactionsState(getState());
        const transaction = transactions.currentSession[tid];
        const mmTransaction = transaction.queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );
        const sourceToken = mmTransaction.token;
        const targetToken = Object.keys(transaction.to)[0] as Token;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const isAvax = selectIsAVAXNetwork(getState());
        const name = selectLabsToken(getState());
        const method: NonPayableTransactionObject<any> = await generateApproveMethod(
            sourceToken,
            targetToken,
            // if approval Tx is for stake/unstake, set a different spender address
            getApprovalAddress(name, mmTransaction.direction, isAvax),
            new BigNumber(MAX_APPROVAL),
        );

        function onTransactionDone(): void {
            void dispatch(refreshBalancesThunk());
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
