/* eslint-disable camelcase */

import {
    GrowthToken,
    PoolIncentiveToken,
    PoolProviders,
    PoolToken,
    Token,
    TOKENS,
    VaultAvaxNames,
} from '../../constants';

export type TpGroStatsJson = {
    gro_stats: TpGroStats;
};

export type TpGroStats = {
    apy: TpApy;
    current_timestamp: string;
    exposure: TpExposure;
    launch_timestamp: string;
    network: string;
    pools: TpPool[];
    system: TpSystem;
    token_price_usd: {
        gro: string;
        gvt: string;
        pwrd: string;
    };
    tvl: TpTvl;
};

export type TpGroStatsMcJson = {
    gro_stats_mc: TpGroStatsMc;
};

export type TpAvalancheGroStatsMc = {
    labs_vault: TpLabVault[];
    launch_timestamp: string;
    network_id: string;
    token_price_usd: {
        avax: string;
    };
    tvl: TpTvlAvax;
};

export type TpGroStatsMc = {
    avalanche: TpAvalancheGroStatsMc;
    current_timestamp: string;
    mainnet: {
        apy: TpApy;
        exposure: TpExposure;
        launch_timestamp: string;
        network_id: string;
        pools: TpPool[];
        system: TpSystem;
        token_price_usd: {
            gro: string;
            gvt: string;
            pwrd: string;
        };
        tvl: TpTvl;
    };
    mc_totals: {
        tvl: McTotal;
    };
    network: string;
    status: string;
};

export type TpStablecoins = 'DAI' | 'USDC' | 'USDT';

export type TpPeriods =
    | 'all_time'
    | 'daily'
    | 'last24h'
    | 'monthly'
    | 'weekly'
    | 'last7d'
    | 'current';

export const DEFAULT_APY_PERIOD: TpPeriods = 'current';

type TpStatBase = {
    amount: string;
    display_name: string;
    last3d_apy: string;
    name: string;
    share: string;
};

export type TpLabVault = TpStatBase & {
    all_time_apy: string;
    avax_exposure: string;
    name: VaultAvaxNames;
    reserves: TpStatBase;
    stablecoin: string;
    strategies: TpStrategyAvax[];
};

export type TpStrategy = TpStatBase & {
    address: string;
};

type TpStrategyAvax = TpStrategy & {
    all_time_apy: string;
    open_position: TpOpenPosition;
    past_5_closed_positions: TpClosedPosition[];
    romad_ratio: string;
    sharpe_ratio: string;
    sortino_ratio: string;
    tvl_cap: string;
};

type TpOpenPosition = {
    active_position?: string;
    apy?: string;
    current_amount?: string;
    open_amount?: string;
    open_ts?: string;
};

type TpClosedPosition = {
    apy: string;
    close_amount: string;
    close_ts: string;
    open_amount: string;
    open_ts: string;
};

export type TpSystem = {
    last3d_apy: string;
    lifeguard: TpStatBase;
    total_amount: string;
    total_share: string;
    vault: TpVault[];
};

export type TpVault = TpStatBase & {
    reserves: TpStatBase;
    strategies: TpStrategy[];
};

type TpApy = Record<TpPeriods, TpProducts> & { hodl_bonus: string };

export type TpProducts = {
    [TOKENS.GVT]: string;
    [TOKENS.PWRD]: string;
};

export type TpTvl = TpProducts & {
    total: string;
    util_ratio: string;
    util_ratio_limit: string;
};

export type TpTvlAvax = {
    total: string;
} & { [key in VaultAvaxNames]: string };

export type TpProtocol = {
    concentration: string;
    display_name: string;
    name: string;
};

export type TpStablecoin = {
    concentration: string;
    display_name: string;
    name: string;
};

type TpExposure = {
    protocols: TpProtocol[];
    stablecoins: TpStablecoin[];
};

export type TpPool = {
    apy: {
        current: {
            pool_fees: string;
            pool_incentive?: string;
            reward: string;
            token: string;
            total: string;
        };
    };
    deposit_url?: string;
    disable?: string;
    display_name: string;
    display_order: string;
    display_type: string;
    lp_usd_price: string;
    name: PoolToken;
    pid: string;
    pool_incentive_token?: PoolIncentiveToken;
    remove_url?: string;
    required_tokens_num: string;
    staked: string;
    tokens: Token[];
    tvl: string;
    tvl_staked: string;
    type: PoolProviders;
};

export type TpUserStatsMcJson = {
    gro_personal_position_mc: TpUserStatsMc;
};

export type TpUserStatsJson = {
    gro_personal_position: TpUserStats;
};

export enum UserTransactionType {
    approvals = 'approvals',
    deposits = 'deposits',
    failures = 'failures',
    stakerDeposits = 'staker_deposits',
    stakerWithdrawals = 'staker_withdrawals',
    transfersIn = 'transfers_in',
    transfersOut = 'transfers_out',
    withdrawals = 'withdrawals',
}

export type TpUserTransaction = {
    [UserTransactionType.approvals]: Approval[];
    [UserTransactionType.transfersIn]: UserTransaction[];
    [UserTransactionType.transfersOut]: UserTransaction[];
    [UserTransactionType.withdrawals]: UserTransaction[];
    [UserTransactionType.deposits]: UserTransaction[];
    [UserTransactionType.failures]: FailureTransaction[];
    [UserTransactionType.stakerDeposits]: UserTransaction[];
    [UserTransactionType.stakerWithdrawals]: UserTransaction[];
};

export type UserTransaction = {
    block_number: string;
    coin_amount?: string;
    hash: string;
    timestamp: string;
    token?: string;
    usd_amount?: string;
};

export type Approval = UserTransaction & {
    spender: string;
};

export type FailureTransaction = UserTransaction & {
    contract_address: string;
    contract_name: string;
};

export type AirdropStats = {
    amount: string;
    amount_to_claim: string;
    claimable: string;
    claimed: string;
    display_name: string;
    expired: string;
    expiry_ts?: string;
    hash: string[];
    merkle_root_index: string;
    name: string;
    participated: string;
    proofs: string[];
    timestamp: string;
    token: PoolToken | GrowthToken;
};

export type TpUserStats = {
    address: string;
    airdrops: AirdropStats[];
    amount_added: TpUserStat;
    amount_removed: TpUserStat;
    current_balance: TpUserStat;
    current_timestamp: string;
    launch_timestamp: string;
    net_amount_added: TpUserStat;
    net_returns: TpUserStat;
    network: string;
    transaction: TpUserTransaction;
};

export type TpGroGateVaults = {
    [key in VaultAvaxNames]?: TpGroGateVault | Record<string, unknown>;
};

export type TpGroGate = {
    gro_balance_at_snapshot: string;
    gro_gate_at_snapshot: string;
    proofs: string[];
    snapshot_ts: string;
    total_claimable_allowance: string;
    total_remaining_allowance: string;
} & TpGroGateVaults;

type RewardStats = {
    claim_now: string;
    vest_all: string;
};

type VestBonusStat = {
    locked_gro: string;
    net_reward: string;
    rewards: RewardStats;
};

type PoolsStats = {
    [key in Token]?: {
        balance: string;
        net_reward: string;
        rewards: RewardStats;
    };
} & {
    all: {
        balance: string;
        net_reward: string;
        rewards: RewardStats;
    };
};

export type TpAvalancheUserStatsMc = {
    amount_added: TpUserStatAvax;
    amount_removed: TpUserStatAvax;
    current_balance: TpUserStatAvax;
    gro_gate: TpGroGate;
    launch_timestamp: string;
    net_amount_added: TpUserStatAvax;
    net_returns: TpUserStatAvax;
    network_id: string;
    status: string;
    transaction: TpUserTransaction;
};

export type TpVestingAirdrop = {
    amount: string;
    claim_initialized: boolean;
    claimable_amount: string;
    claimed_amount: string;
    name: string;
    proofs: string[];
    token: string;
};

export type TpUserStatsMc = {
    address: string;
    avalanche: TpAvalancheUserStatsMc;
    current_timestamp: string;
    ethereum: {
        address: string;
        airdrops: AirdropStats[];
        amount_added: TpUserStat;
        amount_removed: TpUserStat;
        current_balance: TpUserStat;
        gro_balance_combined: string;
        launch_timestamp: string;
        net_amount_added: TpUserStat;
        net_returns: TpUserStat;
        network_id: string;
        pools: PoolsStats;
        status: string;
        transaction: TpUserTransaction;
        vest_bonus: VestBonusStat;
        vesting_airdrop: TpVestingAirdrop;
    };

    mc_totals: {
        amount_added: McTotal;
        amount_removed: McTotal;
        current_balance: McTotal;
        net_amount_added: McTotal;
        net_returns: McTotal;
    };
    network: string;
    status: string;
};

type TpGroGateVault = {
    base_allowance: string;
    base_allowance_claimed: string;
    claimable: string | boolean;
    claimable_allowance: string;
    remaining_allowance: string;
};

type McTotal = {
    avalanche: string;
    ethereum: string;
    total: string;
};

export type TpUserStatAvax = { [key in VaultAvaxNames]?: string } & {
    total: string;
};

type TpUserStat = {
    [TOKENS.PWRD]: string;
    [TOKENS.GVT]: string;
    total: string;
};

export type ChartPoint = {
    date: number;
    gvt: number;
    pwrd: number;
};

export enum APYPeriods {
    all_time_apy = 'response3',
    last7d_apy = 'response1',
    last_month_apy = 'response2',
}

export type HistoricalAPY = {
    [APYPeriods.last7d_apy]: {
        attribute: string;
        end: string;
        frequency: string;
        results: ChartPoint[];
        start: string;
    };
    [APYPeriods.last_month_apy]: {
        attribute: string;
        end: string;
        frequency: string;
        results: ChartPoint[];
        start: string;
    };
    [APYPeriods.all_time_apy]: {
        attribute: string;
        end: string;
        frequency: string;
        results: ChartPoint[];
        start: string;
    };
    current_timestamp: number;
    launch_timestamp: number;
    network: string;
};

export type HistoricalAPYJSON = {
    historical_stats: HistoricalAPY;
};

export type EtherscanGas = {
    message: string;
    result: {
        FastGasPrice: string;
        LastBlock: string;
        ProposedGasPrice: string;
        SafeGasPrice: string;
        gasUsedRatio: string;
        suggestBaseFee: string;
    };
    status: string;
};

export type BlockNativeAPI = {
    blockPrices: {
        baseFeePerGas: number;
        blockNumber: number;
        estimatedPrices: {
            confidence: number;
            maxFeePerGas: number;
            maxPriorityFeePerGas: number;
            price: number;
        }[];
    }[];
};
