/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { web3Instance } from '../../modules/app/services';
import { vesting } from '../contract-info';
import { Vesting } from './types/vesting';

let instance: Vesting;

export function vestingContract(): Vesting {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(vesting.abi, vesting.address) as any as Vesting;
    return instance;
}
