/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { gRouterContract } from '../abis';
import { convertToBN } from './web3.helpers';

type DepositWithPermit = {
    amount: BigNumber;
    deadline: number;
    minAmount: BigNumber;
    r: string;
    s: string;
    tokenIndex: number;
    tranche: boolean;
    v: number;
};

type DepositWithAllowedPermit = {
    amount: BigNumber;
    deadline: number;
    minAmount: BigNumber;
    nonce: string;
    r: string;
    s: string;
    tokenIndex: number;
    tranche: boolean;
    v: number;
};

type RegularDeposit = {
    amount: BigNumber;
    minAmount: BigNumber;
    tokenIndex: number;
    tranche: boolean;
};

type WithdrawWithMinAmount = {
    amount: BigNumber;
    minAmount: BigNumber;
    tokenIndex: number;
    tranche: boolean;
};

export function depositWithPermit({
    amount,
    deadline,
    minAmount,
    r,
    s,
    tokenIndex,
    tranche,
    v,
}: DepositWithPermit) {
    return gRouterContract().methods.depositWithPermit(
        convertToBN(amount),
        tokenIndex,
        tranche,
        convertToBN(minAmount),
        deadline,
        v,
        r,
        s,
    );
}

export function depositWithAllowedPermit({
    amount,
    deadline,
    minAmount,
    nonce,
    r,
    s,
    tokenIndex,
    tranche,
    v,
}: DepositWithAllowedPermit) {
    return gRouterContract().methods.depositWithAllowedPermit(
        convertToBN(amount),
        tokenIndex,
        tranche,
        convertToBN(minAmount),
        deadline,
        nonce,
        v,
        r,
        s,
    );
}

export function regularDeposit({ amount, minAmount, tokenIndex, tranche }: RegularDeposit) {
    return gRouterContract().methods.deposit(
        convertToBN(amount),
        tokenIndex,
        tranche,
        convertToBN(minAmount),
    );
}

export function withdrawWithMinAmount({
    amount,
    minAmount,
    tokenIndex,
    tranche,
}: WithdrawWithMinAmount) {
    return gRouterContract().methods.withdraw(
        convertToBN(amount),
        tokenIndex,
        tranche,
        convertToBN(minAmount),
    );
}
