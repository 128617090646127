/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { sanctionOracle } from '../contract-info';
import { SanctionOracle } from './types/sanction-oracle';

let instance: SanctionOracle;

export function sanctionOracleContract(): SanctionOracle {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(sanctionOracle.abi, sanctionOracle.address) as any as SanctionOracle;
    return instance;
}
