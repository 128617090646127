/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { routerOracleContract } from '../abis/routerOracleContract';
import { convertToBN } from './web3.helpers';

export async function routerOracleUsdTostable(amount: BigNumber, index: number) {
    const result = await routerOracleContract()
        .methods.usdToStable(convertToBN(amount), index)
        .call();
    const [oracleAmount] = Object.values(result);

    return new BigNumber(oracleAmount as string);
}

export async function routerOracleStableToUSD(amount: BigNumber, index: number) {
    const result = await routerOracleContract()
        .methods.stableToUsd(convertToBN(amount), index)
        .call();
    const [oracleAmount] = Object.values(result);
    return new BigNumber(oracleAmount as string);
}
