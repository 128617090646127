import React from 'react';
import { css } from '@emotion/react';
import { Button, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setConnectWalletModalOpened } from '../store/wallet.reducers';
import {
    selectHardwareWalletLoading,
    selectHasWalletConnected,
    selectWalletLoading,
} from '../store/wallet.selectors';

export function ConnectWalletButton({
    fullWidth = false,
    size = 'medium',
}: {
    fullWidth?: boolean;
    size?: 'small' | 'medium' | 'large' | undefined;
}): React.ReactElement | null {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isConnected = useAppSelector(selectHasWalletConnected);
    const isWalletLoading = useAppSelector(selectWalletLoading);
    const hardwareWalletLoading = useAppSelector(selectHardwareWalletLoading);

    const buttonLoading = css`
        background-color: transparent;
        border: 1px solid ${theme.palette.grey[400]};
        color: ${theme.palette.grey[400]};
    `;

    function onClickConnect(): void {
        dispatch(setConnectWalletModalOpened(true));
    }

    return !isConnected ? (
        <Button
            color="secondary"
            css={isWalletLoading || hardwareWalletLoading ? buttonLoading : undefined}
            data-testid="connect-wallet"
            fullWidth={fullWidth}
            sx={{height: '48px', width: '280px'}}
            variant="contained"
            onClick={onClickConnect}
        >
            Connect Wallet
        </Button>
    ) : null;
}
