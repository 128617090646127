import BigNumber from 'bignumber.js';
import { Status, StatusState } from '../../app/app.types';

export type Position = {
    available: BigNumber;
    balance: BigNumber;
    id: number;
    investor?: boolean;
    selectedId?: number;
    startTime: number;
    stopTime: number;
    total: BigNumber;
    vested: BigNumber;
    withdrawn: BigNumber;
};

export type TeamVestingState = {
    openClaimModal: boolean;
    positions: Position[];
    selectedId?: number;
} & StatusState;

export const initialTeamVestingState: TeamVestingState = {
    error: undefined,
    openClaimModal: false,
    positions: [],
    selectedId: undefined,
    status: Status.idle,
};
