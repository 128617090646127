/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */

import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    Collapse,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Typography,
    useTheme,
} from '@mui/material';
import AVAXIcon from '../../assets/images/AVAXNETWORK.svg';
import ETHIcon from '../../assets/images/ETHNETWORK.svg';
import {
    AVAX_NETWORK,
    ETH_NETWORK,
    isMainnet,
    LOCAL_FORK,
    LOCALHOST_NETWORK,
} from '../../constants';
import { useAppSelector } from '../../modules/app/hooks';
import { useChangeNetwork } from '../../modules/wallet/hooks/useChangeNetwork';
import {
    selectHasWalletConnected,
    selectIsAvalancheNetwork,
    selectIsCorrectNetwork,
    selectWalletLoading,
} from '../../modules/wallet/store/wallet.selectors';
import { useGetSizes } from '../../theme/hooks/useGetSizes';
import { orange } from '../../theme/palette';

type TpNetwork = {
    data: {
        blockExplorerUrls?: string[];
        chainId: string;
        chainName?: string;
        nativeCurrency?: {
            decimals: number;
            name: string;
            symbol: string;
        };
        rpcUrls?: string[];
    };

    icon: string;
    label: string;
};

const AVAX = {
    data: AVAX_NETWORK,
    icon: AVAXIcon,
    label: 'Avalanche',
};

const MAINNET = {
    data: ETH_NETWORK,
    icon: ETHIcon,
    label: isMainnet ? 'Ethereum' : 'Mainnet',
};

const LOCAL_HOST = {
    data: LOCALHOST_NETWORK,
    icon: ETHIcon,
    label: 'Localhost',
};

export function SelectBlockchain(): React.ReactElement {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState<TpNetwork | undefined>();
    const theme = useTheme();
    const { isMediumSize, isMobileDisplay, isXsSize } = useGetSizes(theme);
    const isAvalanche = useAppSelector(selectIsAvalancheNetwork);
    const isCorrectNetwork = useAppSelector(selectIsCorrectNetwork);
    const isWalletLoading = useAppSelector(selectWalletLoading);
    const isConnected = useAppSelector(selectHasWalletConnected);

    const { onChangeNetwork } = useChangeNetwork({ setIsLoading, setSelectedNetwork });

    const styles = css`
        padding-bottom: 0px;
        color: white;
        background-color: ${theme.palette.grey[700]};
        margin: ${theme.spacing(0, 1.5, 0)};
        width: 164px;
        height: 40px;
        border-radius: ${open ? '8px 8px 0px 0px' : '8px'};

        &.Mui-selected,
        &.Mui-selected:hover {
            border-right: 2px solid currentColor;
            color: ${theme.palette.grey[100]};
            background-color: ${theme.palette.grey[700]};
        }
        &:hover {
            background-color: ${theme.palette.grey[600]};
        }
        @media (max-width: 600px) {
            max-width: 32px;
            width: 32px;
            height: 32px;
            padding: 0px;
        }
    `;

    const noRadius = css`
        border-radius: 0px;
    `;

    const bottomRadius = css`
        border-radius: 0px 0px 8px 8px;
    `;

    const changeNetwork = css`
        background-color: ${orange[300]};
        padding-top: 0;
        color: ${theme.palette.common.black};
        &:hover {
            background-color: ${theme.palette.warning.light};
        }
    `;

    const listStyle = css`
        position: absolute;
        background-color: transparent;
        z-index: 1;
    `;

    const disabled = css`
        padding-bottom: 0px;
        background-color: ${theme.palette.grey[700]};
        margin: ${theme.spacing(0, 1.5, 0)};
        width: 164px;
        height: 40px;
        border-radius: 0px 0px 8px 8px;
        cursor: pointer;
        &:hover {
            background-color: ${theme.palette.grey[600]};
        }
        @media (max-width: 600px) {
            width: 140px;
        }
    `;

    const imageStyle = css`
        margin: auto;
        vertical-align: middle;
        display: inline;
        @media (max-width: 600px) {
            margin-left: 8px;
            height: 18px;
        }
    `;

    const marginLeft = css`
        @media (max-width: 600px) {
            margin-left: -8px;
        }
    `;

    const option = css`
        @media (max-width: 600px) {
            margin-left: 8px;
        }
    `;

    const loadingSpinner = css`
        @media (max-width: 600px) {
            margin: 7px 0px 0px 7px;
        }
    `;

    const options = useMemo(() => {
        if (!isCorrectNetwork)
            return LOCAL_FORK
                ? [{ ...LOCAL_HOST, cssClass: bottomRadius }]
                : [
                      { ...MAINNET, cssClass: noRadius },
                      { ...AVAX, cssClass: bottomRadius },
                  ];
        if (LOCAL_FORK) return [];
        if (isMainnet) {
            return isAvalanche
                ? [{ ...MAINNET, cssClass: bottomRadius }]
                : [{ ...AVAX, cssClass: bottomRadius }];
        }
        return isAvalanche
            ? [{ ...MAINNET, cssClass: bottomRadius }]
            : [{ ...AVAX, cssClass: bottomRadius }];
    }, [isAvalanche, isCorrectNetwork, bottomRadius, noRadius]);

    const currentNetwork = useMemo(
        () => (LOCAL_FORK ? LOCAL_HOST : isAvalanche ? AVAX : MAINNET),
        [isAvalanche],
    );

    return isConnected ? (
        <React.Fragment>
            <ClickAwayListener onClickAway={(): void => setOpen(false)}>
                <ListItemButton
                    css={
                        isCorrectNetwork || selectedNetwork || isWalletLoading
                            ? styles
                            : [styles, changeNetwork]
                    }
                    onClick={(): void => {
                        setOpen(!open);
                    }}
                >
                    <Grid container spacing={isCorrectNetwork ? 3 : 0}>
                        {isCorrectNetwork || selectedNetwork ? (
                            <React.Fragment>
                                <Grid item sx={{ marginRight: '4px' }} xs={2}>
                                    {isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            css={loadingSpinner}
                                            size={20}
                                        />
                                    ) : (
                                        <img
                                            alt=""
                                            css={imageStyle}
                                            src={selectedNetwork?.icon || currentNetwork.icon}
                                        />
                                    )}
                                </Grid>
                                {(!isMobileDisplay || isMediumSize) && (
                                    <React.Fragment>
                                        <Grid item xs={7}>
                                            <Typography variant="nav">
                                                {selectedNetwork?.label || currentNetwork.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            {open ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <Grid item xs={11}>
                                {isXsSize ? (
                                    <WarningAmberIcon sx={{ ml: '3.7px', mt: '3px' }} />
                                ) : (
                                    <Typography variant="nav">Change Network</Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </ListItemButton>
            </ClickAwayListener>
            <Collapse unmountOnExit in={open} timeout="auto">
                <List disablePadding component="div" css={listStyle}>
                    {options.map(({ cssClass, ...opt }) => (
                        <Box key={opt.label} onClick={(): any => onChangeNetwork(opt)}>
                            <ListItem css={[disabled, cssClass]}>
                                <Box display="flex">
                                    <img alt="" css={[imageStyle, marginLeft]} src={opt.icon} />
                                    <Typography css={option} ml={1} variant="nav">
                                        {opt.label}
                                    </Typography>
                                </Box>
                            </ListItem>
                        </Box>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    ) : (
        <Box />
    );
}
