import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PpWC } from '../../utilTypes';

const NotFound = lazy(() => import('../utils/NotFound'));

type PpSwitch404 = {
    /**
     * Either displays the 404 page on the current route,
     * or, if redirect is true, redirects to the 404 url
     */
    redirect?: boolean;
};

export function Switch404({ children, redirect = false }: PpWC<PpSwitch404>): React.ReactElement {
    return (
        <Switch>
            {children}
            {redirect && <Redirect to="/404" />}
            <Route component={NotFound} path="*" />
        </Switch>
    );
}
