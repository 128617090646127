import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { add, format } from 'date-fns';
import { useAppSelector } from '../../app/hooks';
import { getUnixTimestamp } from '../../utils/date.helpers';
import timerLogo from '../assets/timer.png';
import { selectVestingScheduleFinishDate } from '../store/rewards.selectors';

type TpNewDateInfo = {
    amount: BigNumber;
    extend?: boolean;
} & Pick<BoxProps, 'sx'>;

export function NewDateInfo({ amount, extend, sx }: TpNewDateInfo): React.ReactElement {
    let date = useAppSelector(selectVestingScheduleFinishDate(amount));
    const currentTime = getUnixTimestamp();
    if (extend) {
        date = format(add(new Date(currentTime * 1000), { years: 1 }), 'dd MMM yyyy');
    }

    return (
        <Box display="flex" sx={sx}>
            <img alt="gro_logo" height="32px" src={timerLogo} width="32px" />

            <Box display="flex" flexDirection="column" justifyContent="flex-start" ml={1.5}>
                <Typography variant="h3">{date}</Typography>
                <Typography color="text.secondary" variant="nav">
                    new vesting schedule end date
                </Typography>
            </Box>
        </Box>
    );
}
