/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { AmountPositionType } from '../../lib/web3.types';

export function formatAsCurrency(amount: BigNumber, decimalPlaces = 2): string {
    const amountAsNumber =
        !amount || amount.isNaN() ? 0 : amount.decimalPlaces(decimalPlaces).toNumber();

    const formatter = new Intl.NumberFormat('en-US', {
        compactDisplay: 'short',
        currency: 'USD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: decimalPlaces,

        minimumFractionDigits: decimalPlaces,
        // only ever use $ / £, not USD$
        signDisplay: 'never',
        style: 'currency',
    });
    return formatter.format(amountAsNumber);
}

export function formatNumber(amount = new BigNumber(0), decimalPlaces = 2): string {
    const amountAsNumber =
        !amount || amount.isNaN()
            ? 0
            : amount.decimalPlaces(decimalPlaces, BigNumber.ROUND_FLOOR).toNumber();

    const formatter = new Intl.NumberFormat('en-US', {
        compactDisplay: 'short',
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
        signDisplay: 'never',
    });
    return formatter.format(amountAsNumber);
}

function splitAtIndex(str: string, index: number): Array<string> {
    return [str.substring(0, index), str.substring(index)];
}

// Used to switch when the number will be formatted in the compact way
const AMOUNT_FORMAT_SWITCH = 1000000;

export function formatToParts(amount: BigNumber, sign = false): Intl.NumberFormatPart[] {
    const formatter = new Intl.NumberFormat('en-US', {
        compactDisplay: 'short',
        currency: 'USD',
        currencyDisplay: 'symbol', // only ever use $ / £, not USD$
        maximumSignificantDigits: 20,
        minimumSignificantDigits: 20,
        signDisplay: sign ? 'exceptZero' : 'never',
        style: 'currency',
    });

    return formatter.formatToParts(amount.toNumber());
}

export function renderFormattedAmount(
    amount: BigNumber,
    sign: boolean,
    extended: boolean,
    positionType: string,
): React.ReactElement[] | string {
    if (!amount) {
        return '';
    }

    const amountAsParts = formatToParts(amount, sign);
    const absoluteNumber = amount.abs();

    let maximumFractionDigits = 0;

    if (absoluteNumber.isLessThan(1000) && absoluteNumber.isGreaterThan(0)) {
        maximumFractionDigits = 4;
    }

    if (
        absoluteNumber.isGreaterThanOrEqualTo(1000) &&
        absoluteNumber.isLessThan(AMOUNT_FORMAT_SWITCH)
    ) {
        maximumFractionDigits = 2;
    }

    const numberString = amountAsParts
        .filter(({ type }) => {
            // Remove decimal indicator or fraction if digits is 0
            if (maximumFractionDigits === 0) {
                if (type === 'fraction' || type === 'decimal') {
                    return false;
                }
            }
            return true;
        })
        .map(({ type, value }, index) => {
            switch (type) {
                case 'fraction': {
                    const [decHighlight = '', decGrey = ''] = splitAtIndex(
                        value.substr(0, maximumFractionDigits),
                        2,
                    );

                    return (
                        <Typography
                            key={`${value}-${index}`}
                            component="span"
                            sx={{
                                color:
                                    positionType === AmountPositionType.RETURN &&
                                    amount.isGreaterThan(0)
                                        ? 'success.main'
                                        : 'text.main',
                            }}
                            variant="inherit"
                        >
                            {decHighlight}
                            {extended && (
                                <Typography
                                    component="span"
                                    sx={{ color: 'text.quaternary' }}
                                    variant="inherit"
                                >
                                    {decGrey}
                                </Typography>
                            )}
                        </Typography>
                    );
                }
                case 'plusSign':
                    return (
                        <Typography
                            key={`${value}-${index}`}
                            component="span"
                            sx={{ color: 'success.main' }}
                            variant="inherit"
                        >
                            {value}
                        </Typography>
                    );
                case 'minusSign':
                    return (
                        <Typography
                            key={`${value}-${index}`}
                            component="span"
                            sx={{ color: 'text.main' }}
                            variant="inherit"
                        >
                            {value}
                        </Typography>
                    );
                default:
                    return (
                        <Typography
                            key={`${value}-${index}`}
                            component="span"
                            sx={{
                                color:
                                    positionType === AmountPositionType.RETURN &&
                                    amount.isGreaterThan(0)
                                        ? 'success.main'
                                        : 'text.main',
                            }}
                            variant="inherit"
                        >
                            {value}
                        </Typography>
                    );
            }
        });
    return numberString;
}
