import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import { formatNumber } from '../../utils/currency.helpers';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import { InfoLine } from '../components/InfoLine';
import { RedeemGroModal } from '../components/RedeemGroModal';
import { useForceNetworkChange } from '../hooks/useForceNetworkChange';
import { useGetRedeemData } from '../hooks/useGetRedeemData';
import {
    selectCanClaim,
    selectPricePerShareUnwind,
    selectSharesAvailableUnwind,
    selectTotalDeposited,
    selectUnwindDeadline,
    selectUserBalanceUnwind,
} from '../store/unwind.selectors';
import { Alloy } from './Alloy';

export enum TypeRedeem {
    redeemGro = ExchangeDirection.redeemGro,
    deposit = ExchangeDirection.depositGro,
    withdraw = ExchangeDirection.withdrawGro,
}

export function Redeem(): React.ReactElement {
    useGetRedeemData();

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState<TypeRedeem>(TypeRedeem.deposit);

    const canRedeem = useAppSelector(selectCanClaim);

    const pricePerShare = useAppSelector(selectPricePerShareUnwind);
    const balance = useAppSelector(selectUserBalanceUnwind);
    const sharesAvailable = useAppSelector(selectSharesAvailableUnwind);
    const deadline = useAppSelector(selectUnwindDeadline);
    const totalDeposited = useAppSelector(selectTotalDeposited);
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);

    const formattedDeadline = format(deadline, 'd MMM HH:mm:ss O');

    useForceNetworkChange({ network: 'mainnet' });

    const color = useMemo(() => (canRedeem ? '#E8CBFF' : '#FFC9C6'), [canRedeem]);

    const styles = {
        button: css`
            height: 48px;
        `,
        inputWrapper: css`
            border-radius: 21px;
            background: #051c2e;
            height: 506px;
            width: ${!isXsSize ? '444px' : 'auto'};
        `,
        link: css`
            text-decoration: none;
            color: #deb8fc;
        `,
        warning: css`
            border-radius: 16px;
            border: 1px solid
                ${canRedeem ? 'rgba(222, 184, 252, 0.20)' : 'rgba(254, 174, 168, 0.20)'};
            background: ${canRedeem
                ? 'linear-gradient(0deg, rgba(180, 82, 255, 0.12) 0%, rgba(180, 82, 255, 0.12) 100%)'
                : 'linear-gradient(0deg, rgba(250, 119, 121, 0.12) 0%, rgba(250, 119, 121, 0.12) 100%)'};
        `,
    };

    function handleSubmit(typeRedeem: TypeRedeem): void {
        setIsOpen(true);
        setType(typeRedeem);
    }

    return (
        <Box alignItems="center" display="flex" flexDirection="column">
            <Box>
                <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center" mt={10} p={2}>
                    <Box maxWidth="444px">
                        <Typography fontWeight={600} mb={5} variant="h5">
                            Redeem GRO
                        </Typography>
                        <Typography fontWeight={600} variant="h1">
                            Redeem GRO for USDC
                        </Typography>
                        <Box css={styles.warning} mt={3} p={2}>
                            <Box>
                                <Typography color={color} fontWeight={500} variant="body2Small">
                                    {canRedeem
                                        ? 'Claims are now possible'
                                        : `Deposits must be done before ${formattedDeadline}`}
                                </Typography>
                            </Box>
                            <Typography color={color} sx={{ opacity: 0.5 }} variant="body2Small">
                                {canRedeem
                                    ? 'DAO members who deposited GRO tokens by October 25th can claim a prorated share of the DAO treasury. Majority of treasury is already consolidated and claimable but future treasury recoveries will also be available from the USDC redemption contract. Treasury consolidation is expected to be fully completed by 1 Jan 2024. Multiple claims can be made and will not affect the total claimable amount, but will incur more network fees. Redemption is possible indefinitely from the smart contracts as long as the Ethereum blockchain operates.'
                                    : 'After this date, no more GRO deposits can be made and depositors can start claiming treasury shares. Treasury consolidation is expected to be completed by 1 Jan 2024. All treasury recoveries will be available from the USDC redemption contract. Multiple claims can be made and will not affect the total amount, but will incur more network fees'}
                            </Typography>
                        </Box>
                        <Typography mt={3} variant="body2">
                            The{' '}
                            <a
                                css={styles.link}
                                href="https://snapshot.org/#/gro.xyz/proposal/0x8d4daa83895fe24a121d5e255e783b098ea634a21e6be8c4a5e6ed20c8d03dd1"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Gro DAO has voted to unwind itself
                            </a>
                            . The DAO is distributing its treasury to its members. During a 4 week
                            period, DAO members are able to deposit their GRO into a collection
                            contract. After the 4 week deadline, they can claim their pro-rated
                            share of the treasury. When, and if, further treasury recoveries are
                            made they will be made available through this same contract. There are
                            no penalties for making multiple claims other than extra network fees.
                        </Typography>
                    </Box>
                    <Box
                        css={styles.inputWrapper}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        mt={10}
                        p={4}
                    >
                        <Box>
                            <Typography fontWeight="900" mb={3.5} variant="h2">
                                GRO Redemption
                            </Typography>

                            <InfoLine
                                grey={canRedeem}
                                left="You’ve deposited"
                                right={`${formatNumber(balance)} GRO`}
                            />
                            <InfoLine
                                grey={canRedeem}
                                left="Total GRO deposited"
                                right={`${formatNumber(totalDeposited)} GRO`}
                            />
                            <InfoLine
                                bold={formattedDeadline}
                                grey={canRedeem}
                                left="Deposit period"
                                right="Open until "
                            />
                            <Divider sx={{ mb: 2 }} />
                            <InfoLine
                                grey={!canRedeem}
                                left="Claims"
                                right={`Starting from ${formattedDeadline}`}
                            />
                            <InfoLine
                                grey={!canRedeem}
                                left="Final treasury consolidation"
                                right="by 1 Jan 2024"
                            />
                            {canRedeem && (
                                <React.Fragment>
                                    <InfoLine
                                        grey={!canRedeem}
                                        left="GRO rate"
                                        right={`1 GRO : ${formatNumber(pricePerShare)} USDC`}
                                    />
                                    <InfoLine
                                        grey={!canRedeem}
                                        left="You can claim"
                                        right={`${formatNumber(sharesAvailable)} USDC`}
                                    />
                                </React.Fragment>
                            )}
                        </Box>
                        <Box>
                            <Box display="flex" gap={1} mb={1}>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    css={styles.button}
                                    disabled={canRedeem}
                                    variant="contained"
                                    onClick={(): void => handleSubmit(TypeRedeem.deposit)}
                                >
                                    Deposit GRO
                                </Button>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    css={styles.button}
                                    disabled={canRedeem || balance.isEqualTo(0)}
                                    variant="contained"
                                    onClick={(): void => handleSubmit(TypeRedeem.withdraw)}
                                >
                                    Withdraw GRO
                                </Button>
                            </Box>
                            <Button
                                fullWidth
                                color="secondary"
                                css={styles.button}
                                disabled={!canRedeem}
                                variant="contained"
                                onClick={(): void => handleSubmit(TypeRedeem.redeemGro)}
                            >
                                {!canRedeem && <LockIcon />}
                                Claim USDC
                            </Button>
                        </Box>
                    </Box>
                    <RedeemGroModal
                        isOpen={isOpen}
                        typeRedeem={type}
                        onClose={(): void => setIsOpen(false)}
                    />
                </Box>

                <Divider sx={{ mb: 4, mt: 2, width: '100%' }} />
                <Alloy />
            </Box>
        </Box>
    );
}
