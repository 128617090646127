/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { Token, TokenObject } from '../../../constants';
import { tokenToUsd } from '../../../lib/web3/convert';
import { fetchBalanceOf } from '../../../lib/web3/wallet';
import { removeTokenDecimals } from '../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../app/app.types';
import { fetchMaxWalletTokens } from '../../wallet/services/wallet.services';

export function getExchangeDirection(name: string): ExchangeDirection {
    switch (name) {
        case 'withdraw':
            return ExchangeDirection.withdraw;
        case 'deposit':
            return ExchangeDirection.invest;
        default:
            return ExchangeDirection.invest;
    }
}

type FromTokens = {
    conversions: TokenObject;
    tokens: TokenObject;
    tokensFrom: TokenObject;
    wallet: string;
};

export const getFromTokens = async ({
    conversions,
    tokens,
    tokensFrom,
    wallet,
}: FromTokens): Promise<[Token, BigNumber][]> =>
    Promise.all(
        Object.entries(tokens).map(async ([token]): Promise<[Token, BigNumber]> => {
            const sourceAmount = removeTokenDecimals(token as Token, tokensFrom[token as Token]);
            const usdAmount = tokenToUsd(token as Token, sourceAmount, conversions);

            // Removes all dust from the third decimal forward
            const usesAllBalance = await fetchMaxWalletTokens(
                wallet,
                token as Token,
                usdAmount,
                conversions,
                0.0099,
            );

            // If the user selected some amount (greater than 0)! and the remaining is less than 0.0099 usd, invest all.
            if (usesAllBalance && usdAmount.isGreaterThan(0)) {
                const balance = await fetchBalanceOf(wallet, token as Token);
                return [token as Token, balance];
            }

            return [token as Token, sourceAmount || new BigNumber(0)];
        }),
    );
