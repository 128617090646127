import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function usePercentValue(initValue: string): [string, Dispatch<SetStateAction<string>>] {
    const [value, setValue] = useState(initValue);
    useEffect(() => {
        const slip = parseFloat(value);
        if (slip < 0) {
            setValue('0');
        }
        if (slip > 100) {
            setValue('100');
        }
    }, [value]);

    return [value, setValue];
}
