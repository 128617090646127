/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { v4 as uuid } from 'uuid';
import { burner } from '../../../../lib/contract-info';
import { checkIsApproved } from '../../../../lib/web3/approve';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { createApproval } from '../../../exchange/store/exchange.helpers';
import {
    addWalletTransaction,
    setTransactionStatus,
} from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectExtendAmount, selectRevestAmount } from '../rewards.selectors';

export const generateLockMoreGroThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const wallet = selectWalletAccount(getState());
        const { token } = selectCurrentSessionTransaction(tid)(getState());
        const revestAmount = selectRevestAmount(getState());
        const extendAmount = selectExtendAmount(getState());

        const isApproved = await checkIsApproved(token, wallet, revestAmount, burner.address);
        const mmTid = uuid();

        if (revestAmount.isGreaterThan(0)) {
            createApproval(
                dispatch,
                isApproved,
                ExchangeDirection.revest,
                revestAmount,
                tid,
                token,
            );

            dispatch(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                addWalletTransaction({
                    amount: revestAmount,
                    direction: ExchangeDirection.revest,
                    meta: {
                        from: {
                            [token]: revestAmount,
                        },
                        to: {
                            [token]: revestAmount,
                        },
                    },
                    mmTid: uuid(),
                    status: TransactionStatus.idle,
                    tid,
                    token,
                    type: TransactionType.exchange,
                }),
            );
        }

        if (extendAmount.isGreaterThan(0)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatch(
                addWalletTransaction({
                    amount: extendAmount,
                    direction: ExchangeDirection.extend,
                    meta: {
                        from: {
                            [token]: extendAmount,
                        },
                        to: {
                            [token]: extendAmount,
                        },
                    },
                    mmTid,
                    status: TransactionStatus.idle,
                    tid,
                    token,
                    type: TransactionType.exchange,
                }),
            );
        }

        // Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
