import BigNumber from 'bignumber.js';
import {
    GrowthToken,
    PoolToken,
    StableTokenAvax,
    Token,
    TokenObject,
    TOKENS,
} from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';

export type Transaction = {
    confirmed?: boolean;
    direction: ExchangeDirection;
    from: TokenObject;
    id: string;
    queue: WalletTransaction[];
    slippage: BigNumber;
    timestamp: number;
    to: TokenObject;
    token: GrowthToken | PoolToken | StableTokenAvax | TOKENS.CUSDC | TOKENS.DURA | TOKENS.USDC;
    updatedTimestamp?: number;
} & TransactionStatusState;

export type WalletTransaction = {
    amount?: BigNumber;
    direction?: ExchangeDirection;
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: any;
    timestamp: number;
    token: Token;
    transactionHash?: string;
    type: TransactionType;
    updatedTimestamp: number;
    usdAmount?: BigNumber;
} & TransactionStatusState;

export enum TransactionType {
    approve = 'approve',
    exchange = 'exchange',
}

export enum TransactionStatus {
    confirmed = 'confirmed',
    error = 'error',
    idle = 'idle',
    loading = 'loading',
    pendingApproval = 'pendingApproval',
    pendingConfirmation = 'pendingConfirmation',
    pendingMmApproval = 'pendingMmApproval',
    ready = 'ready',
    timeout = 'timeout',
}

export type TransactionStatusState = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    status: TransactionStatus;
};

export type CurrentSessionTransactions = { [tid: string]: Transaction }; // key is TransactionID

export type TransactionsState = {
    currentSession: CurrentSessionTransactions;
};

export const initialTransactionsState: TransactionsState = {
    currentSession: {},
};
