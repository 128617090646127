/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { TokenObject } from '../../../constants';
import { Status } from '../../app/app.types';
import { initialWalletState } from './wallet.store';

const walletSlice = createSlice({
    initialState: initialWalletState,
    name: 'wallet',
    reducers: {
        clearValues: (state) => {
            state.error = undefined;
        },
        resetWallet: (state) => {
            state = { ...initialWalletState };
            return state;
        },

        setAccount: (state, { payload }: PayloadAction<string>) => {
            state.account = payload;
        },

        setConnectWalletModalOpened(state, { payload }: PayloadAction<boolean>) {
            state.isModalOpened = payload;
        },

        setCountry(state, { payload }: PayloadAction<string>) {
            state.country = payload;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError(state, { payload }: PayloadAction<any>) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload;
        },
        setHardwareWalletLoading(state, { payload }: PayloadAction<boolean>) {
            state.hardwareWalletLoading = payload;
        },
        setLoadingProvider(state, { payload }: PayloadAction<boolean>) {
            state.loadingProvider = payload;
        },
        setNetworkId(state, { payload }: PayloadAction<number>) {
            state.networkId = payload;
        },
        setNotificationText(state, { payload }: PayloadAction<string>) {
            state.notification = payload;
        },
        setOpenBlockModal(state, { payload }: PayloadAction<boolean>) {
            state.openBlockModal = payload;
        },
        setProviderName(state, { payload }: PayloadAction<string>) {
            state.providerName = payload;
        },
        setShowNotification(state, { payload }: PayloadAction<boolean>) {
            state.showNotification = payload;
        },
        setShowVolatility(state, { payload }: PayloadAction<boolean>) {
            state.showVolatility = payload;
        },
        setStakedBalances(
            state,
            {
                payload,
            }: PayloadAction<{
                claimableRewards: TokenObject;
                staked: TokenObject;
            }>,
        ) {
            state.staked = payload.staked;
            state.claimableRewards = payload.claimableRewards;
        },
        setTokenBalances(
            state,
            {
                payload,
            }: PayloadAction<{
                tokens: TokenObject;
                tokensInUsd: TokenObject;
            }>,
        ) {
            state.tokens = payload.tokens;
            state.tokensInUsd = payload.tokensInUsd;
        },
        setTotalGro(state, { payload }: PayloadAction<BigNumber>) {
            state.totalGro = payload;
        },
        setWalletBlocked(state, { payload }: PayloadAction<boolean>) {
            state.walletBlocked = payload;
        },
        setWalletStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
    },
});

export const {
    clearValues,
    resetWallet,
    setAccount,
    setConnectWalletModalOpened,
    setCountry,
    setError,
    setHardwareWalletLoading,
    setLoadingProvider,
    setNetworkId,
    setNotificationText,
    setOpenBlockModal,
    setProviderName,
    setShowNotification,
    setShowVolatility,
    setStakedBalances,
    setTokenBalances,
    setTotalGro,
    setWalletBlocked,
    setWalletStatus,
} = walletSlice.actions;

export const walletReducer = walletSlice.reducer;
