import { Theme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { getPoolVariantColors } from '../pools/pools.helpers';
import { TpPool } from '../stats/stats.types';
import { formatNumber } from '../utils/currency.helpers';
import { AirdropItem } from './airdrop.types';

export function fromartPoolIntoReward(
    pool: TpPool,
    theme: Theme,
    amount?: BigNumber,
): AirdropItem {
    return {
        amount: amount || new BigNumber(0),
        claimed: false,
        display_name: pool.display_name,
        hash: [''],
        name: pool.name,
        participated: false,
        pool: pool.type,
        pool_display_name: pool.display_type,
        timestamp: new BigNumber(0),
        token: pool.name,
        unlocked: true,
        url: pool.deposit_url,
        variant: getPoolVariantColors(pool, theme),
    };
}

export function formatAmountForProductCards(amount: BigNumber): string {
    const decimals = amount.isLessThan(1) && !amount.isZero() ? 2 : 0;
    return formatNumber(amount, decimals);
}

export function formatSmallPriceAmount(amount: BigNumber): string {
    const decimals = amount.isLessThan(0.01) && !amount.isZero() ? 5 : 2;
    const showLesserThan = amount.isLessThan(0.00001) && !amount.isZero();
    if (showLesserThan) return '<0.00001';
    return formatNumber(amount, decimals);
}
