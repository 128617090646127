/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable func-style */
import { Theme } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { add, format } from 'date-fns';
import { PoolToken, TOKENS } from '../../../constants';
import { addTokenDecimals } from '../../../lib/web3/web3.helpers';
import { Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { getPoolPID } from '../../pools/pools.helpers';
import { selectPools } from '../../pools/store/pools.selectors';
import {
    selectClaimableAirdrops,
    selectClaimedAirdrops,
    selectExpiredAirdrops,
    selectGroStatsMc,
    selectLockedAirdrops,
    selectTotalUnclaimedGroFromAirdrops,
    selectUserStatsMc,
} from '../../stats/store/stats.selectors';
import { getUnixTimestamp } from '../../utils/date.helpers';
import {
    selectClaimableRewardsTokens,
    selectWalletStatus,
} from '../../wallet/store/wallet.selectors';
import { emptyItem } from '../airdrop.types';
import { fromartPoolIntoReward } from '../rewards.helpers';
import { GROState } from './rewards.store';

export function selectRewardsState(state: RootState): GROState {
    return state.rewards;
}

export const selectUnvestedGRO = createSelector(
    selectRewardsState,
    (rewards): BigNumber => new BigNumber(rewards.unvestedGRO),
);

export const selectVestedGRO = createSelector(
    selectRewardsState,
    (rewards): BigNumber => new BigNumber(rewards.vestedGRO),
);

export const selectVestingBonus = createSelector(
    selectRewardsState,
    (rewards): BigNumber => new BigNumber(rewards.vestingBonus),
);

export const selectTotalVestingBonus = createSelector(
    selectRewardsState,
    (rewards): BigNumber => new BigNumber(rewards.totalVestingBonus),
);

export const selectTotalVesting = createSelector(
    selectVestedGRO,
    selectUnvestedGRO,
    (vested, unvested) => vested.plus(unvested),
);

export const selectVestedPercentage = createSelector(selectRewardsState, (rewards): number =>
    rewards.vestingPercentage > 100 ? 100 : rewards.vestingPercentage,
);

export const selectRewardsLoading = createSelector(
    selectRewardsState,
    (rewards) =>
        rewards.status !== Status.ready &&
        rewards.status !== Status.error &&
        rewards.status !== Status.idle,
);

export const selectClaimDate = createSelector(
    selectRewardsState,
    (rewards): number => rewards.userClaim,
);

export const selectClaimDelay = createSelector(
    selectRewardsState,
    (rewards): number => rewards.claimDelay,
);

export const selectCanClaim = createSelector(
    selectRewardsState,
    (rewards): boolean => rewards.canClaim,
);

export const selectStartClaimDate = createSelector(
    selectRewardsState,
    (rewards) => rewards.startClaimDate,
);

export const selectEndClaimDateFormatted = createSelector(selectRewardsState, (rewards) =>
    format(new Date(rewards.endClaimDate * 1000), 'dd MMM yyyy'),
);

export const selectVestingScheduleStartDate = (claimAmount: BigNumber) =>
    createSelector(
        selectStartClaimDate,
        selectUnvestedGRO,
        selectVestedGRO,
        (claimDate, unvestedGRO, vestedGRO) => {
            const currentTime = getUnixTimestamp();
            const numberGRO = unvestedGRO.plus(vestedGRO).toNumber();
            const currentAmount = claimAmount.toNumber();
            const oldStartTime = claimDate || currentTime;
            const date =
                (oldStartTime * numberGRO + currentTime * currentAmount) /
                (currentAmount + numberGRO);
            const result = isNaN(date) ? currentTime : date;
            return result;
        },
    );

export const selectVestingScheduleFinishDate = (claimAmount: BigNumber) =>
    createSelector(selectVestingScheduleStartDate(claimAmount), (startDate) =>
        format(add(new Date(startDate * 1000), { years: 1 }), 'dd MMM yyyy'),
    );

export const selectHasEndDate = createSelector(
    selectRewardsState,
    (rewards) => !!rewards.endClaimDate,
);

export const selectAirdropToClaim = createSelector(
    selectRewardsState,
    (rewards) => rewards.airdrop || emptyItem,
);

export const selectTotalLockedAmount = createSelector(
    selectRewardsState,
    (rewards) => new BigNumber(rewards.totalLockedGRO),
);

export const selectClaimablePoolRewards = (theme: Theme) =>
    createSelector(selectPools, selectClaimableRewardsTokens, (pools, poolRewards) =>
        pools
            .filter((pool) => !poolRewards[pool.name]?.isZero() && !!poolRewards[pool.name])
            .map((elem) => fromartPoolIntoReward(elem, theme, poolRewards[elem.name])),
    );

export const selectTotalAmountClaimablePoolRewards = (theme: Theme) =>
    createSelector(selectClaimablePoolRewards(theme), (pools) =>
        pools.reduce((curr, next) => curr.plus(new BigNumber(next.amount)), new BigNumber(0)),
    );

export const selectAvailableRewardsPid = (theme: Theme) =>
    createSelector(selectClaimablePoolRewards(theme), selectGroStatsMc, (pools, stats) =>
        pools.map((elem) => getPoolPID(elem?.token as PoolToken, stats.mainnet?.pools)),
    );

export const selectSpecificClaimablePoolReward = (theme: Theme, token: PoolToken) =>
    createSelector(selectClaimablePoolRewards(theme), (rewards) => {
        const reward = rewards.find((elem) => elem.token === token);
        return reward;
    });

export const selectAllClaimableRewards = (theme: Theme) =>
    createSelector(
        selectClaimablePoolRewards(theme),
        selectClaimableAirdrops,
        selectLockedAirdrops,
        (claimablePools, claimableAirdrops) => [
            ...[...claimablePools, ...claimableAirdrops].sort((a, b) =>
                b.amount.minus(a.amount).toNumber(),
            ),
        ],
    );

export const selectAllRewards = (theme: Theme) =>
    createSelector(
        selectAllClaimableRewards(theme),
        selectClaimedAirdrops,
        selectExpiredAirdrops,
        (claimable, claimed, expired) => [...claimable, ...claimed, ...expired],
    );

export const selectPendingGRO = createSelector(
    selectClaimableRewardsTokens,
    selectTotalUnclaimedGroFromAirdrops,
    selectVestingBonus,
    selectCanClaim,
    (rewards, unclaimedFromAirdrops, vestingBonus, canClaimBonus) =>
        Object.values(rewards).reduce(
            (curr, next) => next.plus(curr),
            unclaimedFromAirdrops.plus(canClaimBonus ? vestingBonus : new BigNumber(0)),
        ),
);

export const selectTotalGRO = createSelector(
    selectPendingGRO,
    selectVestedGRO,
    selectUnvestedGRO,
    (pendingGRO, vestedGRO, unvestedGRO) => pendingGRO.plus(vestedGRO).plus(unvestedGRO),
);

export const selectProjectedVestingBonus = createSelector(
    selectTotalLockedAmount,
    selectPendingGRO,
    selectTotalVestingBonus,
    (totalLockedAmount, pendingGRO, totalVestingBonus) => {
        const pending = pendingGRO.multipliedBy(0.9);
        const pendingPlusLocked = pending.plus(totalLockedAmount);
        return pending.dividedBy(pendingPlusLocked).multipliedBy(totalVestingBonus);
    },
);

export const selectIsRewardsPageLoading = createSelector(
    selectRewardsLoading,
    selectWalletStatus,
    (rewardsLoading, isWalletLoading) => rewardsLoading || isWalletLoading === Status.loading,
);

export const selectRevestAmount = createSelector(
    selectRewardsState,
    (rewards) => rewards.lockMore.revest,
);

export const selectExtendAmount = createSelector(
    selectRewardsState,
    (rewards) => rewards.lockMore.extend,
);

export const selectVestingBonusAPY = createSelector(
    selectTotalVestingBonus,
    selectTotalLockedAmount,
    (vesting, totalLocked) => {
        const time = new BigNumber(52 / 4);
        return vesting.multipliedBy(time).dividedBy(totalLocked);
    },
);

export const selectVestingBonusPct = (newLocked: BigNumber) =>
    createSelector(
        selectTotalVestingBonus,
        selectTotalLockedAmount,
        selectUnvestedGRO,
        (vesting, totalLocked, userLocked) => {
            const userVesting = userLocked.dividedBy(totalLocked).multipliedBy(vesting);

            const userNewVesting = userLocked
                .plus(newLocked)
                .dividedBy(totalLocked)
                .multipliedBy(vesting);

            const finalVesting = userVesting.isZero()
                ? userNewVesting
                : userVesting.minus(userNewVesting);

            return finalVesting.dividedBy(userVesting.isZero() ? 100 : userVesting);
        },
    );

export const selectOpenClaim = createSelector(selectRewardsState, (rewards) => rewards.openClaim);

export const selectClaimToWallet = createSelector(
    selectRewardsState,
    (rewards) => rewards.claimToWallet,
);

export const selectExitToWallet = createSelector(
    selectRewardsState,
    (rewards) => new BigNumber(rewards.exitToWallet),
);

export const selectRewardsPid = createSelector(
    selectRewardsState,
    (rewards) => rewards.rewardsPid,
);

export const selectMaxLockedPeriod = createSelector(
    selectRewardsState,
    (rewards) => rewards.maxLockedPeriod,
);

export const selectCurrentUnlockedGroPct = (currentTime: number) =>
    createSelector(
        selectStartClaimDate,
        selectMaxLockedPeriod,
        (claimDate, maxLockedPeriod) => (currentTime - claimDate) / maxLockedPeriod,
    );

export const selectCurrentVestedGRO = (currentTime: number) =>
    createSelector(
        selectVestedGRO,
        selectUnvestedGRO,
        selectCurrentUnlockedGroPct(currentTime),
        (vestedGRO, unvestedGRO, pct) => vestedGRO.plus(unvestedGRO).multipliedBy(pct),
    );

export const selectNewUnlockedGroPct = (claimAmount: BigNumber) =>
    createSelector(
        selectVestingScheduleStartDate(claimAmount),
        selectMaxLockedPeriod,
        (startDate, maxLockedPeriod) => {
            const currentTime = getUnixTimestamp();
            return (currentTime - startDate) / maxLockedPeriod;
        },
    );

export const selectUnlockedTxnAmount = (txnAmount: BigNumber) =>
    createSelector(
        selectNewUnlockedGroPct(txnAmount),
        selectVestedGRO,
        selectUnvestedGRO,
        selectExtendAmount,
        selectCurrentVestedGRO(getUnixTimestamp()),
        (unlockedPct, vestedGRO, unvestedGRO, extendAmount, currentVested) =>
            extendAmount.isZero()
                ? vestedGRO
                      .plus(unvestedGRO)
                      .plus(txnAmount)
                      .multipliedBy(unlockedPct)
                      .minus(currentVested)
                : new BigNumber(0),
    );

export const selectLockedTxnAmount = (txnAmount: BigNumber) =>
    createSelector(selectUnlockedTxnAmount(txnAmount), (unlocked) => {
        const finalUnlocked = unlocked.isNaN() ? new BigNumber(0) : unlocked;
        return txnAmount.minus(finalUnlocked);
    });

export const selectLockExpectation = createSelector(
    selectRewardsState,
    (state) => state.lockExpectation,
);

export const selectLockMoreGroOpen = createSelector(
    selectRewardsState,
    (state) => state.openLockMoreGro,
);

export const selectUSTAmount = createSelector(
    selectUserStatsMc,
    (state) => new BigNumber(state?.ethereum.vesting_airdrop.amount || 0),
);

export const selectIsElegibleForUst = createSelector(
    selectUserStatsMc,
    (state) => (state?.ethereum.vesting_airdrop.proofs.length || []) > 0,
);

export const selectUSTProofs = createSelector(
    selectUserStatsMc,
    (state) => state?.ethereum.vesting_airdrop.proofs || [],
);

export const selectPWRDUstRewards = createSelector(
    selectUserStatsMc,
    (state) => new BigNumber(state?.ethereum.vesting_airdrop.claimed_amount || 0),
);

export const selectHasClaimedPwrd = createSelector(
    selectRewardsState,
    (rewards) => rewards.hasClaimedUst,
);

export const selectVestedPWRD = createSelector(selectRewardsState, (state) =>
    addTokenDecimals(TOKENS.PWRD, state.ustVested),
);

export const selectIsUstClaim = createSelector(selectRewardsState, (state) => state.isUstClaim);

export const selectLockedPWRD = createSelector(
    selectUSTAmount,
    selectVestedPWRD,
    selectPWRDUstRewards,
    (totalAmount, vestedAmount, claimed) => {
        const formatted = addTokenDecimals(TOKENS.PWRD, totalAmount);
        return formatted.minus(vestedAmount).minus(claimed);
    },
);

export const selectVestingPWRD = createSelector(
    selectLockedPWRD,
    selectVestedPWRD,
    (locked, vested) => locked.plus(vested),
);

export const selectVestingEndDateUst = createSelector(
    selectRewardsState,
    (state) => state.vestingEndDateUST,
);

export const selectNoVestedRewards = createSelector(
    selectIsUstClaim,
    selectVestedGRO,
    selectVestedPWRD,
    (isUstClaim, vestedGRO, vestedPwrd) => (isUstClaim ? vestedPwrd.isZero() : vestedGRO.isZero()),
);

export const selectLockedUst = createSelector(selectRewardsState, (rewards) => rewards.ustLocked);
