import React, { useMemo } from 'react';
import { Button, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { PoolToken } from '../../../constants';
import { useAppSelector } from '../../app/hooks';
import { getPoolPID } from '../../pools/pools.helpers';
import { selectPoolsStats } from '../../stats/store/stats.selectors';
import { selectWalletBlocked } from '../../wallet/store/wallet.selectors';
import { setAirdropToClaim, setOpenClaim, setRewardsPid } from '../store/rewards.reducer';
import { selectClaimablePoolRewards } from '../store/rewards.selectors';

export function ClaimAllBtn({ title }: { title?: string }): React.ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const claimablePoolRewards = useAppSelector(selectClaimablePoolRewards(theme));
    const poolStats = useAppSelector(selectPoolsStats);
    const walletBlocked = useAppSelector(selectWalletBlocked);

    const totalAmount = useMemo(
        () =>
            claimablePoolRewards.reduce(
                (curr, next) => curr.plus(new BigNumber(next.amount)),
                new BigNumber(0),
            ),
        [claimablePoolRewards],
    );

    const rewardsPid = useMemo(
        () =>
            claimablePoolRewards.map((reward) => {
                const pid = getPoolPID(reward.token as PoolToken, poolStats);
                return pid;
            }),
        [claimablePoolRewards, poolStats],
    );

    function onClaim(): void {
        const claim = {
            ...claimablePoolRewards[0],
            amount: totalAmount,
            display_name: 'Pool rewards',
        };
        dispatch(setRewardsPid(rewardsPid));
        dispatch(setAirdropToClaim(claim));
        dispatch(setOpenClaim(true));
    }
    return (
        <Button
            color="secondary"
            disabled={walletBlocked}
            sx={{ padding: '2px 6px' }}
            variant="contained"
            onClick={onClaim}
        >
            {title || 'Claim all'}
        </Button>
    );
}
