/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { reVestGRO } from '../../../../lib/web3/burner';
import { extendVesting } from '../../../../lib/web3/vesting';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { selectWalletAccount, selectWalletTokens } from '../../../wallet/store/wallet.selectors';
import { updateGROValuesThunk } from './updateGROValuesThunk';

export const executeLockMoreGROThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());
        const { queue } = selectCurrentSessionTransaction(tid)(getState());
        const balance = selectWalletTokens(getState())[TOKENS.GRO];
        const mmTransaction = queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        let method: NonPayableTransactionObject<any>;

        if (ExchangeDirection.revest === mmTransaction.direction) {
            const amount = mmTransaction.amount?.isGreaterThan(balance || new BigNumber(0))
                ? balance
                : mmTransaction.amount;
            const tokenAmount = removeTokenDecimals(TOKENS.GRO, amount);
            method = reVestGRO(tokenAmount);
        } else {
            method = extendVesting();
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            // Refecth Stats and wallet info after transaction complete
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
