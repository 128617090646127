/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { airdrops } from '../contract-info';
import { Airdrops } from './types/airdrops';

let instance: Airdrops;

export function airdropsContract(): Airdrops {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(airdrops.abi, airdrops.address) as any as Airdrops;
    return instance;
}
