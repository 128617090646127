/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
import { addTokenObjectDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { fetchTokenBalances } from '../../services/wallet.services';
import { setTokenBalances, setWalletStatus } from '../wallet.reducers';
import { selectIsAVAXNetwork, selectWalletAccount, selectWalletState } from '../wallet.selectors';

export const refreshBalancesThunk: AppActionThunk<Promise<boolean>> =
    () => async (dispatch, getState) => {
        const walletStatus = selectWalletState(getState());
        const address = selectWalletAccount(getState());

        // If the wallet is not initialized, don't do it.
        if (walletStatus.status === Status.error || !address) {
            return false;
        }

        try {
            const isAvax = selectIsAVAXNetwork(getState());
            const { tokens, tokensInUsd } = await fetchTokenBalances(address, isAvax);
            dispatch(
                setTokenBalances({
                    tokens: addTokenObjectDecimals(tokens),
                    tokensInUsd: addTokenObjectDecimals(tokensInUsd),
                }),
            );
            dispatch(setWalletStatus({ status: Status.ready }));
        } catch (e: any) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
        return true;
    };
