/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { teamVesting } from '../contract-info';
import { TeamVesting } from './types/teamVesting';

let instance: TeamVesting;

export function teamVestingContract(): TeamVesting {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(teamVesting.abi, teamVesting.address) as any as TeamVesting;
    return instance;
}
