import { PoolProviders, PoolToken, TOKENS } from '../../../../constants';

export const placeholderPools = {
    [TOKENS.GRO_SINGLE_SIDED]: {
        deposit_url: 'NA',
        disable: 'false',
        display_name: 'GRO',
        display_order: '1',
        display_type: 'Gro Pool',
        name: TOKENS.GRO_SINGLE_SIDED as PoolToken,
        pid: '0',
        remove_url: 'NA',
        required_tokens_num: '1',
        tokens: [TOKENS.GRO],
        type: PoolProviders.GRO,
    },
    [TOKENS.GRO]: {
        deposit_url: 'NA',
        disable: 'false',
        display_name: 'GRO',
        display_order: '1',
        display_type: 'Gro Pool',
        name: TOKENS.GRO_SINGLE_SIDED as PoolToken,
        pid: '0',
        remove_url: 'NA',
        required_tokens_num: '1',
        tokens: [TOKENS.GRO],
        type: PoolProviders.GRO,
    },
    [TOKENS.UNI_50_50]: {
        deposit_url:
            'https://app.uniswap.org/#/add/v2/0x4394be2135357833A9e18D5A73B2a0C629efE984/0x9892fFf05B42AdC940C251cA879D912Dfa94c731',
        disable: 'false',
        display_name: 'GRO / Vault',
        display_order: '2',
        display_type: 'Uniswap v2',
        name: TOKENS.UNI_50_50 as PoolToken,
        pid: '1',
        remove_url:
            'https://app.uniswap.org/#/remove/v2/0x4394be2135357833A9e18D5A73B2a0C629efE984/0x9892fFf05B42AdC940C251cA879D912Dfa94c731',

        required_tokens_num: '2',
        tokens: [TOKENS.GRO, TOKENS.GVT],
        type: PoolProviders.UNISWAP,
    },
    [TOKENS.UNI_USDC_50_50]: {
        deposit_url:
            'https://app.uniswap.org/#/add/v2/0xa553cda420072a759ac352dca4cec70709829614/0x9892fFf05B42AdC940C251cA879D912Dfa94c731',
        disable: 'false',
        display_name: 'GRO / USDC',
        display_order: '3',
        display_type: 'Uniswap v2',
        name: TOKENS.UNI_USDC_50_50 as PoolToken,
        pid: '2',
        remove_url:
            'https://app.uniswap.org/#/remove/v2/0xa553cda420072a759ac352dca4cec70709829614/0x9892fFf05B42AdC940C251cA879D912Dfa94c731',
        required_tokens_num: '2',
        tokens: [TOKENS.GRO, TOKENS.USDC],
        type: PoolProviders.UNISWAP,
    },
    [TOKENS.GVT_SINGLE_SIDED]: {
        deposit_url: 'NA',
        disable: 'false',
        display_name: 'Vault',
        display_order: '4',
        display_type: 'Vault Pool',
        name: TOKENS.GVT_SINGLE_SIDED as PoolToken,
        pid: '3',
        remove_url: 'NA',
        required_tokens_num: '1',
        tokens: [TOKENS.GVT],
        type: PoolProviders.GRO,
    },
    [TOKENS.PWRD_SINGLE_SIDED]: {
        deposit_url: 'NA',
        disable: 'false',
        display_name: 'PWRD',
        display_order: '6',
        display_type: 'PWRD Pool',
        name: TOKENS.PWRD_SINGLE_SIDED as PoolToken,
        pid: '6',
        remove_url: 'NA',
        required_tokens_num: '1',
        tokens: [TOKENS.PWRD],
        type: PoolProviders.GRO,
    },
    [TOKENS.CRV_META]: {
        deposit_url: 'https://curve.fi/factory/44/deposit',
        disable: 'false',
        display_name: 'PWRD-3CRV',
        display_order: '5',
        display_type: 'Curve Meta',
        name: TOKENS.CRV_META as PoolToken,
        pid: '4',
        remove_url: 'https://curve.fi/factory/44/withdraw',
        required_tokens_num: '1',
        staked: '0',
        tokens: [TOKENS.PWRD, TOKENS.DAI, TOKENS.USDC, TOKENS.USDT],
        tvl: '0',
        tvl_staked: '0',
        type: PoolProviders.CURVE,
        unstaked: '0',
    },
    [TOKENS.PWRD3CRV]: {
        deposit_url: 'https://curve.fi/factory/44/deposit',
        disable: 'false',
        display_name: 'PWRD-3CRV',
        display_order: '5',
        display_type: 'Curve Meta',
        name: TOKENS.CRV_META as PoolToken,
        pid: '4',
        remove_url: 'https://curve.fi/factory/44/withdraw',
        required_tokens_num: '1',
        staked: '0',
        tokens: [TOKENS.PWRD, TOKENS.DAI, TOKENS.USDC, TOKENS.USDT],
        tvl: '0',
        tvl_staked: '0',
        type: PoolProviders.CURVE,
        unstaked: '0',
    },
    [TOKENS.BAL_GRO_WETH]: {
        deposit_url:
            'https://app.balancer.fi/#/pool/0x702605f43471183158938c1a3e5f5a359d7b31ba00020000000000000000009f',
        disable: 'false',
        display_name: 'GRO 80% / WETH 20%',
        display_order: '0',
        display_type: 'Balancer v2',
        lp_usd_price: '0.8709478',
        name: TOKENS.BAL_GRO_WETH as PoolToken,
        pid: '5',
        remove_url: 'NA',
        required_tokens_num: '0',
        tokens: [TOKENS.GRO, TOKENS.WETH],
        type: PoolProviders.BALANCER,
    },
};

export const basePool = {
    apy: {
        current: {
            pool_fees: '0',
            pool_incentive: '0',
            reward: '0',
            token: '0',
            total: '0',
        },
    },
    staked: '0', // lptokenstaker
    tvl: '0',
    tvl_staked: '0',
};
