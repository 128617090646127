/* eslint-disable complexity */

import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { getTokenInfo } from '../../../../lib/contract-info';
import { ExchangeDirection } from '../../../app/app.types';
import { isStableToken } from '../../../utils/token.helpers';
import {
    TransactionStatus,
    TransactionType,
    WalletTransaction,
} from '../../store/transactions.store';

type TpTransactionTitle = {
    transaction: WalletTransaction;
};

export function TransactionTitle({ transaction }: TpTransactionTitle): React.ReactElement {
    const color = useMemo(() => {
        const { status } = transaction;
        switch (status) {
            case TransactionStatus.idle:
            case TransactionStatus.confirmed:
                return 'text.tertiary';
            default:
                return 'text.primary';
        }
    }, [transaction]);

    const title = useMemo(() => {
        const { amount, direction, token, type } = transaction;
        const tokenInfo = getTokenInfo(token);

        // if (isStableToken(token)) {
        //     return amount && amount.isZero()
        //         ? `Reset ${tokenInfo.displayName}`
        //         : `Approve ${tokenInfo.displayName}`;
        // }

        if (type === TransactionType.exchange) {
            switch (direction) {
                case ExchangeDirection.invest:
                case ExchangeDirection.investAvax:
                    return `Deposit into ${tokenInfo.displayName}`;
                case ExchangeDirection.withdraw:
                case ExchangeDirection.withdrawAvax:
                    return `Withdraw from ${tokenInfo.displayName}`;
                case ExchangeDirection.extend:
                    return 'From Unlocked GRO';
                case ExchangeDirection.revest:
                    return 'From Wallet';
                case ExchangeDirection.migratePool:
                    return 'Activate your address';
                case ExchangeDirection.stake:
                    return `Stake ${tokenInfo.displayName} tokens`;
                case ExchangeDirection.unstake:
                    return `Unstake ${tokenInfo.displayName} tokens`;
                case ExchangeDirection.depositGro:
                    return 'Deposit GRO';
                case ExchangeDirection.withdrawGro:
                    return 'Withdraw GRO';
                case ExchangeDirection.redeemDura:
                    return 'Redeem DURA';
                case ExchangeDirection.depositUsdc:
                    return 'Deposit USDC';
                case ExchangeDirection.withdrawUsdc:
                    return 'Withdraw USDC';
                case ExchangeDirection.redeemGro:
                    return 'Redeem GRO';
                case ExchangeDirection.teamClaim:
                case ExchangeDirection.claimAirdrop:
                case ExchangeDirection.claimReward:
                case ExchangeDirection.claimBonus:
                case ExchangeDirection.claimAllowance:
                case ExchangeDirection.claimUst:
                    return 'Claim';
                case ExchangeDirection.exit:
                    return 'Exit';
                default:
                    return '';
            }
        }
        if (type === TransactionType.approve) {
            return `Approve ${tokenInfo.displayName}`;
        }

        return '';
    }, [transaction]);

    return (
        <Typography color={color} variant="h3">
            {title}
        </Typography>
    );
}
