import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { GroModal, GroModalHeader } from '../../../components/GroModal';

type DuraDetailsModalProps = {
    isOpen: boolean;
    onCloseModal: () => void;
};

export function DuraDetailsModal({
    isOpen,
    onCloseModal,
}: DuraDetailsModalProps): React.ReactElement {
    useEffect(() => {}, []);

    return (
        <GroModal
            isOpen={isOpen}
            overflow="visible"
            overflowY="auto"
            width="600px"
            onClose={onCloseModal}
        >
            <Box pb={4} px={3}>
                <GroModalHeader onClose={onCloseModal}>
                    <Typography variant="h1">Details on DURA and potential risk:</Typography>
                </GroModalHeader>
                <Box my={2.5}>
                    <Typography mb={2}>
                        DURA Tokens essentially function as representations of backed loans. As
                        these loans are repaid over time, the value of the DURA Tokens appreciates.
                        This mechanism implies that DURA Tokens cannot be readily sold or
                        liquidated before the loans reach their maturity. The latest Maturity date
                        is the 25.02.2025, the first maturity date is the 10.11.2024
                    </Typography>
                    <Typography mb={2}>
                        Given this structure, DURA can be likened to a fixed-income fund. The
                        liquidity necessary for the redemption of DURA primarily stems from the
                        interest payments received on the loans, as well as from the principal upon
                        the loan&apos;s maturity.
                    </Typography>
                    <Typography mb={2}>
                        However, it&apos;s crucial for token holders to understand and appreciate
                        the risks associated with DURA:
                    </Typography>
                    <Typography mb={2}>
                        Default Risk: There&apos;s the risk of non-payment or default from the
                        original borrowers, whose repayments back the DURA Tokens.
                    </Typography>
                    <Typography mb={2}>
                        Governance Risk: Any changes in the governance of the tokens can affect the
                        value or operations related to DURA.
                    </Typography>
                    <Typography mb={2}>
                        Smart Contract Risk: As with many blockchain-based initiatives,
                        there&apos;s the inherent risk of vulnerabilities or flaws in the smart
                        contracts governing the tokens.
                    </Typography>
                    <Typography mb={2}>
                        Vault Token Risks: The native vault tokens associated with the fund may
                        also carry inherent risks.
                    </Typography>
                    <Typography mb={2}>
                        We urge all participants to consider these risks carefully and conduct due
                        diligence before deciding to engage in the redemption process for DURA
                        Tokens.
                    </Typography>
                    <Typography mb={2}>
                        Find more information on the official AlloyX channel:{' '}
                        <a
                            css={css`
                                color: white;
                            `}
                            href="https://alloyx.gitbook.io/alloyx-documents/introduction/intro"
                            rel="noreferrer"
                            target="_blank"
                        >
                            https://alloyx.gitbook.io/alloyx-documents/introduction/intro
                        </a>
                    </Typography>
                    <Typography>
                        The information provided herein does not, and is not intended to,
                        constitute financial or investment advice. Instead, all content provided is
                        for informational purposes only. Users should conduct their own research,
                        analysis, and due diligence before making any financial decisions or
                        investments. Actions taken based on the information provided are at the
                        user&apos;s own risk. Neither the platform nor its affiliates will be
                        liable for any direct, indirect, incidental, consequential, or special
                        losses or damages of any kind arising out of or in connection with reliance
                        on or use of this information.
                    </Typography>
                </Box>
            </Box>
        </GroModal>
    );
}
