/**
 * Approve a transaction being done the first time
 */

import { BigNumber } from 'bignumber.js';
import { GrowthToken, TOKENS } from '../../constants';
import { tokenContract } from '../abis/tokenContract';
import { fetchUtilisationThreshold } from './gTranche';
import { addTokenDecimals } from './web3.helpers';

/**
 * Equivalent to groStats.tvl[GrowthToken] but onchain
 *
 *
 * @param sourceToken
 */
export async function fetchTotalAssets(sourceToken: GrowthToken): Promise<BigNumber> {
    const assets = await tokenContract(sourceToken).methods.totalAssets().call();
    return new BigNumber(assets);
}

export async function fetchTvlStats(): Promise<{
    total: BigNumber;
    totalGVT: BigNumber;
    totalPWRD: BigNumber;
    utilRatio: BigNumber;
    utilRatioThreshold: BigNumber;
}> {
    const totalGVT = fetchTotalAssets(TOKENS.GVT);
    const totalPWRD = fetchTotalAssets(TOKENS.PWRD);
    const utilRatioThreshold = fetchUtilisationThreshold();

    return Promise.all([totalGVT, totalPWRD, utilRatioThreshold]).then((response) => ({
        total: addTokenDecimals(TOKENS.PWRD, response[1]).plus(
            addTokenDecimals(TOKENS.GVT, response[0]),
        ),
        totalGVT: addTokenDecimals(TOKENS.GVT, response[0]),
        totalPWRD: addTokenDecimals(TOKENS.PWRD, response[1]),
        utilRatio: addTokenDecimals(TOKENS.PWRD, response[1]).dividedBy(
            addTokenDecimals(TOKENS.GVT, response[0]),
        ),
        utilRatioThreshold: response[2],
    }));
}
