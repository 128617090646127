/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { groBonusContract } from '../abis/groBonusContract';

export function claimVestingBonus(walletClaim: boolean) {
    return groBonusContract().methods.claim(!walletClaim);
}

export async function fetchVestingBonus(wallet: string): Promise<BigNumber> {
    const gro = await groBonusContract().methods['getPendingBonus(address)'](wallet).call();
    return new BigNumber(gro);
}

export async function fetchTotalVestingBonus(): Promise<BigNumber> {
    const gro = await groBonusContract().methods.totalBonus().call();
    return new BigNumber(gro);
}

export async function fetchClaimDelay(
    wallet: string,
): Promise<{ claimDelay: number; userClaim: number }> {
    const userClaim = await groBonusContract().methods.getLastClaimTime(wallet).call();
    const claimDelay = await groBonusContract().methods.claimDelay().call({ from: wallet });
    // console.log({ claimDelay: parseInt(claimDelay, 10), userClaim: parseInt(userClaim, 10) })
    return { claimDelay: parseInt(claimDelay, 10), userClaim: parseInt(userClaim, 10) };
}

export async function fetchUserCanClaim(wallet: string): Promise<boolean> {
    return groBonusContract().methods.canClaim().call({ from: wallet });
}
