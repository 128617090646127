import React from 'react';
import { css } from '@emotion/react';
import { Box, BoxProps, useTheme } from '@mui/material';

type TpPositionWrapper = {
    children: React.ReactNode;
    testId: string;
} & Pick<BoxProps, 'sx'>;

export function PositionWrapper({ children, sx, testId }: TpPositionWrapper): React.ReactElement {
    const theme = useTheme();

    const styles = {
        wrapper: css`
            border-radius: 15px;
            background: ${theme.palette.primary.dark};
        `,
    };
    return (
        <Box
            component="section"
            css={styles.wrapper}
            data-testid={testId}
            display="flex"
            position="relative"
            sx={{ ...sx, p: 3 }}
        >
            {children}
        </Box>
    );
}
