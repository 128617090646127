/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { getUnixTimestamp } from '../../modules/utils/date.helpers';
import { vestingContract } from '../abis';
import { convertToBN } from './web3.helpers';

export async function fetchUnvestedGRO(wallet: string): Promise<BigNumber> {
    const gro = await vestingContract().methods.vestingBalance(wallet).call();
    return new BigNumber(gro);
}

export async function fetchVestedGRO(wallet: string): Promise<BigNumber> {
    const gro = await vestingContract().methods.vestedBalance(wallet).call();
    return new BigNumber(gro);
}

export async function fetchVestingPercentage(
    wallet: string,
): Promise<{ endDate: number; percentage: number; startDate: number }> {
    try {
        const response = await vestingContract().methods.getVestingDates(wallet).call();
        const [startDate, endDate] = Object.values(response);
        const today = getUnixTimestamp();
        const totalVestingTime = parseInt(endDate, 10) - parseInt(startDate, 10);
        const timeUntilDone = parseInt(endDate, 10) - today;
        const vestedTime = totalVestingTime - timeUntilDone;
        const percentage = (vestedTime * 100) / totalVestingTime;
        return {
            endDate: parseInt(endDate, 10),
            percentage,
            startDate: parseInt(startDate, 10),
        };
    } catch (err) {
        return { endDate: 0, percentage: 0, startDate: 0 };
    }
}

export function exitRewards(amount: BigNumber = new BigNumber(0)) {
    return vestingContract().methods.exit(convertToBN(amount));
}

export async function fetchTotalLockedAmount() {
    const lockedAmount = await vestingContract().methods.totalLockedAmount().call();
    return new BigNumber(lockedAmount || 0);
}

export function extendVesting() {
    return vestingContract().methods.extend(convertToBN(new BigNumber(10000)));
}

export async function getMaxLockedPeriod() {
    const period = await vestingContract().methods.maxLockPeriod().call();
    return new BigNumber(period);
}
