import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, capitalize, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { PoolProviderIcon, TransactionCard } from '../../../components';
import { GroModalHeader, GroModalTxn } from '../../../components/GroModal';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { selectStakedWalletTokens, selectWalletTokens } from '../../wallet/store/wallet.selectors';
import { PoolModal } from '../pools.types';

export function PoolLaunchTransactionModal({
    actionType,
    isOpen,
    onSubmit,
    pool,
    setIsOpen,
}: PoolModal): React.ReactElement {
    const dispatch = useAppDispatch();
    const balance =
        useAppSelector(
            actionType === ExchangeDirection.stake ? selectWalletTokens : selectStakedWalletTokens,
        )[pool.name] || new BigNumber(0);

    const [amount, setAmount] = useState(new BigNumber(0));
    const [startTxn, setStartTxn] = useState(false);

    const theme = useTheme();
    const styles = {
        activeAction: css`
            background-color: ${theme.palette.common.white};
            color: ${theme.palette.common.black};
            border-radius: 8px;
            cursor: pointer;
        `,
        buttonSubmit: css`
            padding-top: 14px;
            padding-bottom: 14px;
        `,
        buttonSwitch: css`
            background: ${theme.palette.grey[700]};
            border-radius: 8px;
        `,
        inputWrapper: css`
            background-color: ${theme.palette.common.black};
            border: 1px solid ${theme.palette.grey[700]};
            border-radius: 5px;
            & .MuiBox-root {
                flex: 1;
            }
        `,
    };

    function handleAmountChange(newAmount: BigNumber): void {
        setAmount(newAmount);
    }

    function handleSubmit(): void {
        dispatch(setExchangeDirection(actionType));
        dispatch(setGrwthToken(pool.name));
        dispatch(
            addTokenLhs({
                [pool.name]:
                    pool.name === TOKENS.GRO_SINGLE_SIDED
                        ? amount
                        : amount.dividedBy(parseFloat(pool.lp_usd_price) || 1),
            }),
        );
        dispatch(
            addTokenRhs({
                [pool.name]:
                    pool.name === TOKENS.GRO_SINGLE_SIDED
                        ? amount
                        : amount.dividedBy(parseFloat(pool.lp_usd_price) || 1),
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    useEffect(() => {
        // reset amount at when modal opens
        if (isOpen) {
            setAmount(
                new BigNumber(
                    pool.name === TOKENS.GRO_SINGLE_SIDED
                        ? balance
                        : balance.multipliedBy(parseFloat(pool.lp_usd_price) || 1),
                ),
            );
        }
        // eslint-disable-next-line
    }, [isOpen, dispatch, balance]);

    const title = actionType.toString().includes('unstake')
        ? 'Unstake from Pool'
        : 'Stake into Pool';

    const poolBalance =
        pool.name === TOKENS.GRO_SINGLE_SIDED
            ? balance
            : balance.multipliedBy(parseFloat(pool.lp_usd_price) || 1);

    return (
        <GroModalTxn
            isOpen={isOpen}
            overflow="visible"
            width="400px"
            onClose={(): void => setIsOpen(false)}
        >
            {!startTxn ? (
                <Box pb={5} px={3}>
                    <GroModalHeader onClose={(): void => setIsOpen(false)}>
                        <Typography mb={1} variant="h1">
                            {title}
                        </Typography>
                    </GroModalHeader>
                    <Box alignItems="center" display="flex" mb={2.5} mt={1}>
                        <Typography color="text.secondary" ml={0.5} mr={1.5} variant="body2">
                            {pool.display_name}
                        </Typography>
                        <PoolProviderIcon
                            fill={theme.palette.text.secondary}
                            height="1rem"
                            poolProvider={pool.type}
                            width="1rem"
                        />
                        <Typography color="text.secondary" ml={0.5} variant="body2">
                            {pool.display_type}
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <TransactionCard
                            selected
                            amount={amount}
                            balance={poolBalance}
                            balanceToDollars={
                                pool.name === TOKENS.GRO_SINGLE_SIDED
                                    ? amount.multipliedBy(parseFloat(pool.lp_usd_price) || 1)
                                    : amount.dividedBy(parseFloat(pool.lp_usd_price) || 1)
                            }
                            isEditable={!(pool.pid === '6' && actionType.includes('unstake'))}
                            token={pool.name}
                            unstake={actionType === ExchangeDirection.unstake}
                            onChange={handleAmountChange}
                        />
                    </Box>
                    {actionType !== ExchangeDirection.invest ? (
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.buttonSubmit}
                            disabled={amount.isZero() || amount.isGreaterThan(poolBalance)}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {capitalize(actionType.toString())}
                        </Button>
                    ) : (
                        <Box mt={4}>
                            <Box display="flex" justifyContent="space-between" mb={3}>
                                <WarningAmberIcon sx={{ height: '20px', width: '20px' }} />
                                <Box width="90%">
                                    <Typography color="text.secondary">
                                        This will deposit GRO into your current Vesting Contract.
                                        GRO in your vesting contract can only be withdrawn via a
                                        rewards claim.
                                    </Typography>
                                </Box>
                            </Box>
                            <Button
                                fullWidth
                                color="secondary"
                                css={styles.buttonSubmit}
                                disabled={amount.isZero() || amount.isGreaterThan(balance)}
                                variant="contained"
                                onClick={(): void => setStartTxn(true)}
                            >
                                Deposit
                            </Button>
                        </Box>
                    )}
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onSubmit}
                />
            )}
        </GroModalTxn>
    );
}
