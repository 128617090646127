/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { lpTokenStakerHandler } from '../contract-info';
import { LpTokenStaker } from './types/lp-token-staker';

let instance: LpTokenStaker;

export function lpTokenStakerContract(): LpTokenStaker {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(
        lpTokenStakerHandler.abi,
        lpTokenStakerHandler.address,
    ) as any as LpTokenStaker;
    return instance;
}
