/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable complexity */
/* eslint-disable react/hook-use-state */

import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TextOrSkeleton } from '../../../components';
import { AVAX_NETWORK, TOKENS, VaultAvaxNames } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    selectIsLoadingUserStatsMc,
    selectUserStatsError,
    selectUserStatsWarning,
} from '../../stats/store/stats.selectors';
import { formatAsCurrency } from '../../utils/currency.helpers';
import { useChangeNetwork } from '../../wallet/hooks/useChangeNetwork';
import {
    selectHardwareWalletLoading,
    selectIsAVAXNetwork,
    selectIsCorrectNetwork,
    selectWalletAccount,
    selectWalletBlocked,
} from '../../wallet/store/wallet.selectors';
import {
    setLabsToken,
    setLabsTransaction,
    setOpenAllowanceModal,
    setWithdrawAmount,
} from '../store/labs.reducer';
import {
    selectDeposited,
    selectDepositedBalance,
    selectIsAllowanceClaimable,
    selectPersonalSpecificReturns,
    selectUserHasClaimed,
} from '../store/labs.selectors';

export function PersonalLabDetails({
    deprecated,
    name,
}: {
    deprecated?: boolean;
    name: VaultAvaxNames;
}): React.ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();
    const correctNetwork = useAppSelector(selectIsCorrectNetwork);
    const isAvax = useAppSelector(selectIsAVAXNetwork);
    const personalSpecificBalance = useAppSelector(selectDepositedBalance(name));
    const personalSpecificReturns = useAppSelector(selectPersonalSpecificReturns(name));
    const loading = useAppSelector(selectIsLoadingUserStatsMc);
    const account = useAppSelector(selectWalletAccount);
    const hardwareWalletLoading = useAppSelector(selectHardwareWalletLoading);
    const [isChangeNetworkLoading, setIsNetworkLoading] = useState(false);
    const { onChangeNetwork } = useChangeNetwork({ setIsLoading: setIsNetworkLoading });

    const isAllowanceClaimable = useAppSelector(selectIsAllowanceClaimable(name));
    const userHasClaimed = useAppSelector(selectUserHasClaimed(name));
    const deposited = useAppSelector(selectDeposited(name));
    const groError = useAppSelector(selectUserStatsError);
    const groWarning = useAppSelector(selectUserStatsWarning);
    const walletBlocked = useAppSelector(selectWalletBlocked);

    const personalSpecificReturnsColor =
        personalSpecificReturns.isZero() ||
        personalSpecificReturns.isLessThan(0) ||
        !correctNetwork
            ? theme.palette.common.white
            : theme.palette.success.light;

    function openWithdraw(): void {
        dispatch(setLabsToken(name));
        dispatch(setWithdrawAmount(new BigNumber(0)));
        dispatch(setLabsTransaction(ExchangeDirection.withdrawAvax));
    }

    function onOpen(): void {
        dispatch(setLabsToken(name));
        if (userHasClaimed || (!userHasClaimed && !isAllowanceClaimable)) {
            dispatch(setLabsTransaction(ExchangeDirection.investAvax));
        } else {
            dispatch(setOpenAllowanceModal(true));
        }
    }

    const tokenName = useMemo(() => {
        if (name.includes('DAI')) return TOKENS.DAI.toUpperCase();
        if (name.includes('USDC')) return TOKENS.USDC.toUpperCase();
        if (name.includes('USDT')) return TOKENS.USDT.toUpperCase();
        return '';
    }, [name]);

    const styles = {
        button: css`
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        buttonsRow: css`
            display: flex;
            margin-top: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1)};
            & button:first-of-type,
            a {
                width: 100%;
                text-decoration: none;
                &:first-of-type {
                    margin-right: ${theme.spacing(1)};
                }
            }
        `,
        darkCircularProgress: css`
            color: ${theme.palette.common.black};
        `,
        migrate: css`
            background: ${theme.palette.error.light};
            border-radius: 4px;
            cursor: pointer;
        `,
        spinner: css`
            vertical-align: middle;
            display: inline;
            color: ${theme.palette.common.black};
        `,
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography
                    align="left"
                    css={css`
                        font-weight: 500;
                    `}
                >
                    Your position
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body2">Balance</Typography>
                <Typography variant="body2">
                    <TextOrSkeleton loading={loading && correctNetwork}>
                        {correctNetwork && personalSpecificBalance.isLessThan(-0.01) && '-'}
                        {correctNetwork ? formatAsCurrency(personalSpecificBalance) : '--'}
                    </TextOrSkeleton>
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2">Returns</Typography>
                <Typography color={personalSpecificReturnsColor} variant="body2">
                    {!groError && !groWarning ? (
                        <TextOrSkeleton loading={loading && correctNetwork}>--</TextOrSkeleton>
                    ) : (
                        <TextOrSkeleton loading={loading && correctNetwork}>n/a</TextOrSkeleton>
                    )}
                </Typography>
            </Box>
            {deprecated ? (
                <Box mt={2}>
                    <Button
                        fullWidth
                        color="secondary"
                        css={styles.button}
                        disabled={
                            (loading && correctNetwork) || deposited.isZero() || walletBlocked
                        }
                        variant="contained"
                        onClick={openWithdraw}
                    >
                        {loading ? <CircularProgress color="secondary" size={22} /> : 'Withdraw'}
                    </Button>
                </Box>
            ) : (
                <React.Fragment>
                    <Box mt={2}>
                        <a
                            css={css`
                                text-decoration: none;
                            `}
                            href="https://bridge.avax.network/login"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Button fullWidth color="secondary" variant="outlined">
                                Bridge {tokenName} to Avalanche
                                <OpenInNewRoundedIcon
                                    css={css`
                                        margin-left: 8px;
                                        font-size: 1rem;
                                    `}
                                />
                            </Button>
                        </a>
                    </Box>
                    {isAvax && account ? (
                        <Box css={styles.buttonsRow}>
                            <Button
                                fullWidth
                                color="secondary"
                                css={styles.button}
                                disabled={loading || walletBlocked}
                                variant="contained"
                                onClick={onOpen}
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="secondary"
                                        css={styles.darkCircularProgress}
                                        size={22}
                                    />
                                ) : (
                                    'Deposit'
                                )}
                            </Button>
                            <Button
                                fullWidth
                                color="secondary"
                                css={styles.button}
                                disabled={loading || deposited.isZero() || walletBlocked}
                                variant="contained"
                                onClick={openWithdraw}
                            >
                                {loading ? (
                                    <CircularProgress color="secondary" size={22} />
                                ) : (
                                    'Withdraw'
                                )}
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            css={css`
                                margin-top: 10px;
                            `}
                        >
                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                onClick={(): Promise<Promise<void>> =>
                                    onChangeNetwork({ data: AVAX_NETWORK, icon: '', label: '' })
                                }
                            >
                                {isChangeNetworkLoading || hardwareWalletLoading ? (
                                    <CircularProgress
                                        color="secondary"
                                        css={styles.spinner}
                                        size={20}
                                    />
                                ) : (
                                    'Connect to Avax'
                                )}
                            </Button>
                        </Box>
                    )}
                </React.Fragment>
            )}
        </Box>
    );
}
