import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import {
    selectMigratedLoading,
    selectPools,
    selectPoolsLoading,
} from '../../pools/store/pools.selectors';
import { updateUserMigratedThunk } from '../../pools/store/thunks/updateUserMigrated';
import { selectRewardsLoading } from '../../rewards/store/rewards.selectors';
import { updateGROValuesThunk } from '../../rewards/store/thunks/updateGROValuesThunk';
import { selectTeamVestingLoading } from '../../teamVesting/store/teamVesting.selectors';
import { updateTeamVestingValuesThunk } from '../../teamVesting/store/thunks/updateTeamVestingValuesThunk';
import { initPoolsWalletThunk } from '../../wallet/store/thunks/initPoolsWalletThunk';
import { selectWalletAccount, selectWalletLoading } from '../../wallet/store/wallet.selectors';
import { updateRedeemValuesThunk } from '../store/thunks/updateRedeemValuesThunk';
import { selectUnwindPoolsReady } from '../store/unwind.selectors';

export function useGetRedeemData(): { loading: boolean; wallet: string } {
    const dispatch = useDispatch();
    const wallet = useAppSelector(selectWalletAccount);

    const walletLoading = useAppSelector(selectWalletLoading);
    const poolsLoading = useAppSelector(selectPoolsLoading);
    const rewardsLoading = useAppSelector(selectRewardsLoading);
    const pools = useAppSelector(selectPools);
    const unwindReady = useAppSelector(selectUnwindPoolsReady);
    const teamVestingLoading = useAppSelector(selectTeamVestingLoading);
    const migrateLoading = useAppSelector(selectMigratedLoading);

    const loading = useMemo(
        () =>
            (walletLoading ||
                migrateLoading ||
                teamVestingLoading ||
                poolsLoading ||
                rewardsLoading) &&
            !unwindReady,
        [
            walletLoading,
            poolsLoading,
            rewardsLoading,
            unwindReady,
            teamVestingLoading,
            migrateLoading,
        ],
    );

    useEffect(() => {
        if (wallet) {
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(updateTeamVestingValuesThunk(wallet));
            void dispatch(updateRedeemValuesThunk(wallet));
        }
    }, [wallet, dispatch]);

    useEffect(() => {
        if (wallet && pools.length > 0) {
            void dispatch(initPoolsWalletThunk(wallet));
            void dispatch(updateUserMigratedThunk(wallet));
        }
    }, [wallet, dispatch, pools.length]);

    return { loading, wallet };
}
