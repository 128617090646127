import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { TOKENS } from '../../../../constants';
import { useAppSelector } from '../../../app/hooks';
import { selectGVTPoolAPR, selectPWRDPoolAPR } from '../../../pools/store/pools.selectors';
import { formatPct } from '../../../utils/percentage.helpers';
import boltGreyLogo from '../../assets/bolt-square-grey.png';
import boltLogo from '../../assets/bolt-square.png';
import { selectExchangeToken } from '../../store/exchange.selectors';

export function GroBoost({ stake }: { stake: boolean }): React.ReactElement {
    const theme = useTheme();

    const token = useAppSelector(selectExchangeToken);
    const gvtAPR = useAppSelector(selectGVTPoolAPR);
    const pwrdAPR = useAppSelector(selectPWRDPoolAPR);

    const text = useMemo(() => {
        const apy = token === TOKENS.GVT ? gvtAPR : pwrdAPR;

        return stake
            ? `+${formatPct(apy.multipliedBy(0.3), false, 1)} to ${formatPct(
                  apy,
                  false,
                  1,
              )} APR (GRO Rewards)`
            : '+0% GRO Boost APY';
    }, [stake, token, gvtAPR, pwrdAPR]);

    return (
        <Box display="flex">
            <Box mr={1.5}>
                <img
                    alt="gvt_logo"
                    height="34px"
                    src={stake ? boltLogo : boltGreyLogo}
                    width="34px"
                />
            </Box>
            <Box>
                <Typography
                    color={stake ? theme.palette.gro.superLight : theme.palette.grey[200]}
                    variant="h3"
                >
                    {text}
                </Typography>
                <Box alignItems="center" display="flex">
                    <Typography color="text.tertiary" mr={0.5} variant="body2Small">
                        When you deposit and stake
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
