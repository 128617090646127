/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useAppSelector } from '../../modules/app/hooks';
import { selectWalletBlocked } from '../../modules/wallet/store/wallet.selectors';
import { GroModal } from './GroModal';

type GroModalTxnTypes = {
    background?: string;
    children?: React.ReactNode;
    enableNavbar?: boolean;
    isOpen: boolean;
    onClose: () => void;
    overflow?: string;
    overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
    width?: string;
};

export function GroModalTxn({
    background = 'rgba(0, 0, 0, 0.5)',
    children,
    enableNavbar,
    isOpen,
    onClose,
    overflow,
    overflowY,
    width = '472px',
}: GroModalTxnTypes): React.ReactElement {
    const walletBlocked = useAppSelector(selectWalletBlocked);

    return (
        <GroModal
            background={background}
            enableNavbar={enableNavbar}
            isOpen={isOpen && !walletBlocked}
            overflow={overflow}
            overflowY={overflowY}
            width={width}
            onClose={onClose}
        >
            {children}
        </GroModal>
    );
}
