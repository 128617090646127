/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
import { TOKENS } from '../../../../constants';
import {
    fetchInvestedPositionVestedBalance,
    fetchInvestorPositionBalance,
    fetchInvestorPositions,
} from '../../../../lib/web3/investorPositions';
import {
    fetchContributorPositions,
    fetchNumberOfPositions,
    fetchPositionBalance,
    fetchPositionVestedBalance,
} from '../../../../lib/web3/teamVesting';
import { addTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { setPositions, setTeamVestingStatus } from '../teamVesting.reducer';
import { Position } from '../teamVesting.store';

async function getInvestorPositionValues(wallet: string): Promise<Position> {
    const balance = await fetchInvestorPositionBalance(wallet);
    const { available, vested } = await fetchInvestedPositionVestedBalance(wallet);
    const { startTime, total, withdrawn } = await fetchInvestorPositions(wallet);

    return {
        available: addTokenDecimals(TOKENS.GRO, available),
        balance: addTokenDecimals(TOKENS.GRO, balance),
        id: 3,
        investor: true,
        startTime,
        stopTime: 0,
        total: addTokenDecimals(TOKENS.GRO, total),
        vested: addTokenDecimals(TOKENS.GRO, vested),
        withdrawn: addTokenDecimals(TOKENS.GRO, withdrawn),
    };
}

async function getPositionValues(wallet: string, id: number): Promise<Position> {
    const balance = await fetchPositionBalance(wallet, id);
    const { available, vested } = await fetchPositionVestedBalance(wallet, id);
    const { startTime, stopTime, total, withdrawn } = await fetchContributorPositions(wallet, id);

    return {
        available: addTokenDecimals(TOKENS.GRO, available),
        balance: addTokenDecimals(TOKENS.GRO, balance),
        id,
        startTime,
        stopTime,
        total: addTokenDecimals(TOKENS.GRO, total),
        vested: addTokenDecimals(TOKENS.GRO, vested),
        withdrawn: addTokenDecimals(TOKENS.GRO, withdrawn),
    };
}

export const updateTeamVestingValuesThunk: AppActionThunk =
    (wallet: string) => async (dispatch) => {
        try {
            dispatch(setTeamVestingStatus({ status: Status.loading }));

            const positions: Position[] = [];

            const openPositions = await fetchNumberOfPositions(wallet);
            const arr = [...Array(openPositions)];

            const promises = arr.map(async (_elem, index) => {
                const position: Position = await getPositionValues(wallet, index);
                positions.push(position);
            });

            await Promise.all(promises);

            const investorPosition = await getInvestorPositionValues(wallet);

            positions.push({ ...investorPosition, id: positions.length });

            dispatch(setPositions(positions));
            dispatch(setTeamVestingStatus({ status: Status.ready }));
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.warn('updateTeamVestingValuesThunk.error', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            dispatch(setTeamVestingStatus({ error: error.message, status: Status.error }));
        }
    };
