/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { Status } from '../../app/app.types';
import { initialUnwindState } from './unwind.store';

const unwindSlice = createSlice({
    initialState: initialUnwindState,
    name: 'unwind',
    reducers: {
        setCusdcExchange: (state, { payload }: PayloadAction<BigNumber>) => {
            state.cusdcExchange = payload;
        },
        setDeadline: (state, { payload }: PayloadAction<string>) => {
            state.deadline = payload;
        },
        setDeadlineAlloy: (state, { payload }: PayloadAction<string>) => {
            state.alloy.deadline = payload;
        },
        setPoolsReady(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.poolsReady = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
        setPricePerShare: (state, { payload }: PayloadAction<BigNumber>) => {
            state.pricePerShare = payload;
        },
        setPricePerShareAlloy: (state, { payload }: PayloadAction<BigNumber>) => {
            state.alloy.pricePerShare = payload;
        },
        setShares: (state, { payload }: PayloadAction<BigNumber>) => {
            state.sharesAvailable = payload;
        },
        setSharesAlloy: (state, { payload }: PayloadAction<BigNumber>) => {
            state.alloy.sharesAvailable = payload;
        },
        setTotalDeposited: (state, { payload }: PayloadAction<BigNumber>) => {
            state.totalDeposited = payload;
        },
        setTotalDepositedAlloy: (state, { payload }: PayloadAction<BigNumber>) => {
            state.alloy.totalDeposited = payload;
        },
        setUnwindStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
        setUserBalance: (state, { payload }: PayloadAction<BigNumber>) => {
            state.userBalance = payload;
        },
        setUserBalanceAlloy: (state, { payload }: PayloadAction<BigNumber>) => {
            state.alloy.userBalance = payload;
        },
    },
});

export const {
    setCusdcExchange,
    setDeadline,
    setDeadlineAlloy,
    setPoolsReady,
    setPricePerShare,
    setPricePerShareAlloy,
    setShares,
    setSharesAlloy,
    setTotalDeposited,
    setTotalDepositedAlloy,
    setUnwindStatus,
    setUserBalance,
    setUserBalanceAlloy,
} = unwindSlice.actions;

export const unwindReducer = unwindSlice.reducer;
