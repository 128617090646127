/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-console */
/* eslint-disable new-cap */

import BigNumber from 'bignumber.js';
import BN from 'bn.js';
import { VaultAvaxNames } from '../../constants';
import { vaultAdaptorContract } from '../abis';
import { convertToBN } from './web3.helpers';

type Withdraw = {
    amount?: BigNumber;
    maxLoss?: BigNumber;
    name: VaultAvaxNames;
    withdrawMax: boolean;
};

type Deposit = {
    amount?: BigNumber;
    name: VaultAvaxNames;
};

type BalanceOf = {
    address?: string;
    name: VaultAvaxNames;
};

export function withdrawVault({
    amount = new BigNumber(0),
    maxLoss = new BigNumber(0),
    name,
    withdrawMax = false,
}: Withdraw) {
    const max = new BN('2').pow(new BN('256')).sub(new BN('1'));
    return vaultAdaptorContract(name).methods.withdraw(
        withdrawMax ? max : convertToBN(amount),
        convertToBN(maxLoss.multipliedBy(10000)),
    );
}

export function depositVault({ amount = new BigNumber(0), name }: Deposit) {
    return vaultAdaptorContract(name).methods.deposit(convertToBN(amount));
}

export function getVaultBalance({ address = '', name }: BalanceOf) {
    return vaultAdaptorContract(name).methods.balanceOf(address).call();
}

export function getVaultPricePerShare({ name }: { name: VaultAvaxNames }) {
    return vaultAdaptorContract(name).methods.getPricePerShare().call();
}

export function getVaultCredit({ name }: { name: VaultAvaxNames }) {
    return vaultAdaptorContract(name).methods['creditAvailable()']().call();
}

export function getTotalVaultDebt({ name }: { name: VaultAvaxNames }) {
    return vaultAdaptorContract(name).methods.totalDebt().call();
}

export function getVaultAllowance({ name }: { name: VaultAvaxNames }) {
    return vaultAdaptorContract(name).methods.BASE_ALLOWANCE().call();
}

export function getUserAllowance({
    address = '',
    name,
}: {
    address?: string;
    name: VaultAvaxNames;
}) {
    return vaultAdaptorContract(name).methods.userAllowance(address).call();
}

export function getDepositLimit({ name }: { name: VaultAvaxNames }) {
    return vaultAdaptorContract(name).methods.depositLimit().call();
}
