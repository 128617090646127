/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { gTrancheContract } from '../abis/gTrancheContract';

export async function fetchUtilisationThreshold() {
    const threshold = await gTrancheContract().methods.utilisationThreshold().call();
    return new BigNumber(threshold);
}
