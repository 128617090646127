/* eslint-disable react/no-multi-comp */
/* eslint-disable complexity */
import React from 'react';
import { css } from '@emotion/react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { PoolProviders, TOKENS } from '../../constants';
import { ReactComponent as CRVSvg } from './3crv.svg';
import { ReactComponent as AirdropSvg } from './airdrop.svg';
import { ReactComponent as ApproveUsageSvg } from './approve-usage.svg';
import { ReactComponent as ArrowDepositSvg } from './arrow-deposit.svg';
import { ReactComponent as ArrowTransferInSvg } from './arrow-transfer-in.svg';
import { ReactComponent as ArrowTransferToSvg } from './arrow-transfer-to.svg';
import { ReactComponent as ArrowWithdrawSvg } from './arrow-withdraw.svg';
import { ReactComponent as BalancerV2Svg } from './balancer_v2.svg';
import { ReactComponent as CheckSvg } from './check.svg';
import { ReactComponent as CrvmetaV1Svg } from './crvmeta_v1.svg';
import { ReactComponent as DaiColorSvg } from './dai-color.svg';
import { ReactComponent as DaiSymbolSvg } from './dai-symbol.svg';
import { ReactComponent as DaiSvg } from './dai.svg';
import { ReactComponent as DiscordAltSvg } from './discord-alt.svg';
import { ReactComponent as ETHSvg } from './eth.svg';
import { ReactComponent as ExchangeArrowSvg } from './exchange-arrow.svg';
import { ReactComponent as GroSvg } from './gro.svg';
import { ReactComponent as GroFullLogoSvg } from './groFull.svg';
import { ReactComponent as GvtCircleSvg } from './gvt-circle.svg';
import { ReactComponent as GvtIsoLogoSvg } from './gvt-isologotype.svg';
import { ReactComponent as GvtSvg } from './gvt.svg';
import { ReactComponent as InfoSvg } from './info.svg';
import { ReactComponent as MetaMaskSvg } from './metamask.svg';
import { ReactComponent as PwrdCircleSvg } from './pwrd-circle.svg';
import { ReactComponent as PwrdIsoLogoSvg } from './pwrd-isologotype.svg';
import { ReactComponent as PwrdSvg } from './pwrd.svg';
import { ReactComponent as UniswapV2Svg } from './uniswap_v2.svg';
import { ReactComponent as UnknownTxnSvg } from './unknown-transaction.svg';
import { ReactComponent as UsdcColorSvg } from './usdc-color.svg';
import { ReactComponent as UsdcSymbolSvg } from './usdc-symbol.svg';
import { ReactComponent as UsdcSvg } from './usdc.svg';
import { ReactComponent as UsdtColorSvg } from './usdt-color.svg';
import { ReactComponent as UsdtSymbolSvg } from './usdt-symbol.svg';
import { ReactComponent as UsdtSvg } from './usdt.svg';
import { ReactComponent as WalletSvg } from './wallet.svg';
import { ReactComponent as WarningSvg } from './warning.svg';
import { ReactComponent as WETHSvg } from './weth.svg';

export function ArrowWithdrawIcon(props: SvgIconProps): React.ReactElement {
    return <ArrowWithdrawSvg {...props} />;
}

export function ArrowDepositIcon(props: SvgIconProps): React.ReactElement {
    return <ArrowDepositSvg {...props} />;
}

export function ArrowTransferInIcon(props: SvgIconProps): React.ReactElement {
    return <ArrowTransferInSvg {...props} />;
}

export function ArrowTransferToIcon(props: SvgIconProps): React.ReactElement {
    return <ArrowTransferToSvg {...props} />;
}

export function ApproveUsageIcon(props: SvgIconProps): React.ReactElement {
    return <ApproveUsageSvg {...props} />;
}

export function GroIcon(props: SvgIconProps): React.ReactElement {
    return <GroSvg {...props} />;
}

export function CheckIcon({
    height = 16,
    width = 16,
    ...props
}: SvgIconProps): React.ReactElement {
    return <CheckSvg height={height} width={width} {...props} />;
}

export function GroArrowIcon({
    color = '#9AAFBF',
    height = 16,
    rotate = 0,
    width = 16,
}: {
    color?: string;
    height?: number | string;
    rotate?: number;
    width?: number | string;
}): React.ReactElement {
    return (
        <svg
            fill="none"
            height={height}
            style={{ transform: `rotate(${rotate}deg)` }}
            viewBox="0 0 20 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)" opacity="0.8">
                <path
                    d="M15.7072 9.29297L10.7072 4.29297L9.29297 5.70718L12.5858 9H5V11H12.5859L9.29297 14.293L10.7072 15.7072L15.7072 10.7072C16.0977 10.3167 16.0977 9.68349 15.7072 9.29297Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect fill={color} height="20" width="20" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function GroChevronIcon({
    fill = 'currentColor',
    height = 16,
    rotate = 0,
    width = 16,
}: {
    fill?: string;
    height?: number | string;
    rotate?: number;
    width?: number | string;
}): React.ReactElement {
    return (
        <svg
            fill={fill}
            height={height}
            style={{ transform: `rotate(${rotate}deg)` }}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11.9697 10.5303L12.5 11.0607L13.5607 10L13.0303 9.46967L11.9697 10.5303ZM8.5 6L9.03033 5.46967C8.73744 5.17678 8.26256 5.17678 7.96967 5.46967L8.5 6ZM3.96967 9.46967L3.43934 10L4.5 11.0607L5.03033 10.5303L3.96967 9.46967ZM13.0303 9.46967L9.03033 5.46967L7.96967 6.53033L11.9697 10.5303L13.0303 9.46967ZM7.96967 5.46967L3.96967 9.46967L5.03033 10.5303L9.03033 6.53033L7.96967 5.46967Z" />
        </svg>
    );
}

export function WarningIcon({
    height = 16,
    width = 16,
    ...props
}: SvgIconProps): React.ReactElement {
    return <WarningSvg height={height} width={width} {...props} />;
}

export function GroInfoIcon({
    height = 16,
    stroke = '#8398A9',
    width = 16,
    ...props
}: SvgIconProps): React.ReactElement {
    return <InfoSvg height={height} stroke={stroke} width={width} {...props} />;
}

/**
 * Loading icons
 * TODO: See if we can refactor this
 */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function usePendingIconStyles() {
    const theme = useTheme();
    return {
        animatedItem: css`
            @keyframes rotating {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
            animation: rotating 2s ${theme.transitions.easing.easeInOut} infinite;
        `,
        numeral: css`
            align-items: center;
            display: inline-flex;
            height: 32px;
            justify-content: center;
            position: absolute;
            width: 32px;
        `,
    };
}

export function GroPendingSpinnerIcon({
    children,
    color = '#79A5FB',
    height = 16,
    secondaryColor = '#395062',
    width = 16,
}: {
    children?: React.ReactNode;
    color?: string;
    height?: number;
    secondaryColor?: string;
    width: number;
}): React.ReactElement {
    const styles = usePendingIconStyles();
    return (
        <React.Fragment>
            <svg
                css={styles.animatedItem}
                fill="none"
                height={height}
                viewBox="0 0 32 32"
                width={width}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    clipRule="evenodd"
                    d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                    fill={secondaryColor}
                    fillRule="evenodd"
                />
                <path
                    clipRule="evenodd"
                    d="M19.979 2.57734C17.0848 1.7194 13.9908 1.81989 11.1584 2.86384C8.32598 3.90779 5.90685 5.83926 4.26173 8.37027C2.61661 10.9013 1.83363 13.8962 2.02952 16.9085C2.22542 19.9208 3.3897 22.7891 5.34877 25.0858C7.30783 27.3824 9.95674 28.9843 12.9005 29.6526C15.8443 30.3209 18.9252 30.0198 21.6839 28.7943L22.4959 30.622C19.3431 32.0227 15.822 32.3667 12.4577 31.603C9.09341 30.8392 6.06609 29.0084 3.82716 26.3837C1.58823 23.759 0.257617 20.481 0.0337371 17.0383C-0.190143 13.5957 0.704701 10.1729 2.58484 7.2803C4.46497 4.38773 7.22969 2.18033 10.4667 0.987246C13.7038 -0.205837 17.2397 -0.32069 20.5474 0.659813C23.855 1.64032 26.7572 3.66365 28.821 6.42812C30.8849 9.19258 32 12.5501 32 16C32 16.5523 31.5523 17 31 17C30.4477 17 30 16.5523 30 16C30 12.9813 29.0243 10.0435 27.2184 7.6246C25.4125 5.20569 22.8732 3.43528 19.979 2.57734Z"
                    fill={color}
                    fillRule="evenodd"
                />
            </svg>
            <span css={styles.numeral}>{children}</span>
        </React.Fragment>
    );
}

export function GroPendingInactive({
    children,
    height = 16,
    width = 16,
}: {
    children: React.ReactNode;
    height: number;
    width: number;
}): React.ReactElement {
    const styles = usePendingIconStyles();
    const theme = useTheme();

    return (
        <React.Fragment>
            <svg
                fill="none"
                height={height}
                viewBox="0 0 32 32"
                width={width}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    clipRule="evenodd"
                    d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                    fill={theme.palette.grey[600]}
                    fillRule="evenodd"
                />
            </svg>
            <span css={styles.numeral} style={{ color: theme.palette.grey[600] }}>
                {children}
            </span>
        </React.Fragment>
    );
}

export function GroFullIcon(props: SvgIconProps): React.ReactElement {
    return <GroFullLogoSvg {...props} />;
}

/**
 *
 * Stablecoin icons
 */
export function DaiIcon(props: SvgIconProps): React.ReactElement {
    return <SvgIcon component={DaiSvg} {...props} />;
}
export function DaiSymbolIcon(props: SvgIconProps): React.ReactElement {
    return <DaiSymbolSvg {...props} />;
}

export function DaiColorIcon(props: SvgIconProps): React.ReactElement {
    return <DaiColorSvg {...props} />;
}

export function CRVColorIcon(props: SvgIconProps): React.ReactElement {
    return <CRVSvg {...props} />;
}

export function UnknownTxnIcon(props: SvgIconProps): React.ReactElement {
    return <UnknownTxnSvg {...props} />;
}

export function UsdcIcon(props: SvgIconProps): React.ReactElement {
    return <SvgIcon component={UsdcSvg} {...props} />;
}
export function UsdcSymbolIcon(props: SvgIconProps): React.ReactElement {
    return <UsdcSymbolSvg {...props} />;
}
export function UsdcColorIcon(props: SvgIconProps): React.ReactElement {
    return <UsdcColorSvg {...props} />;
}
export function UsdtIcon(props: SvgIconProps): React.ReactElement {
    return <SvgIcon component={UsdtSvg} {...props} />;
}
export function UsdtSymbolIcon(props: SvgIconProps): React.ReactElement {
    return <UsdtSymbolSvg {...props} />;
}
export function UsdtColorIcon(props: SvgIconProps): React.ReactElement {
    return <UsdtColorSvg {...props} />;
}

export function PwrdIcon(props: SvgIconProps): React.ReactElement {
    return <PwrdSvg fill="currentColor" {...props} />;
}

export function GvtIcon(props: SvgIconProps): React.ReactElement {
    return <GvtSvg fill="currentColor" {...props} />;
}

type TpIconFunc = SvgIconProps & {
    colored?: boolean;
    token: string;
};

export function TokenIcon({ colored, token, ...props }: TpIconFunc): React.ReactElement | null {
    switch (token) {
        case TOKENS.DAI:
        case TOKENS.DAIE:
            return colored ? <DaiColorIcon {...props} /> : <DaiIcon {...props} />;
        case TOKENS.USDT:
        case TOKENS.USDTE:
            return colored ? <UsdtColorIcon {...props} /> : <UsdtIcon {...props} />;
        case TOKENS.USDC:
        case TOKENS.USDCE:
            return colored ? <UsdcColorIcon {...props} /> : <UsdcIcon {...props} />;
        case TOKENS.GVT:
        case TOKENS.GVT_SINGLE_SIDED:
            return colored ? <GvtCircleSvg {...props} fill="#ea726a" /> : <GvtIcon {...props} />;
        case TOKENS.PWRD:
        case TOKENS.PWRD_SINGLE_SIDED:
            return colored ? <PwrdCircleSvg {...props} fill="#0BA7FE" /> : <PwrdIcon {...props} />;
        case TOKENS.GRO:
            return colored ? <GroIcon {...props} fill="#B452FF" /> : <GroIcon {...props} />;
        case TOKENS.GRO_SINGLE_SIDED:
            return colored ? (
                <GroIcon {...props} fill="#B452FF" />
            ) : (
                <GroIcon {...props} fill="currentColor" />
            );
        case TOKENS.PWRD3CRV:
        case TOKENS.CRV:
            return <CRVColorIcon {...props} />;
        case TOKENS.UNI_USDC_50_50:
            return <UniswapV2Svg {...props} fill="currentColor" />;
        case TOKENS.UNI_50_50:
            return <UniswapV2Svg {...props} fill="currentColor" />;
        case TOKENS.WETH:
            return colored ? <WETHSvg {...props} /> : <ETHSvg {...props} />;
        case 'curve_pwrd3crv':
            return <CrvmetaV1Svg {...props} />;
        default:
            return null;
    }
}

export function PoolProviderIcon({
    poolProvider,
    ...props
}: SvgIconProps & {
    poolProvider: string;
}): React.ReactElement | null {
    switch (poolProvider) {
        case PoolProviders.UNISWAP:
            return <UniswapV2Svg {...props} fill="currentColor" />;
        case PoolProviders.CURVE:
            return <CrvmetaV1Svg {...props} fill="currentColor" />;
        case PoolProviders.BALANCER:
            return <BalancerV2Svg {...props} fill="currentColor" />;
        case PoolProviders.GRO:
            return <GroIcon {...props} />;
        default:
            return null;
    }
}

export function TokenProviderIcon({
    tokenProvider,
    ...props
}: SvgIconProps & {
    tokenProvider: string;
}): React.ReactElement | null {
    switch (tokenProvider) {
        case TOKENS.GRO:
            return <GroIcon {...props} fill="currentColor" />;
        case TOKENS.PWRD:
            return <PwrdIcon {...props} fill="currentColor" />;
        default:
            return <GroIcon {...props} fill="currentColor" />;
    }
}

// TODO: Remove once icons are unified
export function TokenSymbolIcon({ token, ...props }: TpIconFunc): React.ReactElement | null {
    switch (token) {
        case TOKENS.DAI:
            return <DaiSymbolIcon {...props} />;
        case TOKENS.USDT:
            return <UsdtSymbolIcon {...props} />;
        case TOKENS.USDC:
            return <UsdcSymbolIcon {...props} />;
        case TOKENS.GRO:
            return <GroIcon {...props} fill="#B452FF" />;
        case TOKENS.GVT:
            return <GvtIcon {...props} fill="#ea726a" />;
        case TOKENS.PWRD:
            return <PwrdIcon {...props} fill="#0BA7FE" />;
        case 'curve_pwrd3crv':
            return <CrvmetaV1Svg {...props} fill="currentColor" />;
        default:
            return null;
    }
}

export function PwrdIsoLogoIcon(props: SvgIconProps): React.ReactElement {
    return <PwrdIsoLogoSvg fill="#0BA7FE" {...props} />;
}

export function GvtIsoLogoIcon(props: SvgIconProps): React.ReactElement {
    return <GvtIsoLogoSvg fill="#EA726A" {...props} />;
}

/* Other icons */

export function MetaMaskIcon(props: SvgIconProps): React.ReactElement {
    return <MetaMaskSvg {...props} />;
}

export function AirDropIcon(props: SvgIconProps): React.ReactElement {
    return <AirdropSvg {...props} fill="currentColor" />;
}

export function DiscordAltIcon(props: SvgIconProps): React.ReactElement {
    return <DiscordAltSvg viewBox="0 0 20 20" {...props} />;
}

export function ExchangeArrowIcon(props: SvgIconProps): React.ReactElement {
    return <ExchangeArrowSvg viewBox="0 0 30 30" {...props} />;
}

export function WalletIcon(props: SvgIconProps): React.ReactElement {
    return <WalletSvg viewBox="0 0 16 17" {...props} />;
}
