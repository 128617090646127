import { createSelector } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';
import { Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { UnwindStore } from './unwind.store';

export function selectUnwindState(state: RootState): UnwindStore {
    return state.unwind;
}

export const selectUnwindDeadline = createSelector(
    selectUnwindState,
    (state) => new Date(!state.deadline ? 0 : parseInt(state.deadline, 10) * 1000),
);

export const selectAlloyDeadline = createSelector(
    selectUnwindState,
    (state) => new Date(!state.alloy.deadline ? 0 : parseInt(state.alloy.deadline, 10) * 1000),
);

export const selectPricePerShareUnwind = createSelector(
    selectUnwindState,
    (state) => state.pricePerShare,
);

export const selectExchangeCusdc = createSelector(
    selectUnwindState,
    (state) => state.cusdcExchange,
);

export const selectPricePerShareAlloy = createSelector(
    selectUnwindState,
    (state) => state.alloy.pricePerShare,
);

export const selectSharesAvailableUnwind = createSelector(selectUnwindState, (state) =>
    state.sharesAvailable.multipliedBy(state.cusdcExchange),
);

export const selectAvailableCusdc = createSelector(
    selectUnwindState,
    (state) => state.sharesAvailable,
);

export const selectSharesAvailableAlloy = createSelector(
    selectUnwindState,
    (state) => state.alloy.sharesAvailable,
);

export const selectUserBalanceUnwind = createSelector(
    selectUnwindState,
    (state) => state.userBalance,
);

export const selectUserBalanceAlloy = createSelector(
    selectUnwindState,
    (state) => state.alloy.userBalance,
);

export const selectTotalDeposited = createSelector(
    selectUnwindState,
    (state) => state.totalDeposited,
);

export const selectTotalDepositedAlloy = createSelector(
    selectUnwindState,
    (state) => state.alloy.totalDeposited,
);

export const selecUnwindLoading = createSelector(
    selectUnwindState,
    (state) =>
        state.status !== Status.ready &&
        state.status !== Status.error &&
        state.status !== Status.idle,
);

export const selectUnwindPoolsReady = createSelector(
    selectUnwindState,
    (state) => state.poolsReady === Status.ready,
);

export const selectCanClaim = createSelector(
    selectUnwindDeadline,
    selecUnwindLoading,
    (deadline, loading) => isBefore(deadline, Date.now()) && !loading,
);

export const selectCanClaimAlloy = createSelector(
    selectAlloyDeadline,
    selecUnwindLoading,
    (deadline, loading) => isBefore(deadline, Date.now()) && !loading,
);
