import { useEffect } from 'react';

export function useOutsideClick(ref: React.RefObject<HTMLElement>, onClicked: () => void): void {
    useEffect(() => {
        /**
         * Callback if clicked on outside of element
         */
        function handleClickOutside(event: Event): void {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClicked();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return (): void => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClicked]);
}
