/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */

import { TOKENS } from '../../../../constants';
import { exchangeRateCusdc } from '../../../../lib/web3/cusdc';
import {
    getDeadline,
    getPricePerShare,
    getSharesAvailable,
    getTotalDeposited,
    getUserBalance,
} from '../../../../lib/web3/redeem';
import {
    getDeadlineAlloy,
    getPricePerShareAlloy,
    getSharesAvailableAlloy,
    getTotalDepositedAlloy,
    getUserBalanceAlloy,
} from '../../../../lib/web3/redeemAlloy';
import { addTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import {
    setCusdcExchange,
    setDeadline,
    setDeadlineAlloy,
    setPricePerShare,
    setPricePerShareAlloy,
    setShares,
    setSharesAlloy,
    setTotalDeposited,
    setTotalDepositedAlloy,
    setUnwindStatus,
    setUserBalance,
    setUserBalanceAlloy,
} from '../unwind.reducers';

export const updateRedeemValuesThunk: AppActionThunk = (wallet: string) => async (dispatch) => {
    try {
        dispatch(setUnwindStatus({ status: Status.loading }));

        dispatch(setUserBalance(addTokenDecimals(TOKENS.GRO, await getUserBalance(wallet))));
        dispatch(setDeadline(await getDeadline()));
        dispatch(setTotalDeposited(addTokenDecimals(TOKENS.GRO, await getTotalDeposited())));
        dispatch(setPricePerShare(addTokenDecimals(TOKENS.USDC, await getPricePerShare())));
        dispatch(setShares(addTokenDecimals(TOKENS.USDC, await getSharesAvailable(wallet))));
        dispatch(setCusdcExchange(addTokenDecimals(TOKENS.GRO, await exchangeRateCusdc())));

        dispatch(
            setUserBalanceAlloy(addTokenDecimals(TOKENS.GRO, await getUserBalanceAlloy(wallet))),
        );
        dispatch(setDeadlineAlloy(await getDeadlineAlloy()));
        dispatch(
            setTotalDepositedAlloy(addTokenDecimals(TOKENS.GRO, await getTotalDepositedAlloy())),
        );
        dispatch(
            setPricePerShareAlloy(addTokenDecimals(TOKENS.GRO, await getPricePerShareAlloy())),
        );
        dispatch(
            setSharesAlloy(addTokenDecimals(TOKENS.GRO, await getSharesAvailableAlloy(wallet))),
        );

        dispatch(setUnwindStatus({ status: Status.ready }));
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.warn('updateRedeemValuesThunk.error', error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dispatch(setUnwindStatus({ error: error.message, status: Status.error }));
    }
};
