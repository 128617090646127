/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import BigNumber from 'bignumber.js';
import { apolloClient } from '../../../../apolloClient';
import { PoolToken, TOKENS } from '../../../../constants';
import { getPricePerShare } from '../../../../lib/web3/convert';
import { virtualPrice } from '../../../../lib/web3/curve';
import { fetchReserves, fetchTotalSupply } from '../../../../lib/web3/singlePool';
import { fetchTvlStats } from '../../../../lib/web3/stats';
import { addTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { setExchangeStatus } from '../../../exchange/store/exchange.reducers';
import { selectUSDCConversions } from '../../../exchange/store/exchange.selectors';
import { setPools } from '../../../pools/store/pools.reducer';
import { GET_BALANCER_POOL_INFO } from '../../services/query';
import { TpGroStatsMc, TpGroStatsMcJson, TpPool } from '../../stats.types';
import { setGroStatsLoading, setGroStatsMc } from '../stats.reducer';
import { initialStatsState } from '../stats.store';
import { basePool, placeholderPools } from './poolsBody';

function formatPool(pool: PoolToken, price: BigNumber): TpPool {
    return {
        ...placeholderPools[pool],
        ...basePool,
        lp_usd_price: price.toString(),
    };
}

async function getUniPoolValues(
    pool: TOKENS.PWRD_SINGLE_SIDED | TOKENS.GVT_SINGLE_SIDED,
): Promise<TpPool> {
    const lpUsdPrice = await getPricePerShare(pool);
    return formatPool(pool, addTokenDecimals(TOKENS.GRO, lpUsdPrice));
}

async function getGroPrice(): Promise<string> {
    const reserves = await fetchReserves(TOKENS.UNI_USDC_50_50);

    const [gro, usdc] = Object.values(reserves);

    const groAmount = addTokenDecimals(TOKENS.GRO, new BigNumber(gro));
    const usdcAmount = addTokenDecimals(TOKENS.USDC, new BigNumber(usdc));

    const price = new BigNumber(usdcAmount).dividedBy(new BigNumber(groAmount));
    return price.toString();
}

async function getGroPool(): Promise<TpPool> {
    const price = await getGroPrice();
    return formatPool(TOKENS.GRO_SINGLE_SIDED, new BigNumber(price));
}

async function getUniValues(
    pool: TOKENS.UNI_50_50 | TOKENS.UNI_USDC_50_50,
    usdcPrice?: BigNumber,
): Promise<TpPool> {
    const totalSupply = await fetchTotalSupply(pool);
    const poolReserves = await fetchReserves(pool);
    const [gvt, gro] = Object.values(poolReserves);

    const groPrice = await getGroPrice();
    const gvtPrice = await getPricePerShare(TOKENS.GVT_SINGLE_SIDED);

    const gvtPriceDecimals = addTokenDecimals(TOKENS.GRO, gvtPrice);

    const finalPriceGro = new BigNumber(usdcPrice ? gvt : gro)
        .dividedBy(totalSupply)
        .multipliedBy(usdcPrice || groPrice);
    const finalPriceGvt = new BigNumber(usdcPrice ? gro : gvt)
        .dividedBy(totalSupply)
        .multipliedBy(usdcPrice ? groPrice : gvtPriceDecimals);

    return formatPool(pool, finalPriceGro.plus(finalPriceGvt));
}

async function getCurvePrices(): Promise<TpPool> {
    const price = await virtualPrice();

    return {
        ...placeholderPools[TOKENS.CRV_META],
        ...basePool,
        lp_usd_price: addTokenDecimals(TOKENS.GRO, price).toString(),
    };
}

async function getBalancerPrices(): Promise<TpPool> {
    const { data } = await apolloClient.query({
        query: GET_BALANCER_POOL_INFO,
    });

    const pool = data.pools[0];
    const price = new BigNumber(pool.totalLiquidity).dividedBy(pool.totalShares);

    return formatPool(TOKENS.BAL_GRO_WETH, price);
}

export const loadGroStatsOnChainThunk: AppActionThunk<Promise<void>> =
    () => async (dispatch, getState) => {
        try {
            const state = initialStatsState.groStatsMc as unknown as TpGroStatsMcJson;
            // onchain fallback

            const usdcPrice = selectUSDCConversions(getState());

            const { total, totalGVT, totalPWRD, utilRatio, utilRatioThreshold } =
                await fetchTvlStats();

            const tokens = [TOKENS.GVT_SINGLE_SIDED, TOKENS.PWRD_SINGLE_SIDED];
            const uniTokens = [TOKENS.UNI_50_50, TOKENS.UNI_USDC_50_50];

            const pools: TpPool[] = [
                await getGroPool(),
                await getCurvePrices(),
                await getBalancerPrices(),
            ];

            const promises = tokens.map(async (name) => {
                const pool = await getUniPoolValues(
                    name as TOKENS.GVT_SINGLE_SIDED | TOKENS.PWRD_SINGLE_SIDED,
                );
                pools.push(pool);
            });

            const uniPromises = uniTokens.map(async (name) => {
                const pool = await getUniValues(
                    name as TOKENS.UNI_50_50 | TOKENS.UNI_USDC_50_50,
                    name === TOKENS.UNI_USDC_50_50 ? usdcPrice : undefined,
                );
                pools.push(pool);
            });

            await Promise.all([...promises, ...uniPromises]);

            const finalStats = {
                ...state.gro_stats_mc,
                mainnet: {
                    ...state.gro_stats_mc.mainnet,
                    pools,
                    token_price_usd: {
                        ...state.gro_stats_mc.mainnet.token_price_usd,
                        gro: pools[0].lp_usd_price,
                        gvt: pools[3].lp_usd_price,
                        pwrd: 1,
                    },
                    tvl: {
                        gvt: totalGVT.toString(),
                        pwrd: totalPWRD.toString(),
                        total: total.toString(),
                        util_ratio: utilRatio.toString(),
                        util_ratio_limit: utilRatioThreshold.toString(),
                    },
                },
            };

            dispatch(setPools(pools));
            dispatch(setGroStatsMc(finalStats as unknown as TpGroStatsMc));
            dispatch(setGroStatsLoading(false));
            dispatch(setExchangeStatus({ status: Status.ready }));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('loadStatsOnChain.error', e);
        }
    };
