import React from 'react';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/material';
import { Token, TOKENS } from '../../../constants';

type TpRemoveCard = {
    removeToken: (token: Token) => void;
    token?: Token;
};

export function RemoveCard({ removeToken, token }: TpRemoveCard): React.ReactElement {
    const theme = useTheme();
    const styles = {
        closeBtn: css`
            background: ${theme.palette.grey[700]};
            border-radius: 0px 12px 12px 0px;
            cursor: pointer;
        `,
    };
    return (
        <Box
            alignItems="center"
            css={styles.closeBtn}
            display="flex"
            px={1}
            onClick={(): void => removeToken(token || TOKENS.USDC)}
        >
            <CloseIcon fontSize="small" />
        </Box>
    );
}
