import { GroIcon, TpBreadcrumbRoutes } from '../../components';
/**
 * Keep routes as a separate export (i.e. not from index),
 * so that we keep the lazy loading of the module
 */

const base = '/block';
export const routes: TpBreadcrumbRoutes = {
    base: {
        breadcrumb: 'US BLOCK',
        Icon: GroIcon,
        path: base,
    },
};
