import React from 'react';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TextOrSkeleton } from '../../../components';
import { useAppSelector } from '../../app/hooks';
import { selectGroStatsMc } from '../../stats/store/stats.selectors';
import { formatAsCurrency } from '../../utils/currency.helpers';
import { selectIsAVAXNetwork, selectIsCorrectNetwork } from '../../wallet/store/wallet.selectors';

export function PwrdPriceCalculation({
    amount,
    isLoading,
}: {
    amount: BigNumber;
    isLoading: boolean;
}): React.ReactElement {
    const groStats = useAppSelector(selectGroStatsMc);
    const correctNetwork = useAppSelector(selectIsCorrectNetwork);
    const isAvax = useAppSelector(selectIsAVAXNetwork);

    return (
        <Typography color="text.tertiary" fontStyle="italic">
            {correctNetwork && !isAvax ? (
                <TextOrSkeleton loading={isLoading}>
                    ≈{' '}
                    {formatAsCurrency(
                        new BigNumber(groStats.mainnet?.token_price_usd.pwrd).multipliedBy(amount),
                    )}{' '}
                </TextOrSkeleton>
            ) : (
                ` ≈ $ --`
            )}
        </Typography>
    );
}
