import React from 'react';
import { css } from '@emotion/react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Box, Typography, useTheme } from '@mui/material';
import { DISCORD_LINK } from '../../constants';
import { useAppSelector } from '../../modules/app/hooks';
import { selectWalletAccount } from '../../modules/wallet/store/wallet.selectors';

export function BlocedkWalletDisclaimer(): React.ReactElement {
    const theme = useTheme();
    const wallet = useAppSelector(selectWalletAccount);

    const styles = {
        break: css`
            word-break: break-word;
        `,
        wrapper: css`
            width: 432px;
            position: fixed;
            bottom: 80px;
            right: 40px;
            z-index: 9;
            border-radius: 20px;
            background: ${theme.palette.grey[800]};
            @media (max-width: 600px) {
                width: 95%;
                right: 2.5%;
                padding: 24px 16px 32px;
                bottom: 20px;
            }
        `,
    };

    return (
        <Box css={styles.wrapper} pb={4} pt={3} px={4}>
            <Box alignItems="center" display="flex" mb={0.5}>
                <ReportGmailerrorredIcon
                    sx={{ color: theme.palette.error.light, fontSize: '26px' }}
                />
                <Typography color={theme.palette.error.light} ml={1} variant="h1">
                    This address is blocked
                </Typography>
            </Box>
            <Typography color="text.tertiary" css={styles.break} mt={2} variant="body2">
                {wallet} is blocked because the address is sanctioned by the EU, US, or UN.This web
                page uses{' '}
                <a
                    css={css`
                        color: ${theme.palette.text.tertiary};
                    `}
                    href="https://go.chainalysis.com/chainalysis-oracle-docs.html"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                >
                    Chainalysis
                </a>{' '}
                to comply with an up to date list of sanctioned addresses. If you believe this is
                an error please contact the Gro DAO for support on{' '}
                <a
                    css={css`
                        color: ${theme.palette.text.tertiary};
                    `}
                    href={DISCORD_LINK}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                >
                    Discord
                </a>
                .
            </Typography>
        </Box>
    );
}
