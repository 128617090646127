/* eslint-disable no-nested-ternary */

import React from 'react';
import { css } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { formatAsCurrency } from '../../utils/currency.helpers';
import { formatPct } from '../../utils/percentage.helpers';

type TpPositionCard = {
    amount: BigNumber;
    apy: BigNumber;
    dateFrom: string;
    dateTo?: string;
    noOpen?: boolean;
    returnAmount: BigNumber;
    smallDevices?: boolean;
    title: string;
};

export function PositionCard({
    amount,
    apy,
    dateFrom,
    dateTo,
    noOpen,
    returnAmount,
    smallDevices,
    title,
}: TpPositionCard): React.ReactElement {
    const theme = useTheme();
    const positiveReturns = returnAmount.isGreaterThan(0);
    const negativeReturns = returnAmount.isLessThan(0);

    const styles = {
        color: css`
            color: ${positiveReturns
                ? theme.palette.success.light
                : negativeReturns
                ? theme.palette.error.light
                : theme.palette.common.white};
        `,
        icon: css`
            font-size: 16px;
        `,
        wrapper: css`
            background: ${theme.palette.grey[800]};
            border-radius: 5px;
            width: ${smallDevices ? '100%' : '296px'};
            min-height: 42px;
        `,
    };

    return (
        <Box css={styles.wrapper} px={1.5} py={0.5}>
            <Box display="flex" justifyContent="space-between" mb={0.5}>
                <Box display="flex">
                    <Typography mr={1.5} variant="labsSmall">
                        {title}
                    </Typography>
                    {!noOpen && (
                        <Typography variant="labsSmall">{formatAsCurrency(amount)}</Typography>
                    )}
                </Box>
                {!noOpen ? (
                    <Box alignItems="center" display="flex">
                        {positiveReturns && <ArrowDropUpIcon css={[styles.icon, styles.color]} />}
                        {negativeReturns && (
                            <ArrowDropDownIcon css={[styles.icon, styles.color]} />
                        )}
                        <Typography css={styles.color} variant="labsSmall">
                            {formatAsCurrency(returnAmount)}
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="labsSmall">--</Typography>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between">
                {!noOpen ? (
                    <Box display="flex">
                        <Typography color="text.secondary" variant="labsSmall">
                            {dateFrom}
                        </Typography>
                        {dateTo && (
                            <Typography color="text.secondary" ml={0.5} variant="labsSmall">
                                - {dateTo}
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <Typography color="text.secondary" variant="labsSmall">
                        --
                    </Typography>
                )}
                <Typography align="right" color="text.secondary" variant="labsSmall">
                    {!noOpen
                        ? `${apy.isLessThan(0) ? '-' : ''}${formatPct(
                              apy,
                              false,
                              apy.isGreaterThanOrEqualTo(1) ? 0 : 2,
                          )} APY`
                        : '--'}
                </Typography>
            </Box>
        </Box>
    );
}
