/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MAINTENANCE_STATUS } from '../../../../constants';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { setIsStake } from '../../store/exchange.reducers';
import {
    selectExchangeDirection,
    selectExchangeEnabled,
    selectTokenToIsZero,
    selectUserInputExceededWalletBalance,
    selectUserInputExceededWalletBalanceAndStake,
} from '../../store/exchange.selectors';
import { createNewTransactionThunk } from '../../store/thunks/createNewTransactionThunk';

export function ExchangeButton({
    onBack = (): void => {},
    onNext = (): void => {},
    stake,
    text,
}: {
    onBack?: () => void;
    onNext: () => void;
    stake?: boolean;
    text: string;
}): React.ReactElement {
    const transactionEnabled = useAppSelector(selectExchangeEnabled);
    const direction = useAppSelector(selectExchangeDirection);
    const amountIsZero = useAppSelector(selectTokenToIsZero);
    const dispatch = useDispatch();

    async function handleClick(): Promise<void> {
        if (direction === ExchangeDirection.invest) {
            dispatch(setIsStake(!!stake));
        }
        dispatch(createNewTransactionThunk());
        onNext();
        onBack();
    }

    const moreTokensThanAvailable = useAppSelector(selectUserInputExceededWalletBalance);
    const moreTokensAndStakedThanAvailable = useAppSelector(
        selectUserInputExceededWalletBalanceAndStake,
    );

    const insufficient = useMemo(() => {
        if (moreTokensThanAvailable && direction === ExchangeDirection.invest) {
            return true;
        }

        if (moreTokensAndStakedThanAvailable && direction === ExchangeDirection.withdraw) {
            return true;
        }
        return amountIsZero;
    }, [moreTokensThanAvailable, moreTokensAndStakedThanAvailable, direction, amountIsZero]);

    return (
        <Box mt={1.5}>
            <Button
                fullWidth
                color="secondary"
                css={css`
                    height: 48px;
                `}
                disabled={!transactionEnabled}
                variant="contained"
                onClick={handleClick}
            >
                <React.Fragment>
                    {MAINTENANCE_STATUS && 'System paused'}
                    {!transactionEnabled &&
                        !MAINTENANCE_STATUS &&
                        insufficient &&
                        'Insufficient balance'}
                    {transactionEnabled &&
                        (direction === ExchangeDirection.invest ? text : 'Withdraw')}
                </React.Fragment>
            </Button>
        </Box>
    );
}
