import BigNumber from 'bignumber.js';
import {
    POOL_TOKENS,
    PoolToken,
    STABLE_TOKENS_AVAX,
    Token,
    TokenObject,
    TOKENS,
} from '../../../constants';
import { getAllTokens } from '../../../lib/contract-info';
import { fetchTokenToUsdConversionRates, tokenToUsd } from '../../../lib/web3/convert';
import {
    claimableRewardsBalanceOf,
    getPWRDStakedBalance,
    stakedBalanceOf,
} from '../../../lib/web3/pools';
import { fetchBalanceOf } from '../../../lib/web3/wallet';
import { addTokenDecimals } from '../../../lib/web3/web3.helpers';
import { getPoolPID } from '../../pools/pools.helpers';
import { TpPool } from '../../stats/stats.types';

export async function fetchTokenBalances(
    address: string,
    isAvax: boolean,
): Promise<{ tokens: TokenObject; tokensInUsd: TokenObject }> {
    const tokens: TokenObject = {};
    const tokensInUsd: TokenObject = {};
    const tokensToFetch = isAvax ? STABLE_TOKENS_AVAX : getAllTokens();
    const promises = tokensToFetch.map((token) =>
        fetchBalanceOf(address, token).then(async (balance) => {
            // no conversion rate for GRO or PWRD3CRV token or WETH
            const noConversionTokens = [
                TOKENS.GRO,
                TOKENS.PWRD3CRV,
                TOKENS.WETH,
                TOKENS.DAIE,
                TOKENS.USDCE,
                TOKENS.USDTE,
            ];
            if (noConversionTokens.includes(token)) {
                tokens[token] = balance;
            } else {
                const conversionRate = await fetchTokenToUsdConversionRates(token);
                const tokenInUsd = balance.times(addTokenDecimals(TOKENS.USD, conversionRate));
                tokens[token] = balance;
                tokensInUsd[token] = tokenInUsd;
            }
        }),
    );

    await Promise.all(promises);
    return { tokens, tokensInUsd };
}

export async function fetchPoolsBalances(
    address: string,
    pools: TpPool[],
): Promise<{ claimableRewards: TokenObject; tokens: TokenObject }> {
    const tokens: TokenObject = {};
    const claimableRewards: TokenObject = {};
    const balancesPromises = POOL_TOKENS.map((token: PoolToken) => {
        const pid = getPoolPID(token, pools);
        if (token !== TOKENS.PWRD_SINGLE_SIDED) {
            return stakedBalanceOf(address, pid).then(async (balance) => {
                tokens[token] = balance;
            });
        }
        return getPWRDStakedBalance(address).then(async (balance) => {
            tokens[token] = balance;
        });
    });

    const rewardsPromises = POOL_TOKENS.map((token: PoolToken) => {
        const pid = getPoolPID(token, pools);
        return claimableRewardsBalanceOf(address, pid).then(async (reward) => {
            claimableRewards[token] = reward;
        });
    });

    await Promise.all([...balancesPromises, ...rewardsPromises]);
    return { claimableRewards, tokens };
}

const MIN_TRANSACTION_THRESHOLD = 0.5;

/**
 * Returns max allowed token amount in USD from user wallet
 * @param wallet
 * @param token
 * @param amountInUsd
 * @param conversions
 * @param minimumUsdRemaining
 * @returns
 */
export async function fetchMaxWalletTokens(
    wallet: string,
    token: Token,
    amountInUsd: BigNumber, // with decimals
    conversions: TokenObject,
    minimumUsdRemaining = MIN_TRANSACTION_THRESHOLD,
): Promise<boolean> {
    const balance = await fetchBalanceOf(wallet, token);
    const balanceInUsd = tokenToUsd(token, balance, conversions);

    return amountInUsd.minus(balanceInUsd).abs().isLessThan(minimumUsdRemaining);
}
