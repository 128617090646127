import { combineReducers } from 'redux';
import { exchangeReducer } from '../../exchange/store/exchange.reducers';
import { initialExchangeState } from '../../exchange/store/exchange.store';
import { labsReducer } from '../../labs/store/labs.reducer';
import { initialLabsState } from '../../labs/store/labs.store';
import { poolsReducer } from '../../pools/store/pools.reducer';
import { initialPoolState } from '../../pools/store/pools.store';
import { rewardsReducer } from '../../rewards/store/rewards.reducer';
import { initialGROState } from '../../rewards/store/rewards.store';
import { statsReducer } from '../../stats/store/stats.reducer';
import { initialStatsState } from '../../stats/store/stats.store';
import { teamVestingReducer } from '../../teamVesting/store/teamVesting.reducer';
import { initialTeamVestingState } from '../../teamVesting/store/teamVesting.store';
import { transactionsReducer } from '../../transaction/store/transactions.reducer';
import { initialTransactionsState } from '../../transaction/store/transactions.store';
import { unwindReducer } from '../../unwind/store/unwind.reducers';
import { initialUnwindState } from '../../unwind/store/unwind.store';
import { walletReducer } from '../../wallet/store/wallet.reducers';
import { initialWalletState } from '../../wallet/store/wallet.store';

export const rootReducer = combineReducers({
    exchange: exchangeReducer,
    labs: labsReducer,
    pools: poolsReducer,
    rewards: rewardsReducer,
    stats: statsReducer,
    teamVesting: teamVestingReducer,
    transactions: transactionsReducer,
    unwind: unwindReducer,
    wallet: walletReducer,
});

export const rootInitialState = {
    exchange: initialExchangeState,
    labs: initialLabsState,
    pools: initialPoolState,
    rewards: initialGROState,
    stats: initialStatsState,
    teamVesting: initialTeamVestingState,
    transactions: initialTransactionsState,
    unwind: initialUnwindState,
    wallet: initialWalletState,
};
