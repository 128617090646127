/* eslint-disable no-nested-ternary */

import React from 'react';
import { css } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme } from '@mui/material';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import cardHeaderBackground from '../assets/card-header-2.svg';

export function CardHeader({
    children,
    hideArrows,
    hideFrontArrows,
    icon,
    iconEnd,
    isLong,
}: {
    children: React.ReactElement;
    hideArrows?: boolean;
    hideFrontArrows?: boolean;
    icon?: React.ReactElement;
    iconEnd?: React.ReactElement;
    isLong?: boolean;
}): React.ReactElement {
    const theme = useTheme();
    const { isMobileDisplay } = useGetSizes(theme);
    const styles = {
        arrowIcons: css`
            position: absolute;
            left: 0px;
            top: 48px;
            @media (min-width: ${theme.breakpoints.values.md}px) {
                left: ${icon ? '15px' : '0px'};
            }
        `,
        arrowIconsRight: css`
            position: absolute;
            right: 30px;
            top: 47px;
            @media (min-width: ${theme.breakpoints.values.md}px) {
                right: 20px;
            }
        `,
        arrowShape: css`
            background: url(${cardHeaderBackground});
            background-position-x: right;
            /* z-index: 1; */
            background-position-y: center;
            width: 30px;
            background-size: cover;
        `,
        back: css`
            position: absolute;
            left: -33px;
            top: -40px;
        `,
        cover: css`
            height: 40px;
            width: 13px;
            position: absolute;
            background: ${theme.palette.common.black};
            right: -6px;
            top: 6px;
        `,
        iconWrapper: css`
            position: absolute;
            left: -30px;
            top: 42px;
            background: ${theme.palette.common.black};
            border-radius: 50%;
            border: 2px solid ${theme.palette.grey[600]};
            @media (min-width: ${theme.breakpoints.values.md}px) {
                left: -17px;
            }
        `,
        iconWrapperEnd: css`
            position: absolute;
            right: 0px;
            top: 42px;
            background: ${theme.palette.common.black};
            border-radius: 50%;
            border: 2px solid ${theme.palette.grey[600]};
            @media (min-width: ${theme.breakpoints.values.md}px) {
                right: -15px;
            }
        `,
        wrapper: css`
            background: #071623;
            border: 0.972385px solid ${theme.palette.grey[800]};
            border-right: 0px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            margin-left: -1px;
            flex: 1;
            z-index: 1;
        `,
    };
    return (
        <Box display="flex" height="112px" mb={3} position="relative">
            <Box
                css={styles.wrapper}
                pb={3}
                pl={isMobileDisplay ? 4 : hideArrows ? 2 : 6}
                pr={isLong && hideArrows ? 9 : isLong ? 5 : 0}
                pt={2}
            >
                {icon && !isMobileDisplay ? (
                    <Box
                        alignItems="center"
                        css={styles.iconWrapper}
                        display="flex"
                        height="32px"
                        justifyContent="center"
                        width="32px"
                    >
                        {icon}
                    </Box>
                ) : undefined}
                {!hideArrows && (
                    <Box css={styles.arrowIcons} display="flex">
                        <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                        <ChevronRightIcon
                            sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                        />
                    </Box>
                )}

                <Box>
                    <Box width="216px">{children}</Box>
                    {!hideFrontArrows && (
                        <Box css={styles.arrowIconsRight} display="flex">
                            <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                            <ChevronRightIcon
                                sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                            />
                        </Box>
                    )}
                </Box>
                {iconEnd ? (
                    <Box
                        alignItems="center"
                        css={styles.iconWrapperEnd}
                        display="flex"
                        height="32px"
                        justifyContent="center"
                        width="32px"
                    >
                        {iconEnd}
                    </Box>
                ) : undefined}
            </Box>
            <Box css={styles.arrowShape} width="40px" />
            <Box css={styles.cover} />
        </Box>
    );
}
