/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-style */

// reenable staking/unstaking in deposits/withdrawals
// reenable staking button on dashboard
// reenable staking/unstaking pools
// show pools balance again in product card
// show pools balance again in header

import { STABLE_TOKENS_AVAX, Token, TOKENS, VaultAvaxNames } from '../constants';
import crvABIJson from './abis/json/3crv.json';
import airdropsJson from './abis/json/airdrops.json';
import bouncerABIJson from './abis/json/bouncer.json';
import burnerJson from './abis/json/burner.json';
import curveABIJSON from './abis/json/curve.json';
import curvePoolABIJson from './abis/json/curvePool.json';
import cusdcABIJson from './abis/json/cusdc.json';
import daiABIJson from './abis/json/dai.json';
import daieABIJson from './abis/json/daie.json';
import duraABIJSon from './abis/json/dura.json';
import fiatTokenJson from './abis/json/fiatToken.json';
import g2RouterJson from './abis/json/g2Router.json';
import groBonusJson from './abis/json/gro-bonus.json';
import gtABIJson from './abis/json/grwth-token.json';
import gTrancheABIJson from './abis/json/gTranche.json';
import investorPositionsJson from './abis/json/investorPositions.json';
import lpTokenStakerJson from './abis/json/lp-token-staker.json';
import priceOracleABIJson from './abis/json/priceOracle.json';
import redeemJson from './abis/json/redeem.json';
import redeemAlloyJson from './abis/json/redeemAlloy.json';
import routerOracleJson from './abis/json/routerOracle.json';
import sanctionOracleABIJson from './abis/json/sanction-oracle.json';
import teamVestingJson from './abis/json/teamVesting.json';
import uniswapABIJson from './abis/json/uniswap.json';
import usdcABIJson from './abis/json/usdc.json';
import usdceABIJson from './abis/json/usdce.json';
import usdtABIJson from './abis/json/usdt.json';
import usdteABIJson from './abis/json/usdte.json';
import ustClaimJson from './abis/json/ustClaim.json';
import vaultAdaptorABIJson from './abis/json/vaultAdaptor.json';
import vestingABIJson from './abis/json/vesting.json';
import { ContractInfo, TokenInfo } from './web3.types';

export type ContractAddreses = {
    airdrops: string;
    balancer_v2_8020_gro_weth_5: string;
    bouncer: string;
    burner: string;
    crv: string;
    curvePool: string;
    curve_meta_pwrd_3crv_4: string;
    cusdc: string;
    dai: string;
    daie: string;
    dura: string;
    exposure: string;
    g2_router: string;
    g_tranche: string;
    gro: string;
    groBonus: string;
    gvt: string;
    investorPositions: string;
    lifeguard: string;
    lpTokenStakerHandler: string;
    lpTokenStakerHandlerDeprecated: string;
    priceOracle: string;
    pwrd: string;
    redeem: string;
    redeemAlloy: string;
    routerOracle: string;
    sanction_oracle: string;
    single_staking_100_gro_0: string;
    single_staking_100_gvt_3: string;
    teamVesting: string;
    uniswap_v2_5050_gro_gvt_1: string;
    uniswap_v2_5050_gro_usdc_2: string;
    usdc: string;
    usdce: string;
    usdt: string;
    usdte: string;
    ustClaim: string;
    vesting: string;
    votingAggregator: string;
    weth: string;
} & {
    [key in VaultAvaxNames]: string;
};

const vaultAddresses = {
    [VaultAvaxNames['groDAI.e_vault']]: '0x5E57E11483A3F60A76af3045303604522059dA2a',
    [VaultAvaxNames['groDAI.e_vault_v1_5']]: '0x82e40E1626ebb4076419b49b9403d9Ce2425B956',
    [VaultAvaxNames['groDAI.e_vault_v1_5_1']]: '0xA97Ab88e897637e79Ee9d1E44C3373dB55df275d',
    [VaultAvaxNames['groDAI.e_vault_v1_6']]: '0xbED10235A181020F2B3A974C5C9c5454B7414635',
    [VaultAvaxNames['groDAI.e_vault_v1_7']]: '0x6063597B9356B246E706Fd6A48C780F897e3ef55',
    [VaultAvaxNames['groUSDC.e_vault']]: '0x57DaED1ee021BE9991F5d30CF494b6B09B5B449E',
    [VaultAvaxNames['groUSDC.e_vault_v1_5']]: '0x6518becA1c20221cF6e8ba6F77B85818d1a298E7',
    [VaultAvaxNames['groUSDC.e_vault_v1_5_1']]: '0x6BacF8CEffB5E17E1C62824d9216E6ee89E52B99',
    [VaultAvaxNames['groUSDC.e_vault_v1_6']]: '0x72681D8dfCE95b275fd5d2BFBA199f78c9B0d7BA',
    [VaultAvaxNames['groUSDC.e_vault_v1_7']]: '0x2Eb05cfFA24309b9aaf300392A4D8Db745d4E592',
    [VaultAvaxNames['groUSDT.e_vault']]: '0x471F4B4b9A97F82C3a25b034B33A8E306eE9Beb5',
    [VaultAvaxNames['groUSDT.e_vault_v1_5']]: '0x95284D91e69BEAcAaf90AD6Fd3d6C959Eb900BA4',
    [VaultAvaxNames['groUSDT.e_vault_v1_5_1']]: '0x6E4036dB664e994907052e32c5150D8aEb974F39',
    [VaultAvaxNames['groUSDT.e_vault_v1_6']]: '0xAD6C0751E4FcDcA0926174248F67184880a1Dbbd',
    [VaultAvaxNames['groUSDT.e_vault_v1_7']]: '0x6EF44077a1F5e10cDfcCc30EFb7dCdb1d5475581',
};

const finalContractAddresses: ContractAddreses = {
    ...vaultAddresses,
    airdrops: '0xF3d39A7FEbA9bE0C1D18b355E7eD01070Ee2c561',
    balancer_v2_8020_gro_weth_5: '0x702605F43471183158938C1a3e5f5A359d7b31ba',
    bouncer: '0x60861b5AFdf4B6e449dd194a6b54d6A64DFE2d81',
    burner: '0x1F09e308bb18795f62ea7B114041E12b426b8880',
    crv: '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
    curve_meta_pwrd_3crv_4: '0xbcb91E689114B9Cc865AD7871845C95241Df4105',
    curvePool: '0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7',
    cusdc: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
    dai: '0x6b175474e89094c44da98b954eedeac495271d0f',
    daie: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
    dura: '0x4562724cAa90d866c35855b9baF71E5125CAD5B6',
    exposure: '0x2344dba0A1DE9ACf22B7C9936c6693f20B953444',
    g_tranche: '0x19A07afE97279cb6de1c9E73A13B7b0b63F7E67A',
    g2_router: '0xd4139E090e43Ff77172d9dD8BA449d2A9683790d',
    gro: '0x3Ec8798B81485A254928B70CDA1cf0A2BB0B74D7',
    groBonus: '0x7C268Bf50e64258835029c30C91DaA65a9E55b5a',
    gvt: '0x3ADb04E127b9C0a5D36094125669d4603AC52a0c',
    investorPositions: '0x0537d3DA1Ed1dd7350fF1f3B92b727dfdBAB80f1',
    lifeguard: '0xc118F2EB1291F9bc0c2a4C2d61b59f1785b047B5',
    lpTokenStakerHandler: '0x2E32bAd45a1C29c1EA27cf4dD588DF9e68ED376C',
    lpTokenStakerHandlerDeprecated: '0x001C249c09090D79Dc350A286247479F08c7aaD7',
    priceOracle: '0x6a25Fd29E2033d9519B77FA0d649DC9aeD219a13',
    pwrd: '0xF0a93d4994B3d98Fb5e3A2F90dBc2d69073Cb86b',
    redeem: '0x204d9DE758217A39149767731a87Bcc32427b6ef',
    redeemAlloy: '0xeFC812A74Ea5dB6913E676F20fef66b22FC66432',
    routerOracle: '0xfe620D20b3ED77ec0934Fbe9dF2147F6f3C62599',
    sanction_oracle: '0x40C57923924B5c5c5455c48D93317139ADDaC8fb',
    single_staking_100_gro_0: '0x3Ec8798B81485A254928B70CDA1cf0A2BB0B74D7',
    single_staking_100_gvt_3: '0x3ADb04E127b9C0a5D36094125669d4603AC52a0c',
    teamVesting: '0xF43c6bDD2F9158B5A78DCcf732D190C490e28644',
    uniswap_v2_5050_gro_gvt_1: '0x2ac5bC9ddA37601EDb1A5E29699dEB0A5b67E9bB',
    uniswap_v2_5050_gro_usdc_2: '0x21C5918CcB42d20A2368bdCA8feDA0399EbfD2f6',
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    usdce: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    usdte: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    ustClaim: '0x02c133B9fBffb8D2E8cb7B7a94C7c880b331c720',
    vesting: '0x748218256AfE0A19a88EBEB2E0C5Ce86d2178360',
    votingAggregator: '0x2c57F9067E50E819365df7c5958e2c4C14A91C2D',
    weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
};

export const airdrops: ContractInfo = {
    abi: airdropsJson,
    address: finalContractAddresses.airdrops,
    displayName: 'Airdrops',
    id: 'airdrops',
};

export const redeem: ContractInfo = {
    abi: redeemJson,
    address: finalContractAddresses.redeem,
    displayName: 'Redeem',
    id: 'redeem',
};

export const redeemAlloy: ContractInfo = {
    abi: redeemAlloyJson,
    address: finalContractAddresses.redeemAlloy,
    displayName: 'Redeem alloy',
    id: 'redeemAlloy',
};

export const ustClaim: ContractInfo = {
    abi: ustClaimJson,
    address: finalContractAddresses.ustClaim,
    displayName: 'UstClaim',
    id: 'ustClaim',
};

export const sanctionOracle: ContractInfo = {
    abi: sanctionOracleABIJson,
    address: finalContractAddresses.sanction_oracle,
    displayName: 'Sanction Oracle',
    id: 'sanctionOracle',
};

export const bouncer: ContractInfo = {
    abi: bouncerABIJson,
    address: finalContractAddresses.bouncer,
    displayName: 'Bouncer',
    id: 'bouncer',
};

export const teamVesting: ContractInfo = {
    abi: teamVestingJson,
    address: finalContractAddresses.teamVesting,
    displayName: 'Team Vesting',
    id: 'teamVesting',
};

export const investorPositions: ContractInfo = {
    abi: investorPositionsJson,
    address: finalContractAddresses.investorPositions,
    displayName: 'Investor Positions',
    id: 'investorPositions',
};

export const burner: ContractInfo = {
    abi: burnerJson,
    address: finalContractAddresses.burner,
    displayName: 'Burner',
    id: 'burner',
};

export const gTranche: ContractInfo = {
    abi: gTrancheABIJson,
    address: finalContractAddresses.g_tranche,
    displayName: 'GTranche',
    id: 'gTranche',
};

export const routerOracle: ContractInfo = {
    abi: routerOracleJson,
    address: finalContractAddresses.routerOracle,
    displayName: 'RouterOracle',
    id: 'routerOracle',
};

export const curvePool: ContractInfo = {
    abi: curvePoolABIJson,
    address: finalContractAddresses.curvePool,
    displayName: 'curvePool',
    id: 'curvePool',
};

export const vesting: ContractInfo = {
    abi: vestingABIJson,
    address: finalContractAddresses.vesting,
    displayName: 'Vesting',
    id: 'vesting',
};

export const groBonus: ContractInfo = {
    abi: groBonusJson,
    address: finalContractAddresses.groBonus,
    displayName: 'GroBonus',
    id: 'goBonus',
};

export function getVaultAdaptor(name: VaultAvaxNames): ContractInfo {
    return {
        abi: vaultAdaptorABIJson,
        address: finalContractAddresses[name],
        displayName: VaultAvaxNames[name],
        id: VaultAvaxNames[name],
    };
}

export const g2Router: ContractInfo = {
    abi: g2RouterJson,
    address: finalContractAddresses.g2_router,
    displayName: 'GRouter',
    id: 'GRouter',
};

export const priceOracle: ContractInfo = {
    abi: priceOracleABIJson,
    address: finalContractAddresses.priceOracle,
    displayName: 'priceOracle',
    id: 'priceOracle',
};

export const lpTokenStakerHandler: ContractInfo = {
    abi: lpTokenStakerJson,
    address: finalContractAddresses.lpTokenStakerHandler,
    displayName: 'LPTokenStaker',
    id: 'lpTokenStakerHandler',
};

export const lpTokenStakerHandlerDeprecated: ContractInfo = {
    abi: lpTokenStakerJson,
    address: finalContractAddresses.lpTokenStakerHandlerDeprecated,
    displayName: 'LPTokenStakerDeprecated',
    id: 'lpTokenStakerHandlerDeprecated',
};

export const daie: TokenInfo = {
    abi: daieABIJson,
    address: finalContractAddresses.daie,
    decimals: 18,
    displayName: 'DAI.e',
    id: 'dai.e',
    walletTokenName: '',
};

export const dai: TokenInfo = {
    abi: daiABIJson,
    address: finalContractAddresses.dai,
    decimals: 18,
    displayName: 'DAI',
    id: 'dai',
    walletTokenName: '',
};

export const crv: TokenInfo = {
    abi: crvABIJson,
    address: finalContractAddresses.crv,
    decimals: 18,
    displayName: '3CRV',
    id: '3crv',
    walletTokenName: '',
};

export const usdce: TokenInfo = {
    abi: usdceABIJson,
    address: finalContractAddresses.usdce,
    decimals: 6,
    displayName: 'USDC.e',
    id: 'usdce',
    walletTokenName: '',
};

export const cusdc: TokenInfo = {
    abi: cusdcABIJson,
    address: finalContractAddresses.cusdc,
    decimals: 6,
    displayName: 'CUSDC',
    id: 'cusdc',
    walletTokenName: '',
};

export const dura: TokenInfo = {
    abi: duraABIJSon,
    address: finalContractAddresses.dura,
    decimals: 18,
    displayName: 'DURA',
    id: 'dura',
    walletTokenName: '',
};

export const usdc: TokenInfo = {
    abi: usdcABIJson,
    address: finalContractAddresses.usdc,
    decimals: 6,
    displayName: 'USDC',
    id: 'usdc',
    walletTokenName: '',
};

export const usdcProxi: TokenInfo = {
    abi: fiatTokenJson,
    address: finalContractAddresses.usdc,
    decimals: 6,
    displayName: 'USDC PROXI',
    id: 'usdc_proxi',
    walletTokenName: '',
};

export const usdte: TokenInfo = {
    abi: usdteABIJson,
    address: finalContractAddresses.usdte,
    decimals: 6,
    displayName: 'USDT.e',
    id: 'usdt',
    walletTokenName: '',
};

export const usdt: TokenInfo = {
    abi: usdtABIJson,
    address: finalContractAddresses.usdt,
    decimals: 6,
    displayName: 'USDT',
    id: 'usdt',
    walletTokenName: '',
};

export const pwrd: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.pwrd,
    decimals: 18,
    displayName: 'PWRD',
    id: 'pwrd',
    image: 'https://ipfs.io/ipfs/QmeivQajfALLuHmj5D4AcMx48h5Ghxb5JhapkXoieX727w',
    walletTokenName: 'PWRD',
};

export const gro: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.gro,
    decimals: 18,
    displayName: 'GRO',
    id: 'gro',
    image: 'https://ipfs.io/ipfs/QmWAHSDz61ucpkW6EnmUCKJrHxgHD9C9B6hEU2d3AmKMFX',
    walletTokenName: 'Gro DAO Token',
};

export const gvt: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.gvt,
    decimals: 18,
    displayName: 'Vault',
    id: 'gvt',
    image: 'https://ipfs.io/ipfs/QmVh6KsBD9wboNscMddPKaQohCdsQHzB7x2rAqLAHmf9tm',
    walletTokenName: 'Gro Vault',
};

export const usd: TokenInfo = {
    abi: [],
    address: '',
    decimals: 18,
    displayName: 'USD',
    id: 'usd',
    walletTokenName: '',
};

export const weth: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.weth,
    decimals: 18,
    displayName: 'WETH',
    id: 'weth',
    image: '',
    walletTokenName: 'Wrapped ETH',
};

// POOLS

// eslint-disable-next-line @typescript-eslint/naming-convention
export const balancer_v2_8020_gro_weth_5: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.balancer_v2_8020_gro_weth_5,
    decimals: 18,
    displayName: 'GRO-WETH-BAL LP',
    id: 'balancer_v2_8020_gro_weth_5',
    image: '',
    walletTokenName: 'Balancer v2 pool GRO 80% / WETH 20%',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const curve_meta_pwrd_3crv_4: TokenInfo = {
    abi: curveABIJSON,
    address: finalContractAddresses.curve_meta_pwrd_3crv_4,
    decimals: 18,
    displayName: 'PWRD-3CRV LP',
    id: 'curve_meta_pwrd_3crv_4',
    image: '',
    walletTokenName: '3Curve Metapool PWRD/USDT/DAI/USDC',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const single_staking_100_gro_0: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.gro,
    decimals: 18,
    displayName: 'GRO',
    id: 'gro',
    image: 'https://ipfs.io/ipfs/QmWAHSDz61ucpkW6EnmUCKJrHxgHD9C9B6hEU2d3AmKMFX',
    walletTokenName: 'Gro DAO Token',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const single_staking_100_gvt_3: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.gvt,
    decimals: 18,
    displayName: 'Vault',
    id: 'gvt',
    image: 'https://ipfs.io/ipfs/QmVh6KsBD9wboNscMddPKaQohCdsQHzB7x2rAqLAHmf9tm',
    walletTokenName: 'Gro Vault',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const single_staking_100_pwrd_6: TokenInfo = {
    abi: gtABIJson,
    address: finalContractAddresses.pwrd,
    decimals: 18,
    displayName: 'PWRD',
    id: 'pwrd',
    image: '',
    walletTokenName: 'PWRD Vault',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const uniswap_v2_5050_gro_gvt_1: TokenInfo = {
    abi: uniswapABIJson,
    address: finalContractAddresses.uniswap_v2_5050_gro_gvt_1,
    decimals: 18,
    displayName: 'GRO-Vault Uni LP',
    id: 'uniswap_v2_5050_gro_gvt_1',
    image: '',
    walletTokenName: 'Uniswap v2 Vault 50% / GRO 50%',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const uniswap_v2_5050_gro_usdc_2: TokenInfo = {
    abi: uniswapABIJson,
    address: finalContractAddresses.uniswap_v2_5050_gro_usdc_2,
    decimals: 18,
    displayName: 'GRO USDC Uni LP',
    id: 'uniswap_v2_5050_gro_usdc_2',
    image: '',
    walletTokenName: 'Uniswap v2 GRO 50% / USDC 50%',
};

// pretty alias of uniswap_v2_5050_gro_usdc_2
export const pwrd3crv = uniswap_v2_5050_gro_usdc_2;

export const uniTokens = {
    uniswap_v2_5050_gro_gvt_1,
    uniswap_v2_5050_gro_usdc_2,
};

export function getUniTokenInfo(
    token: 'uniswap_v2_5050_gro_gvt_1' | 'uniswap_v2_5050_gro_usdc_2',
): TokenInfo {
    return uniTokens[token];
}

export const allTokens = {
    balancer_v2_8020_gro_weth_5,
    crv,
    curve_meta_pwrd_3crv_4,
    cusdc,
    dai,
    daie,
    dura,
    gro,
    gvt,
    pwrd,
    pwrd3crv,
    single_staking_100_gro_0,
    single_staking_100_gvt_3,
    single_staking_100_pwrd_6,
    uniswap_v2_5050_gro_gvt_1,
    uniswap_v2_5050_gro_usdc_2,
    usd,
    usdc,
    usdce,
    usdt,
    usdte,
    weth,
};

export function getTokenInfo(token: Token): TokenInfo {
    return allTokens[token];
}

export function getIndexFromToken(token: Token): number {
    switch (token) {
        case TOKENS.DAI:
            return 0;
        case TOKENS.USDC:
        case TOKENS.CUSDC:
        case TOKENS.DURA:
            return 1;
        case TOKENS.USDT:
            return 2;
        case TOKENS.CRV:
            return 3;
        default:
            throw new Error(`token:index:error:${token}`);
    }
}

export function getTokenByIndex(index: number): Token {
    switch (index) {
        case 0:
            return TOKENS.DAI;
        case 1:
            return TOKENS.USDC;
        case 2:
            return TOKENS.USDT;
        case 3:
            return TOKENS.CRV;
        default:
            throw new Error(`token:index:error:${index}`);
    }
}

export function getAllTokens(): Token[] {
    const tokensNoAvax = Object.keys(allTokens).filter(
        (token: any) => !STABLE_TOKENS_AVAX.includes(token),
    ) as Token[];
    return tokensNoAvax.filter((token) => token !== TOKENS.USD);
}
