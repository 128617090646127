/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import {
    MINIMUM_INVESTING_VISIBLE_AMOUNT,
    NO_SIGNATURE,
    Token,
    TokenObject,
    TOKENS,
} from '../../../../constants';
import { lpTokenStakerHandler } from '../../../../lib/contract-info';
import { checkIsApproved } from '../../../../lib/web3/approve';
import { stablesToGrowth, tokenToUsd } from '../../../../lib/web3/convert';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import {
    addWalletTransaction,
    setTransactionStatus,
    updateTransactionSource,
    updateTransactionTarget,
} from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { getFromTokens } from '../../helpers/exchange.helpers';
import { createApproval } from '../exchange.helpers';
import { selectExchangeState, selectIsStake } from '../exchange.selectors';

export const generateInvestTransactionThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const wallet = selectWalletAccount(getState());
        const exchange = selectExchangeState(getState());
        const transaction = selectCurrentSessionTransaction(tid)(getState());
        const isStake = selectIsStake(getState());

        // Get the token values and check if we need to do a invest all
        const fromTokens = await getFromTokens({
            conversions: exchange.conversions,
            tokens: transaction.from,
            tokensFrom: exchange.tokens.from,
            wallet,
        });

        const filteredFromTokens = fromTokens
            // Filter out tokens that are not visible in the UI
            // added === usdt because dai and usdc are approved through eip 712 permit
            .filter((item) => item[1].isGreaterThan(MINIMUM_INVESTING_VISIBLE_AMOUNT));

        const sources: TokenObject = Object.fromEntries(filteredFromTokens);

        const grwthToken = exchange.token;

        // 1. Check Approvals
        const promises = filteredFromTokens
            .filter((item) => NO_SIGNATURE.includes(item[0]))
            // Launch approvals
            .map((item) =>
                checkIsApproved(item[0], wallet, item[1]).then((approval): [string, BigNumber] => [
                    item[0],
                    approval,
                ]),
            );

        const approvalMap = await Promise.all(promises);

        approvalMap.forEach(([token, isApproved]) => {
            if (isApproved.isLessThanOrEqualTo(0)) {
                if (isApproved.isLessThan(0) && NO_SIGNATURE.includes(token as Token)) {
                    // USDT requires reset approval to 0 before increase to a new value
                    createApproval(
                        dispatch,
                        isApproved,
                        ExchangeDirection.invest,
                        new BigNumber(0),
                        tid,
                        token as Token,
                    );
                }

                dispatch(
                    addWalletTransaction({
                        // by default undefined will generate an approval with total supply
                        // amount: undefined,
                        direction: ExchangeDirection.invest,
                        meta: {
                            token,
                        },
                        mmTid: uuid(),
                        tid,
                        token: token as Token,
                        type: TransactionType.approve,
                    }),
                );
            }
        });

        // 2. Calculate conversion amount
        const mmTid = uuid();
        const grwthAmount = await stablesToGrowth(grwthToken, sources);

        Object.entries(sources).forEach(([token, amount]) => {
            dispatch(updateTransactionSource({ amount, tid, token: token as Token }));
        });

        dispatch(updateTransactionTarget({ amount: grwthAmount, tid, token: grwthToken }));

        dispatch(
            addWalletTransaction({
                direction: ExchangeDirection.invest,
                meta: {
                    from: sources,
                    to: {
                        [grwthToken]: grwthAmount,
                    },
                },
                mmTid,
                status: TransactionStatus.idle,
                tid,
                token: grwthToken,
                type: TransactionType.exchange,
                usdAmount: tokenToUsd(grwthToken, grwthAmount, exchange.conversions),
            }),
        );

        // 3. If user has selected Stake, configure it.
        if (isStake) {
            const isApproved = await checkIsApproved(
                grwthToken,
                wallet,
                grwthAmount,
                lpTokenStakerHandler.address,
            );

            const tokenStake =
                grwthToken === TOKENS.GVT ? TOKENS.GVT_SINGLE_SIDED : TOKENS.PWRD_SINGLE_SIDED;

            if (isApproved.isLessThanOrEqualTo(0)) {
                dispatch(
                    addWalletTransaction({
                        amount: grwthAmount,
                        // by default undefined will generate an approval with total supply
                        // amount: undefined,
                        direction: ExchangeDirection.stake,
                        meta: {
                            grwthToken: tokenStake,
                        },
                        mmTid: uuid(),
                        status: TransactionStatus.idle,
                        tid,
                        token: tokenStake,
                        type: TransactionType.approve,
                    }),
                );
            }

            dispatch(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                addWalletTransaction({
                    direction: ExchangeDirection.stake,
                    meta: {
                        from: {
                            [grwthToken]: grwthAmount,
                        },
                        to: {
                            [tokenStake]: grwthAmount,
                        },
                    },
                    mmTid: uuid(),
                    status: TransactionStatus.idle,
                    tid,
                    token: tokenStake,
                    type: TransactionType.exchange,
                }),
            );
        }

        // 4. Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
