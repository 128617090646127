/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BigNumber } from 'bignumber.js';
import { lpTokenStakerContract } from '../abis/lpTokenStaker';
import { convertToBN } from './web3.helpers';

export function stake(pid: string, amount: BigNumber) {
    const amountBN = convertToBN(amount);
    return lpTokenStakerContract().methods.deposit(parseInt(pid, 10), amountBN);
}

export function unStake(pid: string, amount: BigNumber) {
    const amountBN = convertToBN(amount);
    return lpTokenStakerContract().methods.withdraw(parseInt(pid, 10), amountBN);
}

export function unstakePWRD(pid: string) {
    return lpTokenStakerContract().methods.emergencyWithdraw(parseInt(pid, 10));
}

export async function stakedBalanceOf(address: string, pid: string): Promise<BigNumber> {
    if (parseInt(pid, 10) >= 0) {
        const amount = await lpTokenStakerContract()
            .methods.userInfo(parseInt(pid, 10), address)
            .call();
        return new BigNumber(amount.amount);
    }
    return new BigNumber(0);
}

export async function getPWRDStakedBalance(address: string): Promise<BigNumber> {
    const amount = await lpTokenStakerContract().methods.getUserPwrd(address).call();
    return new BigNumber(amount);
}

export async function claimableRewardsBalanceOf(address: string, pid: string): Promise<BigNumber> {
    if (parseInt(pid, 10) >= 0) {
        const amount = await lpTokenStakerContract()
            .methods.claimable(parseInt(pid, 10), address)
            .call();
        return new BigNumber(amount);
    }
    return new BigNumber(0);
}

export function claimPoolReward(pid: string, claimToWallet: boolean) {
    return lpTokenStakerContract().methods.claim(!claimToWallet, pid);
}

export function multiClaimRewards(pid: string[], claimToWallet: boolean) {
    return lpTokenStakerContract().methods.multiClaim(!claimToWallet, pid);
}

export function migrateUser(pids: string[], wallet: string) {
    return lpTokenStakerContract().methods.migrateUser(wallet, pids);
}

export function userMigrated(pid: string, wallet: string) {
    return lpTokenStakerContract().methods.userMigrated(wallet, pid).call();
}
