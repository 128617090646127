/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TpBreadcrumbRoutes } from '../../components/navigation/BreadCrumbs';
import { LabIcon } from './assets';

/**
 * Keep routes as a separate export (i.e. not from index),
 * so that we keep the lazy loading of the module
 */

const base = '/labs';
export const routes: TpBreadcrumbRoutes = {
    base: {
        breadcrumb: 'Labs',
        Icon: LabIcon,
        path: base,
    },
};
