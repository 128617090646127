/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable func-style */
import { AppActionThunk } from '../../../app/store';
import {
    loadGroStatsMc,
    setGroStatsError,
} from '../stats.reducer';
import { loadGroStatsOnChainThunk } from './loadGroStatsOnChainThunk';

export const loadGroStatsMcThunk: AppActionThunk<Promise<void>> = () => async (dispatch) => {
    try {
        dispatch(loadGroStatsMc());
            dispatch(setGroStatsError(true));
            void dispatch(loadGroStatsOnChainThunk());
       
    } catch (e) {
        // TODO: fallback
        dispatch(setGroStatsError(true));
        void dispatch(loadGroStatsOnChainThunk());
    }
};
