export const labsBody = [
    {
        all_time_apy: '0.094031',
        amount: '201642.3783764',
        avax_exposure: '0.000000',
        display_name: 'Gro Labs DAI.e AVAX Vault v1.7',
        last3d_apy: '0.000000',
        name: 'groDAI.e_vault_v1_7',
        reserves: {
            amount: '201642.3783764',
            display_name: 'Gro Labs DAI.e AVAX Vault v1.7',
            last3d_apy: '0.000000',
            name: 'groDAI.e_vault_v1_7',
            share: '0.143415',
        },
        share: '0.143415',
        stablecoin: 'DAI.e',
        strategies: [
            {
                address: '0x670ea3f675a332d561d5a9ecaa16c097ac0b0ba5',
            },
        ],
    },
    {
        all_time_apy: '0.080524',
        amount: '525446.7233110',
        avax_exposure: '0.000000',
        display_name: 'Gro Labs USDC.e AVAX Vault v1.7',
        last3d_apy: '0.000000',
        name: 'groUSDC.e_vault_v1_7',
        reserves: {
            amount: '525446.7233110',
            display_name: 'Gro Labs USDC.e AVAX Vault v1.7',
            last3d_apy: '0.000000',
            name: 'groUSDC.e_vault_v1_7',
            share: '0.373718',
        },
        share: '0.373718',
        stablecoin: 'USDC.e',
        strategies: [
            {
                address: '0xa80ec1e57c5a37517a0f5912acc7b30585ebff56',
            },
        ],
    },
    {
        all_time_apy: '0.020723',
        amount: '671138.2381720',
        avax_exposure: '0.000000',
        display_name: 'Gro Labs USDT.e AVAX Vault v1.7',
        last3d_apy: '0.000000',
        name: 'groUSDT.e_vault_v1_7',
        reserves: {
            amount: '671138.2381720',
            display_name: 'Gro Labs USDT.e AVAX Vault v1.7',
            last3d_apy: '0.000000',
            name: 'groUSDT.e_vault_v1_7',
            share: '0.477339',
        },
        share: '0.477339',
        stablecoin: 'USDT.e',
        strategies: [
            {
                address: '0x1a104793650684013c555d6ae07058f76e4679ef',
            },
        ],
    },
];

export const labsDeprecated = [
    {
        all_time_apy: '0.248209',
        amount: '16.1043987',
        avax_exposure: '0.000000',
        last3d_apy: '0.248627',
        name: 'groDAI.e_vault',
        reserves: {
            amount: '16.1043987',
            last3d_apy: '0.0000000',
            name: 'groDAI.e_vault',
            share: '0.0005671',
        },
        share: '0.0005671',
        stablecoin: 'DAI.e',
        strategies: [
            {
                address: '0x4c7ea5b8032c5ea82ddf617dac7972c70e0c0478',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_DAI.e_strat',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '-0.879235',
                        close_amount: '77850.7465039',
                        close_ts: '1646147124',
                        open_amount: '77862.5500713',
                        open_ts: '1646143046',
                    },
                    {
                        apy: '0.031604',
                        close_amount: '77862.0031402',
                        close_ts: '1646227104',
                        open_amount: '77853.6973957',
                        open_ts: '1646147185',
                    },
                    {
                        apy: '0.315641',
                        close_amount: '70070.7728996',
                        close_ts: '1646307003',
                        open_amount: '70004.5392925',
                        open_ts: '1646227165',
                    },
                    {
                        apy: '-0.386538',
                        close_amount: '62152.3972326',
                        close_ts: '1646319023',
                        open_amount: '62164.5971926',
                        open_ts: '1646307024',
                    },
                    {
                        apy: '0.466437',
                        close_amount: '62223.0117991',
                        close_ts: '1646332351',
                        open_amount: '62204.9293643',
                        open_ts: '1646319085',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '51.017631',
                sortino_ratio: '63.832790',
                tvl_cap: '2000000',
            },
        ],
    },
    {
        all_time_apy: '0.221052',
        amount: '103.148384',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDC.e_vault',
        reserves: {
            amount: '103.148384',
            last3d_apy: '0.0000000',
            name: 'groUSDC.e_vault',
            share: '0.0036320',
        },
        share: '0.0036320',
        stablecoin: 'USDC.e',
        strategies: [
            {
                address: '0x247af6e106549033d3a65354fc3a72ff3794fa99',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDC.e_strat',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '0.104792',
                        close_amount: '580308.7627680',
                        close_ts: '1646192907',
                        open_amount: '580193.5853270',
                        open_ts: '1646152584',
                    },
                    {
                        apy: '-0.522851',
                        close_amount: '580126.7705500',
                        close_ts: '1646205083',
                        open_amount: '580279.9684080',
                        open_ts: '1646192962',
                    },
                    {
                        apy: '0.293860',
                        close_amount: '572484.4720340',
                        close_ts: '1646273603',
                        open_amount: '572349.7665960',
                        open_ts: '1646205144',
                    },
                    {
                        apy: '0.121173',
                        close_amount: '142752.1796630',
                        close_ts: '1646319805',
                        open_amount: '142714.6471560',
                        open_ts: '1646273663',
                    },
                    {
                        apy: '0.604896',
                        close_amount: '142793.4375550',
                        close_ts: '1646332351',
                        open_amount: '142742.7965360',
                        open_ts: '1646319865',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '23.476301',
                sortino_ratio: '25.460422',
                tvl_cap: '2000000',
            },
        ],
    },
    {
        all_time_apy: '0.241058',
        amount: '2874.995179',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDT.e_vault',
        reserves: {
            amount: '2874.995179',
            last3d_apy: '0.0000000',
            name: 'groUSDT.e_vault',
            share: '0.1012329',
        },
        share: '0.1012329',
        stablecoin: 'USDT.e',
        strategies: [
            {
                address: '0x94a7c3419504cea9fba06ee739717b236ada0638',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDT.e_strat',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '0.134271',
                        close_amount: '543322.5875360',
                        close_ts: '1646192844',
                        open_amount: '543187.8393850',
                        open_ts: '1646153364',
                    },
                    {
                        apy: '-0.379859',
                        close_amount: '543183.8606040',
                        close_ts: '1646205144',
                        open_amount: '543288.9004980',
                        open_ts: '1646192906',
                    },
                    {
                        apy: '0.428060',
                        close_amount: '535559.1121860',
                        close_ts: '1646274803',
                        open_amount: '535371.0412990',
                        open_ts: '1646205206',
                    },
                    {
                        apy: '0.104111',
                        close_amount: '134573.1787700',
                        close_ts: '1646320047',
                        open_amount: '134543.4756850',
                        open_ts: '1646274863',
                    },
                    {
                        apy: '0.618651',
                        close_amount: '134613.6372080',
                        close_ts: '1646332345',
                        open_amount: '134565.7529990',
                        open_ts: '1646320104',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '29.816789',
                sortino_ratio: '33.153213',
                tvl_cap: '2000000',
            },
        ],
    },
    {
        all_time_apy: '0.132761',
        amount: '0.3586581',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groDAI.e_vault_v1_5',
        reserves: {
            amount: '0.3586581',
            last3d_apy: '0.0000000',
            name: 'groDAI.e_vault_v1_5',
            share: '0.0000126',
        },
        share: '0.0000126',
        stablecoin: 'DAI.e',
        strategies: [
            {
                address: '0xe0d6eff0f64da98b2c0e47102d59709b24cfc76f',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_DAI.e_strat_v1_5',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '0.000000',
                        close_amount: '2256.2021778',
                        close_ts: '1643028085',
                        open_amount: '2256.2021778',
                        open_ts: '1643019028',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '2256.9190839',
                        close_ts: '1643035227',
                        open_amount: '2256.9190839',
                        open_ts: '1643028143',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '2256.7052711',
                        close_ts: '1643037024',
                        open_amount: '2256.7052711',
                        open_ts: '1643035286',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '2256.2054365',
                        close_ts: '1643038405',
                        open_amount: '2256.2054365',
                        open_ts: '1643037085',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '-0.0000000',
                        close_ts: '1643038527',
                        open_amount: '-0.0000000',
                        open_ts: '1643038466',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '8.764900',
                sortino_ratio: '17.646666',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.115362',
        amount: '48.914259',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDC.e_vault_v1_5',
        reserves: {
            amount: '48.914259',
            last3d_apy: '0.0000000',
            name: 'groUSDC.e_vault_v1_5',
            share: '0.0017223',
        },
        share: '0.0017223',
        stablecoin: 'USDC.e',
        strategies: [
            {
                address: '0x45fa601854326de028b982df9839a27d22f36344',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDC.e_strat_v1_5',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '0.000000',
                        close_amount: '806650.0256420',
                        close_ts: '1642898069',
                        open_amount: '806650.0256420',
                        open_ts: '1642898008',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '806646.3934890',
                        close_ts: '1642944632',
                        open_amount: '806646.3934890',
                        open_ts: '1642941628',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '806480.1073390',
                        close_ts: '1643016155',
                        open_amount: '806480.1073390',
                        open_ts: '1643003375',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '806402.4541820',
                        close_ts: '1643022024',
                        open_amount: '806402.4541820',
                        open_ts: '1643016202',
                    },
                    {
                        apy: '0.000000',
                        close_amount: '-0.0000010',
                        close_ts: '1643038226',
                        open_amount: '-0.0000010',
                        open_ts: '1643022086',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '0.000000',
                sortino_ratio: '0.000000',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.000000',
        amount: '0',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDT.e_vault_v1_5',
        reserves: {
            amount: '0',
            last3d_apy: '0.0000000',
            name: 'groUSDT.e_vault_v1_5',
            share: '0.0000000',
        },
        share: '0.0000000',
        stablecoin: 'USDT.e',
        strategies: [
            {
                address: '0xb29380360a44a7630f404c7609114e48fde0ddee',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDT.e_strat_v1_5',
                open_position: {},
                past_5_closed_positions: [],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '0.000000',
                sortino_ratio: '0.000000',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.000000',
        amount: '0',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groDAI.e_vault_v1_6',
        reserves: {
            amount: '0',
            last3d_apy: '0.0000000',
            name: 'groDAI.e_vault_v1_6',
            share: '0.0000000',
        },
        share: '0.0000000',
        stablecoin: 'DAI.e',
        strategies: [
            {
                address: '0xb7a4ed26da881196c3da388b46cd290fd92aacc8',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_DAI.e_strat_v1_6',
                open_position: {},
                past_5_closed_positions: [],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '0.000000',
                sortino_ratio: '0.000000',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.000000',
        amount: '0',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDC.e_vault_v1_6',
        reserves: {
            amount: '0',
            last3d_apy: '0.0000000',
            name: 'groUSDC.e_vault_v1_6',
            share: '0.0000000',
        },
        share: '0.0000000',
        stablecoin: 'USDC.e',
        strategies: [
            {
                address: '0x21da4b3b6738adb122dedbfa722cdab6fa29d4ac',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDC.e_strat_v1_6',
                open_position: {},
                past_5_closed_positions: [],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '0.000000',
                sortino_ratio: '0.000000',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.000000',
        amount: '0',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDT.e_vault_v1_6',
        reserves: {
            amount: '0',
            last3d_apy: '0.0000000',
            name: 'groUSDT.e_vault_v1_6',
            share: '0.0000000',
        },
        share: '0.0000000',
        stablecoin: 'USDT.e',
        strategies: [
            {
                address: '0x9889d8cdcdf4f5a0f30378c909a8930cf0fed2f4',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDT.e_strat_v1_6',
                open_position: {},
                past_5_closed_positions: [],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '0.000000',
                sortino_ratio: '0.000000',
                tvl_cap: '10000000',
            },
        ],
    },
    {
        all_time_apy: '0.091660',
        amount: '2881.0035324',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groDAI.e_vault_v1_7',
        reserves: {
            amount: '2881.0035324',
            last3d_apy: '0.0000000',
            name: 'groDAI.e_vault_v1_7',
            share: '0.1014444',
        },
        share: '0.1014444',
        stablecoin: 'DAI.e',
        strategies: [
            {
                address: '0x670ea3f675a332d561d5a9ecaa16c097ac0b0ba5',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_DAI.e_strat_v1_7',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '0.147909',
                        close_amount: '293694.9423659',
                        close_ts: '1653655288',
                        open_amount: '293694.6875321',
                        open_ts: '1653655103',
                    },
                    {
                        apy: '0.043205',
                        close_amount: '269026.6744536',
                        close_ts: '1654087404',
                        open_amount: '268858.5565243',
                        open_ts: '1653655348',
                    },
                    {
                        apy: '-0.121282',
                        close_amount: '242066.4248259',
                        close_ts: '1654520123',
                        open_amount: '242495.9172731',
                        open_ts: '1654088064',
                    },
                    {
                        apy: '-0.783568',
                        close_amount: '253595.1714021',
                        close_ts: '1654589369',
                        open_amount: '254031.8584967',
                        open_ts: '1654520184',
                    },
                    {
                        apy: '-0.573425',
                        close_amount: '239778.6221692',
                        close_ts: '1655071466',
                        open_amount: '241969.9258505',
                        open_ts: '1654589903',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '-73.525196',
                sortino_ratio: '-87.764951',
                tvl_cap: '2000000',
            },
        ],
    },
    {
        all_time_apy: '0.078493',
        amount: '22388.702468',
        avax_exposure: '0.000000',
        last3d_apy: '0.000000',
        name: 'groUSDC.e_vault_v1_7',
        reserves: {
            amount: '22388.702468',
            last3d_apy: '0.0000000',
            name: 'groUSDC.e_vault_v1_7',
            share: '0.7883396',
        },
        share: '0.7883396',
        stablecoin: 'USDC.e',
        strategies: [
            {
                address: '0xa797c615fc49a39363fdfa3398e8d524756e9a13',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDC.e_strat_v1_7',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '-0.002915',
                        close_amount: '667221.0593680',
                        close_ts: '1654087285',
                        open_amount: '667247.9046740',
                        open_ts: '1653655230',
                    },
                    {
                        apy: '-0.106076',
                        close_amount: '619149.1933670',
                        close_ts: '1654520065',
                        open_amount: '620087.2910420',
                        open_ts: '1654088003',
                    },
                    {
                        apy: '-0.731925',
                        close_amount: '634316.1932310',
                        close_ts: '1654589369',
                        open_amount: '635337.2722010',
                        open_ts: '1654520123',
                    },
                    {
                        apy: '-0.105098',
                        close_amount: '610082.0562320',
                        close_ts: '1654989508',
                        open_amount: '610899.5652390',
                        open_ts: '1654589844',
                    },
                    {
                        apy: '8.655805',
                        close_amount: '-96574.0618780',
                        close_ts: '1655078127',
                        open_amount: '-111253.8300270',
                        open_ts: '1654990463',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '19.973115',
                sortino_ratio: '76.196812',
                tvl_cap: '2000000',
            },
        ],
    },
    {
        all_time_apy: '0.020200',
        amount: '86.591438',
        last3d_apy: '0.000000',
        name: 'groUSDT.e_vault_v1_7',
        reserves: {
            amount: '86.591438',
            last3d_apy: '0.0000000',
            name: 'groUSDT.e_vault_v1_7',
            share: '0.0030490',
        },
        share: '0.0030490',
        stablecoin: 'USDT.e',
        strategies: [
            {
                address: '0x370e6e9cd875db40293f9dba86361ca7ff399b63',
                all_time_apy: '0.000000',
                amount: '0',
                last3d_apy: '0.000000',
                name: 'ah_USDT.e_strat_v1_7',
                open_position: {},
                past_5_closed_positions: [
                    {
                        apy: '-0.122818',
                        close_amount: '721690.0089450',
                        close_ts: '1654087403',
                        open_amount: '722907.6192100',
                        open_ts: '1653655344',
                    },
                    {
                        apy: '-0.298534',
                        close_amount: '707010.5303870',
                        close_ts: '1654519826',
                        open_amount: '709962.3531820',
                        open_ts: '1654087763',
                    },
                    {
                        apy: '-0.965480',
                        close_amount: '717217.1554260',
                        close_ts: '1654589369',
                        open_amount: '718746.1186160',
                        open_ts: '1654519885',
                    },
                    {
                        apy: '-0.129310',
                        close_amount: '699142.0352480',
                        close_ts: '1654989508',
                        open_amount: '700308.9319810',
                        open_ts: '1654590144',
                    },
                    {
                        apy: '9.076680',
                        close_amount: '-110588.4007890',
                        close_ts: '1655077405',
                        open_amount: '-128269.8754120',
                        open_ts: '1654990824',
                    },
                ],
                romad_ratio: '0.000000',
                share: '0.0000000',
                sharpe_ratio: '14.756008',
                sortino_ratio: '56.347069',
                tvl_cap: '2000000',
            },
        ],
    },
];
