/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import BigNumber from 'bignumber.js';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { claimInvestorPosition } from '../../../../lib/web3/investorPositions';
import { claimTeamVesting } from '../../../../lib/web3/teamVesting';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { updateGROValuesThunk } from '../../../rewards/store/thunks/updateGROValuesThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectedTeamPositionId, selectSelectedTeamPosition } from '../teamVesting.selectors';

export const executeTeamClaimTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());

        const transaction = selectCurrentSessionTransaction(tid)(getState());

        const mmTransaction = transaction.queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        const position = selectSelectedTeamPosition(getState());

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const selectedPosition = selectedTeamPositionId(getState());

        let method: NonPayableTransactionObject<any>;

        if (position?.investor) {
            method = claimInvestorPosition(new BigNumber(mmTransaction.amount || 0));
        } else {
            method = claimTeamVesting(
                new BigNumber(mmTransaction.amount || 0),
                selectedPosition || 0,
            );
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
