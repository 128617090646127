/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { depositVault, withdrawVault } from '../../../../lib/web3/vaultAdaptor';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectLabsMaxLoss, selectLabsToken, selectWithdrawMax } from '../labs.selectors';
import { updateLabsValuesTHunk } from './updateLabsValuesThunk';

export const executeLabsTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());
        const { direction, queue } = selectCurrentSessionTransaction(tid)(getState());
        const mmTransaction = queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        let method: NonPayableTransactionObject<any>;

        const name = selectLabsToken(getState());

        if (ExchangeDirection.investAvax === direction) {
            method = depositVault({
                amount: mmTransaction.amount,
                name,
            });
        } else {
            const maxLoss = selectLabsMaxLoss(getState());
            const withdrawMax = selectWithdrawMax(getState());
            method = withdrawVault({ amount: mmTransaction.amount, maxLoss, name, withdrawMax });
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateLabsValuesTHunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
