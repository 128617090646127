import { QueryClient } from 'react-query';

// See: https://react-query.tanstack.com/guides/important-defaults
// and: https://react-query.tanstack.com/reference/QueryClient
// and: defaultOptions.queries are the same as: https://react-query.tanstack.com/reference/useQuery
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 180000, // ensure queries do not refetch within 3 mins
        },
    },
});
