/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable no-warning-comments */
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { setExchangeStatus } from '../../../exchange/store/exchange.reducers';
import { getUserStatsMc } from '../../services/stats.service';
import { loadUserStatsMc, setUserStatsError, setUserStatsMc } from '../stats.reducer';

export const loadUserStatsMcThunk: AppActionThunk<Promise<void>> =
    (account: string) => async (dispatch) => {
        try {
            dispatch(setUserStatsError(false));
        } catch (e) {
            // TODO: fallback
            dispatch(setUserStatsError(true));
        }
    };
