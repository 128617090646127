/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { strategyContract } from '../abis';

type Address = {
    address: string;
};

export async function getEstimatedTotalAssets({ address }: Address) {
    const assets = await strategyContract(address).methods.estimatedTotalAssets().call();
    return assets;
}
