/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable complexity */
import { v4 as uuid } from 'uuid';
import { TokenObject } from '../../../../constants';
import { ExchangeDirection, Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { generateLabsTransactionThunk } from '../../../labs/store/thunks/generateLabsTransactionThunk';
import { generateClaimTransactionThunk } from '../../../pools/store/thunks/generateClaimTransactionThunk';
import { generateMigratePoolsTransactionThunk } from '../../../pools/store/thunks/generateMigratePoolsTransactionThunk';
import { generatePoolTransactionThunk } from '../../../pools/store/thunks/generatePoolTransactionThunk';
import { generateClaimUstTransactionThunk } from '../../../rewards/store/thunks/generateClaimUstTransactionThunk';
import { generateExitTransactionThunk } from '../../../rewards/store/thunks/generateExitTransactionThunk';
import { generateLockMoreGroThunk } from '../../../rewards/store/thunks/generateLockMoreGroThunk';
import { generateTeamClaimTransactionThunk } from '../../../teamVesting/store/thunks/generateTeamClaimTransactionThunk';
import { addTransaction } from '../../../transaction/store/transactions.reducer';
import { TransactionStatus } from '../../../transaction/store/transactions.store';
import { generateRedeemAlloyTransactionThunk } from '../../../unwind/store/thunks/generateRedeemAlloyTransactionThunk';
import { generateRedeemTransactionThunk } from '../../../unwind/store/thunks/generateRedeemTransactionThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { checkIfWalletIsSanctioned } from '../../../wallet/wallet.helpers';
import { setExchangeStatus } from '../exchange.reducers';
import { selectSlippage } from '../exchange.selectors';
import { generateInvestTransactionThunk } from './generateInvestTransactionThunk';
import { generateWithdrawTransactionThunk } from './generateWithdrawTransactionThunk';

/*
Initializes a new transaction
*/
export const createNewTransactionThunk: AppActionThunk =
    (tid = uuid()) =>
    async (dispatch, getState) => {
        try {
            const { exchange } = getState();
            const growthToken = exchange.token;
            const { direction } = exchange;
            const sources: TokenObject = exchange.tokens.from;
            const targets: TokenObject = exchange.tokens.to;
            const slippage = selectSlippage(getState());
            const wallet = selectWalletAccount(getState());

            // Keep only non-zero tokens -> some operations might
            // recalculated amounts more precisely later
            const s = Object.entries(sources)
                .filter(([_, amount]) => amount?.isGreaterThan(0))
                .map(([token, amount]) => [token, amount]);

            const t = Object.entries(targets)
                .filter(([_, amount]) => amount?.isGreaterThan(0))
                .map(([token, amount]) => [token, amount]);

            const transaction = {
                direction,
                error: null,
                from: Object.fromEntries(s) as TokenObject,
                id: tid,
                queue: [],
                slippage,
                status: TransactionStatus.loading,
                timestamp: Date.now(),
                to: Object.fromEntries(t) as TokenObject,
                token: growthToken,
            };

            dispatch(addTransaction(transaction));

            const isSanctioned = await checkIfWalletIsSanctioned(wallet, dispatch);

            if (isSanctioned) return;

            switch (direction) {
                case ExchangeDirection.invest:
                    dispatch(generateInvestTransactionThunk(tid));
                    break;
                case ExchangeDirection.investAvax:
                case ExchangeDirection.withdrawAvax:
                    dispatch(generateLabsTransactionThunk(tid));
                    break;
                case ExchangeDirection.withdraw:
                    dispatch(generateWithdrawTransactionThunk(tid));
                    break;
                case ExchangeDirection.stake:
                case ExchangeDirection.unstake:
                    dispatch(generatePoolTransactionThunk(tid));
                    break;
                case ExchangeDirection.teamClaim:
                    dispatch(generateTeamClaimTransactionThunk(tid));
                    break;
                case ExchangeDirection.claimReward:
                case ExchangeDirection.claimBonus:
                case ExchangeDirection.claimAirdrop:
                case ExchangeDirection.claimAllowance:
                    dispatch(generateClaimTransactionThunk(tid));
                    break;
                case ExchangeDirection.exit:
                    dispatch(generateExitTransactionThunk(tid));
                    break;
                case ExchangeDirection.claimUst:
                    dispatch(generateClaimUstTransactionThunk(tid));
                    break;
                case ExchangeDirection.lockMoreGro:
                    dispatch(generateLockMoreGroThunk(tid));
                    break;
                case ExchangeDirection.migratePool:
                    dispatch(generateMigratePoolsTransactionThunk(tid));
                    break;
                case ExchangeDirection.redeemGro:
                case ExchangeDirection.depositGro:
                case ExchangeDirection.withdrawGro:
                    dispatch(generateRedeemTransactionThunk(tid));
                    break;
                case ExchangeDirection.redeemDura:
                case ExchangeDirection.depositUsdc:
                case ExchangeDirection.withdrawUsdc:
                    dispatch(generateRedeemAlloyTransactionThunk(tid));
                    break;
                default:
                    break;
            }
        } catch (error: any) {
            setExchangeStatus({ error: error.message, status: Status.error });
        }
    };
