import BigNumber from 'bignumber.js';
import { TokenObject } from '../../../constants';
import { Status, StatusState } from '../../app/app.types';

export type WalletState = {
    account?: string;
    claimableRewards: TokenObject;
    country: string;
    hardwareWalletLoading: boolean;
    isModalOpened: boolean;
    loadingProvider: boolean;
    networkId: number;
    notification: string;
    openBlockModal: boolean;
    providerName: string;
    showNotification: boolean;
    showVolatility: boolean;
    // stacked amounts
    staked: TokenObject;
    // wallet amounts
    tokens: TokenObject;
    tokensInUsd: TokenObject;
    totalGro: BigNumber;
    walletBlocked: boolean;
} & StatusState;

export const initialWalletState: WalletState = {
    account: undefined,
    claimableRewards: {},
    country: '',
    error: undefined,
    hardwareWalletLoading: false,
    isModalOpened: false,
    loadingProvider: true,
    networkId: 0,
    notification: '',
    openBlockModal: false,
    providerName: '',
    showNotification: false,
    showVolatility: false,
    staked: {},

    // tokens with balance converted to usd in user's account/wallet
    status: Status.idle,

    // account address
    tokens: {},

    // tokens in user's account/wallet
    tokensInUsd: {},

    totalGro: new BigNumber(0),
    walletBlocked: false,
};
