import DashboardIcon from '@mui/icons-material/Dashboard';
import type { TpBreadcrumbRoutes } from '../../components';
/**
 * Keep routes as a separate export (i.e. not from index),
 * so that we keep the lazy loading of the module
 */

const base = '/';
export const routes: TpBreadcrumbRoutes = {
    base: {
        breadcrumb: 'Dashboard',
        Icon: DashboardIcon,
        path: base,
    },
};
