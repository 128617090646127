/* eslint-disable func-style */
/* eslint-disable no-nested-ternary */

import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import { PoolToken, TOKENS } from '../../../../constants';
import { lpTokenStakerHandler } from '../../../../lib/contract-info';
import { checkIsApproved } from '../../../../lib/web3/approve';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { createApproval } from '../../../exchange/store/exchange.helpers';
import { selectExchangeState } from '../../../exchange/store/exchange.selectors';
import {
    addWalletTransaction,
    setTransactionStatus,
} from '../../../transaction/store/transactions.reducer';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';

export const generatePoolTransactionThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const wallet = selectWalletAccount(getState());
        const exchange = selectExchangeState(getState());

        const token = exchange.token as PoolToken;
        const amount = removeTokenDecimals(token, exchange.tokens.from[token]) || new BigNumber(0);

        // 1. Check Approvals
        const isApproved = await checkIsApproved(
            token,
            wallet,
            amount,
            lpTokenStakerHandler.address,
        );

        if (exchange.direction === ExchangeDirection.stake) {
            createApproval(dispatch, isApproved, exchange.direction, amount, tid, token);
        }

        const mmTid = uuid();

        const grwthToken =
            token === TOKENS.GVT_SINGLE_SIDED
                ? TOKENS.GVT
                : token === TOKENS.PWRD_SINGLE_SIDED
                ? TOKENS.PWRD
                : token === TOKENS.GRO_SINGLE_SIDED
                ? TOKENS.GRO
                : token;

        dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            addWalletTransaction({
                direction: exchange.direction,
                meta: {
                    from: {
                        [exchange.direction === ExchangeDirection.stake ? grwthToken : token]:
                            amount,
                    },
                    to: {
                        [exchange.direction === ExchangeDirection.stake ? token : grwthToken]:
                            amount,
                    },
                },
                mmTid,
                status: TransactionStatus.idle,
                tid,
                token,
                type: TransactionType.exchange,
            }),
        );

        // Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
