/**
 * Web3 helper functions
 */
import BigNumber from 'bignumber.js';
import { UniToken } from '../../constants';
import { uniTokenContract } from '../abis/uniswapContract';

/**
 *
 * @param token - token name for which to get the balance
 */

type Reserves = {
    _blockTimestampLast: string;
    _reserve0: string;
    _reserve1: string;
};

export async function fetchReserves(token: UniToken): Promise<Reserves> {
    try {
        const reserves = await uniTokenContract(token).methods.getReserves().call();
        return reserves;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('fetchReserves.error', token);
        return {
            _blockTimestampLast: '',
            _reserve0: '',
            _reserve1: '',
        };
    }
}

export async function fetchTotalSupply(token: UniToken): Promise<BigNumber> {
    try {
        const totalSupply = await uniTokenContract(token).methods.totalSupply().call();
        return new BigNumber(totalSupply || 0);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('fetchTotalSupply.error', token);
        return new BigNumber(0);
    }
}
