import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { PpWC } from '../../utilTypes';

export function ToolTipText({
    children,
    ...rest
}: PpWC<TypographyProps<'h2'>>): React.ReactElement {
    return (
        <Typography variant="body1" {...rest}>
            {children}
        </Typography>
    );
}
