import React, { lazy, Suspense, useEffect } from 'react';
import { css } from '@emotion/react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { AppContainer, ErrorBoundary, Loading, Switch404 } from './components';
import { BlocedkWalletDisclaimer } from './components/disclaimers/BlockedWalletDisclaimer';
import { useAppSelector, useScrollTop } from './modules/app/hooks';
import { routes as routesDashboard } from './modules/dashboard/dashboard.routes';
import { ExchangeModal } from './modules/exchange/components/ExchangeModal';
import { routes as routesGovernance } from './modules/governance/governance.routes';
import { routes as routesLab } from './modules/labs/labs.routes';
import { Labs } from './modules/labs/screens/Labs';
import { routes as routesPools } from './modules/pools/pools.routes';
import { selectShouldBlock } from './modules/pools/store/pools.selectors';
import { BlockModal } from './modules/rewards/components/Modals/BlockModal';
import { ClaimModal } from './modules/rewards/components/Modals/ClaimModal';
import { LockMoreGROModal } from './modules/rewards/components/Modals/LockMoreGROModal';
import { routes as routesGro } from './modules/rewards/rewards.routes';
import { useInitGroStatsMc } from './modules/stats/hooks/useInitGroStatsMc';
import { ClaimTeamVestingModal } from './modules/teamVesting/components/ClaimTeamVestingModal';
import { TeamVesting } from './modules/teamVesting/screens/TeamVesting';
import { routes as routesTeamVesting } from './modules/teamVesting/teamVesting.routes';
import { ReactComponent as GroIcon } from './modules/unwind/assets/gro.svg';
import { Disconnect } from './modules/unwind/components/Disconnect';
import { Alloy } from './modules/unwind/screens/Alloy';
import { Protocol } from './modules/unwind/screens/Protocol';
import { Redeem } from './modules/unwind/screens/Redeem';
import { ConnectModal } from './modules/wallet/components/ConnectModal';
import { useWeb3 } from './modules/wallet/hooks/useWeb3';
import {
    selectOpenBlockModal,
    selectWalletBlocked,
} from './modules/wallet/store/wallet.selectors';
import { routes as routesWallet } from './modules/wallet/wallet.routes';
import { AppGlobalStyles } from './theme/AppGlobalStyles';

const Landing = lazy(() => import('./modules/unwind'));

const allRoutes = {
    routesDashboard,
    routesGovernance,
    routesGro,
    routesLab,
    routesPools,
    routesTeamVesting,
};

export type TpAllRoutes = typeof allRoutes;

export function App(): React.ReactElement {
    const style = {
        icon: css`
            position: absolute;
            cursor: pointer;
            top: 38px;
            left: 32px;
        `,
    };
    useWeb3();
    useScrollTop();
    useInitGroStatsMc();

    const shouldBlock = useAppSelector(selectShouldBlock);

    const history = useHistory();
    const location = useLocation();
    const walletBlocked = useAppSelector(selectWalletBlocked);
    const openBlockModal = useAppSelector(selectOpenBlockModal);

    useEffect(() => {
        if (shouldBlock) {
            history.push(routesWallet.base.path);
        }
    }, [shouldBlock, history, location]);

    return (
        <AppContainer>
            <AppGlobalStyles />
            <GroIcon css={style.icon} onClick={(): void => history.push('/')} />
            <Disconnect />
            {/* {isLgSize && <Drawer routes={allRoutes} />}
            {!isLgSize && <DrawerSmallScreen routes={allRoutes} />} */}
            <ErrorBoundary>
                <Suspense fallback={<Loading fullScreen />}>
                    <Switch404>
                        <Route path="/redeem">
                            <Redeem />
                        </Route>
                        <Route path="/redeem-dura">
                            <Alloy />
                        </Route>
                        <Route path="/protocol">
                            <Protocol />
                        </Route>
                        <Route path={routesTeamVesting.base.path}>
                            <TeamVesting />
                        </Route>
                        <Route path={routesLab.base.path}>
                            <Labs />
                        </Route>

                        {/* Dashboard goes last as least specific path */}
                        <Route path={routesDashboard.base.path}>
                            <Landing />
                        </Route>
                    </Switch404>
                </Suspense>
            </ErrorBoundary>
            <ClaimModal />
            <ClaimTeamVestingModal />
            <LockMoreGROModal />
            <ExchangeModal />
            <BlockModal />
            {walletBlocked && !openBlockModal && <BlocedkWalletDisclaimer />}
            <ConnectModal />
        </AppContainer>
    );
}
