/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import { GroModalHeader } from '../../../../components/GroModal';
import { etherscanTxUrl, NO_SIGNATURE, Token } from '../../../../constants';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectSignature } from '../../../exchange/store/exchange.selectors';
import { executeTransactionThunk } from '../../../exchange/store/thunks/executeTransactionThunk';
import { setLabsTransaction } from '../../../labs/store/labs.reducer';
import { selectIsAVAXNetwork } from '../../../wallet/store/wallet.selectors';
import {
    selectCurrentSessionTransaction,
    selectLastTransactionQueueStatus,
    selectUserApprovedAllQueuedTransactions,
} from '../../store/transactions.selectors';
import {
    TransactionStatus,
    TransactionType,
    WalletTransaction,
} from '../../store/transactions.store';
import { getModalTitle, hasTransactionQueueError } from '../../transaction.helpers';
import { SentTransaction } from '../SentTransaction/SentTransaction';
import { SignDeposit } from '../SentTransaction/SignDeposit';
import { StepAnimation } from './StepAnimation';

export type ConfirmationModalProps = {
    onBack: () => void;
    onClose: () => void;
    tid: string;
};

export function ConfirmationSteps({
    onBack,
    onClose,
    tid,
}: ConfirmationModalProps): React.ReactElement {
    const transaction = useAppSelector(selectCurrentSessionTransaction(tid));
    const { token } = transaction;
    const { direction } = transaction;
    const status = useAppSelector(selectLastTransactionQueueStatus);
    const isAvax = useAppSelector(selectIsAVAXNetwork);
    const dispatch = useAppDispatch();
    const signature = useAppSelector(selectSignature);
    const [signatureError, setSignatureError] = useState(false);

    const userApprovedAllTransactions = useAppSelector(
        selectUserApprovedAllQueuedTransactions(tid),
    );

    // eslint-disable-next-line func-style
    const handleClose = (): void => {
        setTimeout(() => {
            onClose();
            if (
                transaction.direction === ExchangeDirection.claimAllowance &&
                userApprovedAllTransactions
            ) {
                dispatch(setLabsTransaction(ExchangeDirection.investAvax));
            }
        }, 300);
    };

    /**
     * Figure out the state of the transaction queue and execute the next step
     */
    const handleNextTransaction = useCallback(() => {
        // 1. Find the first executable transaction
        const nextWalletTransaction = transaction.queue.find(
            (e: WalletTransaction) =>
                e.status === TransactionStatus.idle || e.status === TransactionStatus.error,
        );

        // 2. If is an approval transaction, continue
        if (nextWalletTransaction) {
            if (
                nextWalletTransaction.type === TransactionType.approve &&
                nextWalletTransaction.direction !== ExchangeDirection.stake
            ) {
                // 3. If a exchange transaction is already underway, do nothing
                //    But there is any approve transaction pending, continue
                const isPending = transaction.queue.find(
                    (e: WalletTransaction) =>
                        e.status === TransactionStatus.pendingApproval ||
                        // only wait for any pending approval to 0 (means reset)
                        // when reseting the approval to 0 we have to wait for this Tx or next one will fail
                        e.status === TransactionStatus.pendingMmApproval ||
                        (e.type === TransactionType.approve &&
                            e.amount?.isZero() &&
                            e.status === TransactionStatus.pendingConfirmation),
                );

                if (isPending) {
                    return;
                }

                dispatch(executeTransactionThunk(tid, nextWalletTransaction.id));
                return;
            }
        }

        // 3. If a exchange transaction is already underway, do nothing
        //    But there is any approve transaction pending, continue
        const isPending = transaction.queue.find(
            (e: WalletTransaction) =>
                e.status === TransactionStatus.pendingApproval ||
                e.status === TransactionStatus.pendingMmApproval ||
                e.status === TransactionStatus.timeout ||
                e.status === TransactionStatus.pendingConfirmation,
        );

        if (isPending) {
            return;
        }

        if (nextWalletTransaction) {
            dispatch(executeTransactionThunk(tid, nextWalletTransaction.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction.queue]);

    const shouldTriggerTxns = useMemo(() => {
        const canInves =
            NO_SIGNATURE.includes(Object.keys(transaction.from)[0] as Token) || signature;
        return transaction.direction !== ExchangeDirection.invest || canInves;
    }, [signature, transaction]);

    /**
     * Compute where the transaction is the last (exchange) transaction
     */
    useEffect(() => {
        if (
            transaction.status === TransactionStatus.ready &&
            !hasTransactionQueueError(transaction) &&
            shouldTriggerTxns
        ) {
            // console.log(transaction)
            handleNextTransaction();
        }
    }, [transaction, handleNextTransaction, shouldTriggerTxns]);

    // Clicking close from the button will redirect to dashboard
    function handleCloseFromButton(): void {
        handleClose();
    }

    const theme = useTheme();
    const { isMobileDisplay } = useGetSizes(theme);

    const hasErrors = useMemo(
        () =>
            transaction.queue.find((elem) => elem.status === TransactionStatus.error) ||
            signatureError,
        [transaction, signatureError],
    );

    const allConfirmed = useMemo(
        () =>
            transaction.queue.filter((elem) => elem.status === TransactionStatus.confirmed)
                .length === transaction.queue.length,
        [transaction],
    );

    const txnsSent = useMemo(
        () =>
            transaction.queue.filter((elem) =>
                [
                    TransactionStatus.confirmed,
                    TransactionStatus.pendingApproval,
                    TransactionStatus.pendingConfirmation,
                ].includes(elem.status),
            ).length > 0,
        [transaction],
    );

    return (
        // <GroModal isOpen={isOpen} overflow={overflow} onClose={handleCloseFromBackground}>
        <React.Fragment>
            <GroModalHeader allowBack={!txnsSent} onBack={onBack} onClose={handleClose}>
                <Box
                    sx={{
                        padding: theme.spacing(0, isMobileDisplay ? 2.5 : 3, 3),
                        textAlign: 'left',
                    }}
                >
                    <Typography pb={3} variant="h1">
                        {getModalTitle(token, direction)}
                    </Typography>

                    <StepAnimation
                        allApproved={userApprovedAllTransactions}
                        allConfirmed={allConfirmed}
                        hasErrors={!!hasErrors}
                    />
                </Box>
            </GroModalHeader>

            {userApprovedAllTransactions && status === TransactionStatus.timeout && (
                <Box
                    sx={{
                        alignItems: 'stretch',
                        display: 'flex',
                        flexDirection: 'column',
                        p: theme.spacing(0, isMobileDisplay ? 2.5 : 3),
                    }}
                >
                    <Alert severity="warning">
                        Your transaction is still pending on chain but is taking awhile to process.
                        It’s likely that the transaction Gas fee is too low. You can wait until Gas
                        prices drop and your current transaction is processed or take steps in your
                        wallet to speed up your transaction
                    </Alert>
                </Box>
            )}
            <Box
                sx={{
                    alignItems: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    p: theme.spacing(0, isMobileDisplay ? 2.5 : 3),
                }}
            >
                {!NO_SIGNATURE.includes(Object.keys(transaction.from)[0] as Token) &&
                    ExchangeDirection.invest === transaction.direction && (
                        <SignDeposit error={signatureError} setError={setSignatureError} />
                    )}

                {transaction.queue.map((tx, idx, arr) => (
                    <SentTransaction
                        key={tx.id}
                        isLast={arr.length - 1 === idx}
                        link={etherscanTxUrl(tx.transactionHash || '', isAvax)}
                        transaction={tx}
                        onRetry={handleNextTransaction}
                    />
                ))}
            </Box>

            <Box pb={3} pt={2.5} px={isMobileDisplay ? 2.5 : 3}>
                <Button
                    fullWidth
                    color="secondary"
                    data-testid="confirm-modal-close"
                    disabled={!userApprovedAllTransactions}
                    size="large"
                    variant="contained"
                    onClick={(): void => {
                        handleCloseFromButton();
                    }}
                >
                    Done
                </Button>
            </Box>
        </React.Fragment>
        // </GroModal>
    );
}
