/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import { apolloClient } from './apolloClient';
import { App } from './App';
import { AlertsProvider, ErrorBoundary } from './components';
import { queryClient } from './modules/app/services';
import { store } from './modules/app/store';
import { reportWebVitals } from './reportWebVitals';
import { themeGro } from './theme';

WebFont.load({
    google: {
        families: ['Roboto:100,300,400,500,700,900'],
    },
});

// For testing purposes
window.store = store;

const rootComponent = (
    <Provider store={store}>
        <CookiesProvider>
            <HashRouter>
                <ThemeProvider theme={themeGro}>
                    <ErrorBoundary>
                        <QueryClientProvider client={queryClient}>
                            <AlertsProvider>
                                <ApolloProvider client={apolloClient}>
                                    <App />
                                </ApolloProvider>
                            </AlertsProvider>
                        </QueryClientProvider>
                    </ErrorBoundary>
                </ThemeProvider>
            </HashRouter>
        </CookiesProvider>
    </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(rootComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
