import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { GroIcon, TextOrSkeleton } from '../../../components';
import { useAppSelector } from '../../app/hooks';
import { ReactComponent as WalletIcon } from '../../rewards/assets/wallet.svg';
import { CardHeader } from '../../rewards/components/CardHeader';
import { GroPriceCalculation } from '../../rewards/components/GroPriceCalculation';
import { selectTeamVestingLoading } from '../store/teamVesting.selectors';

export function TotalClaimed({
    formattedLockedValue,
    lattestLockedValue,
}: {
    formattedLockedValue: string;
    lattestLockedValue: BigNumber;
}): React.ReactElement {
    const theme = useTheme();
    const isLoading = useAppSelector(selectTeamVestingLoading);
    const noVestedGRO = lattestLockedValue.isEqualTo(0);

    const styles = {
        iconCard: css`
            width: 24px;
            margin-right: ${theme.spacing(1)};
            & path {
                fill: ${!noVestedGRO ? theme.palette.common.white : theme.palette.grey[200]};
            }
        `,
    };
    return (
        <Box position="relative">
            <CardHeader
                hideArrows
                hideFrontArrows
                isLong
                icon={
                    <WalletIcon
                        css={css`
                            color: ${theme.palette.grey[200]};
                            height: 15.2px;
                            width: 16px;
                        `}
                    />
                }
            >
                <Box pl={4}>
                    <Box alignItems="center" display="flex">
                        <Box alignItems="center" display="flex">
                            <Typography color="text.secondary" mr={1} variant="h3">
                                3. Total claimed GRO
                            </Typography>
                        </Box>
                    </Box>
                    <Box alignItems="center" display="flex" mt={1}>
                        <GroIcon css={styles.iconCard} />

                        <Typography
                            color={
                                noVestedGRO ? theme.palette.grey[200] : theme.palette.common.white
                            }
                            fontSize={30}
                            variant="h2"
                        >
                            <TextOrSkeleton loading={isLoading}>
                                {formattedLockedValue}
                            </TextOrSkeleton>
                        </Typography>
                    </Box>

                    <GroPriceCalculation amount={lattestLockedValue} isLoading={isLoading} />
                </Box>
            </CardHeader>
        </Box>
    );
}
