/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
    AVALANCHE_NETWORK,
    ETHEREUM_NETWORK,
    EthereumNetworkCodes,
    LOCAL_FORK,
    LOCAL_FORK_AVAX,
} from '../../constants';

export function isCorrectNetwork(chainId: number): boolean {
    if (LOCAL_FORK) return EthereumNetworkCodes[ETHEREUM_NETWORK] === chainId;
    return [EthereumNetworkCodes.mainnet, EthereumNetworkCodes.avax].includes(chainId);
}

export function isAVAXNetwork(chainId: number): boolean {
    return chainId === EthereumNetworkCodes[AVALANCHE_NETWORK] || LOCAL_FORK_AVAX;
}
