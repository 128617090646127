import { useEffect } from 'react';
import { useLocation } from 'react-router';

export function useScrollTop(): void {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname, location.search, location.hash]);
}
