import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import { VaultAvaxNames } from '../../../constants';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { useAppSelector } from '../../app/hooks';
import { selectDeposited } from '../store/labs.selectors';
import { LabsCoinIcon } from './LabsCoinIcon';
import { LabsDetails } from './LabsDetails';
import { LabsLogos } from './LabsLogos';
import { LabsPosition } from './LabsPosition';
import { LabsStrategy } from './LabsStrategy';
import { PersonalLabDetails } from './PersonalLabDetails';

export function Strategy({
    deprecated,
    name,
}: {
    deprecated?: boolean;
    name: VaultAvaxNames;
}): React.ReactElement {
    const gradient = deprecated
        ? ' linear-gradient(180deg, rgba(25, 64, 172, 0.1) 0%, rgba(17, 34, 90, 0.1) 100%), #01101C;'
        : `linear-gradient(
                    180deg,
                    rgba(25, 64, 172, 0.8) 0%,
                    rgba(17, 34, 90, 0.8) 100%
                ),
                linear-gradient(0deg, #01101c, #01101c)`;
    const border = deprecated
        ? 'linear-gradient(180deg, rgba(83, 196, 254, 0.3) 0%, rgba(25, 64, 172, 0.3) 100%)'
        : `linear-gradient(
                180deg,
                rgba(83, 196, 254, 0.8) 0%,
                rgba(25, 64, 172, 0.8) 100%
            )`;
    const theme = useTheme();
    const { isBelowMdSize } = useGetSizes(theme);

    const deposited = useAppSelector(selectDeposited(name));

    const styles = {
        details: css`
            position: relative;
            border-radius: 24px;
            background: ${gradient};
            margin: 1px;
        `,
        detailsWrap: css`
            background: ${border};
            border-radius: 24px;
            min-width: 310px;
            @media (max-width: 678px) {
                min-width: auto;
                width: 100%;
            }
        `,
        disclaimer: css`
            background: ${deposited.isLessThan(1)
                ? theme.palette.info.light
                : theme.palette.error.light};
            border-radius: 4px;
            position: absolute;
            top: -20px;
            left: 70px;
        `,
        hide: css`
            @media (max-width: 678px) {
                display: none;
            }
        `,
        hideForBigScreens: css`
            @media (min-width: 678px) {
                display: none;
            }
        `,
        personalDetails: css`
            background: ${theme.palette.common.black};
            border-radius: 24px;
        `,
        strategyInfo: css`
            @media (max-width: 678px) {
                display: none;
            }
        `,
        wrapper: css`
            border: solid 1px ${theme.palette.grey[600]};
            border-radius: 24px;
            position: relative;
        `,
    };

    return deposited.isGreaterThan(0) ? (
        <Box
            css={styles.wrapper}
            display="flex"
            justifyContent={isBelowMdSize ? 'flex-start' : 'space-between'}
        >
            <LabsLogos />
            <Box css={styles.detailsWrap}>
                <Box css={styles.details}>
                    {deprecated && (
                        <Box css={styles.disclaimer} px={2} py={1}>
                            <Typography
                                color={theme.palette.common.black}
                                fontWeight="500"
                                variant="nav"
                            >
                                LAB INACTIVE
                            </Typography>
                        </Box>
                    )}
                    <LabsCoinIcon name={name} />
                    <Box pb={deprecated ? 9 : 4.5} pt={4} px={2}>
                        <LabsDetails name={name} />
                    </Box>
                    <Box css={styles.personalDetails} pb={3} pt={2} px={2}>
                        <PersonalLabDetails deprecated={deprecated} name={name} />
                        <Box css={styles.hideForBigScreens} width="100%">
                            <LabsPosition smallDevices name={name} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!isBelowMdSize ? (
                <React.Fragment>
                    <Box css={styles.strategyInfo}>
                        <LabsStrategy name={name} />
                    </Box>
                    <LabsPosition name={name} />
                </React.Fragment>
            ) : (
                <Box css={styles.hide} px={3} width="100%">
                    <Box width="100%">
                        <LabsPosition smallDevices name={name} />
                    </Box>
                </Box>
            )}
        </Box>
    ) : (
        <Box />
    );
}
