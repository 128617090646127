/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { burner } from '../contract-info';
import { Burner } from './types/burner';

let instance: Burner;

export function burnerContract(): Burner {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(burner.abi, burner.address) as any as Burner;
    return instance;
}
