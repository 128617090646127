/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import BigNumber from 'bignumber.js';
import { StableTokenMainnet, Token, TOKENS } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { getIndexFromToken } from '../../../../lib/contract-info';
import { curvePriceFromDollar } from '../../../../lib/web3/curvePool';
import {
    depositWithAllowedPermit,
    depositWithPermit,
    regularDeposit,
    withdrawWithMinAmount,
} from '../../../../lib/web3/gRouter';
import { grwthTokenPricePerShare } from '../../../../lib/web3/grwthToken';
import {
    routerOracleStableToUSD,
    routerOracleUsdTostable,
} from '../../../../lib/web3/routerOracle';
import { fetchBalanceOf } from '../../../../lib/web3/wallet';
import { addTokenDecimals, removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { isGrwthToken } from '../../../utils/token.helpers';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectExchangeState, selectSignature } from '../exchange.selectors';
import { initExchangeFlowsThunk } from './initExchangeFlowsThunk';

export const executeExchangeTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());
        const exchange = selectExchangeState(getState());
        const signature = selectSignature(getState());

        const { direction } = exchange;
        const transaction = selectCurrentSessionTransaction(tid)(getState());
        const mmTransaction = transaction.queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        const grwthToken = mmTransaction.token;

        if (!grwthToken || !isGrwthToken(grwthToken)) {
            dispatch(
                setWalletTransactionStatus({
                    error: 'Internal Error: invalid Grwth token',
                    mmTid,
                    status: TransactionStatus.error,
                    tid,
                }),
            );
            return;
        }

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const token = Object.keys(transaction.from)[0] as StableTokenMainnet;
        const toToken = Object.keys(transaction.to)[0] as StableTokenMainnet;
        const amount = Object.values(transaction.from)[0];
        const amountWithDecimals = removeTokenDecimals(token as Token, amount);
        const tranche = grwthToken === TOKENS.PWRD;

        const slippage = new BigNumber(10000).minus(transaction.slippage).dividedBy(10000);

        const pricePerShare = await grwthTokenPricePerShare(
            grwthToken === TOKENS.PWRD ? TOKENS.PWRD : TOKENS.GVT,
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let method: NonPayableTransactionObject<any>;
        if (direction === ExchangeDirection.invest) {
            const depositOracleAmount = await routerOracleStableToUSD(
                amountWithDecimals,
                getIndexFromToken(token),
            );
            const minAmount = depositOracleAmount.dividedBy(pricePerShare).multipliedBy(slippage);

            if (token === TOKENS.USDC) {
                method = depositWithPermit({
                    amount: amountWithDecimals,
                    deadline: signature?.deadline || 0,
                    minAmount,
                    r: signature?.r || '',
                    s: signature?.s || '',
                    tokenIndex: getIndexFromToken(token),
                    tranche,
                    v: signature?.v || 0,
                });
            } else if (token === TOKENS.DAI) {
                method = depositWithAllowedPermit({
                    amount: amountWithDecimals,
                    deadline: signature?.deadline || 0,
                    minAmount,
                    nonce: signature?.nonce || '',
                    r: signature?.r || '',
                    s: signature?.s || '',
                    tokenIndex: getIndexFromToken(token),
                    tranche,
                    v: signature?.v || 0,
                });
            } else {
                method = regularDeposit({
                    amount: amountWithDecimals,
                    minAmount,
                    tokenIndex: getIndexFromToken(token),
                    tranche,
                });
            }
        } else {
            const balance = await fetchBalanceOf(wallet, grwthToken);
            const finalAmount = amountWithDecimals.isGreaterThan(balance)
                ? balance
                : amountWithDecimals;
            const dollarAmount = pricePerShare.multipliedBy(
                addTokenDecimals(grwthToken, finalAmount),
            );
            const withdrawOracleAmount =
                toToken !== TOKENS.CRV
                    ? await routerOracleUsdTostable(dollarAmount, getIndexFromToken(toToken))
                    : await curvePriceFromDollar(dollarAmount);

            method = withdrawWithMinAmount({
                amount: finalAmount,
                minAmount: withdrawOracleAmount
                    .multipliedBy(slippage)
                    .shiftedBy([TOKENS.DAI, TOKENS.CRV].includes(toToken as Token) ? 0 : -12),
                tokenIndex: getIndexFromToken(toToken),
                tranche,
            });
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            setTimeout(() => {
                void dispatch(loadUserStatsMcThunk(wallet));
            }, 8000);
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
