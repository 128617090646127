/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Drawer } from '../../../components';
import { MaintenanceStatusAlert } from '../../../components/alerts/status';
import { LabsStatusAlert } from '../../../components/alerts/status/LabsStatusAlert';
import { MobileHeaderCard } from '../../../components/cards/MobileHeaderCard';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { useAppSelector } from '../../app/hooks';
import { LabsProtocolPosition } from '../../stats/components/LabsProtocolPosition/LabsProtocolPosition';
import { selectIsLoadingUserStatsMc } from '../../stats/store/stats.selectors';
import { useForceNetworkChange } from '../../unwind/hooks/useForceNetworkChange';
import { initWalletThunk } from '../../wallet/store/thunks/initWalletThunk';
import { selectWalletAccount } from '../../wallet/store/wallet.selectors';
import { DepositModal } from '../components/DepositModal';
import { GroAllowance } from '../components/GroAllowance';
import { GroAllowanceIncreased } from '../components/GroAllowanceIncreased';
import { LabsStrategyBanner } from '../components/LabsStrategyBanner';
import { Strategy } from '../components/Strategy';
import { WithdrawModal } from '../components/WithdrawModal/WithdrawModal';
import { setDeprecatedScreen } from '../store/labs.reducer';
import {
    selectDeprecatedLabs,
    selectLabsNotification,
    selectPersonalTotalLabsPosition,
    selectPersonalTotalNetReturns,
} from '../store/labs.selectors';
import { updateLabsValuesTHunk } from '../store/thunks/updateLabsValuesThunk';

export function Labs(): React.ReactElement {
    const dispatch = useDispatch();
    const wallet = useAppSelector(selectWalletAccount);
    const [value, setValue] = useState('deprecated');
    const notification = useAppSelector(selectLabsNotification);
    const deprecated = useAppSelector(selectDeprecatedLabs);
    const statsLoading = useAppSelector(selectIsLoadingUserStatsMc);
    const returns = useAppSelector(selectPersonalTotalNetReturns);
    const balance = useAppSelector(selectPersonalTotalLabsPosition);
    const theme = useTheme();
    const { isMobileDisplay, isXsSize } = useGetSizes(theme);

    useForceNetworkChange({ network: 'avax' });

    const styles = {
        notification: css`
            background: ${theme.palette.error.light};
            border-radius: 100px;
            width: 20px;
            height: 20px;
        `,
    };

    function handleChange(event: any, newValue: string): void {
        setValue(newValue);
        dispatch(setDeprecatedScreen(newValue === 'deprecated'));
    }

    useEffect(
        () => (): void => {
            dispatch(setDeprecatedScreen(false));
        },
        [dispatch],
    );

    useEffect(() => {
        if (wallet) {
            void dispatch(initWalletThunk(wallet));
            void dispatch(updateLabsValuesTHunk(wallet));
        }
    }, [wallet, dispatch]);

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            mt={10}
            px={isMobileDisplay ? 1.5 : 0}
        >
            <Box maxWidth="984px">
                <MaintenanceStatusAlert />
                <LabsStatusAlert />
                {isXsSize ? (
                    <MobileHeaderCard
                        dollarReturns
                        balance={balance}
                        headerInfo={[]}
                        isLoading={wallet ? statsLoading : false}
                        returns={returns}
                        sx={{ mb: 0.5, mt: 0 }}
                        title="Balance"
                    />
                ) : (
                    <LabsProtocolPosition />
                )}

                <TabContext value={value}>
                    <Box alignItems="center" display="flex">
                        <TabList aria-label="lab API tabs example" onChange={handleChange}>
                            {/* <Tab label="Active labs" value="current" /> */}
                            {deprecated.length > 0 && (
                                <Tab
                                    icon={
                                        notification > 0 ? (
                                            <Box
                                                alignItems="center"
                                                css={styles.notification}
                                                display="flex"
                                                justifyContent="center"
                                            >
                                                <Typography
                                                    color="black"
                                                    fontWeight="700"
                                                    variant="nav"
                                                >
                                                    {notification}
                                                </Typography>
                                            </Box>
                                        ) : undefined
                                    }
                                    iconPosition="end"
                                    label="Inactive labs"
                                    value="deprecated"
                                />
                            )}
                        </TabList>
                    </Box>
                    <Box
                        mb={isXsSize ? 5 : 7.5}
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    />
                    {/* <TabPanel sx={{ padding: 0 }} value="current">
                            {active.map((vault) => (
                                <Box key={vault.name} mb={8}>
                                    <Strategy name={vault.name as VaultAvaxNames} />
                                </Box>
                            ))}
                        </TabPanel> */}
                    <TabPanel sx={{ padding: 0 }} value="deprecated">
                        {deprecated.map((vault) => (
                            <Box key={vault.name} mb={8}>
                                <Strategy deprecated name={vault.name} />
                            </Box>
                        ))}
                    </TabPanel>
                </TabContext>
                <GroAllowanceIncreased />
                <GroAllowance />
                <WithdrawModal />
                <DepositModal />
            </Box>
        </Box>
    );
}
