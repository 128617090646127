import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TransactionCard } from '../../../components';
import { GroModal, GroModalHeader } from '../../../components/GroModal';
import { TOKENS } from '../../../constants';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { selectStableTokenBalance } from '../../wallet/store/wallet.selectors';
import { TypeRedeem } from '../screens/Alloy';
import {
    selectCanClaimAlloy,
    selectSharesAvailableAlloy,
    selectUserBalanceAlloy,
} from '../store/unwind.selectors';

type RedeemGroModalProps = {
    isOpen: boolean;
    onClose: () => void;
    typeRedeem: TypeRedeem;
};

export function RedeemAlloyModal({
    isOpen,
    onClose,
    typeRedeem,
}: RedeemGroModalProps): React.ReactElement {
    const styles = {
        link: css`
            color: white;
        `,
    };
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);
    const [hasAccepted, setHasAccepted] = useState(false);
    const canRedeem = useAppSelector(selectCanClaimAlloy);
    const sharesAvailable = useAppSelector(selectSharesAvailableAlloy);
    const gro = useAppSelector(selectStableTokenBalance(TOKENS.GRO));
    const balance = useAppSelector(selectUserBalanceAlloy);

    const dispatch = useDispatch();

    const [amount, setAmount] = useState(new BigNumber(0));
    const [startTxn, setStartTxn] = useState(false);

    function onCloseModal(): void {
        onClose();
        setTimeout(() => {
            setStartTxn(false);
            setHasAccepted(false);
        }, 1000);
    }

    function handleAmountChange(newAmount: BigNumber): void {
        setAmount(newAmount);
    }

    const inputBalance = useMemo(() => {
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return gro;
            case TypeRedeem.withdraw:
                return balance;
            case TypeRedeem.redeemGro:
                return sharesAvailable;
            default:
                return gro;
        }
    }, [typeRedeem, gro, balance, sharesAvailable]);

    const noFunds = useMemo(
        () => inputBalance.isEqualTo(0) || amount.isGreaterThan(inputBalance),
        [inputBalance, amount],
    );

    const exchangeDirection = useMemo(() => {
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return ExchangeDirection.depositUsdc;
            case TypeRedeem.withdraw:
                return ExchangeDirection.withdrawUsdc;
            case TypeRedeem.redeemGro:
                return ExchangeDirection.redeemDura;
            default:
                return ExchangeDirection.depositUsdc;
        }
    }, [typeRedeem]);

    useEffect(() => {
        if (!inputBalance.isEqualTo(0)) {
            setAmount(inputBalance);
        }
    }, [inputBalance, typeRedeem]);

    function handleSubmit(): void {
        dispatch(setExchangeDirection(exchangeDirection));
        dispatch(setGrwthToken(canRedeem ? TOKENS.DURA : TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [canRedeem ? TOKENS.DURA : TOKENS.GRO]: amount,
            }),
        );
        dispatch(
            addTokenRhs({
                [canRedeem ? TOKENS.DURA : TOKENS.GRO]: amount,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    const displayWarning = useMemo(
        () => !hasAccepted && typeRedeem === TypeRedeem.deposit,
        [hasAccepted, typeRedeem],
    );

    const text = useMemo(() => {
        if (displayWarning) return 'Important Conditions regarding AlloyX DURA tokens';
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return 'Deposit GRO';
            case TypeRedeem.withdraw:
                return 'Withdraw GRO';
            case TypeRedeem.redeemGro:
                return 'Claim DURA';
            default:
                return 'Deposit GRO';
        }
    }, [typeRedeem, displayWarning]);

    return (
        <GroModal
            isOpen={isOpen}
            overflow="visible"
            width={displayWarning ? '600px' : '400px'}
            onClose={onCloseModal}
        >
            {!startTxn ? (
                <Box pb={4} px={isXsSize ? 1.5 : 3}>
                    <GroModalHeader onClose={onCloseModal}>
                        <Box display="flex">
                            {displayWarning && <WarningAmberIcon sx={{ mr: 1.5 }} />}
                            <Typography variant="h1">{text}</Typography>
                        </Box>
                    </GroModalHeader>
                    {displayWarning ? (
                        <Box my={2.5}>
                            <Typography mb={1}>
                                AlloyX’ DURA tokens are separate from Gro DAO -{' '}
                                <a
                                    css={styles.link}
                                    href="https://alloyx.gitbook.io/alloyx-documents/introduction/intro"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    more about AlloyX can be read here.
                                </a>
                            </Typography>
                            <Typography mb={1}>
                                KYC Requirement: AlloyX requires users to complete a Know Your
                                Customer (KYC) process to redeem their DURA tokens.
                            </Typography>
                            <Typography mb={1}>
                                No US Citizens: AlloyX does not allow US citizens to redeem DURA.
                            </Typography>
                            <Typography mb={1}>
                                Redemption Date: DURA tokens cannot be redeemed immediately.
                                Interest payments are available occasionally on a
                                first-claim-first-serve basis. AlloyX estimates that the principal
                                will be fully unlocked throughout 2025.
                            </Typography>
                            <Typography mb={1}>
                                Other Risks: There are inherent risks associated with
                                undercollateralized loans backing the DURA tokens. Please ensure
                                you comprehend{' '}
                                <a
                                    css={styles.link}
                                    href="https://alloyx.gitbook.io/alloyx-documents/risks/smart-contract-security"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    these risks.
                                </a>
                            </Typography>
                            <Typography>Do you acknowledge and accept these terms?</Typography>
                            <Box display="flex" gap="12px" mt={4}>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    sx={{ height: '48px' }}
                                    variant="contained"
                                    onClick={onCloseModal}
                                >
                                    No, Cancel
                                </Button>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    sx={{ height: '48px' }}
                                    variant="contained"
                                    onClick={(): void => setHasAccepted(true)}
                                >
                                    Yes, I Understand
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box my={2.5}>
                                <TransactionCard
                                    hideDollar
                                    isEditable
                                    selected
                                    amount={amount}
                                    balance={inputBalance}
                                    balanceToDollars={inputBalance}
                                    showIcon={!canRedeem}
                                    text={canRedeem ? 'DURA' : 'GRO'}
                                    token={
                                        canRedeem
                                            ? TOKENS.GRO_SINGLE_SIDED
                                            : TOKENS.GRO_SINGLE_SIDED
                                    }
                                    onChange={handleAmountChange}
                                />
                            </Box>
                            <Button
                                fullWidth
                                color="secondary"
                                disabled={noFunds}
                                sx={{ height: '48px' }}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {noFunds ? 'Insufficient balance' : text}
                            </Button>
                        </Box>
                    )}
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen
                    onBack={(): void => setStartTxn(false)}
                    onClose={onCloseModal}
                />
            )}
        </GroModal>
    );
}
