/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable complexity */

import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { executeLabsTransactionThunk } from '../../../labs/store/thunks/executeLabsTransactionThunk';
import { executeClaimTransactionThunk } from '../../../pools/store/thunks/executeClaimTransactionThunk';
import { executeMigratePoolsTransactionThunk } from '../../../pools/store/thunks/executeMigratePoolsTransactionThunk';
import { executePoolTransactionThunk } from '../../../pools/store/thunks/executePoolTransactionThunk';
import { executeClaimUstTransactionThunk } from '../../../rewards/store/thunks/executeClaimUstTransactionThunk';
import { executeExitTransactionThunk } from '../../../rewards/store/thunks/executeExitTransactionThunk';
import { executeLockMoreGROThunk } from '../../../rewards/store/thunks/executeLockMoreGroThunk';
import { executeTeamClaimTransactionThunk } from '../../../teamVesting/store/thunks/executeTeamClaimTransactionThunk';
import { selectTransactionsState } from '../../../transaction/store/transactions.selectors';
import { TransactionType, WalletTransaction } from '../../../transaction/store/transactions.store';
import { executeRedeemAlloyTransactionThunk } from '../../../unwind/store/thunks/executeRedeemAlloyTransactionThunk';
import { executeRedeemTransactionThunk } from '../../../unwind/store/thunks/executeRedeemTransactionThunk';
import { executeApproveTransactionThunk } from './executeApproveTransactionThunk';
import { executeExchangeTransactionThunk } from './executeExchangeTransactionThunk';

export const executeTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const transactions = selectTransactionsState(getState());
        const mmTransaction = transactions.currentSession[tid].queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;
        if (mmTransaction.type === TransactionType.approve) {
            dispatch(executeApproveTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.depositGro ||
            mmTransaction.direction === ExchangeDirection.redeemGro ||
            mmTransaction.direction === ExchangeDirection.withdrawGro
        ) {
            dispatch(executeRedeemTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.depositUsdc ||
            mmTransaction.direction === ExchangeDirection.redeemDura ||
            mmTransaction.direction === ExchangeDirection.withdrawUsdc
        ) {
            dispatch(executeRedeemAlloyTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.investAvax ||
            mmTransaction.direction === ExchangeDirection.withdrawAvax
        ) {
            dispatch(executeLabsTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.stake ||
            mmTransaction.direction === ExchangeDirection.unstake
        ) {
            dispatch(executePoolTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (mmTransaction.direction === ExchangeDirection.exit) {
            dispatch(executeExitTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (mmTransaction.direction === ExchangeDirection.claimUst) {
            dispatch(executeClaimUstTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (mmTransaction.direction === ExchangeDirection.teamClaim) {
            dispatch(executeTeamClaimTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.claimReward ||
            mmTransaction.direction === ExchangeDirection.claimBonus ||
            mmTransaction.direction === ExchangeDirection.claimAirdrop ||
            mmTransaction.direction === ExchangeDirection.claimAllowance
        ) {
            dispatch(executeClaimTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (mmTransaction.direction === ExchangeDirection.migratePool) {
            dispatch(executeMigratePoolsTransactionThunk(tid, mmTransaction.id));
            return;
        }

        if (
            mmTransaction.direction === ExchangeDirection.extend ||
            mmTransaction.direction === ExchangeDirection.revest
        ) {
            dispatch(executeLockMoreGROThunk(tid, mmTransaction.id));
            return;
        }

        dispatch(executeExchangeTransactionThunk(tid, mmTid));
    };
