/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { loadGroStatsOnChainThunk } from '../store/thunks/loadGroStatsOnChainThunk';

export function useInitGroStatsMc(): void {
    const dispatch = useAppDispatch();



    useEffect(() => {

        void dispatch(loadGroStatsOnChainThunk());

    }, [dispatch]);
}
