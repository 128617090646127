/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import { PoolToken } from '../../../../constants';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { AppActionThunk } from '../../../app/store';
import { selectExchangeState } from '../../../exchange/store/exchange.selectors';
import {
    addWalletTransaction,
    setTransactionStatus,
} from '../../../transaction/store/transactions.reducer';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';

export const generateClaimTransactionThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const exchange = selectExchangeState(getState());
        const token = exchange.token as PoolToken;
        const amount = removeTokenDecimals(token, exchange.tokens.from[token]) || new BigNumber(0);

        const mmTid = uuid();

        dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            addWalletTransaction({
                direction: exchange.direction,
                meta: {
                    from: {
                        [token]: amount,
                    },
                    to: {
                        [token]: amount,
                    },
                },
                mmTid,
                status: TransactionStatus.idle,
                tid,
                token,
                type: TransactionType.exchange,
            }),
        );

        // Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
