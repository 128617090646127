import React from 'react';
import { Box, Typography } from '@mui/material';
import { SmallButton } from '../../../../components';

export function ErrorInfo({
    error,
    onRetry,
}: {
    error: string;
    onRetry: () => void;
}): React.ReactElement {
    return (
        <Box display="flex" flexDirection="column" mt={0.5}>
            <Typography color="error.light" mb={1.5} variant="body2Small">
                {error}
            </Typography>
            <SmallButton
                noPadding
                text="Try again"
                variant="contained"
                onClick={(): void => onRetry()}
            />
        </Box>
    );
}
