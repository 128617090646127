/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { redeemAlloyContract } from '../abis/redeemAlloyContract';
import { convertToBN } from './web3.helpers';

export function depositUSDC(amount: BigNumber) {
    return redeemAlloyContract().methods.deposit(convertToBN(amount));
}

export function withdrawUSDC(amount: BigNumber) {
    return redeemAlloyContract().methods.withdraw(convertToBN(amount));
}

export function redeemDura(amount: BigNumber) {
    return redeemAlloyContract().methods.claim(convertToBN(amount));
}

export async function getPricePerShareAlloy() {
    const price = await redeemAlloyContract().methods.getDURAPerGRO().call();
    return new BigNumber(price);
}

export async function getUserBalanceAlloy(wallet: string) {
    const balance = await redeemAlloyContract().methods.getUserBalance(wallet).call();
    return new BigNumber(balance);
}

export async function getTotalDepositedAlloy() {
    const balance = await redeemAlloyContract().methods.totalGRO().call();
    return new BigNumber(balance);
}

export async function getSharesAvailableAlloy(wallet: string) {
    const shares = await redeemAlloyContract().methods.getDuraAvailable(wallet).call();
    return new BigNumber(shares);
}

export async function getDeadlineAlloy() {
    const deadline = await redeemAlloyContract().methods.getDeadline().call();
    return deadline;
}
