/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
import { addTokenObjectDecimals } from '../../../../lib/web3/web3.helpers';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { setPoolsReady } from '../../../unwind/store/unwind.reducers';
import { fetchPoolsBalances } from '../../services/wallet.services';
import { setAccount, setStakedBalances } from '../wallet.reducers';

/**
 * Initializes staked balances for currently selected account.
 */
export const initPoolsWalletThunk: AppActionThunk<Promise<boolean>> =
    (account: string) => async (dispatch, getState) => {
        const groStats = selectGroStatsMc(getState());
        try {
            dispatch(setPoolsReady({ status: Status.loading }));
            dispatch(setAccount(account));

            const { claimableRewards, tokens } = await fetchPoolsBalances(
                account,
                groStats.mainnet?.pools,
            );
            dispatch(
                setStakedBalances({
                    claimableRewards: addTokenObjectDecimals(claimableRewards),
                    staked: addTokenObjectDecimals(tokens),
                }),
            );
            dispatch(setPoolsReady({ status: Status.ready }));
            return true;
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.warn('initPoolsWalletThunk.error', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            dispatch(setPoolsReady({ error: error.message, status: Status.error }));
            return false;
        }
    };
