/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../constants';
import { addTokenDecimals } from '../../../lib/web3/web3.helpers';
import { Status } from '../../app/app.types';
import { AirdropItem } from '../airdrop.types';
import { initialGROState } from './rewards.store';

type SetLockMore = {
    extend: BigNumber;
    revest: BigNumber;
};

const rewardsSlice = createSlice({
    initialState: initialGROState,
    name: 'gro',
    reducers: {
        resetAirdropToClaim: (state) => {
            state.airdrop = undefined;
        },
        resetRewards: (state) => {
            state = { ...initialGROState };
            return state;
        },
        setAirdropToClaim: (state, { payload }: PayloadAction<AirdropItem>) => {
            state.airdrop = payload;
        },
        setCanClaim: (state, { payload }: PayloadAction<boolean>) => {
            state.canClaim = payload;
        },
        setClaimDate: (
            state,
            { payload }: PayloadAction<{ claimDelay: number; userClaim: number }>,
        ) => {
            state.userClaim = payload.userClaim;
            state.claimDelay = payload.claimDelay;
        },
        setClaimToWallet: (state, { payload }: PayloadAction<boolean>) => {
            state.claimToWallet = payload;
        },
        setExitToWallet: (state, { payload }: PayloadAction<BigNumber>) => {
            state.exitToWallet = payload.toNumber();
        },
        setGROStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
        setHasClaimedUst: (state, { payload }: PayloadAction<boolean>) => {
            state.hasClaimedUst = payload;
        },
        setIsUstClaim: (state, { payload }: PayloadAction<boolean>) => {
            state.isUstClaim = payload;
        },
        setLockExpectation: (state, { payload }: PayloadAction<BigNumber>) => {
            state.lockExpectation = payload;
        },
        setLockMore: (state, { payload }: PayloadAction<SetLockMore>) => {
            state.lockMore = payload;
        },
        setMaxLockedPeriod: (state, { payload }: PayloadAction<BigNumber>) => {
            state.maxLockedPeriod = payload.toNumber();
        },
        setOpenClaim: (state, { payload }: PayloadAction<boolean>) => {
            state.openClaim = payload;
        },
        setOpenLockMoreGro: (state, { payload }: PayloadAction<boolean>) => {
            state.openLockMoreGro = payload;
        },
        setPercentage: (
            state,
            { payload }: PayloadAction<{ endDate: number; percentage: number; startDate: number }>,
        ) => {
            state.vestingPercentage = payload.percentage;
            state.endClaimDate = payload.endDate;
            state.startClaimDate = payload.startDate;
        },
        setRewardsPid: (state, { payload }: PayloadAction<string[]>) => {
            state.rewardsPid = payload;
        },
        setTotalLockedAmountGRO: (state, { payload }: PayloadAction<{ amount: BigNumber }>) => {
            state.totalLockedGRO = addTokenDecimals(TOKENS.GRO, payload.amount).toNumber();
        },
        setTotalVestingBonus: (state, { payload }: PayloadAction<{ amount: BigNumber }>) => {
            state.totalVestingBonus = addTokenDecimals(TOKENS.GRO, payload.amount).toNumber();
        },
        setUnvested: (state, { payload }: PayloadAction<{ amount: BigNumber }>) => {
            state.unvestedGRO = addTokenDecimals(TOKENS.GRO, payload.amount).toNumber();
        },
        setUstLocked: (state, { payload }: PayloadAction<BigNumber>) => {
            state.ustLocked = payload;
        },
        setUstVested: (state, { payload }: PayloadAction<BigNumber>) => {
            state.ustVested = payload;
        },
        setVested: (state, { payload }: PayloadAction<{ amount: BigNumber }>) => {
            state.vestedGRO = addTokenDecimals(TOKENS.GRO, payload.amount).toNumber();
        },
        setVestingBonus: (state, { payload }: PayloadAction<{ amount: BigNumber }>) => {
            state.vestingBonus = addTokenDecimals(TOKENS.GRO, payload.amount).toNumber();
        },
        setVestingEndDateUst: (state, { payload }: PayloadAction<number>) => {
            state.vestingEndDateUST = payload;
        },
    },
});

export const {
    resetAirdropToClaim,
    resetRewards,
    setAirdropToClaim,
    setCanClaim,
    setClaimDate,
    setClaimToWallet,
    setExitToWallet,
    setGROStatus,
    setHasClaimedUst,
    setIsUstClaim,
    setLockExpectation,
    setLockMore,
    setMaxLockedPeriod,
    setOpenClaim,
    setOpenLockMoreGro,
    setPercentage,
    setRewardsPid,
    setTotalLockedAmountGRO,
    setTotalVestingBonus,
    setUnvested,
    setUstLocked,
    setUstVested,
    setVested,
    setVestingBonus,
    setVestingEndDateUst,
} = rewardsSlice.actions;

export const rewardsReducer = rewardsSlice.reducer;
