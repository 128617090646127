/* eslint-disable react/jsx-handler-names */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { TransactionCard } from '../../../../components';
import {
    MINIMUM_INVESTING_VISIBLE_AMOUNT,
    StableTokenMainnet,
    Token,
    TOKENS,
} from '../../../../constants';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { isGrwthToken } from '../../../utils/token.helpers';
import {
    selectWalletAccount,
    selectWalletLoading,
    selectWalletState,
    selectWalletTokens,
} from '../../../wallet/store/wallet.selectors';
import { TokensExchange } from '../../exchange.types';
import { useCalculateStableCoinPrice } from '../../hooks/useCalculateStableCoinPrice';
import { updateLhsInvest, updateLhsWithdraw } from '../../store/exchange.reducers';
import {
    selectExchangeConversions,
    selectExchangeDirection,
    selectExchangeState,
    selectExchangeToken,
    selectIsExchangeLoading,
} from '../../store/exchange.selectors';

export enum TokenCardSizes {
    large = 1,
    small = 0,
}

export enum TokenCardHeaderAlign {
    default = 'default',
    reverse = 'reverse',
}

export type TokenCardProps = {
    account?: string;
    amount?: BigNumber;
    balance?: BigNumber;
    checkBalance?: boolean;
    className?: string;
    disabled?: boolean;
    headerAlign?: TokenCardHeaderAlign;
    isActive?: boolean;
    isEditable?: boolean;
    isSelected?: boolean;
    loadingBody?: boolean;
    loadingHeader?: boolean;
    message?: string;
    onChange?: (val: BigNumber) => void;
    onClick?: () => void;
    size?: TokenCardSizes;
    token: Token;
    usdBalance?: BigNumber;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useWithdrawToStableCard(token: Token) {
    const exchange = useAppSelector(selectExchangeState);
    const exchangeToken = useAppSelector(selectExchangeToken);
    const grwthAmount = exchange.tokens.from[exchangeToken];
    const stableTokens = exchange.tokens.to;
    const conversions = useAppSelector(selectExchangeConversions);

    const balance = useAppSelector(selectWalletTokens)[token];

    const [amount, setAmount] = useState(new BigNumber(0));
    const [size, setSize] = useState(TokenCardSizes.small);
    const [isActive, setIsActive] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const inExchange = stableTokens[token] !== undefined;

    useEffect(() => {
        const numCoinsInExchange = Object.keys(stableTokens).length;

        if (inExchange) {
            setAmount(new BigNumber(stableTokens[token] || 0));
        }

        if (!inExchange && numCoinsInExchange === 0) {
            const baseAmount = new BigNumber(grwthAmount || 0)
                .multipliedBy(conversions[exchangeToken] || 0)
                .dividedBy(conversions[token] as BigNumber);

            setAmount(baseAmount);
        }

        if (!inExchange && numCoinsInExchange > 0) {
            setAmount(new BigNumber(0));
        }
    }, [inExchange, isActive, stableTokens, grwthAmount, token, conversions, exchangeToken]);

    /**
     * Change the state of the card depending on whether it's part of the exchange or not.
     */
    useEffect(() => {
        if (inExchange && !isActive) {
            setIsActive(true);
            setSize(TokenCardSizes.large);
        }

        if (!inExchange && isActive) {
            setIsActive(false);
            setIsEditable(false);
            setSize(TokenCardSizes.small);
        }
    }, [inExchange, isActive]);

    // eslint-disable-next-line func-style
    const handleChange = (): void => {};

    return {
        amount,
        balance,
        conversion: conversions[token],
        handleChange,
        isActive,
        isEditable,
        size,
    };
}

export function TokenCardWrapper({
    bottomCard,
    className,
    onSelectToken,
    removeToken,
    token,
    tokensNotAdded,
}: {
    bottomCard?: boolean;
    className?: string;
    onSelectToken: (val: TokensExchange) => void;
    removeToken?: (token: Token) => void;
    token: Token;
    tokensNotAdded: TokensExchange[];
}): React.ReactElement {
    const dispatch = useAppDispatch();
    const account = useAppSelector(selectWalletAccount);
    const usdBalance = useAppSelector(selectWalletState).tokensInUsd[token];
    const balance = useAppSelector(selectWalletTokens)[token] || new BigNumber(0);
    const isWalletLoading = useAppSelector(selectWalletLoading);
    const isExchangeLoading = useAppSelector(selectIsExchangeLoading);
    const exchangeDirection = useAppSelector(selectExchangeDirection);
    const exchange = useAppSelector(selectExchangeState);

    const tokenCardProps: TokenCardProps = {
        account,
        amount: new BigNumber(0),
        balance: balance.isGreaterThanOrEqualTo(MINIMUM_INVESTING_VISIBLE_AMOUNT)
            ? balance
            : new BigNumber(0),
        checkBalance: false,
        className,
        disabled: false,
        headerAlign: TokenCardHeaderAlign.default,
        isActive: false,
        isEditable: false,
        isSelected: false,
        loadingBody: isWalletLoading,
        loadingHeader: isExchangeLoading,
        size: TokenCardSizes.large,
        token,
        usdBalance: balance.isGreaterThanOrEqualTo(MINIMUM_INVESTING_VISIBLE_AMOUNT)
            ? usdBalance
            : new BigNumber(0),
    };

    const withdrawStableCardProps = useWithdrawToStableCard(token);

    if (isGrwthToken(token)) {
        if (exchangeDirection === ExchangeDirection.invest) {
            tokenCardProps.amount = exchange.tokens.to[token] || tokenCardProps.amount;
            tokenCardProps.headerAlign = TokenCardHeaderAlign.reverse;
            tokenCardProps.disabled = true;
        } else {
            tokenCardProps.isActive = true;
            tokenCardProps.checkBalance = true;
            tokenCardProps.isEditable = true;
            tokenCardProps.amount = exchange.tokens.from[token] || tokenCardProps.amount;
            // eslint-disable-next-line react-hooks/rules-of-hooks
        }
    } else if (exchangeDirection === ExchangeDirection.invest) {
        tokenCardProps.message = balance.isLessThan(MINIMUM_INVESTING_VISIBLE_AMOUNT)
            ? 'No balance'
            : undefined;
        tokenCardProps.disabled = !!tokenCardProps.message;
        tokenCardProps.size = tokenCardProps.disabled ? TokenCardSizes.small : tokenCardProps.size;
        tokenCardProps.amount = exchange.tokens.from[token] || tokenCardProps.amount;
        tokenCardProps.isEditable = true;
        tokenCardProps.checkBalance = true;
        tokenCardProps.isActive = !tokenCardProps.amount?.isZero();
    } else {
        tokenCardProps.amount = withdrawStableCardProps.amount;
        tokenCardProps.isSelected = !!exchange.tokens.to[token];
        tokenCardProps.size = withdrawStableCardProps.size;
        tokenCardProps.isEditable = withdrawStableCardProps.isEditable;
        tokenCardProps.headerAlign = TokenCardHeaderAlign.reverse;
        tokenCardProps.onChange = withdrawStableCardProps.handleChange;
    }

    const { dollars, tokens } = useCalculateStableCoinPrice(
        tokenCardProps.token as StableTokenMainnet,
        tokenCardProps.amount,
    );

    const ballanceToDollars = useMemo(() => {
        if (tokenCardProps.token === TOKENS.GVT) return tokenCardProps.amount || new BigNumber(0);
        if (tokenCardProps.token === TOKENS.PWRD)
            return new BigNumber(tokenCardProps.amount || 0).multipliedBy(
                withdrawStableCardProps.conversion || new BigNumber(1),
            );
        return dollars;
    }, [dollars, tokenCardProps.amount, tokenCardProps.token, withdrawStableCardProps.conversion]);

    const handleChangeWithdrawStable = useCallback(
        (value: BigNumber): void => {
            dispatch(updateLhsInvest({ price: dollars, token, value }));
        },
        [dispatch, token, dollars],
    );

    const handleChangeWithdrawGrowth = useCallback(
        (value: BigNumber): void => {
            dispatch(
                updateLhsWithdraw({
                    token,
                    toToken: tokenCardProps.token,
                    toTokenAmount: tokens,
                    value,
                }),
            );
        },
        [dispatch, token, tokens, tokenCardProps.token],
    );

    if (isGrwthToken(token)) {
        tokenCardProps.onChange = handleChangeWithdrawGrowth;
    } else if (exchangeDirection === ExchangeDirection.invest) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tokenCardProps.onChange = handleChangeWithdrawStable;
    }

    return (
        <TransactionCard
            selected
            amount={tokenCardProps.amount || new BigNumber(0)}
            balance={tokenCardProps.balance || new BigNumber(0)}
            balanceToDollars={ballanceToDollars}
            bottomCard={bottomCard}
            isEditable={tokenCardProps.isEditable}
            removeToken={removeToken}
            token={tokenCardProps.token}
            tokensNotAdded={tokensNotAdded}
            onChange={tokenCardProps.onChange}
            onSelectToken={onSelectToken}
        />
    );
}
