import { Dispatch } from 'react';
import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import { Token } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { addWalletTransaction } from '../../transaction/store/transactions.reducer';
import { TransactionType } from '../../transaction/store/transactions.store';

export function createApproval(
    dispatch: Dispatch<any>,
    isApproved: BigNumber,
    direction: ExchangeDirection,
    amount: BigNumber,
    tid: string,
    token: Token,
): void {
    if (isApproved.isLessThanOrEqualTo(0)) {
        dispatch(
            addWalletTransaction({
                amount,
                direction,
                meta: {
                    token,
                },
                mmTid: uuid(),
                tid,
                token,
                type: TransactionType.approve,
            }),
        );
    }
}
