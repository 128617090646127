import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { GroIcon, TransactionCard } from '../../../components';
import { GroModal, GroModalHeader } from '../../../components/GroModal';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { selectGroStatsMc } from '../../stats/store/stats.selectors';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { setOpenTeamClaimModal } from '../store/teamVesting.reducer';
import { selectBalancePosition, selectOpenTeamClaimModal } from '../store/teamVesting.selectors';

export function ClaimTeamVestingModal(): React.ReactElement {
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState(new BigNumber(0));
    const [startTxn, setStartTxn] = useState(false);
    const balance = useAppSelector(selectBalancePosition);

    const groStats = useAppSelector(selectGroStatsMc);

    function onClose(): void {
        dispatch(setOpenTeamClaimModal(false));
    }
    const theme = useTheme();
    const isOpen = useAppSelector(selectOpenTeamClaimModal);
    const styles = {
        activeAction: css`
            background-color: ${theme.palette.common.white};
            color: ${theme.palette.common.black};
            border-radius: 8px;
            cursor: pointer;
        `,
        buttonSubmit: css`
            padding-top: 14px;
            padding-bottom: 14px;
        `,
        buttonSwitch: css`
            background: ${theme.palette.grey[700]};
            border-radius: 8px;
        `,
        inputWrapper: css`
            background-color: ${theme.palette.common.black};
            border: 1px solid ${theme.palette.grey[700]};
            border-radius: 5px;
            & .MuiBox-root {
                flex: 1;
            }
        `,
    };

    function handleAmountChange(newAmount: BigNumber): void {
        setAmount(newAmount);
    }

    function handleSubmit(): void {
        dispatch(setExchangeDirection(ExchangeDirection.teamClaim));
        dispatch(setGrwthToken(TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [TOKENS.GRO]: amount,
            }),
        );
        dispatch(
            addTokenRhs({
                [TOKENS.GRO]: amount,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    useEffect(() => {
        // reset amount at when modal opens
        if (isOpen) {
            setAmount(new BigNumber(balance));
        }
        // eslint-disable-next-line
    }, [isOpen, dispatch, balance]);

    return (
        <GroModal isOpen={isOpen} overflow="visible" width="400px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={5} px={3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography mb={1} variant="h1">
                            Claim Rewards
                        </Typography>
                    </GroModalHeader>
                    <Box alignItems="center" display="flex" mb={2.5} mt={1}>
                        <GroIcon fill={theme.palette.text.secondary} height="1rem" width="1rem" />
                        <Typography color="text.secondary" ml={0.5} variant="body2">
                            Contributor Rewards
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <TransactionCard
                            isEditable
                            selected
                            showIcon
                            amount={amount}
                            balance={balance}
                            balanceToDollars={amount.multipliedBy(
                                groStats.mainnet?.token_price_usd.gro || 1,
                            )}
                            token={TOKENS.GRO_SINGLE_SIDED}
                            onChange={handleAmountChange}
                        />
                    </Box>
                    <Button
                        fullWidth
                        color="secondary"
                        css={styles.buttonSubmit}
                        disabled={amount.isGreaterThan(balance) || amount.isEqualTo(0)}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Claim
                    </Button>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModal>
    );
}
