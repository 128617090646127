import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TransactionCard } from '../../../../components';
import { TxnModal } from '../../../../components/dialogs/TxnModal';
import { GroModalHeader } from '../../../../components/GroModal';
import { ExchangeArrowIcon } from '../../../../components/icons';
import { TOKENS } from '../../../../constants';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../../exchange/store/thunks/createNewTransactionThunk';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { selectWalletTokens } from '../../../wallet/store/wallet.selectors';
import { setLockExpectation, setLockMore, setOpenLockMoreGro } from '../../store/rewards.reducer';
import {
    selectLockedTxnAmount,
    selectLockMoreGroOpen,
    selectVestedGRO,
} from '../../store/rewards.selectors';
import { NewDateInfo } from '../NewDateInfo';
import { VestingPctInfo } from '../VestingPctInfo';

export function LockMoreGRO(): React.ReactElement {
    const dispatch = useDispatch();
    const [startTxn, setStartTxn] = useState(false);
    const isOpen = useAppSelector(selectLockMoreGroOpen);
    const [amountUnlocked, setAmountUnlocked] = useState(new BigNumber(0));
    const [amountWallet, setAmountWallet] = useState(new BigNumber(0));
    const balance = useAppSelector(selectWalletTokens)[TOKENS.GRO];
    const vestedGRO = useAppSelector(selectVestedGRO);
    const groStats = useAppSelector(selectGroStatsMc);
    const [selectedUnlockedGro, setSelectedUnlockedGro] = useState(
        !balance?.isGreaterThanOrEqualTo(0.01) && vestedGRO.isGreaterThan(0),
    );

    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);

    function onClose(): void {
        dispatch(setOpenLockMoreGro(false));
    }

    function editAmount(): void {
        const newAmount = !selectedUnlockedGro ? vestedGRO : new BigNumber(0);
        setSelectedUnlockedGro(!selectedUnlockedGro);
        setAmountUnlocked(newAmount);
    }

    const balanceExceeded = useMemo(
        () => (amountWallet && balance && amountWallet.isGreaterThan(balance)) || !balance,
        [balance, amountWallet],
    );

    const totalAmount = useMemo(
        () => amountWallet.plus(amountUnlocked),
        [amountWallet, amountUnlocked],
    );

    const noUnlockedAmount = useMemo(
        () => totalAmount.isZero() || amountUnlocked.isGreaterThan(0),
        [totalAmount, amountUnlocked],
    );

    // this calculates how much of the amount of the transaction would be locked
    const lockedAmount = useAppSelector(selectLockedTxnAmount(totalAmount));

    const lockedPct = useMemo(
        () => (noUnlockedAmount ? totalAmount : lockedAmount),
        [noUnlockedAmount, totalAmount, lockedAmount],
    );

    const disableButton = useMemo(
        () => balanceExceeded || totalAmount.isLessThanOrEqualTo(0),
        [totalAmount, balanceExceeded],
    );

    const styles = {
        arrowIcon: css`
            transform: rotate(90deg);
            margin-left: 40%;
        `,
        bigButton: css`
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
        `,
        button: css`
            z-index: 9;
            padding: 1px 0px;
            border-radius: 8px;
            max-width: 141px;
            min-width: 141px;
            height: 24px;
            background: rgba(255, 255, 255, 0.8);
            &:hover {
                background: ${theme.palette.common.white};
            }
        `,
        icon: css`
            height: 14px;
            margin-right: 6px;
            margin-bottom: -1px;
            & path {
                fill: ${theme.palette.grey[100]};
            }
        `,
        submit: css`
            margin-top: 24px;
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        vestingWrapper: css`
            background: ${theme.palette.info.superDark};
            border-radius: 8px;
        `,
    };

    function onConfirm(): void {
        dispatch(
            setLockMore({
                extend: amountUnlocked,
                revest: amountWallet,
            }),
        );
        dispatch(setLockExpectation(lockedPct));
        dispatch(resetExchange());
        dispatch(setExchangeDirection(ExchangeDirection.lockMoreGro));
        dispatch(setGrwthToken(TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [TOKENS.GRO]: totalAmount,
            }),
        );
        dispatch(
            addTokenRhs({
                [TOKENS.GRO]: totalAmount,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    useEffect(() => {
        if (isOpen) {
            setStartTxn(false);
            if (balance?.isGreaterThanOrEqualTo(0.001)) {
                setAmountWallet(balance || new BigNumber(0));
                setAmountUnlocked(new BigNumber(0));
                setSelectedUnlockedGro(false);
            } else {
                setAmountWallet(new BigNumber(0));
                setAmountUnlocked(vestedGRO);
                setSelectedUnlockedGro(vestedGRO.isGreaterThan(0));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <TxnModal isOpen={isOpen} setStartTxn={setStartTxn} startTxn={startTxn} onClose={onClose}>
            <Box pb={4} px={isXsSize ? 1.5 : 3}>
                <GroModalHeader onClose={onClose}>
                    <Typography variant="h1">Lock GRO</Typography>
                </GroModalHeader>

                <Typography color="text.tertiary" mt={1} variant="body2">
                    Lock GRO into your vesting schedule to earn higher vesting bonus.
                </Typography>
                <Typography color="text.tertiary" mb={2} mt={2.5} variant="body2">
                    From
                </Typography>
                <Box mb={1}>
                    <TransactionCard
                        isEditable
                        selected
                        amount={amountWallet}
                        balance={balance || new BigNumber(0)}
                        balanceToDollars={amountWallet.multipliedBy(
                            new BigNumber(groStats.mainnet?.token_price_usd.gro),
                        )}
                        text="GRO"
                        token={TOKENS.GRO_SINGLE_SIDED}
                        onChange={setAmountWallet}
                    />
                </Box>
                <TransactionCard
                    amount={vestedGRO}
                    balance={vestedGRO}
                    balanceToDollars={vestedGRO.multipliedBy(
                        new BigNumber(groStats.mainnet?.token_price_usd.gro),
                    )}
                    selected={selectedUnlockedGro && vestedGRO.isGreaterThan(0)}
                    text="Unlocked GRO"
                    token={TOKENS.GRO_SINGLE_SIDED}
                    onClick={editAmount}
                />
                <Box alignItems="center" display="flex">
                    <Typography color="text.secondary" mb={2} mt={2.5} variant="body2">
                        To
                    </Typography>
                    <ExchangeArrowIcon css={styles.arrowIcon} />
                </Box>
                <Box css={styles.vestingWrapper} pb={2}>
                    <TransactionCard
                        bottomCard
                        amount={lockedPct}
                        balance={lockedPct}
                        balanceToDollars={lockedPct.multipliedBy(
                            new BigNumber(groStats.mainnet?.token_price_usd.gro),
                        )}
                        selected={!lockedPct.isZero()}
                        text="Locked GRO"
                        token={TOKENS.GRO_SINGLE_SIDED}
                    />
                    <VestingPctInfo amount={lockedPct} sx={{ ml: 2, my: 2 }} />
                    <NewDateInfo
                        amount={lockedPct}
                        extend={selectedUnlockedGro}
                        sx={{ mt: 2, mx: 2 }}
                    />
                </Box>
                <Button
                    fullWidth
                    color="secondary"
                    css={styles.submit}
                    disabled={disableButton}
                    variant="contained"
                    onClick={onConfirm}
                >
                    {!disableButton && 'Confirm transaction'}
                    {balanceExceeded &&
                        !totalAmount.isLessThanOrEqualTo(0) &&
                        'Insufficient balance'}
                    {totalAmount.isLessThanOrEqualTo(0) && 'Enter an amount'}
                </Button>
            </Box>
        </TxnModal>
    );
}

export const LockMoreGROModal = React.memo(LockMoreGRO);
