/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { MINIMUM_INVESTING_VISIBLE_AMOUNT, TOKENS } from '../../../../constants';
import { useAppSelector } from '../../../app/hooks';
import { getFromTokens } from '../../../exchange/helpers/exchange.helpers';
import { signTransaction } from '../../../exchange/helpers/signTransaction';
import { selectExchangeState } from '../../../exchange/store/exchange.selectors';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { TransactionStatus } from '../../store/transactions.store';
import { ErrorInfo } from './ErrorInfo';
import { TransactionStatusDisplay } from './TransactionStatusDisplay';

type TpSignDeposit = {
    error: boolean;
    setError: (error: boolean) => void;
};

export function SignDeposit({ error, setError }: TpSignDeposit): React.ReactElement {
    const theme = useTheme();
    const [hasSigned, setHasSigned] = useState(false);
    const [signing, setSigning] = useState(false);
    const dispatch = useDispatch();
    const exchange = useAppSelector(selectExchangeState);

    const status = useMemo(() => {
        if (error) return TransactionStatus.error;
        if (signing) return TransactionStatus.pendingApproval;
        if (hasSigned) return TransactionStatus.confirmed;
        return TransactionStatus.idle;
    }, [signing, hasSigned, error]);
    const wallet = useAppSelector(selectWalletAccount);

    const borderColor = useMemo(() => {
        switch (status) {
            case TransactionStatus.idle:
                return theme.palette.grey[300];
            case TransactionStatus.pendingApproval:
                return 'rgba(121, 165, 251, 1)';
            case TransactionStatus.confirmed:
                return theme.palette.success.light;
            case TransactionStatus.error:
                return theme.palette.error.light;
            default:
                return theme.palette.grey[300];
        }
    }, [status, theme]);

    const styles = {
        circle: css`
            border-radius: 50%;
            background: ${borderColor};
            height: 8px;
            width: 8px;
            position: absolute;
            left: -4.5px;
            bottom: -3px;
        `,
        wrapper: css`
            border-left: 2px solid ${borderColor};
            position: relative;
        `,
    };

    const transactionToken = useMemo(async () => {
        const fromTokens = await getFromTokens({
            conversions: exchange.conversions,
            tokens: exchange.tokens.from,
            tokensFrom: exchange.tokens.from,
            wallet,
        });
        const filteredFromTokens = fromTokens
            // Filter out tokens that are not visible in the UI
            // added === usdt because dai and usdc are approved through eip 712 permit
            .filter((item) => item[1].isGreaterThan(MINIMUM_INVESTING_VISIBLE_AMOUNT));
        return filteredFromTokens[0];
    }, []);

    function onFail(): void {
        setSigning(false);
        setError(true);
    }

    function onSigned(): void {
        setSigning(false);
        setError(false);
        setHasSigned(true);
    }

    async function getSignature(): Promise<void> {
        const [token, value] = await transactionToken;
        setSigning(true);
        setError(false);
        await signTransaction(
            wallet,
            dispatch,
            new BigNumber(value),
            token as TOKENS,
            onSigned,
            onFail,
        );
    }

    useEffect(() => {
        getSignature();
    }, []);

    return (
        <Box css={styles.wrapper} mb="2px" pl={2} py={2}>
            <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography color="text.primary" variant="h3">
                    Sign Approval
                </Typography>
                {status !== TransactionStatus.idle && <TransactionStatusDisplay status={status} />}
            </Box>
            <Box alignItems="flex-end" display="flex" justifyContent="space-between">
                <Box>
                    {status === TransactionStatus.error && (
                        <ErrorInfo error="User declined signature" onRetry={getSignature} />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
