/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { AVAX_NETWORK, ETH_NETWORK } from '../../../constants';
import { useAppSelector } from '../../app/hooks';
import { useChangeNetwork } from '../../wallet/hooks/useChangeNetwork';
import {
    selectIsAVAXNetwork,
    selectIsMainnet,
    selectNetworkId,
} from '../../wallet/store/wallet.selectors';

export function useForceNetworkChange({ network }: { network: 'mainnet' | 'avax' }): void {
    const isMainnet = useAppSelector(selectIsMainnet);
    const isAvax = useAppSelector(selectIsAVAXNetwork);
    const currentNetwork = useAppSelector(selectNetworkId);

    const { onChangeNetwork } = useChangeNetwork({ setIsLoading: () => {} });

    useEffect(() => {
        if (network === 'avax' && !isAvax) {
            void onChangeNetwork({ data: AVAX_NETWORK, icon: '', label: '' });
        }

        if (network === 'mainnet' && !isMainnet) {
            void onChangeNetwork({ data: ETH_NETWORK, icon: '', label: '' });
        }
    }, [currentNetwork, network]);
}
