/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable func-style */
import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { METAMASK_ERROR_REJECTED_SIGNATURE, Token, TOKENS } from '../../../constants';
import { RootState } from '../../app/store';
import { selectExchangeConversions } from '../../exchange/store/exchange.selectors';
import { UserTransactionType } from '../../stats/stats.types';
import { selectUserStatsMc } from '../../stats/store/stats.selectors';
import {
    formatClientTransactionsToHistoryTransactions,
    formatUserStatsTransactionsToHistoryTransactions,
    getTransactionComputedStatus,
} from '../transaction.helpers';
import { TransactionsState, TransactionStatus, TransactionType } from './transactions.store';

export const selectTransactionsState = (state: RootState): TransactionsState => state.transactions;

export const selectCurrentSessionTransaction = (tid: string) =>
    createSelector(selectTransactionsState, (state) => state.currentSession[tid]);

export const selectLastTransaction = createSelector(selectTransactionsState, (exchange) => {
    if (!Object.keys(exchange.currentSession).length) {
        return undefined;
    }
    return Object.values(exchange.currentSession).sort((ta, tb) => tb.timestamp - ta.timestamp)[0];
});

// Returns the last user transaction that is different than rejected by metamask signature
export const selectLastTransactionDifferentThanUserRejected = createSelector(
    selectTransactionsState,
    (exchange) => {
        if (!Object.keys(exchange.currentSession).length) {
            return undefined;
        }
        return Object.values(exchange.currentSession)
            .sort((ta, tb) => tb.timestamp - ta.timestamp)
            .filter((transaction) => {
                const isErrorMetamaskSignature = transaction.queue.some(
                    (mmt) =>
                        mmt.status === TransactionStatus.error &&
                        mmt.error === METAMASK_ERROR_REJECTED_SIGNATURE,
                );
                return !isErrorMetamaskSignature;
            })[0];
    },
);

export const selectLastTransactionQueueStatus = createSelector(
    selectLastTransactionDifferentThanUserRejected,
    getTransactionComputedStatus,
);

export const selectTransactionIdStatus = (tid: string) =>
    createSelector(selectCurrentSessionTransaction(tid), getTransactionComputedStatus);

export const selectActiveTransactionOngoingDifferentThan = (tid: string) =>
    createSelector(selectTransactionsState, (state) =>
        Object.values(state.currentSession).find(
            (tx) =>
                tx.id !== tid &&
                !!tx.queue.find(
                    (q) =>
                        (q.status === TransactionStatus.pendingConfirmation ||
                            q.status === TransactionStatus.timeout) &&
                        q.type !== TransactionType.approve,
                ),
        ),
    );

// Checks if there is a transaction with a state of error or pending approval
export const selectUserApprovedAllQueuedTransactions = (tid: string) =>
    createSelector(
        selectTransactionsState,
        (state) =>
            !Object.values(state.currentSession).find(
                (tx) =>
                    tx.id === tid &&
                    !!tx.queue.find(
                        (q) =>
                            (q.status === TransactionStatus.pendingApproval ||
                                q.status === TransactionStatus.pendingMmApproval ||
                                q.status === TransactionStatus.error ||
                                q.status === TransactionStatus.idle ||
                                q.status === TransactionStatus.loading) &&
                            q.type !== TransactionType.approve,
                    ),
            ),
    );

// Returns a tx that is pending on the blockchain or confirmed, for confirmation modal
export const selectCurrentActiveQueuedTransaction = (tid: string) =>
    createSelector(selectTransactionsState, (state) =>
        Object.values(state.currentSession).find(
            (tx) =>
                tx.id === tid &&
                !!tx.queue.find(
                    (q) =>
                        q.status === TransactionStatus.pendingConfirmation ||
                        q.status === TransactionStatus.timeout ||
                        q.status === TransactionStatus.confirmed,
                ),
        ),
    );

export const selectTransactionHistory = createSelector(
    [selectTransactionsState, selectUserStatsMc, selectExchangeConversions],
    (state, userStats, conversions) => {
        const clientSideHistory = formatClientTransactionsToHistoryTransactions(
            state.currentSession,
            conversions,
            new BigNumber(0),
        );
        const userStatsHistory = formatUserStatsTransactionsToHistoryTransactions(
            userStats
                ? userStats.ethereum.transaction
                : {
                      [UserTransactionType.deposits]: [],
                      [UserTransactionType.withdrawals]: [],
                      [UserTransactionType.transfersIn]: [],
                      [UserTransactionType.transfersOut]: [],
                      [UserTransactionType.approvals]: [],
                      [UserTransactionType.failures]: [],
                      [UserTransactionType.stakerDeposits]: [],
                      [UserTransactionType.stakerWithdrawals]: [],
                  },
        );
        const history = clientSideHistory
            .filter((item) => {
                const exists = userStatsHistory.find((i) => i.hash === item.hash);
                return !exists;
            })
            .concat(userStatsHistory)
            // Sort by date descending
            .sort((prev, next) => (prev.timestamp > next.timestamp ? -1 : 1));

        return history;
    },
);

export const selectTransactonHistoryByToken = (token?: Token) =>
    createSelector(selectTransactionHistory, (history) =>
        history.filter((tx) => (token ? tx.token === token : true)),
    );

export const selectLastGvtHistoryTransaction = createSelector(
    selectTransactionHistory,
    (history) => history.find((tx) => tx.token === TOKENS.GVT),
);

export const selectLastPWRDHistoryTransaction = createSelector(
    selectTransactionHistory,
    (history) => history.find((tx) => tx.token === TOKENS.PWRD),
);

export const selectLastHistoryTransaction = createSelector(
    selectTransactionHistory,
    (history) => history[0],
);

export const selectLastHistoryTransactionByToken = (token?: Token) => {
    if (token === TOKENS.GVT) {
        return selectLastGvtHistoryTransaction;
    }

    if (token === TOKENS.PWRD) {
        return selectLastPWRDHistoryTransaction;
    }

    return selectLastHistoryTransaction;
};
