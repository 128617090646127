/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { curve_meta_pwrd_3crv_4 } from '../contract-info';
import { Curve } from './types/curve';

let instance: Curve;

export function curveContract(): Curve {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;

    instance = new Contract(
        curve_meta_pwrd_3crv_4.abi,
        curve_meta_pwrd_3crv_4.address,
    ) as any as Curve;
    return instance;
}
