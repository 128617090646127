/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useEffect, useMemo, useState } from 'react';
import { Theme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { AirdropItem } from '../airdrop.types';
import { setOpenClaim, setRewardsPid } from '../store/rewards.reducer';
import {
    selectAirdropToClaim,
    selectClaimToWallet,
    selectOpenClaim,
    selectRewardsPid,
} from '../store/rewards.selectors';

type TpUseClaim = {
    onBack: () => void;
    onClaim: () => void;
    onClose: () => void;
    open: boolean;
    rewardToClaim: AirdropItem;
    rewardsPid: string[];
    selectedAmount: BigNumber;
    startTxn: boolean;
    vestingColor: string;
    vestingSelected: boolean;
    walletAmount: BigNumber;
    walletColor: string;
    walletColorWarning: string;
    walletSelected: boolean;
};

export function useClaimModal(theme: Theme): TpUseClaim {
    const dispatch = useDispatch();
    const open = useAppSelector(selectOpenClaim);
    const rewardToClaim = useAppSelector(selectAirdropToClaim);
    const [startTxn, setStartTxn] = useState(false);

    const claimToWallet = useAppSelector(selectClaimToWallet);

    const rewardsPid = useAppSelector(selectRewardsPid);

    const vestingColor = useMemo(
        () => (!claimToWallet ? theme.palette.common.white : theme.palette.grey[200]),
        [claimToWallet, theme],
    );
    const walletColor = useMemo(
        () => (claimToWallet ? theme.palette.common.white : theme.palette.grey[200]),
        [claimToWallet, theme],
    );

    const walletColorWarning = useMemo(
        () => (claimToWallet ? theme.palette.warning.light : theme.palette.grey[200]),
        [claimToWallet, theme],
    );

    const walletAmount = useMemo(() => rewardToClaim?.amount || new BigNumber(0), [rewardToClaim]);

    const selectedAmount = useMemo(
        () => (!claimToWallet ? rewardToClaim?.amount : walletAmount) || new BigNumber(0),
        [walletAmount, claimToWallet, rewardToClaim],
    );

    function onClose(): void {
        dispatch(setOpenClaim(false));
        dispatch(setRewardsPid([]));
    }

    function onBack(): void {
        setStartTxn(false);
    }

    function onClaim(): void {
        // dispatch(setOpenClaim(false));
        setStartTxn(true);
        dispatch(resetExchange());
        dispatch(
            setExchangeDirection(
                rewardToClaim?.vesting
                    ? ExchangeDirection.claimBonus
                    : rewardToClaim?.pool
                    ? ExchangeDirection.claimReward
                    : ExchangeDirection.claimAirdrop,
            ),
        );
        dispatch(setGrwthToken(rewardToClaim?.token || TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [rewardToClaim?.token || TOKENS.GRO]: rewardToClaim?.amount,
            }),
        );
        dispatch(
            addTokenRhs({
                [rewardToClaim?.token || TOKENS.GRO]: rewardToClaim?.amount,
            }),
        );
        dispatch(createNewTransactionThunk());
    }

    useEffect(() => {
        if (open) {
            setStartTxn(false);
        }
    }, [open, dispatch]);

    return {
        onBack,
        onClaim,
        onClose,
        open,
        rewardsPid,
        rewardToClaim,
        selectedAmount,
        startTxn,
        vestingColor,
        vestingSelected: !claimToWallet,
        walletAmount,
        walletColor,
        walletColorWarning,
        walletSelected: claimToWallet,
    };
}
