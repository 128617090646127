/* eslint-disable no-nested-ternary */

import React from 'react';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

type TpSmallButton = {
    disabled?: boolean;
    icon?: React.ReactElement;
    long?: boolean;
    noPadding?: boolean;
    onClick: () => void;
    text: string;
    tiny?: boolean;
    variant: 'outlined' | 'contained' | 'text';
};

export function SmallButton({
    disabled,
    icon,
    long,
    noPadding,
    onClick,
    text,
    tiny,
    variant,
}: TpSmallButton): React.ReactElement {
    const styles = {
        smallBtn: css`
            height: 24px;
            width: ${long ? '100px' : tiny ? '60px' : '80px'};
            ${noPadding ? 'padding: 0;' : undefined}
        `,
    };
    return (
        <Button
            color="secondary"
            css={styles.smallBtn}
            disabled={disabled}
            size="small"
            variant={variant}
            onClick={onClick}
        >
            {icon}
            {text}
        </Button>
    );
}
