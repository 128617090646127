import React from 'react';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TextOrSkeleton } from '../../../components';
import { useAppSelector } from '../../app/hooks';
import { selectGroStatsMc } from '../../stats/store/stats.selectors';
import { formatAsCurrency, formatNumber } from '../../utils/currency.helpers';
import { selectIsAVAXNetwork, selectIsCorrectNetwork } from '../../wallet/store/wallet.selectors';

export function GroPriceCalculation({
    amount,
    isLoading,
    showGROValue,
}: {
    amount: BigNumber;
    isLoading: boolean;
    showGROValue?: boolean;
}): React.ReactElement {
    const groStats = useAppSelector(selectGroStatsMc);
    const correctNetwork = useAppSelector(selectIsCorrectNetwork);
    const isAvax = useAppSelector(selectIsAVAXNetwork);

    return (
        <Typography color="text.tertiary" fontStyle="italic">
            {correctNetwork && !isAvax ? (
                <TextOrSkeleton loading={isLoading}>
                    ≈{' '}
                    {formatAsCurrency(
                        new BigNumber(groStats.mainnet?.token_price_usd.gro).multipliedBy(amount),
                    )}{' '}
                    <span>
                        {showGROValue &&
                            `(1GRO = $${formatNumber(
                                new BigNumber(groStats.mainnet?.token_price_usd.gro),
                            )})`}
                    </span>
                </TextOrSkeleton>
            ) : (
                ` ≈ $ -- (1GRO = $${formatNumber(
                    new BigNumber(groStats.mainnet?.token_price_usd.gro),
                )})`
            )}
        </Typography>
    );
}
