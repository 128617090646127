import React from 'react';
import { ConfirmationModal } from '../../modules/transaction/components/ConfirmationModal';
import { GroModalTxn } from '../GroModal';

type TpTxnModal = {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    setStartTxn: (_val: boolean) => void;
    startTxn: boolean;
};

export function TxnModal({
    children,
    isOpen,
    onClose,
    setStartTxn,
    startTxn,
}: TpTxnModal): React.ReactElement {
    return (
        <GroModalTxn isOpen={isOpen} overflowY="auto" onClose={onClose}>
            {!startTxn ? (
                children
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModalTxn>
    );
}
