import React from 'react';
import { css } from '@emotion/react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GroModal, GroModalHeader } from '../../../../components/GroModal';
import { DISCORD_LINK } from '../../../../constants';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { useAppSelector } from '../../../app/hooks';
import { setOpenBlockModal } from '../../../wallet/store/wallet.reducers';
import { selectOpenBlockModal, selectWalletAccount } from '../../../wallet/store/wallet.selectors';

export function BlockModal(): React.ReactElement {
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);

    const isOpen = useAppSelector(selectOpenBlockModal);
    const wallet = useAppSelector(selectWalletAccount);
    const dispatch = useDispatch();

    function onClose(): void {
        dispatch(setOpenBlockModal(false));
    }

    const styles = {
        break: css`
            word-break: break-word;
        `,
        submit: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
    };

    return (
        <GroModal isOpen={isOpen} width="432px" onClose={onClose}>
            <Box pb={4} px={isXsSize ? 1.5 : 5}>
                <GroModalHeader onClose={onClose}>
                    <Box alignItems="center" display="flex" mb={1.5}>
                        <ReportGmailerrorredIcon
                            sx={{ color: theme.palette.error.light, fontSize: '26px' }}
                        />
                        <Typography color={theme.palette.error.light} ml={1} variant="h1">
                            This address is blocked
                        </Typography>
                    </Box>
                </GroModalHeader>
                <Typography color="text.tertiary" css={styles.break} mt={3} variant="body2">
                    {wallet} is blocked because the address is sanctioned by the EU, US, or UN.
                    This web page uses{' '}
                    <a
                        css={css`
                            color: ${theme.palette.text.tertiary};
                        `}
                        href="https://go.chainalysis.com/chainalysis-oracle-docs.html"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                    >
                        Chainalysis
                    </a>{' '}
                    to comply with an up to date list of sanctioned addresses.
                </Typography>
                <Typography color="text.tertiary" mb={31} mt={3} variant="body2">
                    If you believe this is an error please contact the Gro DAO for support on{' '}
                    <a
                        css={css`
                            color: ${theme.palette.text.tertiary};
                        `}
                        href={DISCORD_LINK}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                    >
                        Discord
                    </a>
                    .
                </Typography>
                <Button
                    fullWidth
                    color="secondary"
                    css={styles.submit}
                    variant="contained"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Box>
        </GroModal>
    );
}
