import { Breakpoints } from '@mui/system';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const breakpoints: Partial<
    {
        step: number;
        unit: string;
    } & Breakpoints
> = {
    values: { xs: 460, sm: 600, md: 1000, lg: 1280, xl: 1920 },
};
