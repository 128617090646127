/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { lpTokenStakerHandlerDeprecated } from '../contract-info';
import { LpTokenStakerDeprecated } from './types/lp-token-staker-deprecated';

let instance: LpTokenStakerDeprecated;

export function lpTokenStakerDeprecatedContract(): LpTokenStakerDeprecated {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(
        lpTokenStakerHandlerDeprecated.abi,
        lpTokenStakerHandlerDeprecated.address,
    ) as any as LpTokenStakerDeprecated;
    return instance;
}
