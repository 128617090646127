/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { VaultAvaxNames } from '../../../constants';
import { ExchangeDirection, Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { TpAvalancheGroStatsMc, TpLabVault } from '../../stats/stats.types';
import {
    selectGroStatsMc,
    selectIsLoadingGroMcStats,
    selectIsLoadingUserStatsMc,
    selectUserStatsMc,
} from '../../stats/store/stats.selectors';
import { LabsState } from './labs.store';
import { labsDeprecated } from './thunks/labsBody';

function getNewVaultEntry(name: VaultAvaxNames, stats?: TpAvalancheGroStatsMc) {
    return stats?.labs_vault.find((elem) => elem.name === name);
}

export function dateNumToString(dateNum: number, includeYr = false): string {
    return format(new Date(dateNum * 1000), includeYr ? 'HH:mm dd MMM yyyy' : 'HH:mm dd MMM');
}

export function selectLabsState(state: RootState): LabsState {
    return state.labs;
}

export const selectIsLabsLoading = createSelector(
    selectIsLoadingGroMcStats,
    selectIsLoadingUserStatsMc,
    (loadingGroMc, loadingUserStatsMc) => loadingGroMc || loadingUserStatsMc,
);

const selectPersonalAvalancheStats = createSelector(
    selectUserStatsMc,
    (stats) => stats?.avalanche,
);

const selectGroAvalancheStats = createSelector(selectGroStatsMc, (stats) => stats?.avalanche);

export const selectLabsContractsLoading = createSelector(
    selectLabsState,
    (labs) => labs.status !== Status.ready && labs.status !== Status.error,
);

export const selectPersonalTotalLabsPosition = createSelector(
    selectPersonalAvalancheStats,
    (stats) => new BigNumber(stats?.current_balance.total || 0),
);

export const selectPersonalTotalNetReturns = createSelector(
    selectPersonalAvalancheStats,
    (stats) => new BigNumber(stats?.net_returns.total || 0),
);

export const selectDeprecatedScreen = createSelector(
    selectLabsState,
    (labs) => labs.deprecatedScreen,
);

export const selectPersonalSpecificBalance = (name: VaultAvaxNames) =>
    createSelector(
        selectPersonalAvalancheStats,
        (stats) => new BigNumber(stats?.current_balance[name] || 0),
    );

export const selectPersonalSpecificReturns = (name: VaultAvaxNames) =>
    createSelector(
        selectPersonalAvalancheStats,
        (stats) => new BigNumber(stats?.net_returns[name] || 0),
    );

export const selectAllTimeApy = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        return new BigNumber(strategy?.all_time_apy || 0);
    });

export const selectLast3dApy = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        return new BigNumber(strategy?.last3d_apy || 0);
    });

export const selectTVLSpecific = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => new BigNumber(stats?.tvl[name] || 0));

export const selectLabsVaultEntry = createSelector(selectGroAvalancheStats, (stats) =>
    stats?.labs_vault.find((x) => x.name === 'groDAI.e_vault'),
);

export const selectStatsReservesSpecific = (name: VaultAvaxNames) =>
    createSelector(selectLabsState, (labs) => {
        const valut = labs[name];
        return new BigNumber(valut.vaultBalance);
    });

export const selectStatsAvaxExposure = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        return new BigNumber(strategy?.avax_exposure || '');
    });

export const selectOpenPositionDetails = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        return strategy?.strategies[0].open_position;
    });

export const selectStrategyAddress = (token: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(token, stats);
        return strategy?.strategies[0].address || '';
    });

export const selectStrategyTVLCap = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        return new BigNumber(strategy?.strategies[0].tvl_cap || '');
    });

export const selectCurrentPosIsOpen = (name: VaultAvaxNames) =>
    createSelector(
        selectOpenPositionDetails(name),
        (openPositionDetails) => openPositionDetails?.active_position === 'true',
    );

export const selectCurrentPosOpenAmount = (name: VaultAvaxNames) =>
    createSelector(
        selectCurrentPosIsOpen(name),
        selectOpenPositionDetails(name),
        (currentPosIsOpen, openPositionDetails) =>
            currentPosIsOpen && 'open_amount' in openPositionDetails!
                ? new BigNumber(openPositionDetails.open_amount || 0)
                : new BigNumber(0),
    );

export const selectCurrentPosCurrentAmount = (name: VaultAvaxNames) =>
    createSelector(
        selectCurrentPosIsOpen(name),
        selectOpenPositionDetails(name),
        (currentPosIsOpen, openPositionDetails) =>
            currentPosIsOpen && 'current_amount' in openPositionDetails!
                ? new BigNumber(openPositionDetails.current_amount || 0)
                : new BigNumber(0),
    );

export const selectCurrentPosReturn = (name: VaultAvaxNames) =>
    createSelector(
        selectCurrentPosOpenAmount(name),
        selectCurrentPosCurrentAmount(name),
        (currentPosOpenAmount, currentPosCurrentAmount) =>
            currentPosCurrentAmount.minus(currentPosOpenAmount),
    );

export const selectCurrentPosTs = (name: VaultAvaxNames) =>
    createSelector(
        selectCurrentPosIsOpen(name),
        selectOpenPositionDetails(name),
        (currentPosIsOpen, openPositionDetails) =>
            currentPosIsOpen && 'open_ts' in openPositionDetails!
                ? dateNumToString(Number(openPositionDetails.open_ts), true)
                : '',
    );

export const selectCurrentPosAPY = (name: VaultAvaxNames) =>
    createSelector(
        selectCurrentPosIsOpen(name),
        selectOpenPositionDetails(name),
        (currentPosIsOpen, openPositionDetails) =>
            currentPosIsOpen && 'apy' in openPositionDetails!
                ? new BigNumber(openPositionDetails.apy!)
                : new BigNumber(0),
    );

export const selectPastPosDetails = (name: VaultAvaxNames) =>
    createSelector(selectGroAvalancheStats, selectDeprecatedScreen, (stats) => {
        const strategy = getNewVaultEntry(name, stats);
        const positions = strategy?.strategies[0].past_5_closed_positions || [];
        return [...positions].reverse();
    });

export const selectLabsTvl = createSelector(
    selectGroAvalancheStats,
    (stats) => new BigNumber(stats?.tvl.total || 0),
);

export const selectLabsTransactionDirection = createSelector(
    selectLabsState,
    (labs) => labs.direction,
);

export const selectOpenDepositModal = createSelector(
    selectLabsState,
    (labs) => labs.direction === ExchangeDirection.investAvax,
);

export const selectOpenAllowanceModal = createSelector(
    selectLabsState,
    (labs) => labs.openAllowance,
);

export const selectOpenIncreasedAllowanceModal = createSelector(
    selectLabsState,
    (labs) => labs.openIncreasedAllowance,
);

export const selectLabsToken = createSelector(
    selectLabsState,
    (labs) => labs.token || VaultAvaxNames['groDAI.e_vault_v1_5'],
);

function getGroGate(stats: any, token: VaultAvaxNames) {
    let formattedToken = VaultAvaxNames['groUSDT.e_vault'];
    if (token.includes('DAI')) formattedToken = VaultAvaxNames['groDAI.e_vault'];
    if (token.includes('USDC')) formattedToken = VaultAvaxNames['groUSDC.e_vault'];
    return stats?.gro_gate[formattedToken] || stats?.gro_gate[`${formattedToken}_v1_7`];
}

export const selectGroGate = createSelector(
    selectPersonalAvalancheStats,
    selectLabsToken,
    (stats, token) => getGroGate(stats, token),
);

export const selectLabsTokenValues = createSelector(
    selectLabsState,
    selectLabsToken,
    (labs, token) => labs[token],
);

export const selectClaimableAllowance = createSelector(
    selectGroGate,
    (groGate) => new BigNumber(groGate?.claimable_allowance || ''),
);

export const selectDepositedAvaxToken = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.deposited),
);

export const selectDepositedBalance = (token: VaultAvaxNames) =>
    createSelector(selectLabsState, (labs) => {
        const valut = labs[token];
        return new BigNumber(valut.deposited).multipliedBy(valut.pricePerShare);
    });

export const selectRemainingAllowance = createSelector(
    selectGroGate,
    (groGate) => new BigNumber(groGate?.remaining_allowance || ''),
);

export const selectRemainingAllowanceByName = (token: VaultAvaxNames) =>
    createSelector(selectPersonalAvalancheStats, (stats) => {
        const statsObj = getGroGate(stats, token);
        return new BigNumber(statsObj?.remaining_allowance || '');
    });

export const selectClaimedAllowance = createSelector(
    selectRemainingAllowance,
    selectClaimableAllowance,
    (remaining, claimable) => claimable.minus(remaining),
);

export const selectHasClaimed = createSelector(selectClaimedAllowance, (value) =>
    value.isGreaterThan(0),
);

export const selectIsAllowanceClaimable = (token: VaultAvaxNames) =>
    createSelector(selectPersonalAvalancheStats, (stats) => {
        const statsObj = getGroGate(stats, token);
        return statsObj?.claimable === true || statsObj?.claimable === 'true';
    });

export const selectGRORequired = createSelector(
    selectPersonalAvalancheStats,
    (stats) => new BigNumber(stats?.gro_gate.gro_gate_at_snapshot || 0),
);

export const selectGROAvailable = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum?.gro_balance_combined || 0),
);

export const selectSnapshotDate = createSelector(selectPersonalAvalancheStats, (stats) => {
    if (stats?.gro_gate.snapshot_ts === 'N/A') return '';
    return format(new Date(parseInt(stats?.gro_gate.snapshot_ts || '0', 10) * 1000), 'd MMM');
});

export const selectPriceAvaxToken = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.pricePerShare),
);

export const selectDeposited = (token: VaultAvaxNames) =>
    createSelector(selectLabsState, (labs) => new BigNumber(labs[token].deposited));

export const selectDebtAvaxToken = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.debt),
);

export const selectAssetsAvaxToken = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.assets),
);

export const selectCurrentBalanceUserStats = createSelector(
    selectPersonalAvalancheStats,
    selectLabsToken,
    (stats, token) => new BigNumber(stats?.current_balance[token] || 0),
);

export const selectCurrentAllowance = createSelector(
    selectCurrentBalanceUserStats,
    selectRemainingAllowance,
    (deposited, remaining) => deposited.plus(remaining),
);

export const selectMaxAllowanceHasBeenClaimed = (token: VaultAvaxNames) =>
    createSelector(
        selectPersonalAvalancheStats,
        selectRemainingAllowance,
        selectDepositedAvaxToken,
        (avax, remaining, deposited) => {
            const netReturns = avax?.net_returns;
            const statsObj = getGroGate(avax, token);
            const baseAllowance = new BigNumber(statsObj?.base_allowance || '');

            return deposited
                .plus(remaining)
                .minus(netReturns ? netReturns[token] || 0 : 0)
                .isGreaterThan(baseAllowance);
        },
    );

export const selectMerkleProof = createSelector(
    selectPersonalAvalancheStats,
    (stats) => stats?.gro_gate.proofs,
);

export const selectShouldOpenWithdrawal = createSelector(
    selectLabsState,
    (labs) => labs.direction === ExchangeDirection.withdrawAvax,
);

export const selectTokenName = createSelector(selectLabsToken, (token) => {
    switch (token) {
        case VaultAvaxNames['groDAI.e_vault']:
        case VaultAvaxNames['groDAI.e_vault_v1_5_1']:
        case VaultAvaxNames['groDAI.e_vault_v1_5']:
        case VaultAvaxNames['groDAI.e_vault_v1_6']:
        case VaultAvaxNames['groDAI.e_vault_v1_7']:
            return 'groDAI.e';
        case VaultAvaxNames['groUSDC.e_vault']:
        case VaultAvaxNames['groUSDC.e_vault_v1_5_1']:
        case VaultAvaxNames['groUSDC.e_vault_v1_5']:
        case VaultAvaxNames['groUSDC.e_vault_v1_6']:
        case VaultAvaxNames['groUSDC.e_vault_v1_7']:
            return 'groUSDC.e';
        case VaultAvaxNames['groUSDT.e_vault']:
        case VaultAvaxNames['groUSDT.e_vault_v1_5_1']:
        case VaultAvaxNames['groUSDT.e_vault_v1_5']:
        case VaultAvaxNames['groUSDT.e_vault_v1_6']:
        case VaultAvaxNames['groUSDT.e_vault_v1_7']:
            return 'groUSDT.e';
        default:
            return '';
    }
});

export const selectVaultVersion = (vault: VaultAvaxNames) => {
    switch (vault) {
        case VaultAvaxNames['groDAI.e_vault']:
        case VaultAvaxNames['groUSDC.e_vault']:
        case VaultAvaxNames['groUSDT.e_vault']:
            return 'v1';
        case VaultAvaxNames['groDAI.e_vault_v1_5']:
        case VaultAvaxNames['groUSDC.e_vault_v1_5']:
        case VaultAvaxNames['groUSDT.e_vault_v1_5']:
            return 'v1.5';
        case VaultAvaxNames['groDAI.e_vault_v1_5_1']:
        case VaultAvaxNames['groUSDC.e_vault_v1_5_1']:
        case VaultAvaxNames['groUSDT.e_vault_v1_5_1']:
            return 'v1.5.1';
        case VaultAvaxNames['groDAI.e_vault_v1_6']:
        case VaultAvaxNames['groUSDC.e_vault_v1_6']:
        case VaultAvaxNames['groUSDT.e_vault_v1_6']:
            return 'v1.6';
        case VaultAvaxNames['groDAI.e_vault_v1_7']:
        case VaultAvaxNames['groUSDC.e_vault_v1_7']:
        case VaultAvaxNames['groUSDT.e_vault_v1_7']:
            return 'v1.8';
        default:
            return '';
    }
};

const tokenPrice = new BigNumber(1);

export const selectWithdrawAmount = createSelector(selectLabsState, (labs) => labs.amount);

export const selectVaultBalance = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.vaultBalance),
);

export const selectUserCantWithdraw = createSelector(
    selectWithdrawAmount,
    selectDepositedAvaxToken,
    (amount, deposited) => amount.isGreaterThan(deposited) || deposited.isZero(),
);

export const selectTriggerWarning = createSelector(
    selectAssetsAvaxToken,
    selectDebtAvaxToken,
    selectUserCantWithdraw,
    selectLabsContractsLoading,
    (debt, assets, cantWithdraw, loading) =>
        !loading && !assets.isLessThanOrEqualTo(debt) && !cantWithdraw,
);

export const selectStableAmountToWithdraw = createSelector(
    selectWithdrawAmount,
    selectTriggerWarning,
    selectPriceAvaxToken,
    selectDebtAvaxToken,
    selectAssetsAvaxToken,
    (withdraw, triggerWarning, priceShare, debt, assets) => {
        const warningValue = withdraw.multipliedBy(priceShare).minus(debt).plus(assets);
        const finalValue = warningValue.isLessThan(0) ? new BigNumber(0) : warningValue;
        return triggerWarning ? finalValue : withdraw.multipliedBy(priceShare);
    },
);

export const selectTotalStableCoin = createSelector(
    selectWithdrawAmount,
    selectPriceAvaxToken,
    (amount, shares) => amount.multipliedBy(shares),
);

export const selectInputtedAmountToDollars = createSelector(selectTotalStableCoin, (stable) =>
    stable.multipliedBy(tokenPrice),
);

export const selectWithdrawToDollars = createSelector(selectStableAmountToWithdraw, (stable) =>
    stable.multipliedBy(tokenPrice),
);

export const selectLabsMaxLoss = createSelector(
    selectTriggerWarning,
    selectAssetsAvaxToken,
    selectPriceAvaxToken,
    selectDebtAvaxToken,
    selectDepositedAvaxToken,
    selectWithdrawAmount,
    (trigger, assets, priceShare, debt, balance, amount) => {
        const numerator = amount.multipliedBy(priceShare).minus(debt).plus(assets);
        const denominator = amount.multipliedBy(priceShare);
        const maxLoss = new BigNumber(1)
            .minus(numerator.dividedBy(denominator))
            .abs()
            .plus(0.0001);
        const final = maxLoss.isGreaterThan(1) ? new BigNumber(0.99) : maxLoss;
        return !trigger ? new BigNumber(0) : final;
    },
);

export const selectDepositLimitToken = createSelector(
    selectLabsTokenValues,
    (info) => new BigNumber(info.depositLimit),
);

export const selectWithdrawMax = createSelector(
    selectLabsState,
    selectDepositedAvaxToken,
    (labs, deposited) => labs.amount.isGreaterThanOrEqualTo(deposited),
);

export const selectUserHasClaimed = (token: VaultAvaxNames) =>
    createSelector(selectPersonalAvalancheStats, (avax) => {
        const statsObj = avax?.gro_gate[token];
        const baseAllowanceClaimed = statsObj?.base_allowance_claimed;
        return baseAllowanceClaimed === 'true';
    });

export const selectBaseAllowance = (token: VaultAvaxNames) =>
    createSelector(selectPersonalAvalancheStats, (avax) => {
        const statsObj = getGroGate(avax, token);
        const baseAllowance = statsObj?.base_allowance;
        return new BigNumber(baseAllowance || 0);
    });

export const selectLabsVaults = createSelector(
    selectGroAvalancheStats,
    (stats) => stats?.labs_vault || [],
);

export const selectDeprecatedLabs = createSelector(
    selectLabsVaults,
    selectPersonalAvalancheStats,
    (vault, stats) => {
        const netReturns = stats?.net_returns;
        const currentBalance = stats?.current_balance;
        const deprecatedVaults: TpLabVault[] = [];
        const allDepcreatedLabs = [...labsDeprecated];

        return allDepcreatedLabs as TpLabVault[];
    },
);

export const selectActiveLabs = createSelector(selectLabsVaults, (vault) => []);

export const selectLabsVaultsNames = createSelector(selectLabsVaults, (vaults) => [
    ...labsDeprecated.map((elem) => elem.name),
]);

export const selectLabsNotification = createSelector(
    selectLabsState,
    selectDeprecatedLabs,
    (labs, deprecated) =>
        deprecated.filter((elem) => new BigNumber(labs[elem.name].deposited).isGreaterThan(1))
            .length,
);

export const selectTotalClaimableAllowance = (token: VaultAvaxNames) =>
    createSelector(selectBaseAllowance(token), selectClaimableAllowance, (base, claimable) =>
        base.plus(claimable),
    );

export const selectLabsHighestAPY = createSelector(selectActiveLabs, (labs) => new BigNumber(0));

export const selectLabsHighestAverageAPY = createSelector(
    selectActiveLabs,
    (labs) => new BigNumber(0),
);

export const selectTotalBalance = createSelector(selectLabsState, (labs) => {
    const names = labsDeprecated.map((elem) => elem.name) as VaultAvaxNames[];
    const balances = names.map((name) => {
        const vault = labs[name];
        return new BigNumber(vault.deposited).multipliedBy(vault.pricePerShare);
    });

    return balances.reduce((curr, next) => curr.plus(next), new BigNumber(0));
});
