/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable func-style */
/* eslint-disable consistent-return */
/* eslint-disable no-console */

import { Dispatch } from 'react';
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../constants';
import { daiContract } from '../../../lib/abis/daiContract';
import { usdcContract, usdcProxiContract } from '../../../lib/abis/usdcContract';
import { dai, g2Router, usdc } from '../../../lib/contract-info';
import { setSignature } from '../store/exchange.reducers';

export async function signTransaction(
    account: string,
    dispatch: Dispatch<any>,
    value: BigNumber,
    token: TOKENS,
    setHasSigned: () => void,
    setLoading: () => void,
): Promise<void> {
    const EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
    ];

    const EIP2612_TYPE = [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
    ];

    const PERMIT_ALLOWED_TYPE = [
        { name: 'holder', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'expiry', type: 'uint256' },
        { name: 'allowed', type: 'bool' },
    ];

    const deadline = new Date(Date.now() + 20 * 60000).getTime();
    const nonce =
        token === TOKENS.USDC
            ? await usdcProxiContract().methods.nonces(account).call()
            : await daiContract().methods.nonces(account).call();

    const allowed = token === TOKENS.DAI;

    const name =
        token === TOKENS.USDC
            ? await usdcContract().methods.name().call()
            : await daiContract().methods.name().call();
    const verifyingContract = token === TOKENS.USDC ? usdc.address : dai.address;
    const version =
        token === TOKENS.USDC
            ? await usdcProxiContract().methods.version().call()
            : await daiContract().methods.version().call();

    const { web3 }: any = window;

    if (web3) {
        const domain = {
            chainId: 1,
            name,
            verifyingContract,
            version,
        };

        const message = allowed
            ? {
                  allowed,
                  expiry: deadline,
                  holder: account,
                  nonce: parseInt(nonce, 10),
                  spender: g2Router.address,
              }
            : {
                  deadline,
                  nonce: parseInt(nonce, 10),
                  owner: account,
                  spender: g2Router.address,
                  // we need the actual nonce
                  value: value.toString(),
              };

        const data = JSON.stringify({
            domain,
            message,
            primaryType: 'Permit',
            types: {
                EIP712Domain,
                Permit: allowed ? PERMIT_ALLOWED_TYPE : EIP2612_TYPE,
            },
        });

        await web3.currentProvider.sendAsync(
            {
                from: account,
                id: 1,
                jsonrpc: '2.0',
                method: 'eth_signTypedData_v4',
                params: [account, data],
            },
            (err: any, result: any) => {
                if (err) {
                    setLoading();
                    return console.error(err, 'AQUI?');
                }

                const signature = result.result.substring(2);
                const r = `0x${signature.substring(0, 64)}`;
                const s = `0x${signature.substring(64, 128)}`;
                const v = parseInt(signature.substring(128, 130), 16);
                const finalV = v === 0 || v === 1 ? v + 27 : v;

                // daiContract(network).methods.permit(message.holder, message.spender, message.nonce, message.expiry, message.allowed, v, r, s).send({from: message.holder})
                // gRouterContract(network).methods.depositWithAllowedPermit(convertToBN(value), 0, false, convertToBN(new BigNumber(9900)), message.expiry, message.nonce, v, r, s).send({ from: message.holder})
                // depositWithAllowedPermit({ deadline: message.expiry, network, nonce: message.nonce, r, s, slippage: new BigNumber(9900), tokenIndex: '0', tranche: false, v })
                dispatch(setSignature({ deadline, nonce, r, s, v: finalV }));
                setHasSigned();
                return { deadline, r, s, v };
            },
        );
    }
}
