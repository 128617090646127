import React from 'react';
import { PositionWrapper } from '../PositionWrapper';
import { LabsBalancePosition } from './LabsBalancePosition';

export function LabsProtocolPosition(): React.ReactElement {
    return (
        <PositionWrapper sx={{ mb: 2, mt: 1.5 }} testId="labs-position">
            <LabsBalancePosition />
        </PositionWrapper>
    );
}
