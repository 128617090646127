/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import strategyJson from './json/strategy.json';
import { Strategy } from './types/strategy';

let instance: Strategy;

const contract: { abi: any[] } = {
    abi: strategyJson,
};

export function strategyContract(address: string): Strategy {
    const { Contract } = web3Instance().eth;
    instance = new Contract(contract.abi, address) as any as Strategy;
    return instance;
}
