/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable default-param-last */
/* eslint-disable no-nested-ternary */
/**
 * 1. For consistency, all conversion functions in this file should return BigNumbers in place of
 *      numbers
 * 2. All tokens, except USD (for now) should be returned in they're own decimal conventions, i.e.
 *      the decimals that their smart contract accepts them in. For example, 1 DAI should be
 *      returned as 1 * 10 ** 18
 *
 *      This leads to some boilerplate in other parts of the codebase but ensures consistency and
 *      makes the code way easier to reason about.
 *
 *      Note that this is not necessarily true for the input to each function, which is context
 *      dependent. Read the docstring for each function to determine the convention for its args.
 *
 *
 * N.B. TODO: Point 2 above lists USD as an exception. For now, USD is returned in base 10 units.
 *          We might want to change that in the future for more consistency
 */

import { BigNumber } from 'bignumber.js';
import { GrowthToken, Token, TokenObject, TOKENS } from '../../constants';
import { minusPercentageBasisPoints } from '../../modules/utils/percentage.helpers';
import { isGrwthToken, isPoolToken } from '../../modules/utils/token.helpers';
import { tokenContract } from '../abis/tokenContract';
import { getIndexFromToken } from '../contract-info';
import { routerOracleStableToUSD, routerOracleUsdTostable } from './routerOracle';
import { addTokenDecimals, removeTokenDecimals } from './web3.helpers';

export function tokenToUsd(
    token: Token,
    amount = new BigNumber(0),
    conversions: TokenObject,
): BigNumber {
    return amount.multipliedBy(conversions[token] || 0);
}

export function usdToToken(token: Token, amount: BigNumber, conversions: TokenObject): BigNumber {
    return amount.dividedBy(conversions[token] || 0);
}

/**
 * Returns price per 1 share in LP tokens
 *
 * @param contract
 */
export async function getPricePerShare(token: Token): Promise<BigNumber> {
    const pps = await tokenContract(token).methods.getPricePerShare().call();
    return new BigNumber(pps);
}

/**
 *
 * @param contract
 * @param coins
 * @param direction
 */
export async function stablesToUsd(amounts: TokenObject, index: number): Promise<BigNumber> {
    let usd = '';
    const token = Object.keys(amounts)[0];
    const value = Object.values(amounts)[0];
    const amount = addTokenDecimals(token as Token, await routerOracleStableToUSD(value, index));

    usd = removeTokenDecimals(TOKENS.USD, amount).toString();

    // if (index === 3) usd = addTokenDecimals(Object.keys(amounts)[0] as Token, await routerOracleStableToUSD(Object.values(amounts)[0], index)).toString()

    return new BigNumber(usd);
}

export async function usdToStable(amount: BigNumber, token: Token): Promise<BigNumber> {
    const index = getIndexFromToken(token);
    const stableAmount = await routerOracleUsdTostable(amount, index);

    const formatted = [0, 3].includes(index) ? stableAmount : stableAmount.shiftedBy(-12);

    return formatted;
}

/**
 * Returns the absolute amount of GrwthToken you'll get before taking into account the slippage.
 *
 * @param token - Grwth token name
 * @param coins {Object<token, amount>}
 * @param direction
 */
export async function stablesToGrowth(token: Token, stablecoins: TokenObject): Promise<BigNumber> {
    let pps: BigNumber; // price per share in USD

    const index = getIndexFromToken(Object.keys(stablecoins)[0] as Token);

    const amountUsd = await stablesToUsd(stablecoins, index);

    switch (token) {
        case TOKENS.PWRD:
            return amountUsd;
        case TOKENS.GVT:
            pps = await getPricePerShare(token); // in usd
            return removeTokenDecimals(TOKENS.GVT, amountUsd.dividedBy(pps)); // number of gvt tokens
        default:
            throw new Error('Unknown token');
    }
}

/**
 * Calculates the splits of stable coins for a given amount of (max) Grwth token amount. The
 * conversion maintains the proportions of stable coins.
 */
export async function growthToStables(
    token: GrowthToken,
    amount: BigNumber,
    coins: Token[],
    conversions: TokenObject,
): Promise<TokenObject> {
    const usdAmount = minusPercentageBasisPoints(
        tokenToUsd(token, amount, conversions),
        new BigNumber(0),
    );

    const coinAmount = await usdToStable(usdAmount, coins[0]);

    return { [coins[0]]: coinAmount };
}

/**
 * Convert token amount WITHOUT DECIMALS to USD WITHOUT DECIMALS
 * @param token
 * @param amount
 * @returns
 */
export async function growthToUsd(token: Token, amount: BigNumber): Promise<BigNumber> {
    let pps: BigNumber;
    let usdAmount: BigNumber;

    switch (token) {
        case TOKENS.PWRD:
            return amount;
        case TOKENS.GVT:
            pps = await getPricePerShare(token); // in usd
            usdAmount = pps.times(addTokenDecimals(token, amount)); // gvt in usd
            return usdAmount;
        default:
            throw new Error('Unknown token');
    }
}

/**
 * Any token WITHOUT DECIMALS to USD with onchain calcs, returns bignumber WITHOUT DECIMALS
 *
 * @param token
 * @param amount
 * @param direction
 */
export async function tokenToUsdOnChain(token: Token, amount: BigNumber): Promise<BigNumber> {
    if (isGrwthToken(token)) {
        return growthToUsd(token, amount);
    }

    // pools LP token has no usd conversion
    if (isPoolToken(token)) {
        return removeTokenDecimals(TOKENS.USD, new BigNumber(1));
    }
    // we don't currently convert WETH
    if (TOKENS.WETH === token) {
        return removeTokenDecimals(TOKENS.USD, new BigNumber(1));
    }

    // if (direction === ExchangeDirection.invest) {
    const index = getIndexFromToken(token);
    const stablesAmount = await stablesToUsd({ [token]: amount }, index);
    return stablesAmount;
    // }
    // // for withdrawal conversions we asume amount is in USD so we need to fix its decimals
    // const amountInUsd = removeTokenDecimals(TOKENS.USD, addTokenDecimals(token, amount));

    // const stableAmount = await usdToStable(amountInUsd, token);

    // const usdAmount = removeTokenDecimals(token, new BigNumber(1)).dividedBy(stableAmount);

    // return removeTokenDecimals(TOKENS.USD, usdAmount);
}

/**
 * Conversion rate.
 */
export async function fetchTokenToUsdConversionRates(token: Token): Promise<BigNumber> {
    const usd = await tokenToUsdOnChain(token, removeTokenDecimals(token, new BigNumber(1)));

    return usd;
}
