/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { gTranche } from '../contract-info';
import { GTranche } from './types/gTranche';

let instance: GTranche;

export function gTrancheContract(): GTranche {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(gTranche.abi, gTranche.address) as any as GTranche;
    return instance;
}
