/* eslint-disable sort-keys-fix/sort-keys-fix */
import BigNumber from 'bignumber.js';
import { TpGroStatsMc, TpUserStatsMc } from '../stats.types';

export type StatsState = {
    groStatsError: boolean;
    groStatsMc: TpGroStatsMc;
    groStatsWarning: boolean;
    labsBalance: BigNumber;
    loadingGroMcStats: boolean;
    loadingUserStatsMc: boolean;
    userStatsError: boolean;
    userStatsMc?: TpUserStatsMc;
    userStatsWarning: boolean;
};

export const initialStatsState: StatsState = {
    groStatsMc: {
        gro_stats_mc: {
            avalanche: {
                launch_timestamp: '1637746393',
                token_price_usd: {
                    avax: '0',
                },
                labs_vault: [],
                tvl: {
                    labs_dai_vault: '0',
                    labs_usdc_vault: '0',
                    labs_usdt_vault: '0',
                    total: '0',
                },
            },
            current_timestamp: '0',
            mainnet: {
                apy: {
                    last24h: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    last7d: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    daily: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    weekly: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    monthly: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    all_time: {
                        pwrd: '0',
                        gvt: '0',
                    },
                    hodl_bonus: '0',
                    current: {
                        pwrd: '0',
                        gvt: '0',
                    },
                },
                current_timestamp: '0',
                launch_timestamp: '0',
                network: 'mainnet',
                exposure: {
                    protocols: [],
                    stablecoins: [],
                },
                system: {
                    total_amount: '0',
                    total_share: '0',
                    last3d_apy: '0',
                    lifeguard: {
                        name: '3CRV',
                        stablecoins: [
                            {
                                name: 'DAI',
                                display_name: 'DAI',
                                amount: '0.0000000',
                            },
                            {
                                name: 'USDC',
                                display_name: 'USDC',
                                amount: '0.0000000',
                            },
                            {
                                name: 'USDT',
                                display_name: 'USDT',
                                amount: '0.0000000',
                            },
                        ],
                        amount: '0.0000000',
                        display_name: '3CRV',
                        last3d_apy: '0.000000',
                        share: '0.000000',
                    },
                    vault: [],
                },
                pools: [],
                tvl: {
                    pwrd: '0',
                    gvt: '0',
                    total: '0',
                    util_ratio: '0',
                    util_ratio_limit_PD: '0',
                    util_ratio_limit_GW: '0',
                },
                token_price_usd: {
                    gvt: '0',
                    gro: '0',
                    pwrd: '0',
                },
            },
            mc_totals: {
                tvl: {
                    avalanche: '0',
                    mainnet: '0',
                    total: '0',
                },
            },
            network: 'mainnet',
        },
    } as unknown as TpGroStatsMc,
    loadingGroMcStats: false,
    loadingUserStatsMc: false,
    userStatsMc: undefined,
    groStatsError: false,
    userStatsError: false,
    labsBalance: new BigNumber(0),
    userStatsWarning: false,
    groStatsWarning: false,
};
