/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../app/app.types';
import { TpPool } from '../../stats/stats.types';
import { initialPoolState } from './pools.store';

const poolsSlice = createSlice({
    initialState: initialPoolState,
    name: 'pools',
    reducers: {
        resetPools: (state) => {
            state = { ...initialPoolState, pools: state.pools };
            return state;
        },
        setDeprecated: (state, { payload }: PayloadAction<boolean>) => {
            state.deprecated = payload;
        },
        setMigratedStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.migrated = payload.status;
        },
        setOpenMigrationModal: (state, { payload }: PayloadAction<boolean>) => {
            state.openMigrationModal = payload;
        },
        setPidsToMigrate: (state, { payload }: PayloadAction<string[]>) => {
            state.pidsToMigrate = payload;
        },
        setPools: (state, { payload }: PayloadAction<TpPool[]>) => {
            state.pools = payload;
        },
        setPoolsStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
    },
});

export const {
    resetPools,
    setDeprecated,
    setMigratedStatus,
    setOpenMigrationModal,
    setPidsToMigrate,
    setPools,
    setPoolsStatus,
} = poolsSlice.actions;

export const poolsReducer = poolsSlice.reducer;
