/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Token, TOKENS } from '../../../../constants';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { selectStableAmountToWithdraw, selectTokenName } from '../../../labs/store/labs.selectors';
import { selectExtendAmount, selectRevestAmount } from '../../../rewards/store/rewards.selectors';
import { formatNumber } from '../../../utils/currency.helpers';
import { getTokenName } from '../../../utils/token.helpers';
import { TransactionType, WalletTransaction } from '../../store/transactions.store';

type TpTokenAmount = {
    isClaim: boolean;
    toToken?: boolean;
    transaction: WalletTransaction;
};

export function TokenAmount({ isClaim, toToken, transaction }: TpTokenAmount): React.ReactElement {
    const { type } = transaction;

    const isRevest = ExchangeDirection.revest === transaction.direction;
    const isExtend = ExchangeDirection.extend === transaction.direction;
    const isWithdrawLabs = ExchangeDirection.withdrawAvax === transaction.direction;
    const isInvestLabs = ExchangeDirection.investAvax === transaction.direction;
    const isStaked = ExchangeDirection.stake === transaction.direction;
    const isUnstaked = ExchangeDirection.unstake === transaction.direction;

    const extendAmount = useAppSelector(selectExtendAmount);
    const revestAmount = useAppSelector(selectRevestAmount);
    const stableCoin = useAppSelector(selectStableAmountToWithdraw);
    const name = useAppSelector(selectTokenName);

    const tokensFrom = Object.entries(transaction.meta.from).filter((elem) =>
        (elem as [string, BigNumber])[1].isGreaterThan(0),
    ) as [string, BigNumber][];

    const tokensTo = Object.entries(transaction.meta.to).filter((elem) =>
        (elem as [string, BigNumber])[1].isGreaterThan(0),
    ) as [string, BigNumber][];

    return (
        <Box mt={toToken ? '2px' : 0}>
            {!toToken &&
                tokensFrom.map(([key, value], i, arr) => (
                    <Box
                        key={key}
                        alignItems="center"
                        display="flex"
                        mb={arr.length - 1 === i ? 0 : 0.5}
                    >
                        <Typography color="text.tertiary" variant="body2Small">
                            {isWithdrawLabs
                                ? name
                                : getTokenName(
                                      isClaim ? TOKENS.GRO : (key as Token),
                                      isUnstaked,
                                  )}{' '}
                            {formatNumber(
                                isExtend
                                    ? extendAmount
                                    : isRevest
                                    ? revestAmount
                                    : isInvestLabs
                                    ? stableCoin
                                    : value,
                            )}
                        </Typography>
                    </Box>
                ))}
            {toToken &&
                tokensTo.map(([key, value], i, arr) => (
                    <Box
                        key={key}
                        alignItems="center"
                        display="flex"
                        mb={arr.length - 1 === i ? 0 : 0.5}
                    >
                        <Typography color="text.tertiary" variant="body2Small">
                            ⤷ {(isExtend || isRevest) && 'Locked'}{' '}
                            {isInvestLabs ? name : getTokenName(key as Token, isStaked)}{' '}
                            {formatNumber(
                                isExtend
                                    ? extendAmount
                                    : isRevest
                                    ? revestAmount
                                    : isWithdrawLabs
                                    ? stableCoin
                                    : value,
                            )}
                        </Typography>
                    </Box>
                ))}
            {type === TransactionType.approve && (
                <Typography color="text.tertiary" variant="body2Small">
                    MAX spend limit
                </Typography>
            )}
        </Box>
    );
}
