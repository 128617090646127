/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import Select, { OptionTypeBase } from 'react-select';

function useStyles() {
    const theme = useTheme();
    return {
        absLabel: css`
            background: ${theme.palette.common.black};
            font-size: 13px;
            left: ${theme.spacing(1)};
            padding: 1px 5px;
            top: -${theme.spacing(1)};
        `,
        customInput: css`
            background-color: rgba(1, 16, 28, 1);
            border: none;
            color: ${theme.palette.common.white};
            font-size: 14px;
            font-weight: 500;
            padding: 6px 1rem 5px 10px;
            text-align: left;
            width: 100%;
            z-index: 1;
        `,
        selectLabel: css`
            color: ${theme.palette.common.white};
            font-weight: 500;
            padding-bottom: ${theme.spacing(1)};
        `,
        wrapperInline: css`
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
        `,
        wrapperInput: css`
            background-color: rgba(1, 16, 28, 1);
            border-radius: 5px;
            height: 20px;
            left: 73px;
            position: absolute;
            top: 14%;
            width: 65px;
            z-index: 2;
        `,
    };
}

export type ExchangeOptionSelectOption = {
    label: React.ReactElement;
    name: string;
    value: string;
};

type SelectProps = {
    customValue: any;
    isInline?: boolean;
    isSearchable: boolean;
    labelText: string;
    onChange: (val: any) => void;
    onChangeCustomValue: (val: any) => void;
    options: ExchangeOptionSelectOption[];
    value: OptionTypeBase;
};

export function ExchangeOptionSelect({
    customValue,
    isInline,
    isSearchable,
    labelText,
    onChange,
    onChangeCustomValue,
    options,
    value,
}: SelectProps): React.ReactElement {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const customIptRef = useRef(null);
    const theme = useTheme();

    const defaultCustomStyles = useMemo(
        () => ({
            control: (provided: any) => ({
                ...provided,
                ':hover': {
                    '*': {
                        color: '#ffffff !important',
                    },
                },
                backgroundColor: 'rgba(1, 16, 28, 1)',
                borderColor: theme.palette.grey[200],
                borderRadius: '5px',
                borderStyle: 0,
                boxShadow: 0,
                cursor: 'pointer',
                fontSize: '13px',
                height: '24px',
                width: 100,
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                color: theme.palette.grey[200],
            }),
            indicatorsContainer: (provided: any) => ({
                ...provided,
                padding: '0px !important',
                transform: isMenuOpen ? 'rotate(180deg)' : null,
                transition: 'transform 300ms',
            }),
            indicatorSeparator: () => ({}),
            menu: (provided: any) => ({
                ...provided,
                backgroundColor: theme.palette.grey[600],
                borderRadius: '0 0 3px 3px',
                color: theme.palette.grey[200],
                // top: 'calc(100% - 10px)',
                zIndex: 10,
            }),

            menuList: (provided: any) => ({
                ...provided,
                maxHeight: '140px',
                overflow: 'hidden',
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                '&:hover': {
                    background: theme.palette.grey[400],
                },
                background: state.isSelected ? theme.palette.grey[400] : 'transparent',
                color: theme.palette.grey[200],
                cursor: 'pointer',
                fontSize: '14px',
                marginLeft: '1px',
                maxHeight: '35px',
                paddingLeft: '12px',
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                overflow: 'visible',
                paddingLeft: '12px',
            }),
        }),
        [isMenuOpen, theme.palette.grey],
    );

    function onMenuOpen() {
        setIsMenuOpen(true);
    }

    function onMenuClose() {
        setIsMenuOpen(false);
    }

    function handleOnChange(obj: ExchangeOptionSelectOption) {
        onChange(obj);
        if (obj && obj.value === 'custom') {
            setTimeout(() => {
                if (customIptRef.current) {
                    (customIptRef.current as any).focus();
                }
            }, 100);
        }
    }

    const styles = useStyles();

    return (
        <div css={isInline ? styles.wrapperInline : undefined}>
            {labelText && (
                <Typography color="text.tertiary" mr={isInline ? 1 : 0} variant="body2Small">
                    {labelText}
                </Typography>
            )}

            <Select
                open
                isSearchable={isSearchable}
                menuPlacement="top"
                menuPosition="fixed"
                options={options}
                styles={defaultCustomStyles}
                value={value}
                onChange={handleOnChange}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
            />
            {value && value.value === 'custom' && (
                <div css={styles.wrapperInput}>
                    <input
                        ref={customIptRef}
                        css={styles.customInput}
                        // Prevent user from using the exponential (e) number
                        data-testid="custom-value-ipt"
                        lang="en"
                        // placeholder="Custom"
                        type="number"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        value={customValue}
                        onChange={(e) => onChangeCustomValue(e.target.value)}
                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    />
                </div>
            )}
        </div>
    );
}
