import BigNumber from 'bignumber.js';
import { Status, StatusState } from '../../app/app.types';

export type UnwindStore = {
    alloy: {
        deadline: string;
        pricePerShare: BigNumber;
        sharesAvailable: BigNumber;
        totalDeposited: BigNumber;
        userBalance: BigNumber;
    };
    cusdcExchange: BigNumber;
    deadline: string;
    poolsReady: Status;
    pricePerShare: BigNumber;
    sharesAvailable: BigNumber;
    totalDeposited: BigNumber;
    userBalance: BigNumber;
} & StatusState;

export const initialUnwindState: UnwindStore = {
    alloy: {
        deadline: '',
        pricePerShare: new BigNumber(0),
        sharesAvailable: new BigNumber(0),
        totalDeposited: new BigNumber(0),
        userBalance: new BigNumber(0),
    },
    cusdcExchange: new BigNumber(0),
    deadline: '',
    error: undefined,
    poolsReady: Status.idle,
    pricePerShare: new BigNumber(0),
    sharesAvailable: new BigNumber(0),
    status: Status.loading,
    totalDeposited: new BigNumber(0),
    userBalance: new BigNumber(0),
};
