/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import BigNumber from 'bignumber.js';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { initialClaim, ustClaim } from '../../../../lib/web3/ustClaim';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
// import { updateGROValuesThunk } from '../../../rewards/store/thunks/updateGROValuesThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { TransactionStatus } from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { initWalletThunk } from '../../../wallet/store/thunks/initWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectHasClaimedPwrd } from '../rewards.selectors';
import proofs from './proofs.json';
import { updateGROValuesThunk } from './updateGROValuesThunk';

export const executeClaimUstTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const hasClaimed = selectHasClaimedPwrd(getState());

        let method: NonPayableTransactionObject<any>;

        if (hasClaimed) {
            method = ustClaim();
        } else {
            const item = proofs.airdrops.find((elem) => elem.address === wallet);
            method = initialClaim({
                proof: item?.proofs || [],
                totalClaim: new BigNumber(item?.amount || 0),
            });
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
            void dispatch(initWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
