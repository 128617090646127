import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import AlphaHomoraLogo from '../../../assets/images/ALPHA_HOMORA.svg';
import AvaxLogo from '../../../assets/images/AVALANCHE.svg';
import SimpleAlphaLogo from '../assets/alphalogo.png';
import SimpleAvaxLogo from '../assets/avaxlogo.png';

export function LabsLogos(): React.ReactElement {
    const theme = useTheme();

    const styles = {
        alpha: css`
            position: absolute;
            left: 66%;
            top: -20px;
            background: ${theme.palette.common.black};
            border: 1px solid ${theme.palette.grey[400]};
            padding: 7px 16px 3px 10px;
            border-radius: 8px;
            @media (max-width: 820px) {
                left: 60%;
            }
            @media (max-width: 720px) and (min-width: 679px) {
                left: 50%;
            }
            @media (max-width: 678px) {
                content: url(${SimpleAlphaLogo});
                z-index: 1;
                background: transparent;
                padding: 0;
                border: 0px;
                left: 82%;
                top: -15px;
            }
            @media (max-width: 480px) {
                left: 75%;
            }
        `,
        avax: css`
            position: absolute;
            left: 80%;
            top: -20px;
            background: ${theme.palette.common.black};
            border: 1px solid ${theme.palette.grey[400]};
            padding: 5px 16px;
            border-radius: 8px;
            @media (max-width: 820px) {
                left: 76%;
            }
            @media (max-width: 720px) and (min-width: 679px) {
                left: 72%;
            }
            @media (max-width: 678px) {
                content: url(${SimpleAvaxLogo});
                z-index: 1;
                background: transparent;
                padding: 0;
                border: 0px;
                left: 90%;
                top: -15px;
            }
            @media (max-width: 480px) {
                left: 87%;
            }
        `,
    };
    return (
        <React.Fragment>
            <img alt="Alpha Homora Logo" css={styles.alpha} src={AlphaHomoraLogo} />
            <img alt="Avalanche Logo" css={styles.avax} src={AvaxLogo} />
        </React.Fragment>
    );
}
