/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import { formatNumber } from '../../utils/currency.helpers';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import { DuraDetailsModal } from '../components/DuraDetailsModal';
import { InfoLine } from '../components/InfoLine';
import { RedeemAlloyModal } from '../components/RedeemAlloyModal';
import { useForceNetworkChange } from '../hooks/useForceNetworkChange';
import { useGetRedeemData } from '../hooks/useGetRedeemData';
import {
    selectAlloyDeadline,
    selectCanClaimAlloy,
    selectPricePerShareAlloy,
    selectSharesAvailableAlloy,
    selectTotalDepositedAlloy,
    selectUserBalanceAlloy,
} from '../store/unwind.selectors';

export enum TypeRedeem {
    redeemGro = ExchangeDirection.redeemDura,
    deposit = ExchangeDirection.depositUsdc,
    withdraw = ExchangeDirection.withdrawUsdc,
}

export function Alloy(): React.ReactElement {
    useGetRedeemData();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [type, setType] = useState<TypeRedeem>(TypeRedeem.deposit);

    const canRedeem = useAppSelector(selectCanClaimAlloy);

    const pricePerShare = useAppSelector(selectPricePerShareAlloy);
    const balance = useAppSelector(selectUserBalanceAlloy);
    const sharesAvailable = useAppSelector(selectSharesAvailableAlloy);
    const deadline = useAppSelector(selectAlloyDeadline);
    const totalDeposited = useAppSelector(selectTotalDepositedAlloy);

    const formattedDeadline = format(deadline, 'd MMM HH:mm:ss O');

    useForceNetworkChange({ network: 'mainnet' });

    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);

    const styles = {
        button: css`
            height: 48px;
        `,
        details: css`
            color: #ffc9c6;
            cursor: pointer;
        `,
        inputWrapper: css`
            border-radius: 21px;
            background: #051c2e;
            height: 506px;
            width: ${!isXsSize ? '444px' : 'auto'};
        `,
        link: css`
            text-decoration: none;
            color: #deb8fc;
        `,
        warning: css`
            border-radius: 16px;
            border: 1px solid
                ${canRedeem ? 'rgba(222, 184, 252, 0.20)' : 'rgba(254, 174, 168, 0.20)'};
            background: ${canRedeem
                ? 'linear-gradient(0deg, rgba(180, 82, 255, 0.12) 0%, rgba(180, 82, 255, 0.12) 100%)'
                : 'linear-gradient(0deg, rgba(250, 119, 121, 0.12) 0%, rgba(250, 119, 121, 0.12) 100%)'};
        `,
    };

    function handleSubmit(typeRedeem: TypeRedeem): void {
        setIsOpen(true);
        setType(typeRedeem);
    }

    return (
        <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center" pb={2} px={2}>
            <Box maxWidth="444px">
                <Typography fontWeight={600} variant="h1">
                    Redeem GRO for DURA
                </Typography>

                <Typography mt={3} variant="body2">
                    Depositing GRO in this section will let you claim a share of AlloyX DURA
                    tokens. DURA tokens may be redeemable for USDC in the future by AlloyX.
                </Typography>
                <Typography mt={3}>
                    Gro DAO is holding ~117k USDC (as of 11th October 2023) of its treasury in
                    AlloyX as DURA tokens. Because AlloyX DURA tokens are representing illiquid
                    under-collateralized loans, the DAO cannot liquidate them and release the USDC
                    now. Therefore a separate redemption contract has been deployed where DAO
                    members can deposit their GRO in exchange directly for DURA tokens. User’s will
                    be able to claim their share of DURA tokens based on their prorated share of
                    deposited GRO tokens. GRO deposits for DURA are handled separately from
                    deposits for USDC redemption.
                </Typography>
                <Typography mt={3}>
                    DURA tokens are issued by AlloyX{' '}
                    <span css={styles.details} onClick={(): void => setIsOpenDetails(true)}>
                        (details here)
                    </span>
                    . In order to redeem DURA tokens for USDC, several conditions are important to
                    consider. AlloyX requires users to complete a Know Your Customer (KYC) process
                    to redeem their DURA tokens. AlloyX does not allow US citizens to redeem DURA.
                    DURA tokens cannot be redeemed immediately. Interest payments are available
                    occasionally on a first-claim-first-serve basis. AlloyX estimates that the
                    principal will be fully unlocked throughout 2025. There are various other risks
                    associated with undercollateralized loans backing the DURA tokens.
                </Typography>
            </Box>
            <Box
                css={styles.inputWrapper}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                p={4}
            >
                <Box>
                    <Typography fontWeight="900" mb={3.5} variant="h2">
                        GRO Redemption
                    </Typography>

                    <InfoLine
                        grey={canRedeem}
                        left="You’ve deposited"
                        right={`${formatNumber(balance)} GRO`}
                    />
                    <InfoLine
                        grey={canRedeem}
                        left="Total GRO deposited"
                        right={`${formatNumber(totalDeposited)} GRO`}
                    />
                    <InfoLine
                        bold={formattedDeadline}
                        grey={canRedeem}
                        left="Deposit period"
                        right="Open until "
                    />
                    <Divider sx={{ mb: 2 }} />
                    <InfoLine
                        grey={!canRedeem}
                        left="Claims"
                        right={`Starting from ${formattedDeadline}`}
                    />
                    {canRedeem && (
                        <React.Fragment>
                            <InfoLine
                                grey={!canRedeem}
                                left="GRO rate"
                                right={`1 GRO : ${formatNumber(pricePerShare)} DURA`}
                            />
                            <InfoLine
                                grey={!canRedeem}
                                left="You can claim"
                                right={`${formatNumber(sharesAvailable)} DURA`}
                            />
                        </React.Fragment>
                    )}
                </Box>
                <Box>
                    <Box display="flex" gap={1} mb={1}>
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.button}
                            disabled={canRedeem}
                            variant="contained"
                            onClick={(): void => handleSubmit(TypeRedeem.deposit)}
                        >
                            Deposit GRO
                        </Button>
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.button}
                            disabled={canRedeem || balance.isEqualTo(0)}
                            variant="contained"
                            onClick={(): void => handleSubmit(TypeRedeem.withdraw)}
                        >
                            Withdraw GRO
                        </Button>
                    </Box>
                    <Button
                        fullWidth
                        color="secondary"
                        css={styles.button}
                        disabled={!canRedeem}
                        variant="contained"
                        onClick={(): void => handleSubmit(TypeRedeem.redeemGro)}
                    >
                        {!canRedeem && <LockIcon />}
                        Claim DURA
                    </Button>
                </Box>
            </Box>
            <RedeemAlloyModal
                isOpen={isOpen}
                typeRedeem={type}
                onClose={(): void => setIsOpen(false)}
            />
            <DuraDetailsModal
                isOpen={isOpenDetails}
                onCloseModal={(): void => setIsOpenDetails(false)}
            />
        </Box>
    );
}
