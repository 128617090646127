import React from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GroModal, GroModalHeader } from '../../../components/GroModal';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import { formatAsCurrency } from '../../utils/currency.helpers';
import {
    setLabsTransaction,
    setOpenAllowanceModal,
    setOpenIncreasedAllowanceModal,
} from '../store/labs.reducer';
import {
    selectClaimableAllowance,
    selectCurrentAllowance,
    selectOpenIncreasedAllowanceModal,
    selectRemainingAllowance,
} from '../store/labs.selectors';

export function GroAllowanceIncreased(): React.ReactElement {
    const dispatch = useDispatch();

    const isOpen = useAppSelector(selectOpenIncreasedAllowanceModal);

    function onClose(): void {
        dispatch(setOpenIncreasedAllowanceModal(false));
    }

    function openAllowance(): void {
        dispatch(setOpenAllowanceModal(true));
        dispatch(setOpenIncreasedAllowanceModal(false));
    }

    function openDeposit(): void {
        dispatch(setLabsTransaction(ExchangeDirection.investAvax));
        dispatch(setOpenIncreasedAllowanceModal(false));
    }

    const remainingAllowance = useAppSelector(selectRemainingAllowance);
    const currentAllowance = useAppSelector(selectCurrentAllowance);
    const newAllowance = useAppSelector(selectClaimableAllowance);

    const styles = {
        button: css`
            width: 173px;
            height: 48px;
        `,
    };

    return (
        <GroModal isOpen={isOpen} onClose={onClose}>
            <Box pb={5} px={5}>
                <GroModalHeader onClose={onClose}>
                    <Typography variant="h1">Allowance increased</Typography>
                </GroModalHeader>
                <Box mb={4} mt={2}>
                    <Typography color="text.secondary" variant="body2">
                        This strategy&apos;s allowance has increased. Do you want to request an
                        increase to your allowance?
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body2">Your remaining allowance</Typography>
                    <Typography variant="body2">{formatAsCurrency(remainingAllowance)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body2">Your current allowance</Typography>
                    <Typography variant="body2">
                        up to {formatAsCurrency(currentAllowance)}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">New claimable allowance</Typography>
                    <Typography variant="body2">up to {formatAsCurrency(newAllowance)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button
                        color="secondary"
                        css={styles.button}
                        variant="contained"
                        onClick={openAllowance}
                    >
                        Request increase
                    </Button>
                    <Button
                        color="secondary"
                        css={styles.button}
                        variant="outlined"
                        onClick={openDeposit}
                    >
                        Skip
                    </Button>
                </Box>
            </Box>
        </GroModal>
    );
}
