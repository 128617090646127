import React, { useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { useForceNetworkChange } from '../../unwind/hooks/useForceNetworkChange';
import { formatNumber } from '../../utils/currency.helpers';
import { selectWalletAccount } from '../../wallet/store/wallet.selectors';
import { ClaimableGro } from '../components/ClaimableGro';
import { LockedGro } from '../components/LockedGro';
import { TotalClaimed } from '../components/TotalClaimed';
import { VestingPosition } from '../components/VestingPosition';
import {
    selectPositionsAmount,
    selectTeamVestingLoading,
    selectTotalClaimableTeamVestingAmount,
    selectTotalLockedTeamVestingAmount,
    selectTotalWithdrawnTeamVestingAmount,
} from '../store/teamVesting.selectors';
import { updateTeamVestingValuesThunk } from '../store/thunks/updateTeamVestingValuesThunk';

export function TeamVesting(): React.ReactElement {
    const dispatch = useDispatch();
    const wallet = useAppSelector(selectWalletAccount);
    const positions = useAppSelector(selectPositionsAmount);
    const lockedAmount = useAppSelector(selectTotalLockedTeamVestingAmount);
    const vestedAmount = useAppSelector(selectTotalClaimableTeamVestingAmount);
    const withdrawnAmount = useAppSelector(selectTotalWithdrawnTeamVestingAmount);
    const isLoading = useAppSelector(selectTeamVestingLoading);

    useForceNetworkChange({ network: 'mainnet' });

    useEffect(() => {
        if (wallet) {
            void dispatch(updateTeamVestingValuesThunk(wallet));
        }
    }, [wallet, dispatch]);

    return (
        <Box alignItems="center" display="flex" flexDirection="column" mt={10}>
            <Box display="flex">
                <LockedGro
                    formattedLockedValue={formatNumber(lockedAmount)}
                    lattestLockedValue={lockedAmount}
                />
                <ClaimableGro
                    formattedLockedValue={formatNumber(vestedAmount)}
                    lattestLockedValue={vestedAmount}
                />
                <TotalClaimed
                    formattedLockedValue={formatNumber(withdrawnAmount)}
                    lattestLockedValue={withdrawnAmount}
                />
            </Box>
            {isLoading ? (
                <React.Fragment>
                    <Skeleton
                        height={97}
                        sx={{ borderRadius: '16px', mb: 2 }}
                        variant="rectangular"
                        width={1008}
                    />
                    <Skeleton
                        height={97}
                        sx={{ borderRadius: '16px', mb: 2 }}
                        variant="rectangular"
                        width={1008}
                    />
                </React.Fragment>
            ) : (
                positions.map((elem) => (
                    <VestingPosition
                        key={elem.id}
                        available={elem.available}
                        cliff={elem.cliff}
                        id={elem.id}
                        locked={elem.locked}
                        percentage={elem.percentage}
                        startTime={elem.startTime}
                        stopTime={elem.stopTime}
                        withdrawn={elem.withdrawn}
                    />
                ))
            )}
        </Box>
    );
}
