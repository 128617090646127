import { useDispatch } from 'react-redux';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import {
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
    showExchangeModal,
} from '../store/exchange.reducers';
import { initExchangeFlowsThunk } from '../store/thunks/initExchangeFlowsThunk';

type TpUseExchange = {
    onExchange: (direction: ExchangeDirection) => void;
};

export function useExchange(token: TOKENS.PWRD | TOKENS.GVT | TOKENS.GRO): TpUseExchange {
    const dispatch = useDispatch();

    function onExchange(direction: ExchangeDirection): void {
        dispatch(resetExchange());
        dispatch(setGrwthToken(token));
        dispatch(setExchangeDirection(direction));
        dispatch(initExchangeFlowsThunk());
        dispatch(showExchangeModal(true));
    }

    return {
        onExchange,
    };
}
