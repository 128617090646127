import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';

type InfoLineProps = {
    bold?: string;
    grey?: boolean;
    left: string;
    right: string;
};

export function InfoLine({ bold, grey, left, right }: InfoLineProps): React.ReactElement {
    const [state, setState] = useState<any>();

    const styles = {
        icon: css`
            font-size: 14px;
        `,
    };

    useEffect(() => {}, []);

    return (
        <Box display="flex" justifyContent="space-between" mb={2} sx={{ opacity: grey ? 0.4 : 1 }}>
            <Box alignItems="center" display="flex">
                <Typography mr={0.4} variant="body2Small">
                    {left}
                </Typography>
                {/* <InfoOutlinedIcon css={styles.icon} /> */}
            </Box>
            <Box display="flex">
                <Typography variant="body2Small">{right}</Typography>
                {bold && (
                    <Typography color="#FFC9C6" ml={0.5} variant="body2Small">
                        {bold}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
