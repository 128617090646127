/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { STABLE_TOKENS, StableTokenMainnet, Token, TOKENS } from '../../../constants';
import { getIndexFromToken } from '../../../lib/contract-info';
import { calculateDeposit, calculateWithdraw } from '../../../lib/web3/priceOracle';
import { addTokenDecimals, removeTokenDecimals } from '../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    setStablePriceLoading,
    updateLhsInvest,
    updateLhsWithdraw,
} from '../store/exchange.reducers';
import {
    selectExchangeDirection,
    selectExchangeToken,
    selectSlippage,
    selectWalletFrom,
} from '../store/exchange.selectors';

function useDebounce(
    value: BigNumber,
    token: StableTokenMainnet,
    delay: number,
): { dollars: BigNumber; tokens: BigNumber } {
    // State and setters for debounced value
    const exchangeDirection = useAppSelector(selectExchangeDirection);
    const dispatch = useDispatch();
    const tokenFrom = useAppSelector(selectWalletFrom);
    const tokenValue = Object.values(tokenFrom)[0];
    const tokenName = Object.keys(tokenFrom)[0] as Token;
    const [debouncedValue, setDebouncedValue] = useState(new BigNumber(0));
    const [stableTokenAmount, setStableTokenAmount] = useState(new BigNumber(0));
    const slippage = useAppSelector(selectSlippage);
    const exchangeToken = useAppSelector(selectExchangeToken);

    async function calculateOnDepositExchange(amount: BigNumber, index: number): Promise<void> {
        const result = await calculateDeposit(
            amount,
            index,
            slippage,
            exchangeToken === TOKENS.PWRD,
        );
        const withDecimals = addTokenDecimals(TOKENS.USD, result);
        setDebouncedValue(withDecimals);
        dispatch(updateLhsInvest({ price: withDecimals, token, value }));
        dispatch(setStablePriceLoading(false));
    }

    async function calculateOnWithdrawExchange(index: number): Promise<void> {
        const amount = removeTokenDecimals(tokenName, tokenValue);
        const { oracleAmount, tokenAmount } = await calculateWithdraw(
            amount,
            index,
            slippage,
            exchangeToken === TOKENS.PWRD,
        );
        const withDecimals = addTokenDecimals(TOKENS.USD, oracleAmount);
        const tokenAmountDecimals = addTokenDecimals(token, tokenAmount);
        setStableTokenAmount(tokenAmountDecimals);
        setDebouncedValue(withDecimals);
        dispatch(
            updateLhsWithdraw({
                token: tokenName,
                toToken: token,
                toTokenAmount: tokenAmountDecimals,
                value: tokenValue,
            }),
        );
        dispatch(setStablePriceLoading(false));
    }

    useEffect(() => {
        if (exchangeDirection !== ExchangeDirection.invest || !STABLE_TOKENS.includes(token))
            return (): void => {};
        dispatch(setStablePriceLoading(true));
        const handler = setTimeout(() => {
            const amountWithDecimals = removeTokenDecimals(token, value);
            calculateOnDepositExchange(amountWithDecimals, getIndexFromToken(token)).catch(
                (err) => {
                    // eslint-disable-next-line no-console
                    console.warn(err);
                },
            );
        }, delay);

        return (): void => {
            clearTimeout(handler);
            dispatch(setStablePriceLoading(false));
        };
    }, [value, delay]);

    useEffect(() => {
        if (exchangeDirection !== ExchangeDirection.withdraw) return (): void => {};
        dispatch(setStablePriceLoading(true));
        const handler = setTimeout(() => {
            calculateOnWithdrawExchange(
                getIndexFromToken(token),
                // eslint-disable-next-line no-console
            ).catch((err) => console.warn(err));
        }, delay);
        return (): void => {
            dispatch(setStablePriceLoading(false));
            clearTimeout(handler);
        };
    }, [tokenValue, delay]);

    return { dollars: debouncedValue, tokens: stableTokenAmount };
}

export function useCalculateStableCoinPrice(
    token: StableTokenMainnet,
    stableAmount = new BigNumber(0),
): { dollars: BigNumber; tokens: BigNumber } {
    const result = useDebounce(stableAmount, token, 1000);
    return result;
}
