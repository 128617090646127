/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { priceOracle } from '../contract-info';
import { PriceOracle } from './types/priceOracle';

let instance: PriceOracle;

export function priceOracleContract(): PriceOracle {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(priceOracle.abi, priceOracle.address) as any as PriceOracle;
    return instance;
}
