/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */

import { VaultAvaxNames } from '../../../../constants';
import { getVaultAdaptor } from '../../../../lib/contract-info';
import { getEstimatedTotalAssets } from '../../../../lib/web3/strategy';
import {
    getDepositLimit,
    getTotalVaultDebt,
    getUserAllowance,
    getVaultBalance,
    getVaultCredit,
    getVaultPricePerShare,
} from '../../../../lib/web3/vaultAdaptor';
import { fetchBalanceOf } from '../../../../lib/web3/wallet';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { getLabsToken } from '../labs.helper';
import { setLabsStatus, setTokenValues, TpSetTokenValues } from '../labs.reducer';
import { selectLabsVaultsNames } from '../labs.selectors';
import { labsDeprecated } from './labsBody';

async function getValuesForToken(
    name: VaultAvaxNames,
    wallet: string,
    address: string,
): Promise<TpSetTokenValues> {
    const token = getLabsToken(name);
    const price = await getVaultPricePerShare({ name });
    const deposited = await getVaultBalance({ address: wallet, name });
    const credit = await getVaultCredit({ name });
    const assets = await getEstimatedTotalAssets({ address });
    const debt = await getTotalVaultDebt({ name });
    const vaultBalance = await fetchBalanceOf(getVaultAdaptor(name).address, token);
    const currentAllowance = await getUserAllowance({ address: wallet, name });
    const depositLimit = await getDepositLimit({ name });

    return {
        assets,
        credit,
        currentAllowance,
        debt,
        deposited,
        depositLimit,
        name,
        price,
        token,
        vaultBalance: vaultBalance.toNumber().toFixed(),
    };
}

export const updateLabsValuesTHunk: AppActionThunk =
    (wallet: string, vaultName?: VaultAvaxNames) => async (dispatch, getState) => {
        try {
            dispatch(setLabsStatus({ status: Status.loading }));
            const vaultNames = selectLabsVaultsNames(getState());

            const vaults = vaultName ? [vaultName] : vaultNames;

            const promises = vaults.map(async (name) => {
                const address =
                    [...labsDeprecated].find((elem) => elem.name === name)?.strategies[0]
                        .address || '';
                dispatch(
                    setTokenValues(
                        await getValuesForToken(name as VaultAvaxNames, wallet, address),
                    ),
                );
            });

            await Promise.all(promises);

            dispatch(setLabsStatus({ status: Status.ready }));
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.warn('updateLabsValuesTHunk.error', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            dispatch(setLabsStatus({ error: error.message, status: Status.error }));
        }
    };
