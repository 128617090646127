/* eslint-disable func-style */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TokenIcon } from '../../../../components';
import { GroModalHeader } from '../../../../components/GroModal';
import { getTokenInfo } from '../../../../lib/contract-info';
import { tokenToUsd } from '../../../../lib/web3/convert';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { useAppSelector } from '../../../app/hooks';
import { formatAsCurrency, formatNumber } from '../../../utils/currency.helpers';
import { getTokenName } from '../../../utils/token.helpers';
import { usePercentValue } from '../../hooks/usePercentValue';
import { SlippageOption, useSlippageOptions } from '../../hooks/useSlippageOptions';
import { setSlippage } from '../../store/exchange.reducers';
import {
    selectExchangeConversions,
    selectExchangeToken,
    selectTokenTo,
} from '../../store/exchange.selectors';
import { ExchangeOptionSelect } from '../ExchangeOptionSelect';
import { ExchangeButton } from './ExchangeButton';
import { GroBoost } from './GroBoost';
import { TokenApyBase } from './TokenApyBase';

type TpStakeNow = {
    onBack: () => void;
    onClose: () => void;
    onNext: () => void;
};

export function StakeNow({ onBack, onClose, onNext }: TpStakeNow): React.ReactElement {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [customSlippage, setCustomSlippage] = usePercentValue('');
    const slippageOptions = useSlippageOptions();
    const [selectedSlippage, setSelectedSlippage] = useState(slippageOptions[1]);
    const tokenAmount = useAppSelector(selectTokenTo);
    const exchangeToken = useAppSelector(selectExchangeToken);
    const { displayName } = getTokenInfo(exchangeToken);
    const conversions = useAppSelector(selectExchangeConversions);
    const { isMobileDisplay } = useGetSizes(theme);
    const [stake, setStake] = useState(true);

    const handleSelectedSlippageChange = (slipObj: SlippageOption): void => {
        setSelectedSlippage(slipObj);

        if (slipObj.value === 'custom') {
            // fees/slippages in basis points!
            dispatch(setSlippage(new BigNumber(customSlippage).multipliedBy(100)));
            return;
        }

        // fees/slippages in basis points!
        dispatch(setSlippage(new BigNumber(slipObj.value).multipliedBy(100)));
    };

    // Allow numbers starting by '.' or ','
    // Any value > 100 will be converted to 100 / any value < 0 will be converted to 0
    const handleCustomSlippageChange = (value: string): void => {
        if (value) {
            if (value !== '.' && value !== ',') {
                let slip = parseFloat(value);
                if (slip > 100) {
                    slip = 100;
                } else if (slip < 0) {
                    slip = 0;
                }
                setCustomSlippage(value);

                // fees/slippages in basis points!
                dispatch(setSlippage(new BigNumber(slip).multipliedBy(100)));
            } else {
                setCustomSlippage('0');
                dispatch(setSlippage(new BigNumber(0)));
            }
        } else {
            setCustomSlippage('');
            dispatch(setSlippage(new BigNumber(0)));
        }
    };

    const styles = {
        actionButton: css`
            height: 48px;
        `,
        checkbox: css`
            margin-right: 6px;
            margin-left: 8px;
            width: 16px;
            height: 16px;
            margin-top: -2px;
        `,
        link: css`
            color: ${theme.palette.grey[200]};
            text-decoration: none;
        `,
        slippage: css`
            & span {
                font-size: 16px;
                color: ${theme.palette.grey[200]};
            }
        `,
        stakeWrapper: css`
            background: rgba(255, 255, 255, 0.05);
            border-radius: 4px;
            cursor: pointer;
        `,
        tokenAmount: css`
            border: 1px solid ${theme.palette.grey[400]};
            border-radius: 8px;
            background: ${theme.palette.grey[800]};
        `,
        tokenData: css`
            background: ${theme.palette.info.superDark};
            border-radius: 8px;
        `,
    };
    return (
        <Box>
            <Box px={3}>
                <GroModalHeader allowBack onBack={onBack} onClose={onClose}>
                    <Typography mt={1} variant="h1">
                        Deposit into {displayName}
                    </Typography>
                </GroModalHeader>
                <Box css={styles.tokenData} mt={4}>
                    <Box
                        alignItems="flex-start"
                        css={styles.tokenAmount}
                        display="flex"
                        justifyContent="space-between"
                        p={2}
                    >
                        <Box>
                            <Typography mb={1} variant={isMobileDisplay ? 'h3' : 'h2'}>
                                {formatAsCurrency(
                                    tokenToUsd(exchangeToken, tokenAmount, conversions),
                                )}
                            </Typography>
                            <Typography
                                color="text.tertiary"
                                variant={isMobileDisplay ? 'body2Small' : 'body2'}
                            >
                                {getTokenName(exchangeToken)} {formatNumber(tokenAmount)}
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex">
                            <TokenIcon
                                colored
                                css={css`
                                    width: 20px;
                                    height: 20px;
                                `}
                                token={exchangeToken}
                            />
                            <Typography ml={0.5} variant={isMobileDisplay ? 'h3' : 'h2'}>
                                {displayName}
                            </Typography>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <TokenApyBase token={exchangeToken} />
                        <Box mt={1.5}>
                            <GroBoost stake={stake} />
                        </Box>
                    </Box>
                </Box>
                <Box css={styles.stakeWrapper} mt={4} p={1} onClick={(): void => setStake(!stake)}>
                    <Typography
                        color={stake ? theme.palette.common.white : theme.palette.grey[200]}
                        variant="h3"
                    >
                        <Checkbox checked={stake} color="secondary" css={styles.checkbox} />
                        Stake {displayName} tokens
                    </Typography>
                </Box>
                <a
                    css={styles.link}
                    href="https://docs.gro.xyz/gro-docs/gro-products/gro-tokenomics#distribution-community-incentives"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    title="gro rewards"
                >
                    <Typography color="text.tertiary" mt={1} variant="body2Small">
                        Learn more about $GRO rewards
                        <OpenInNewOutlinedIcon
                            sx={{ fontSize: '14px', marginBottom: '-2px', marginLeft: '4px' }}
                        />
                    </Typography>
                </a>
                <ExchangeButton stake={stake} text="Deposit" onBack={onBack} onNext={onNext} />
                {/* <Tooltip
                    disableInteractive
                    title={
                        <ToolTipText>
                            When withdrawing assets, {fee}% is deducted from the amount and given
                            pro-rata to remaining asset providers.
                        </ToolTipText>
                    }
                >
                    <Box mt={2} pb={1} play="flex">
                        <Typography color="text.tertiary" mr={0.5} variant="body2Small">
                            HODL contribution:
                        </Typography>
                        <Typography color="text.tertiary" mr={0.5} variant="body2Small">
                            -0.5% on withdrawal
                        </Typography>
                        <GroInfoIcon height={14.8} stroke={theme.palette.grey[300]} width={14.8} />
                    </Box>
                </Tooltip> */}
                <Box
                    css={styles.slippage}
                    display="flex"
                    justifyContent="space-between"
                    mb={4}
                    mt={2}
                >
                    <ExchangeOptionSelect
                        isInline
                        customValue={customSlippage.toString()}
                        isSearchable={false}
                        labelText="Slippage"
                        options={slippageOptions}
                        value={selectedSlippage}
                        onChange={handleSelectedSlippageChange}
                        onChangeCustomValue={handleCustomSlippageChange}
                    />
                </Box>
            </Box>
        </Box>
    );
}
