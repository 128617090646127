/* eslint-disable complexity */

import BigNumber from 'bignumber.js';
import {
    GROWTH_TOKENS,
    MINIMUM_INVESTING_VISIBLE_AMOUNT,
    POOL_TOKENS,
    STABLE_TOKENS,
    Token,
    TokenObject,
    TOKENS,
} from '../../constants';
import { getIndexFromToken } from '../../lib/contract-info';

export function isStableToken(token: Token | string): boolean {
    return STABLE_TOKENS.map((t) => t.toString()).indexOf(token.toString()) !== -1;
}

export function isGrwthToken(token: Token | string): boolean {
    return GROWTH_TOKENS.map((t) => t.toString()).indexOf(token.toString()) !== -1;
}

export function isPoolToken(token: Token | string): boolean {
    return POOL_TOKENS.map((t) => t.toString()).indexOf(token.toString()) !== -1;
}

export function sortStableTtokensByIndex(a: Token, b: Token): number {
    return getIndexFromToken(a) - getIndexFromToken(b);
}

// eslint-disable-next-line func-style
export const sortStableTtokensByAmountAndIndex =
    (balances: TokenObject) =>
    (a: Token, b: Token): number => {
        if ((balances[a] || new BigNumber(0)).isLessThan(MINIMUM_INVESTING_VISIBLE_AMOUNT)) {
            return 1;
        }
        if ((balances[b] || new BigNumber(0)).isLessThan(MINIMUM_INVESTING_VISIBLE_AMOUNT)) {
            return -1;
        }
        return getIndexFromToken(a) - getIndexFromToken(b);
    };

/**
 * Token objects to BigNumber array sorted by its contract info.
 * Only valid for stable coins tokens
 * @param tokens
 * @returns
 */
export function tokenObjectToArray(tokens: TokenObject): BigNumber[] {
    return STABLE_TOKENS.map((token) => tokens[token] || new BigNumber(0));
}

export function getTokenColors(token: TOKENS): {
    background?: string;
    border?: string;
} {
    switch (token) {
        case TOKENS.WETH:
            return {
                background: 'linear-gradient(135deg, #F9FAFB 0%, #F9FAFB 100%);',
                border: '#F9FAFB',
            };
        case TOKENS.GRO:
            return {
                background: 'linear-gradient(135deg, #C376FF 0%, #A939FF 100%);',
                border: '#DEB8FC',
            };
        case TOKENS.PWRD:
            return {
                background: 'linear-gradient(135deg, #3DB9FF 0%, #0095E9 100%);',
                border: '#8AD5FF',
            };
        case TOKENS.GVT:
            return {
                background: 'linear-gradient(135deg, #FA8B84 0%, #E75E55 100%);',
                border: '#FEAEA8',
            };
        case TOKENS.DAI:
            return {
                background: 'linear-gradient(135deg, #FFC251 0%, #F09C01 100%);',
                border: '#FFD589',
            };
        case TOKENS.USDT:
            return {
                background: 'linear-gradient(135deg, #5CBDA3 0%, #308D74 100%);',
                border: '#8BD4C0;',
            };
        case TOKENS.USDC:
            return {
                background: 'linear-gradient(135deg, #368AE6 0%, #1F65B1 100%);',
                border: '#8BC0FB',
            };
        case TOKENS.UNI_USDC_50_50:
        case TOKENS.PWRD3CRV:
            return {
                background: 'linear-gradient(135deg, #5E7485 0%, #142C3F 100%);',
                border: '#5E7485',
            };
        default:
            return {
                background: 'linear-gradient(135deg, #C376FF 0%, #A939FF 100%);',
                border: '#DEB8FC',
            };
    }
}

export function getTokenName(token: TOKENS, isStaked?: boolean): string {
    switch (token) {
        case TOKENS.DAI:
            return 'DAI';
        case TOKENS.DAIE:
            return 'DAI.e';
        case TOKENS.USDT:
            return 'USDT';
        case TOKENS.USDTE:
            return 'USDT.e';
        case TOKENS.USDC:
            return 'USDC';
        case TOKENS.USDCE:
            return 'USDC.e';
        case TOKENS.CUSDC:
            return 'CUSDC';
        case TOKENS.DURA:
            return 'DURA';
        case TOKENS.GVT:
            return 'Vault token';
        case TOKENS.GVT_SINGLE_SIDED:
            return 'Staked Vault token';
        case TOKENS.PWRD:
            return 'PWRD';
        case TOKENS.PWRD_SINGLE_SIDED:
            return 'Staked PWRD';
        case TOKENS.GRO:
            return 'GRO';
        case TOKENS.GRO_SINGLE_SIDED:
            return 'Staked GRO';
        case TOKENS.CRV:
            return '3CRV';
        case TOKENS.PWRD3CRV:
        case TOKENS.UNI_USDC_50_50:
        case TOKENS.UNI_50_50:
        case TOKENS.WETH:
            return isStaked ? 'Staked Pool token' : 'Pool token';
        default:
            return isStaked ? 'Staked Pool token' : 'Pool token';
    }
}
