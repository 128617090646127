/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Menu, Typography, useTheme } from '@mui/material';
import { Token, TOKENS } from '../../../constants';
import { getTokenInfo } from '../../../lib/contract-info';
import { TokensExchange } from '../../../modules/exchange/exchange.types';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { TokenIcon } from '../../icons';

type TpDropdownSelector = {
    onSelectToken: (val: TokensExchange) => void;
    token: Token;
    tokensNotAdded: TokensExchange[];
};

export function DropdownSelector({
    onSelectToken,
    token,
    tokensNotAdded,
}: TpDropdownSelector): React.ReactElement {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const theme = useTheme();
    const { isMobileDisplay } = useGetSizes(theme);

    const styles = {
        pointer: css`
            cursor: pointer;
            margin-right: -2px;
        `,
    };

    function onToggle(e: any): void {
        setAnchorEl(open ? null : e.currentTarget);
    }

    const { displayName } = getTokenInfo(token || TOKENS.USDC);

    const iconSize = isMobileDisplay ? 16 : 20;
    const variant = isMobileDisplay ? 'h3' : 'h2';

    return (
        <Box
            alignItems="center"
            aria-controls="stable-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            css={styles.pointer}
            display="flex"
            onClick={onToggle}
        >
            <TokenIcon colored height={iconSize} token={token} width={iconSize} />
            <Typography ml={0.5} variant={variant}>
                {displayName}
            </Typography>
            {tokensNotAdded.length > 0 && (
                <React.Fragment>
                    <KeyboardArrowDownIcon />
                    <Menu
                        disableScrollLock
                        anchorEl={anchorEl}
                        id="stable-menu"
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        open={open}
                        onClose={onToggle}
                    >
                        {tokensNotAdded.map((tk, i) => (
                            <Box
                                key={tk}
                                css={styles.pointer}
                                display="flex"
                                pt={i !== 2 ? i : 1}
                                px={1}
                                onClick={(): void => onSelectToken(tk)}
                            >
                                <TokenIcon colored height={iconSize} token={tk} width={iconSize} />
                                <Typography ml={0.5} variant={variant}>
                                    {getTokenInfo(tk).displayName}
                                </Typography>
                            </Box>
                        ))}
                    </Menu>
                </React.Fragment>
            )}
        </Box>
    );
}
