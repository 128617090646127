/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { investorPositionsContract } from '../abis/investorPositionsContract';
import { convertToBN } from './web3.helpers';

export async function fetchInvestorPositions(
    wallet: string,
): Promise<{ startTime: number; total: BigNumber; withdrawn: BigNumber }> {
    const { startTime, total, withdrawn } = await investorPositionsContract()
        .methods.investorPositions(wallet)
        .call();
    return {
        startTime: parseInt(startTime, 10),
        total: new BigNumber(total),
        withdrawn: new BigNumber(withdrawn),
    };
}

export async function fetchInvestedPositionVestedBalance(
    wallet: string,
): Promise<{ available: BigNumber; vested: BigNumber }> {
    const { available, vested } = await investorPositionsContract()
        .methods.vestedBalance(wallet)
        .call();
    return { available: new BigNumber(available), vested: new BigNumber(vested) };
}

export async function fetchInvestorPositionBalance(wallet: string): Promise<BigNumber> {
    const balance = await investorPositionsContract().methods.totalBalance(wallet).call();
    return new BigNumber(balance);
}

export function claimInvestorPosition(amount: BigNumber) {
    return investorPositionsContract().methods.claim(convertToBN(amount));
}
