/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { ReactComponent as ConfirmedIcon } from '../../assets/confirmed.svg';
import { ReactComponent as SubmittedIcon } from '../../assets/submitted.svg';
import { ReactComponent as WalletAlertIcon } from '../../assets/wallet-alert.svg';

export function StepAnimation({
    allApproved,
    allConfirmed,
    hasErrors,
}: {
    allApproved: boolean;
    allConfirmed: boolean;
    hasErrors: boolean;
}): React.ReactElement {
    const theme = useTheme();
    const styles = {
        icon: css`
            font-size: 45px;
            color: rgba(121, 165, 251, 1);
        `,
        iconPosition: css`
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        `,
        iconWrapper: css`
            background: ${theme.palette.grey[700]};
            border-radius: 50%;
            position: relative;
            height: 104px;
            width: 104px;
        `,
        progress: css`
            animation-duration: 2.4s;
        `,
    };

    const text = useMemo(() => {
        if (hasErrors) return 'Transaction failed, please try again';
        if (allConfirmed) return 'Transactions complete';
        if (allApproved) return 'All transactions submitted';
        return 'Approve transactions in your wallet';
    }, [hasErrors, allApproved, allConfirmed]);

    const icon = useMemo(() => {
        if (hasErrors) return <WalletAlertIcon css={styles.iconPosition} />;
        if (allConfirmed) return <ConfirmedIcon css={styles.iconPosition} />;
        if (allApproved) return <SubmittedIcon css={styles.iconPosition} />;
        return <AccountBalanceWalletIcon css={[styles.icon, styles.iconPosition]} />;
    }, [hasErrors, allApproved, allConfirmed]);

    return (
        <Box>
            <Box display="center" justifyContent="center">
                <Box alignItems="center" css={styles.iconWrapper} display="flex" p={1.5}>
                    {!hasErrors && !allConfirmed && (
                        <CircularProgress
                            css={styles.progress}
                            size={80}
                            sx={{
                                color: 'rgba(121, 165, 251, 1)',
                            }}
                            thickness={1.5}
                        />
                    )}
                    {icon}
                </Box>
            </Box>

            <Typography color="text.tertiary" mt={1.5} textAlign="center" variant="body2">
                {text}
            </Typography>
        </Box>
    );
}
