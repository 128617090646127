/* eslint-disable @typescript-eslint/no-misused-promises */

import React from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { useChangeNetwork } from '../../wallet/hooks/useChangeNetwork';
import { useFormatedAddress } from '../../wallet/hooks/useFormattedAddress';
import { setConnectWalletModalOpened } from '../../wallet/store/wallet.reducers';
import { selectWalletAccount } from '../../wallet/store/wallet.selectors';

export function Disconnect(): React.ReactElement {
    const wallet = useAppSelector(selectWalletAccount);
    const dispatch = useDispatch();

    const formattedAddress = useFormatedAddress(wallet);

    const { onClickDisconnect } = useChangeNetwork({});

    const styles = {
        button: css`
            height: 36px;
            width: 88px;
            font-size: 12px;
        `,
        link: css`
            color: white;
            text-decoration: none;
        `,
        wrap: css`
            position: absolute;
            right: 32px;
            top: 38px;
            display: flex;
            align-items: center;
        `,
    };

    async function onDisconnect(): Promise<void> {
        await onClickDisconnect();
    }

    function onClickConnect(): void {
        dispatch(setConnectWalletModalOpened(true));
    }

    return (
        <Box css={styles.wrap}>
            {wallet ? (
                <Box alignItems="center" display="flex">
                    <Typography mr={2.5}>{formattedAddress}</Typography>
                    <Button
                        color="secondary"
                        css={styles.button}
                        variant="outlined"
                        onClick={onDisconnect}
                    >
                        Disconnect
                    </Button>
                </Box>
            ) : (
                <Button
                    color="secondary"
                    css={styles.button}
                    variant="outlined"
                    onClick={onClickConnect}
                >
                    Connect
                </Button>
            )}
            <Box ml={1.5}>
                <a
                    css={styles.link}
                    href="https://medium.com/gro-protocol/beyond-gro-dao-winding-down-f078dd85f9e4"
                    rel="noreferrer"
                    target="_blank"
                >
                    <Typography fontSize="12px">FAQs</Typography>
                </a>
            </Box>
        </Box>
    );
}
