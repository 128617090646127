import BigNumber from 'bignumber.js';
import { GrowthToken, PoolToken } from '../../constants';

export type AirdropItem = {
    amount: BigNumber;
    amount_to_claim?: string;
    claimed: boolean;
    display_name: string;
    expired?: boolean;
    expiry_ts?: number;
    hash: string[];
    merkle_root_index?: string;
    name: string;
    participated: boolean;
    pool?: string;
    pool_display_name?: string;
    proofs?: string[];
    timestamp: BigNumber;
    token?: PoolToken | GrowthToken;
    unlocked: boolean;
    url?: string;
    variant?: {
        background?: string;
        border?: string;
    };
    vesting?: boolean;
};

export const emptyItem = {
    amount: new BigNumber(0),
    amount_to_claim: '',
    claimed: false,
    display_name: '',
    expired: false,
    hash: [],
    merkle_root_index: '',
    name: '',
    participated: false,
    pool: '',
    pool_display_name: '',
    proofs: [],
    timestamp: new BigNumber(0),
    token: undefined,
    unlocked: false,
    url: '',
    variant: {
        background: '',
        border: '',
    },
    vesting: false,
};
