import React from 'react';
import { BasicAlert } from './BasicAlert';

export function LabsStatusAlert(): React.ReactElement | null {
    return (
        <BasicAlert
            text="Labs strategies are paused while strategy performance is being evaluated. There is no set timeline for resuming Labs activities yet. Deposits and withdrawals are not affected. Vault & PWRD are still actively generating yield."
            title="Labs status"
        />
    );
}
