import React from 'react';
import { Box, Typography } from '@mui/material';
import { Token, TOKENS } from '../../../../constants';
import { blue, red } from '../../../../theme/palette';
import { useGroStats } from '../../../stats/hooks/useGroStats';
import { formatPct } from '../../../utils/percentage.helpers';
import { ReactComponent as GvtSquare } from '../../assets/gvt-square.svg';
import { ReactComponent as PwrdSquare } from '../../assets/pwrd-square.svg';

export function TokenApyBase({ token }: { token: Token }): React.ReactElement {
    const { apyDefault } = useGroStats();
    const value = token === TOKENS.GVT ? apyDefault.gvt : apyDefault.pwrd;

    return (
        <Box display="flex">
            <Box mr={1.5}>{token === TOKENS.GVT ? <GvtSquare /> : <PwrdSquare />}</Box>
            <Box>
                <Typography color={token === TOKENS.GVT ? red[50] : blue[50]} variant="h3">
                    {value.isLessThan(0) ? '-' : ''}
                    {formatPct(value)} APY (base)
                </Typography>
                <Box alignItems="center" display="flex">
                    <Typography color="text.tertiary" mr={0.5} variant="body2Small">
                        When you deposit
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
