import React from 'react';
import { Skeleton } from '@mui/material';

export type PpStringOrSkeleton = {
    children: React.ReactNode;
    fallback?: React.ReactNode;
    loading?: boolean;
    loadingFallback?: React.ReactElement;
};

export function TextOrSkeleton({
    children,
    fallback = '\u00A0',
    loading = false,
    loadingFallback,
}: PpStringOrSkeleton): React.ReactElement {
    if (loading) {
        return loadingFallback || <Skeleton variant="text" />;
    }

    if (children !== 0 && !children) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <React.Fragment>{fallback}</React.Fragment>;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{children}</React.Fragment>;
}
