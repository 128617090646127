/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { PoolToken } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { claimAirdrop } from '../../../../lib/web3/airdrops';
import { claimAllowance } from '../../../../lib/web3/bouncer';
import { claimVestingBonus } from '../../../../lib/web3/groBonus';
import { claimPoolReward, multiClaimRewards } from '../../../../lib/web3/pools';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { selectExchangeState } from '../../../exchange/store/exchange.selectors';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { selectLabsToken, selectMerkleProof } from '../../../labs/store/labs.selectors';
import {
    selectAirdropToClaim,
    selectClaimToWallet,
    selectRewardsPid,
} from '../../../rewards/store/rewards.selectors';
import { updateGROValuesThunk } from '../../../rewards/store/thunks/updateGROValuesThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import { TransactionStatus } from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';

export const executeClaimTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());

        const { direction } = selectCurrentSessionTransaction(tid)(getState());
        const exchange = selectExchangeState(getState());

        const token = exchange.token as PoolToken;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        let method: NonPayableTransactionObject<any>;

        const pids = selectRewardsPid(getState());

        const walletClaim = selectClaimToWallet(getState());
        const airdrop = selectAirdropToClaim(getState());
        const name = selectLabsToken(getState());
        const merkleProof = selectMerkleProof(getState());

        switch (direction) {
            case ExchangeDirection.claimBonus:
                method = claimVestingBonus(walletClaim);
                break;
            case ExchangeDirection.claimAirdrop:
                method = claimAirdrop({
                    amount: airdrop?.amount_to_claim,
                    merkleProof: airdrop?.proofs,
                    trancheId: airdrop?.merkle_root_index,
                    walletClaim,
                });
                break;
            case ExchangeDirection.claimAllowance:
                method = claimAllowance({
                    amount: exchange.tokens.from[token],
                    merkleProof,
                    name,
                });
                break;
            case ExchangeDirection.claimReward:
                if (pids.length === 1) {
                    method = claimPoolReward(pids[0], walletClaim);
                } else {
                    method = multiClaimRewards(pids, walletClaim);
                }
                break;
            default:
                method = multiClaimRewards(pids, walletClaim);
                break;
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
