/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable func-style */
import BigNumber from 'bignumber.js';
import { Token } from '../../../../constants';
import { getAllTokens } from '../../../../lib/contract-info';
import { fetchTokenToUsdConversionRates } from '../../../../lib/web3/convert';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
// import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { refreshBalancesThunk } from '../../../wallet/store/thunks/refreshBalancesThunk';
import { setConversionRate, setExchangeStatus } from '../exchange.reducers';
import { calculateExchangeLimitsThunk } from './calculateExchangeLimitsThunk';

/**
 * Initialize exchange.
 *
 * Ideally this should be called each time an exchange page is loaded or reloaded
 */
export const initExchangeFlowsThunk: AppActionThunk =
    () =>
    async (dispatch, getState): Promise<void> => {
        try {
            const previousExchangeStatus = getState().exchange.status;

            dispatch(setExchangeStatus({ status: Status.loading }));

            // If the wallet is still not initialized wait
            if (getState().wallet.status !== Status.ready) {
                dispatch(setExchangeStatus({ status: previousExchangeStatus }));
                return;
            }

            // Wait for new balances to get loaded
            await dispatch(refreshBalancesThunk());

            // Call getState() now to fetch the updated token balances
            const { wallet } = getState();

            // 0. Init conversions rates (based on max amounts in wallet)
            const promises = getAllTokens()
                .filter(
                    (token) => wallet.tokens[token], // && wallet.tokens[token] !== 0,
                )
                .map((token) =>
                    fetchTokenToUsdConversionRates(token).then(
                        (usd) => [token, usd] as [Token, BigNumber],
                    ),
                );

            await Promise.all(promises).then((conversions) => {
                conversions.forEach(([token, value]) =>
                    dispatch(setConversionRate({ token, value })),
                );
            });

            // We wait until limits are calculated until changing the status
            await dispatch(calculateExchangeLimitsThunk(true));

            dispatch(setExchangeStatus({ status: Status.ready }));
        } catch (error: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setExchangeStatus(error.message);
        }
    };
