import BoltIcon from '@mui/icons-material/Bolt';
import GroupsIcon from '@mui/icons-material/Groups';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import { TpBreadcrumbRoutes } from '../../components';
/**
 * Keep routes as a separate export (i.e. not from index),
 * so that we keep the lazy loading of the module
 */

const base = '/governance';
const snapshot = '/snapshot';
const forum = '/forum';

const votingUrl = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/iu.test(
    navigator.userAgent,
)
    ? 'https://snapshot.org/#/gro.xyz'
    : 'https://vote.gro.xyz/';

export const routes: TpBreadcrumbRoutes = {
    base: {
        breadcrumb: 'Governance',
        children: [
            {
                breadcrumb: 'Voting',
                href: votingUrl,
                Icon: BoltIcon,
                path: snapshot,
            },
            {
                breadcrumb: 'Forum',
                href: 'https://community.gro.xyz/',
                Icon: GroupsIcon,
                path: forum,
            },
        ],
        Icon: ThumbsUpDownIcon,
        path: base,
    },
};
