import React, { useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { TransactionStatus } from '../../store/transactions.store';

export function TransactionStatusDisplay({
    status,
}: {
    status: TransactionStatus;
}): React.ReactElement {
    const theme = useTheme();
    const text = useMemo(() => {
        switch (status) {
            case TransactionStatus.confirmed:
                return 'Confirmed';
            case TransactionStatus.error:
                return 'Failed';
            default:
                return 'Pending';
        }
    }, [status]);

    const color = useMemo(() => {
        if (
            [
                TransactionStatus.pendingApproval,
                TransactionStatus.pendingConfirmation,
                TransactionStatus.pendingMmApproval,
                TransactionStatus.error,
                TransactionStatus.timeout,
            ].includes(status)
        ) {
            return 'text.primary';
        }
        return 'text.tertiary';
    }, [status]);

    const icon = useMemo(() => {
        switch (status) {
            case TransactionStatus.confirmed:
                return (
                    <DoneIcon
                        height={12}
                        sx={{ color: theme.palette.success.light, fontSize: '12px' }}
                    />
                );
            case TransactionStatus.error:
                return (
                    <PriorityHighIcon
                        height={12}
                        sx={{ color: theme.palette.error.light, fontSize: '14px' }}
                    />
                );
            default:
                return (
                    <CircularProgress
                        color="primary"
                        size={12}
                        sx={{ animationDuration: '2.4s', color: 'rgba(121, 165, 251, 1)' }}
                    />
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <Box alignItems="center" display="flex">
            <Typography color={color} mr={0.5} variant="h3">
                {text}
            </Typography>
            {icon}
        </Box>
    );
}
