/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { useOutsideClick } from '../../modules/app/hooks';
import { GroChevronIcon } from '../icons';

export function PercentInput({
    dropdownBackground,
    dropdownColor,
    fraction,
    onChange,
    setIsInputFocused,
}: {
    dropdownBackground: string;
    dropdownColor: string;
    fraction: number;
    onChange: (val: number) => void;
    setIsInputFocused: (val: boolean) => void;
}): React.ReactElement {
    const percent = fraction * 100;
    const options = [
        { label: '0%', value: 0 },
        { label: '25%', value: 0.25 },
        { label: '50%', value: 0.5 },
        { label: '75%', value: 0.75 },
        { label: '100%', value: 1 },
    ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef(null);

    useOutsideClick(ref, () => {
        setIsMenuOpen(false);
    });

    function handleMenuClick(): void {
        setIsMenuOpen(!isMenuOpen);
    }

    function handleMenuOptionClick(value: number): void {
        onChange(value);
        setIsMenuOpen(false);
        setIsInputFocused(false);
    }

    const styles = {
        menu: css`
            border-radius: 5px;
            opacity: 0.95;
            padding: 5px 0;
            position: absolute;
            color: 'inherit';
            z-index: 1000;
        `,
        option: css`
            background-color: ${dropdownBackground};
            color: ${dropdownColor};
            padding: 4px;
            width: 59px;
        `,
        selector: css`
            align-items: center;
            color: inherit;
            display: flex;
        `,
        wrapper: css`
            cursor: pointer;
        `,
    };

    return (
        <Box ref={ref} css={styles.wrapper}>
            <Box css={styles.selector} onClick={handleMenuClick}>
                <Typography data-testid="percent-input-title" variant="h3">{`${
                    percent > 100 ? '>100' : percent.toFixed(0)
                }%`}</Typography>
                <GroChevronIcon rotate={isMenuOpen ? 0 : 180} />
            </Box>
            {isMenuOpen && (
                <Box css={styles.menu}>
                    {options.map((option) => (
                        <Box
                            key={option.label}
                            css={styles.option}
                            data-testid={option.label}
                            onClick={(): void => handleMenuOptionClick(option.value)}
                        >
                            <Typography variant="navSub">{option.label}</Typography>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
}
