/* eslint-disable no-nested-ternary */

import React from 'react';
import { Box } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../../modules/app/app.types';
import { trackGoal } from '../../../modules/app/fathom.helpers';
import { useAppSelector } from '../../../modules/app/hooks';
import { useExchange } from '../../../modules/exchange/hooks/useExchange';
import { selectStakedToken } from '../../../modules/wallet/store/wallet.selectors';
import { ButtonTypes, TxnButton } from '../../buttons';

const FATHOM_IDS = {
    [TOKENS.PWRD]: 'XCRZEI0K',
    [TOKENS.GVT]: 'UGHYTC7K',
    [TOKENS.GRO]: '',
};

export function CardButton({
    balance,
    isLabs,
    isPools,
    token,
}: {
    balance: BigNumber;
    isLabs?: boolean;
    isPools?: boolean;
    token: TOKENS.GRO | TOKENS.PWRD | TOKENS.GVT;
}): React.ReactElement {
    const history = useHistory();
    const staked = useAppSelector(selectStakedToken(token));

    const { onExchange } = useExchange(token);

    function navigateToPage(): void {
        history.push(isPools ? '/pools' : isLabs ? '/labs' : '/rewards');
    }

    function onDeposit(): void {
        onExchange(ExchangeDirection.invest);
        trackGoal(FATHOM_IDS[token]);
    }

    return token !== TOKENS.GRO ? (
        <Box display="flex">
            <Box flex="1">
                <TxnButton type={ButtonTypes.deposit} onClick={onDeposit} />
            </Box>
            {!balance.plus(staked).isEqualTo(0) && (
                <Box flex="1" ml={1}>
                    <TxnButton
                        type={ButtonTypes.withdraw}
                        onClick={(): void => onExchange(ExchangeDirection.withdraw)}
                    />
                </Box>
            )}
        </Box>
    ) : (
        <TxnButton
            type={isPools ? ButtonTypes.pools : isLabs ? ButtonTypes.labs : ButtonTypes.rewards}
            onClick={navigateToPage}
        />
    );
}

export const CardButtons = React.memo(CardButton);
