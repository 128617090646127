/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TransactionCard } from '../../../../../components';
import { TOKENS } from '../../../../../constants';
import { ExchangeDirection } from '../../../../app/app.types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectStakedToken, selectWalletTokens } from '../../../../wallet/store/wallet.selectors';
import { setUnstakeAmount, updateLhsWithdraw } from '../../../store/exchange.reducers';
import {
    selectExchangeConversions,
    selectExchangeDirection,
    selectExchangeLimits,
    selectExhangeVolatilityStatus,
    selectIsExchangeLoading,
    selectUnstakeAmount,
    selectWalletFrom,
} from '../../../store/exchange.selectors';
import { LoadingCard } from '../LoadingCard';

export function ExchangeWithdraw({
    grwthToken,
    isOpen,
}: {
    grwthToken: TOKENS.GVT | TOKENS.PWRD;
    isOpen: boolean;
}): React.ReactElement {
    const dispatch = useAppDispatch();
    const balance = useAppSelector(selectWalletTokens)[grwthToken];
    const staked = useAppSelector(selectStakedToken(grwthToken));
    const walletFrom = useAppSelector(selectWalletFrom);
    const unstakedAmount = useAppSelector(selectUnstakeAmount);
    const isExchangeLoading = useAppSelector(selectIsExchangeLoading);
    const [amountStaked, setAmountStaked] = useState(new BigNumber(0));
    const [amountWallet, setAmountWallet] = useState(balance || new BigNumber(0));
    const direction = useAppSelector(selectExchangeDirection);
    const conversions = useAppSelector(selectExchangeConversions);
    const limits = useAppSelector(selectExchangeLimits);
    const volatile = useAppSelector(selectExhangeVolatilityStatus);

    const theme = useTheme();

    const styles = {
        wrapper: css`
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background: ${volatile || direction === ExchangeDirection.invest
                ? theme.palette.info.dark
                : limits.limitReached
                ? '#0C2436'
                : 'transparent'};
        `,
    };

    const totalAmount = useMemo(
        () => amountWallet.plus(amountStaked),
        [amountStaked, amountWallet],
    );

    const handleChangeWithdrawGrowth = useCallback(
        (value: BigNumber): void => {
            dispatch(
                updateLhsWithdraw({
                    token: grwthToken,
                    toToken: TOKENS.USDC,
                    toTokenAmount: new BigNumber(0),
                    value,
                }),
            );
        },
        [dispatch, grwthToken],
    );

    useEffect(() => {
        handleChangeWithdrawGrowth(totalAmount);
    }, [totalAmount, handleChangeWithdrawGrowth]);

    useEffect(() => {
        if (!isExchangeLoading) {
            handleChangeWithdrawGrowth(totalAmount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExchangeLoading]);

    useEffect(() => {
        dispatch(setUnstakeAmount(amountStaked));
    }, [amountStaked, dispatch]);

    useEffect(() => {
        if (isOpen) {
            if (new BigNumber(unstakedAmount).isZero()) {
                setAmountStaked(staked);
            }
            if (new BigNumber(walletFrom[grwthToken] || 0).isZero()) {
                setAmountWallet(balance || new BigNumber(0));
            }
            handleChangeWithdrawGrowth(totalAmount);
        }
    }, [isOpen]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography color="text.tertiary">From</Typography>
            </Box>
            {isExchangeLoading ? <LoadingCard /> : null}
            <Box css={styles.wrapper}>
                {(balance?.isGreaterThan(0) || !staked.isGreaterThan(0)) && !isExchangeLoading ? (
                    <TransactionCard
                        isEditable
                        selected
                        showIcon
                        amount={amountWallet}
                        balance={balance || new BigNumber(0)}
                        balanceToDollars={
                            grwthToken === TOKENS.GVT
                                ? amountWallet
                                : amountWallet.multipliedBy(
                                      new BigNumber(conversions[grwthToken] || 0),
                                  )
                        }
                        text={grwthToken === TOKENS.GVT ? 'Vault' : 'PWRD'}
                        token={grwthToken}
                        onChange={setAmountWallet}
                    />
                ) : null}
                <Box mt={staked.isGreaterThan(0) && balance?.isGreaterThan(0) ? 1 : 0}>
                    {staked.isGreaterThan(0) && !isExchangeLoading && (
                        <TransactionCard
                            isEditable
                            selected
                            showIcon
                            amount={amountStaked}
                            balance={staked}
                            balanceToDollars={
                                grwthToken === TOKENS.GVT
                                    ? amountStaked
                                    : amountStaked.multipliedBy(
                                          new BigNumber(conversions[grwthToken] || 0),
                                      )
                            }
                            subtitle="Staked"
                            text={grwthToken === TOKENS.GVT ? 'Vault' : 'PWRD'}
                            token={grwthToken}
                            onChange={setAmountStaked}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
