import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from '@mui/material';
import { useAppSelector } from '../../modules/app/hooks';
import { selectWalletBlocked } from '../../modules/wallet/store/wallet.selectors';

export enum ButtonTypes {
    'deposit' = 'deposit',
    'labs' = 'labs',
    'pools' = 'pools',
    'rewards' = 'rewards',
    'withdraw' = 'withdraw',
}

type TxnButtonProps = {
    onClick: () => void;
    type: ButtonTypes;
};

type Variant = 'contained' | 'outlined' | 'text' | undefined;

export function TxnButton({ onClick, type }: TxnButtonProps): React.ReactElement {
    const styles = {
        txnBtn: css`
            height: 44px;
            @media (max-width: 845px) and (min-width: 764px) {
                & .icon {
                    display: none;
                }
            }
            @media (max-width: 566px) and (min-width: 517px) {
                & .icon {
                    display: none;
                }
            }
        `,
    };

    const walletBlocked = useAppSelector(selectWalletBlocked);

    const buttonConfig = useMemo(() => {
        switch (type) {
            case ButtonTypes.deposit:
                return {
                    disabled: walletBlocked,
                    icon: (
                        <ArrowDownwardIcon
                            className="icon"
                            sx={{ marginLeft: '-12px', marginRight: '8px' }}
                        />
                    ),
                    text: 'Deposit',
                    variant: 'contained',
                };
            case ButtonTypes.withdraw:
                return {
                    disabled: walletBlocked,
                    icon: (
                        <ArrowUpwardIcon
                            className="icon"
                            sx={{ marginLeft: '-12px', marginRight: '8px' }}
                        />
                    ),
                    text: 'Withdraw',
                    variant: 'outlined',
                };
            case ButtonTypes.pools:
                return {
                    disabled: false,
                    icon: <ArrowForwardIcon />,
                    text: 'Go to Pools',
                    variant: 'outlined',
                };
            case ButtonTypes.labs:
                return {
                    disabled: false,
                    icon: <ArrowForwardIcon />,
                    text: 'Go to Labs',
                    variant: 'contained',
                };
            case ButtonTypes.rewards:
                return {
                    disabled: false,
                    icon: <ArrowForwardIcon />,
                    text: 'Go to GRO Vesting',
                    variant: 'outlined',
                };
            default:
                return { text: '', variant: undefined };
        }
    }, [type, walletBlocked]);

    return (
        <Button
            fullWidth
            color="secondary"
            css={styles.txnBtn}
            disabled={buttonConfig.disabled}
            variant={buttonConfig.variant as Variant}
            onClick={onClick}
        >
            {[ButtonTypes.deposit, ButtonTypes.withdraw].includes(type) ? (
                <React.Fragment>
                    {buttonConfig.icon}
                    {buttonConfig.text}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {buttonConfig.text}
                    {buttonConfig.icon}
                </React.Fragment>
            )}
        </Button>
    );
}
