import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TransactionCard } from '../../../components';
import { GroModal, GroModalHeader } from '../../../components/GroModal';
import { TOKENS } from '../../../constants';
import { useGetSizes } from '../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { selectStableTokenBalance } from '../../wallet/store/wallet.selectors';
import { TypeRedeem } from '../screens/Redeem';
import {
    selectCanClaim,
    selectSharesAvailableUnwind,
    selectUserBalanceUnwind,
} from '../store/unwind.selectors';

type RedeemGroModalProps = {
    isOpen: boolean;
    onClose: () => void;
    typeRedeem: TypeRedeem;
};

export function RedeemGroModal({
    isOpen,
    onClose,
    typeRedeem,
}: RedeemGroModalProps): React.ReactElement {
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);
    const canRedeem = useAppSelector(selectCanClaim);
    const sharesAvailable = useAppSelector(selectSharesAvailableUnwind);
    const gro = useAppSelector(selectStableTokenBalance(TOKENS.GRO));
    const balance = useAppSelector(selectUserBalanceUnwind);

    const dispatch = useDispatch();

    const [amount, setAmount] = useState(new BigNumber(0));
    const [startTxn, setStartTxn] = useState(false);

    function onCloseModal(): void {
        onClose();
        setTimeout(() => {
            setStartTxn(false);
        }, 1000);
    }

    function handleAmountChange(newAmount: BigNumber): void {
        setAmount(newAmount);
    }

    const inputBalance = useMemo(() => {
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return gro;
            case TypeRedeem.withdraw:
                return balance;
            case TypeRedeem.redeemGro:
                return sharesAvailable;
            default:
                return gro;
        }
    }, [typeRedeem, gro, balance, sharesAvailable]);

    const noFunds = useMemo(
        () => inputBalance.isEqualTo(0) || amount.isGreaterThan(inputBalance),
        [inputBalance, amount],
    );

    const exchangeDirection = useMemo(() => {
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return ExchangeDirection.depositGro;
            case TypeRedeem.withdraw:
                return ExchangeDirection.withdrawGro;
            case TypeRedeem.redeemGro:
                return ExchangeDirection.redeemGro;
            default:
                return ExchangeDirection.depositGro;
        }
    }, [typeRedeem]);

    useEffect(() => {
        if (!inputBalance.isEqualTo(0)) {
            setAmount(inputBalance);
        }
    }, [inputBalance, typeRedeem]);

    function handleSubmit(): void {
        dispatch(setExchangeDirection(exchangeDirection));
        dispatch(setGrwthToken(canRedeem ? TOKENS.USDC : TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [canRedeem ? TOKENS.USDC : TOKENS.GRO]: amount,
            }),
        );
        dispatch(
            addTokenRhs({
                [canRedeem ? TOKENS.USDC : TOKENS.GRO]: amount,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    const text = useMemo(() => {
        switch (typeRedeem) {
            case TypeRedeem.deposit:
                return 'Deposit GRO';
            case TypeRedeem.withdraw:
                return 'Withdraw GRO';
            case TypeRedeem.redeemGro:
                return 'Claim USDC';
            default:
                return 'Deposit GRO';
        }
    }, [typeRedeem]);

    return (
        <GroModal isOpen={isOpen} overflow="visible" width="400px" onClose={onCloseModal}>
            {!startTxn ? (
                <Box pb={4} px={isXsSize ? 1.5 : 3}>
                    <GroModalHeader onClose={onCloseModal}>
                        <Typography variant="h1">{text}</Typography>
                    </GroModalHeader>

                    <Box my={2.5}>
                        <TransactionCard
                            hideDollar
                            isEditable
                            selected
                            showIcon
                            amount={amount}
                            balance={inputBalance}
                            balanceToDollars={inputBalance}
                            text={canRedeem ? 'USDC' : 'GRO'}
                            token={canRedeem ? TOKENS.USDC : TOKENS.GRO_SINGLE_SIDED}
                            onChange={handleAmountChange}
                        />
                    </Box>
                    <Button
                        fullWidth
                        color="secondary"
                        disabled={noFunds}
                        sx={{ height: '48px' }}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {noFunds ? 'Insufficient balance' : text}
                    </Button>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen
                    onBack={(): void => setStartTxn(false)}
                    onClose={onCloseModal}
                />
            )}
        </GroModal>
    );
}
