/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dispatch } from 'redux';
import { fetchAddressIsSanctioned } from '../../lib/web3/sanctionOracle';
import { getUserIP } from '../stats/services/stats.service';
import { setCountry, setOpenBlockModal, setWalletBlocked } from './store/wallet.reducers';

// '0x7F367cC41522cE07553e823bf3be79A889DEbe1B'

export async function checkIfWalletIsSanctioned(
    wallet: string,
    dispatch: Dispatch<any>,
): Promise<boolean> {
    const isSanctioned = await fetchAddressIsSanctioned(wallet);

    if (isSanctioned) {
        dispatch(setWalletBlocked(true));
        dispatch(setOpenBlockModal(true));
    }

    return isSanctioned;
}

export async function checkIPCountry(dispatch: Dispatch<any>): Promise<void> {
    const country = await getUserIP();
    dispatch(setCountry(country.location.country));
}
