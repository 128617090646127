/* eslint-disable @typescript-eslint/ban-ts-comment */

import axios from 'axios';
import BigNumber from 'bignumber.js';
import { BlockNativeAPI } from '../../stats/stats.types';

export function getRealisticGasLimit(estimatedGas: number): number {
    return Math.ceil(estimatedGas);
}

export async function fetchGasAPI(): Promise<{
    maxFeePerGas: number | null;
    maxPriorityFeePerGas: number | null;
}> {
    const { data } = await axios.get<BlockNativeAPI>(
        'https://api.blocknative.com/gasprices/blockprices',
        {
            headers: {
                Authorization: process.env.REACT_APP_BLOCKNATIVE || '',
            },
        },
    );

    if (data.blockPrices) {
        const { maxFeePerGas, maxPriorityFeePerGas } = data.blockPrices[0].estimatedPrices[1];

        return {
            maxFeePerGas: Math.ceil(
                new BigNumber(maxFeePerGas).multipliedBy(1000000000).toNumber(),
            ),
            maxPriorityFeePerGas: Math.ceil(
                new BigNumber(maxPriorityFeePerGas).multipliedBy(1000000000).toNumber(),
            ),
        };
    }

    return {
        maxFeePerGas: null,
        maxPriorityFeePerGas: null,
    };
}
