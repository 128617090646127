import { BigNumber } from 'bignumber.js';

/**
 *
 * @param percentage number percentage in basis points to multiplier (50 => 0.995)
 * @returns
 */
export function percentageToMultiplier(percentage: BigNumber): BigNumber {
    return percentage.isZero()
        ? new BigNumber(1)
        : new BigNumber(1).minus(new BigNumber(percentage).dividedBy(10000));
}

/**
 * Discount the defined percentage
 * @param amount number the amount base
 * @param percentage percentage in basis points (50 => multiplied by 0.995)
 */
export function minusPercentageBasisPoints(
    baseAmount: BigNumber,
    percentage: BigNumber,
): BigNumber {
    return percentage.isZero()
        ? baseAmount
        : baseAmount.multipliedBy(percentageToMultiplier(percentage));
}

export function formatPct(pct?: BigNumber, sign = false, decimals = 2): string {
    const pctAsNumber =
        !pct || pct.isNaN()
            ? 0
            : pct.multipliedBy(100).decimalPlaces(decimals, BigNumber.ROUND_HALF_UP).toNumber();

    const formatter = new Intl.NumberFormat('en-US', {
        compactDisplay: 'short',
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
        signDisplay: sign ? 'exceptZero' : 'never',
    });
    return `${formatter.format(pctAsNumber)}%`;
}
