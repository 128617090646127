/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { userMigrated } from '../../../../lib/web3/pools';
import { getUserInfo } from '../../../../lib/web3/poolsDeprecated';
import { Status } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { setOpenMigrationModal, setPidsToMigrate, setPoolsStatus } from '../pools.reducer';
import { selectPidsAndStaked } from '../pools.selectors';

export const updateUserMigratedThunk: AppActionThunk =
    (wallet: string) => async (dispatch, getState) => {
        try {
            dispatch(setPoolsStatus({ status: Status.loading }));
            const pids = selectPidsAndStaked(getState());
            const promises = pids.map(async (elem) => {
                const isMigrated = await userMigrated(elem.pid, wallet);
                const { amount } = await getUserInfo(elem.pid, wallet);
                const hasStakedAssets = new BigNumber(amount).minus(elem.staked).isGreaterThan(0);
                return {
                    [elem.pid]: hasStakedAssets && !isMigrated,
                };
            });
            const hasStaked = await Promise.all(promises);
            const pidToMigrate = hasStaked.filter((val) => Object.values(val)[0] === true);
            const pidKeys = pidToMigrate.flatMap((elem) => Object.keys(elem));
            const shouldMigrate = !!pidToMigrate.length;
            dispatch(setPidsToMigrate(pidKeys));
            dispatch(setOpenMigrationModal(!!shouldMigrate));
            dispatch(setPoolsStatus({ status: Status.ready }));
        } catch (error: any) {
            // eslint-disable-next-line no-console
            console.warn('updateUserMigratedThunk.error', error);
            dispatch(setPoolsStatus({ error: error.message, status: Status.error }));
        }
    };
