/* eslint-disable func-style */
/* eslint-disable react/hook-use-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, Button, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GroModalHeader, GroModalTxn } from '../../../../components/GroModal';
import { ExchangeArrowIcon } from '../../../../components/icons';
import { MAINTENANCE_STATUS, TOKENS } from '../../../../constants';
import { getTokenInfo } from '../../../../lib/contract-info';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import { selectCapitalExposureNames } from '../../../stats/store/stats.selectors';
import { ConfirmationModal } from '../../../transaction/components/ConfirmationModal';
import {
    resetExchange,
    resetSignature,
    setExchangeDirection,
    setGrwthToken,
    showExchangeModal,
} from '../../store/exchange.reducers';
import {
    selectExchangeDirection,
    selectExchangeEnabled,
    selectExchangeLimits,
    selectExchangeToken,
    selectExhangeVolatilityStatus,
    selectIsExchangeLoading,
    selectShowExchangeModal,
} from '../../store/exchange.selectors';
import { initExchangeFlowsThunk } from '../../store/thunks/initExchangeFlowsThunk';
import { InvestLimitsBlock } from '../LimitsWarning/InvestLimitsBlock';
import { WithdrawLimitsBlock } from '../LimitsWarning/WithdrawLimitsBlock';
import { VolatilityAlert } from '../VolatilityAlert';
import { ExchangeDeposit } from './Deposit/ExchangeDeposit';
import { ExchangeButton } from './ExchangeButton';
import { ExchangeTo } from './ExchangeTo';
import { StakeNow } from './StakeNow';
import { ExchangeWithdraw } from './Withdrawal/ExchangeWithdraw';

export function ExchangeModal(): React.ReactElement {
    const dispatch = useDispatch();
    const transactionEnabled = useAppSelector(selectExchangeEnabled);
    const limits = useAppSelector(selectExchangeLimits);
    const direction = useAppSelector(selectExchangeDirection);
    const isOpen: boolean = useAppSelector(selectShowExchangeModal);
    const [isNextStep, setNextStep] = useState(false);
    const volatile = useAppSelector(selectExhangeVolatilityStatus);
    const [startTxn, setStartTxn] = useState(false);
    const exposureNames = useAppSelector(selectCapitalExposureNames);

    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);
    const styles = {
        actionButton: css`
            height: 48px;
        `,
        slippage: css`
            & span {
                font-size: 16px;
                color: ${theme.palette.grey[200]};
            }
        `,
    };
    const grwthToken = useAppSelector(selectExchangeToken);

    const token = grwthToken === TOKENS.GVT ? TOKENS.GVT : TOKENS.PWRD;

    const isExchangeLoading = useAppSelector(selectIsExchangeLoading);

    const { displayName } = getTokenInfo(grwthToken);

    function onBack(): void {
        if (direction === ExchangeDirection.invest) {
            setNextStep(true);
            dispatch(resetSignature());
        }
        setStartTxn(false);
    }

    function changeDirection(): void {
        dispatch(
            setExchangeDirection(
                direction === ExchangeDirection.invest
                    ? ExchangeDirection.withdraw
                    : ExchangeDirection.invest,
            ),
        );
        dispatch(resetExchange());
        dispatch(setGrwthToken(grwthToken));
        dispatch(initExchangeFlowsThunk());
    }

    function onClose(): void {
        dispatch(showExchangeModal(false));
        dispatch(resetExchange());
        setNextStep(false);
        setStartTxn(false);
    }

    // const isExchangeLoading = useAppSelector(selectIsExchangeLoading);

    return (
        <GroModalTxn isOpen={isOpen} overflow="auto" width="442px" onClose={onClose}>
            {!isNextStep && !startTxn ? (
                <Box px={isXsSize ? 1.5 : 3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography mb={3} variant="h1">
                            {direction === ExchangeDirection.invest
                                ? 'Deposit into'
                                : 'Withdraw from'}{' '}
                            {displayName}
                        </Typography>
                    </GroModalHeader>
                    {direction === ExchangeDirection.invest ? (
                        <ExchangeDeposit />
                    ) : (
                        <ExchangeWithdraw grwthToken={token} isOpen={isOpen} />
                    )}
                    {/* <VolatilityAlert />
                    <Box>
                        {direction === ExchangeDirection.invest ? (
                            <InvestLimitsBlock />
                        ) : (
                            <WithdrawLimitsBlock />
                        )}
                    </Box> */}
                    <Box position="relative">
                        <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{
                                height: '36px',
                                left: '45%',
                                mb: 0.5,
                                mt: -1,
                                position: 'absolute',
                                svg: {
                                    height: '34px',
                                    width: '34px',
                                },
                                top: '16px',
                                transform: 'rotate(90deg)',
                                width: '36px',
                            }}
                            onClick={changeDirection}
                        >
                            <ExchangeArrowIcon />
                        </IconButton>
                    </Box>
                    <ExchangeTo grwthToken={grwthToken} />

                    <Box mb={4} mt={1.5}>
                        {isExchangeLoading ? (
                            <Button
                                disabled
                                fullWidth
                                color="secondary"
                                css={css`
                                    height: 48px;
                                `}
                                variant="contained"
                                onClick={(): void => {}}
                            >
                                <CircularProgress
                                    color="secondary"
                                    css={css`
                                        vertical-align: middle;
                                        display: inline;
                                    `}
                                    size={24}
                                />
                            </Button>
                        ) : direction === ExchangeDirection.invest ? (
                            <Button
                                fullWidth
                                color="secondary"
                                css={styles.actionButton}
                                disabled={!transactionEnabled || limits.limitReached}
                                variant="contained"
                                onClick={(): void => setNextStep(true)}
                            >
                                {limits.limitReached && !volatile && 'System limit reached'}
                                {(volatile || MAINTENANCE_STATUS) && 'System paused'}
                                {!transactionEnabled &&
                                    !volatile &&
                                    !MAINTENANCE_STATUS &&
                                    !limits.limitReached &&
                                    'Insufficient balance'}
                                {transactionEnabled && 'Next'}
                            </Button>
                        ) : (
                            <ExchangeButton
                                text="Withdraw"
                                onNext={(): void => setStartTxn(true)}
                            />
                        )}
                        {direction === ExchangeDirection.invest && (
                            <Box mt={2.5}>
                                <Typography color="text.tertiary" fontSize="12px" variant="body2">
                                    By depositing you are being exposed to risks from the following
                                    protocols and products, and acknowledge that these may change
                                    based on Gro DAO governance:{' '}
                                    {exposureNames.map((elem, i, arr) => (
                                        <span key={elem}>
                                            {elem}
                                            {i < arr.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                    .{' '}
                                    <a
                                        css={css`
                                            text-decoration: none;
                                            color: ${theme.palette.text.tertiary};
                                        `}
                                        href="https://docs.gro.xyz/gro-docs/pwrd_vault/system-exposure/yield-strategies"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                    >
                                        <span
                                            css={css`
                                                border-bottom: 1px solid
                                                    ${theme.palette.text.tertiary};
                                                &:hover {
                                                    border-bottom: none;
                                                }
                                            `}
                                        >
                                            Learn more{' '}
                                            <OpenInNewRoundedIcon
                                                css={css`
                                                    margin-left: 2px;
                                                    font-size: 0.65rem;
                                                    margin-bottom: -2px;
                                                `}
                                            />
                                        </span>
                                    </a>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : startTxn ? (
                <ConfirmationModal isOpen={startTxn} onBack={onBack} onClose={onClose} />
            ) : (
                <StakeNow
                    onBack={(): void => setNextStep(false)}
                    onClose={onClose}
                    onNext={(): void => setStartTxn(true)}
                />
            )}
        </GroModalTxn>
    );
}
