/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { routerOracle } from '../contract-info';
import { RouterOracle } from './types/routerOracle';

let instance: RouterOracle;

export function routerOracleContract(): RouterOracle {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(routerOracle.abi, routerOracle.address) as any as RouterOracle;
    return instance;
}
