/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import {
    GrowthToken,
    GVT_BETA_CAP,
    MAINTENANCE_STATUS,
    MANUAL_CAP_ENABLED,
    MINIMUM_INVESTING_TOTAL_AMOUNT,
    Token,
    TOKENS,
    TOKENS_DEPOSIT,
} from '../../../constants';
import { tokenToUsd } from '../../../lib/web3/convert';
import { ExchangeDirection, Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { selectGroStatsMc } from '../../stats/store/stats.selectors';
import {
    selectStakedWalletTokens,
    selectWalletState,
    selectWalletTokens,
} from '../../wallet/store/wallet.selectors';
import { getLimitsInvest, getLimitsWithdraw } from '../helpers/limits';
import { ExchangeState } from './exchange.store';

export function selectExchangeState(state: RootState): ExchangeState {
    return state.exchange;
}

export const selectExchangeStatus = createSelector(selectExchangeState, (state) => state.status);

export const selectIsExchangeLoading = createSelector(
    selectExchangeStatus,
    (status) => status === Status.loading,
);

export const selectShowExchangeModal = createSelector(
    selectExchangeState,
    (state) => state.showExchangeModal,
);

export const selectExchangeDirection = createSelector(
    selectExchangeState,
    (state) => state.direction,
);

export const selectExchangeConversions = createSelector(
    selectExchangeState,
    (state) => state.conversions,
);

export const selectUSDCConversions = createSelector(
    selectExchangeConversions,
    (conversions) => new BigNumber(conversions.usdc || 0),
);

export const selectWithdrawalLimits = createSelector(selectExchangeState, getLimitsWithdraw);

export const selectInvestLimits = createSelector(selectExchangeState, getLimitsInvest);

export const selectExchangeLimits = createSelector(
    [selectExchangeState, selectWithdrawalLimits, selectInvestLimits],
    (state, withdrawLimits, investLimits) => {
        if (state.direction === ExchangeDirection.invest) {
            return investLimits;
        }
        return withdrawLimits;
    },
);

export const selectUnstakeAmount = createSelector(
    selectExchangeState,
    (state) => state.unstakeAmount,
);

export const selectUserInputExceededWalletBalanceAndStake = createSelector(
    [selectExchangeState, selectWalletState, selectStakedWalletTokens, selectUnstakeAmount],
    (exchange, wallet, staked, toUnstake) => {
        const tokens = exchange.tokens.from;

        const walletTokens = wallet.tokens;

        if (!walletTokens) {
            return true;
        }

        let limitReached = false;

        Object.keys(tokens).forEach((token) => {
            const stakedToken =
                token === TOKENS.PWRD ? TOKENS.PWRD_SINGLE_SIDED : TOKENS.GVT_SINGLE_SIDED;
            const totalStaked = staked[stakedToken as Token];
            const totalInWallet = walletTokens[token as Token];
            const toWithdraw = tokens[token as Token];
            if (toUnstake.isGreaterThan(totalStaked || new BigNumber(0))) {
                limitReached = true;
            }
            if (
                !walletTokens[token as Token] ||
                toWithdraw?.isGreaterThan(totalInWallet?.plus(totalStaked || 0) || 0)
            ) {
                limitReached = true;
            }
        });
        return limitReached;
    },
);

export const selectUserInputExceededWalletBalance = createSelector(
    [selectExchangeState, selectWalletState],
    (exchange, wallet) => {
        const tokens = exchange.tokens.from;

        const walletTokens = wallet.tokens;

        if (!walletTokens) {
            return true;
        }

        let limitReached = false;

        Object.keys(tokens).forEach((token) => {
            if (
                !walletTokens[token as Token] ||
                walletTokens[token as Token]?.isLessThan(tokens[token as Token] || 0)
            ) {
                limitReached = true;
            }
        });
        return limitReached;
    },
);

export const selectExchangeToken = createSelector(selectExchangeState, (state) => state.token);

export const selectTokenTo = createSelector(
    [selectExchangeState, selectExchangeToken],
    (state, token) => new BigNumber(state.tokens.to[token] || 0),
);

export const selectSlippage = createSelector(selectExchangeState, (state) => state.slippage);

export const selectManualCap = createSelector([selectExchangeToken], (token) =>
    token === TOKENS.GVT ? GVT_BETA_CAP : GVT_BETA_CAP.multipliedBy(0.6),
);

/**
 * Returns difference between user input + system TVL and manual cap
 * positive value means we have room to invest/buy more
 * negative or zero means we have exceeded or reached manual cap
 */
export const selectRemainingManualCap = createSelector(
    [selectGroStatsMc, selectManualCap, selectExchangeState],
    (groStats, manualCap, exchange) => {
        const fromTokenInUsd = tokenToUsd(
            exchange.token,
            new BigNumber((exchange.token && exchange.tokens.to[exchange.token]) || 0),
            exchange.conversions,
        );
        return manualCap
            .minus(fromTokenInUsd.plus(groStats.mainnet?.tvl[exchange.token as GrowthToken]))
            .decimalPlaces(0, BigNumber.ROUND_FLOOR);
    },
);

export const selectManualCapDiff = createSelector(
    [selectExchangeState, selectRemainingManualCap],
    (exchange, manualCapDiff) => {
        if (exchange.direction !== ExchangeDirection.invest || exchange.status !== Status.ready) {
            return new BigNumber(1);
        }

        if (exchange.status === Status.ready) {
            return manualCapDiff;
        }

        return new BigNumber(1);
    },
);

export const selectManualCapReached = createSelector(selectManualCapDiff, (manualCapDiff) =>
    MANUAL_CAP_ENABLED ? manualCapDiff.isLessThanOrEqualTo(0) : false,
);

export const selectExhangeVolatilityStatus = createSelector(
    selectExchangeState,
    (exchange) => exchange.volatilityStatus,
);

export const selectExchangeEnabled = createSelector(
    [
        selectExchangeState,
        selectUserInputExceededWalletBalance,
        selectUserInputExceededWalletBalanceAndStake,
        selectManualCapReached,
    ],
    (state, moreTokensThanAvailable, moreTokensAndStakedThanAvailable, manualCapReached) => {
        if (MAINTENANCE_STATUS) {
            return false;
        }

        const sourceTokens = Object.keys(state.tokens.from);
        if (!sourceTokens.length) {
            return false;
        }
        const targetTokens = Object.keys(state.tokens.to);
        if (!targetTokens.length) {
            return false;
        }

        const total = targetTokens.reduce((prev, next) => {
            const nextValue = state.tokens.to[next as Token] || new BigNumber(0);
            return prev.plus(nextValue);
        }, new BigNumber(0));

        if (total.isLessThanOrEqualTo(0)) {
            return false;
        }

        // Disable the button if the value of withdrawal is less than 0.5 usd
        const totalAmount = Object.keys(state.tokens.from).reduce(
            (sum, key) =>
                sum.plus(
                    tokenToUsd(key as Token, state.tokens.from[key as Token], state.conversions),
                ),
            new BigNumber(0),
        );

        if (totalAmount.isLessThan(MINIMUM_INVESTING_TOTAL_AMOUNT)) {
            return false;
        }

        if (moreTokensThanAvailable && state.direction === ExchangeDirection.invest) {
            return false;
        }

        if (moreTokensAndStakedThanAvailable && state.direction === ExchangeDirection.withdraw) {
            return false;
        }

        return !manualCapReached;
    },
);

export const selectExchangeShowModal = createSelector(
    selectExchangeState,
    (state) => state.showModal,
);

export const selectIsStake = createSelector(selectExchangeState, (state) => state.isStake);

export const selectWalletFrom = createSelector(selectExchangeState, (state) => state.tokens.from);

export const selectStablesInOrder = createSelector(selectWalletTokens, (tokens) => {
    const stable = TOKENS_DEPOSIT.map((token) => ({
        name: token,
        value: tokens[token],
    }));
    const ordered = stable.sort(
        (a, b) => new BigNumber(b.value || 0).toNumber() - new BigNumber(a.value || 0).toNumber(),
    );

    const names = ordered
        .filter((elem) => new BigNumber(elem.value || 0).isGreaterThan(0))
        .map((elem) => elem.name);
    return names.length > 0 ? names : [TOKENS.USDC];
});

export const selectTokenToIsZero = createSelector(selectExchangeState, (state) => {
    const targetTokens = Object.keys(state.tokens.to);
    const total = targetTokens.reduce((prev, next) => {
        const nextValue = state.tokens.to[next as Token] || new BigNumber(0);
        return prev.plus(nextValue);
    }, new BigNumber(0));

    if (total.isLessThanOrEqualTo(0)) {
        return true;
    }
    return false;
});

export const selectSignature = createSelector(selectExchangeState, (state) => state.signature);
export const selectStablesLoading = createSelector(
    selectExchangeState,
    (state) => state.stablePriceLoading,
);
