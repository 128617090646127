import React, { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TransactionCard } from '../../../../components';
import { useAppSelector } from '../../../app/hooks';
import { getLabsToken } from '../../store/labs.helper';
import { setWithdrawAmount } from '../../store/labs.reducer';
import {
    selectDepositedAvaxToken,
    selectInputtedAmountToDollars,
    selectLabsToken,
    selectTokenName,
    selectWithdrawAmount,
} from '../../store/labs.selectors';

export function WithdrawCard({ isOpen }: { isOpen: boolean }): React.ReactElement {
    const dispatch = useDispatch();

    const amount = useAppSelector(selectWithdrawAmount);

    const withdrawAmounToDollars = useAppSelector(selectInputtedAmountToDollars);

    const name = useAppSelector(selectTokenName);
    const balance = useAppSelector(selectDepositedAvaxToken);

    const token = useAppSelector(selectLabsToken);

    function handleAmountChange(newAmount: BigNumber): void {
        dispatch(setWithdrawAmount(newAmount));
    }

    useEffect(() => {
        if (isOpen) {
            dispatch(setWithdrawAmount(amount.isZero() ? balance || new BigNumber(0) : amount));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <TransactionCard
            isEditable
            selected
            amount={amount}
            balance={balance}
            balanceToDollars={withdrawAmounToDollars}
            text={name}
            token={getLabsToken(token)}
            onChange={handleAmountChange}
        />
    );
}
