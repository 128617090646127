/* eslint-disable complexity */

import { capitalize } from '@mui/material';
import BigNumber from 'bignumber.js';
import { METAMASK_ERROR_REJECTED_SIGNATURE, Token, TokenObject } from '../../constants';
import { getTokenInfo } from '../../lib/contract-info';
import { usdToToken } from '../../lib/web3/convert';
import { ExchangeDirection } from '../app/app.types';
import { TpUserTransaction, UserTransactionType } from '../stats/stats.types';
import { minusPercentageBasisPoints } from '../utils/percentage.helpers';
import { isGrwthToken } from '../utils/token.helpers';
import {
    CurrentSessionTransactions,
    Transaction,
    TransactionStatus,
    TransactionType,
} from './store/transactions.store';
import {
    HistoryTransaction,
    HistoryTransactionStatus,
    HistoryTransactionType,
} from './transactions.types';

export function getTransactionComputedStatus(transaction?: Transaction): TransactionStatus {
    let status = TransactionStatus.idle;

    if (transaction && transaction.queue.length) {
        const isPending = transaction.queue.some(
            (mmt) =>
                mmt.type === TransactionType.exchange &&
                mmt.status === TransactionStatus.pendingConfirmation,
        );

        if (isPending) {
            status = TransactionStatus.pendingConfirmation;
        }

        const isTimeout = transaction.queue.some(
            (mmt) => mmt.status === TransactionStatus.timeout,
        );

        if (isTimeout) {
            status = TransactionStatus.timeout;
        }

        const isError = transaction.queue.some((mmt) => mmt.status === TransactionStatus.error);

        if (isError) {
            status = TransactionStatus.error;
        }

        const isConfirmed = transaction.queue.every(
            (mmt) => mmt.status === TransactionStatus.confirmed,
        );

        if (isConfirmed) {
            status = TransactionStatus.confirmed;
        }
    }

    return status;
}

export function hasTransactionQueueError(transaction: Transaction): boolean {
    const hasError = transaction.queue.find((e) => e.status === TransactionStatus.error);
    return !!hasError;
}

export function formatClientTransactionsToHistoryTransactions(
    transactions: CurrentSessionTransactions,
    conversions: TokenObject,
    withdrawalFee: BigNumber,
): HistoryTransaction[] {
    const txs: HistoryTransaction[] = [];

    Object.keys(transactions)
        .map((key) => transactions[key])
        // Sort by date descending
        .sort((prev, next) => (prev.timestamp > next.timestamp ? -1 : 1))
        .forEach((t) => {
            t.queue
                // Remove user rejected transactions
                .filter(
                    (item) =>
                        !(
                            item.status === TransactionStatus.error &&
                            item.error === METAMASK_ERROR_REJECTED_SIGNATURE
                        ),
                )
                // Remove user pending confirmation transactions
                .filter(
                    (item) =>
                        !(
                            item.status === TransactionStatus.pendingApproval ||
                            item.status === TransactionStatus.pendingMmApproval
                        ),
                )
                .forEach((tx) => {
                    let txStatus: HistoryTransactionStatus = HistoryTransactionStatus.pending;

                    if (tx.status === TransactionStatus.error) {
                        txStatus = HistoryTransactionStatus.error;
                    }

                    if (tx.status === TransactionStatus.confirmed) {
                        txStatus = HistoryTransactionStatus.success;
                    }

                    let txType = HistoryTransactionType.approve;

                    if (
                        tx.type === TransactionType.exchange &&
                        tx.direction === ExchangeDirection.withdraw
                    ) {
                        txType = HistoryTransactionType.withdraw;
                    }

                    if (
                        tx.type === TransactionType.exchange &&
                        tx.direction === ExchangeDirection.invest
                    ) {
                        txType = HistoryTransactionType.deposit;
                    }

                    if (tx.status === TransactionStatus.idle) {
                        // Ignore idle txs
                        return;
                    }

                    txs.push({
                        coinAmount: tx.usdAmount
                            ? usdToToken(tx.token, tx.usdAmount, conversions)
                            : undefined,
                        hash: tx.transactionHash || '',
                        status: txStatus,
                        timestamp: new BigNumber(tx.timestamp),
                        token: tx.token,
                        type: txType,
                        usdAmount:
                            txType === HistoryTransactionType.withdraw
                                ? minusPercentageBasisPoints(
                                      tx.usdAmount || new BigNumber(0),
                                      withdrawalFee,
                                  )
                                : tx.usdAmount,
                    });
                });
        });

    return txs;
}

function getHistoryTransactionType(key: UserTransactionType): HistoryTransactionType {
    switch (key) {
        case UserTransactionType.transfersIn:
            return HistoryTransactionType.transferIn;
        case UserTransactionType.transfersOut:
            return HistoryTransactionType.transferOut;
        case UserTransactionType.withdrawals:
            return HistoryTransactionType.withdraw;
        case UserTransactionType.deposits:
            return HistoryTransactionType.deposit;
        case UserTransactionType.approvals:
            return HistoryTransactionType.approve;
        case UserTransactionType.failures:
            return HistoryTransactionType.failure;
        case UserTransactionType.stakerDeposits:
            return HistoryTransactionType.stakerDeposits;
        case UserTransactionType.stakerWithdrawals:
            return HistoryTransactionType.stakerWithdrawals;
        default:
            return HistoryTransactionType.deposit;
    }
}
export function formatUserStatsTransactionsToHistoryTransactions(
    userTransactions: TpUserTransaction,
): HistoryTransaction[] {
    const transactions: HistoryTransaction[] = [];

    Object.keys(userTransactions).forEach((key) => {
        const uType = key as UserTransactionType;
        const type = getHistoryTransactionType(uType);

        userTransactions[uType].forEach((tx) => {
            const transaction = {
                coinAmount: tx.coin_amount ? new BigNumber(tx.coin_amount) : undefined,
                hash: tx.hash,
                status:
                    uType === UserTransactionType.failures
                        ? HistoryTransactionStatus.error
                        : HistoryTransactionStatus.success,
                timestamp: new BigNumber(tx.timestamp).multipliedBy(1000),
                token: tx.token ? (tx.token.toLowerCase() as Token) : undefined,
                type,
                usdAmount: new BigNumber(tx.usd_amount || 0),
            };

            transactions.push(transaction);
        });
    });
    return transactions.sort((prev, next) => (prev.timestamp > next.timestamp ? -1 : 1));
}

function getOperationName(type: ExchangeDirection): string {
    switch (type) {
        case ExchangeDirection.invest:
            return 'Deposit';
        case ExchangeDirection.withdraw:
            return 'Withdrawal';
        default:
            // stake/unstake
            return capitalize(type);
    }
}

export function getModalTitle(token: Token, direction: ExchangeDirection): string {
    switch (direction) {
        case ExchangeDirection.claimReward:
        case ExchangeDirection.teamClaim:
            return 'Claim Rewards';
        case ExchangeDirection.claimBonus:
            return 'Claim Vesting Bonus';
        case ExchangeDirection.claimAirdrop:
            return 'Claim Airdrop';
        case ExchangeDirection.claimAllowance:
            return 'Claim Allowance';
        case ExchangeDirection.exit:
            return 'Exit GRO rewards';
        case ExchangeDirection.claimUst:
            return 'Claim PWRD rewards';
        case ExchangeDirection.investAvax:
            return 'Deposit';
        case ExchangeDirection.withdrawAvax:
            return 'Withdrawal';
        case ExchangeDirection.migrate:
            return 'Migrate position';
        case ExchangeDirection.lockMoreGro:
            return 'Lock more GRO';
        case ExchangeDirection.depositGro:
            return 'Deposit GRO';
        case ExchangeDirection.depositUsdc:
            return 'Deposit USDC';
        case ExchangeDirection.withdrawGro:
            return 'Withdraw GRO';
        case ExchangeDirection.withdrawUsdc:
            return 'Withdraw USDC';
        case ExchangeDirection.redeemGro:
            return 'Redeem GRO';
        case ExchangeDirection.redeemDura:
            return 'Redeem DURA';
        case ExchangeDirection.migratePool:
            return 'Activate address in new Pools';
        case ExchangeDirection.invest:
            return 'Deposit and Stake';
        default:
            return `${
                (isGrwthToken(token) && getTokenInfo(token).displayName) || ''
            } ${getOperationName(direction)}`;
    }
}
