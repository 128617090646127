import React from 'react';
import { MAINTENANCE_STATUS } from '../../../constants';
import { BasicAlert } from './BasicAlert';

export function MaintenanceStatusAlert(): React.ReactElement | null {
    return MAINTENANCE_STATUS ? (
        <BasicAlert
            text="Gro Protocol is undergoing a planned upgrade to deploy additional functionality and
                will be available again soon. Please refrain from making deposits / withdrawals
                during this time."
            title="System status"
        />
    ) : null;
}
