/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { redeemContract } from '../abis/redeemContract';
import { convertToBN } from './web3.helpers';

export function depositGro(amount: BigNumber) {
    return redeemContract().methods.deposit(convertToBN(amount));
}

export function withdrawGro(amount: BigNumber) {
    return redeemContract().methods.withdraw(convertToBN(amount));
}

export function redeemGro(amount: BigNumber) {
    return redeemContract().methods.claim(convertToBN(amount));
}

export async function getPricePerShare() {
    const price = await redeemContract().methods.getPricePerShareUSDC().call();
    return new BigNumber(price);
}

export async function getUserBalance(wallet: string) {
    const balance = await redeemContract().methods.getUserBalance(wallet).call();
    return new BigNumber(balance);
}

export async function getTotalDeposited() {
    const balance = await redeemContract().methods.totalGRO().call();
    return new BigNumber(balance);
}

export async function getSharesAvailable(wallet: string) {
    const shares = await redeemContract().methods.getSharesAvailable(wallet).call();
    return new BigNumber(shares);
}

export async function getDeadline() {
    const deadline = await redeemContract().methods.getDeadline().call();
    return deadline;
}
