import { alpha, Theme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { PoolProviders, PoolToken, Token, TokenObject, TOKENS } from '../../constants';
import { TpPool } from '../stats/stats.types';

export function isEligible(pool: TpPool, wallet: TokenObject): boolean {
    if (pool.required_tokens_num === '0') {
        return true;
    }
    if (pool.required_tokens_num) {
        return (
            pool.tokens.filter((token) => wallet[token] && !wallet[token]?.isZero()).length >=
            parseInt(pool.required_tokens_num, 10)
        );
    }
    return true;
}

export function getPoolPID(token: PoolToken, pools: TpPool[]): string {
    return pools.find((pool) => pool.name === token)?.pid ?? '-1';
}

export function getPoolTokenURL(token: Token, pool?: string): string {
    switch (token) {
        case TOKENS.GVT:
            return '/vault/deposit';
        case TOKENS.PWRD:
            return '/pwrd/deposit';
        case TOKENS.GRO:
            if (pool === TOKENS.BAL_GRO_WETH) {
                return 'https://app.balancer.fi/#/trade/0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE/0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7';
            }
            return 'https://app.uniswap.org/#/swap?outputCurrency=0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7';
        case TOKENS.UNI_USDC_50_50:
        case TOKENS.PWRD3CRV:
            return 'https://curve.fi/factory/44/deposit';
        case TOKENS.USDT:
            return 'https://app.uniswap.org/#/swap?outputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7';
        case TOKENS.USDC:
            return 'https://app.uniswap.org/#/swap?outputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
        case TOKENS.DAI:
            return 'https://app.uniswap.org/#/swap?outputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f';
        case TOKENS.WETH:
            return 'https://app.balancer.fi/#/trade/0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
        default:
            return '';
    }
}

export function getPoolZeroState(pool: PoolToken): string {
    if (pool === TOKENS.PWRD_SINGLE_SIDED) {
        return 'Deposit stables into PWRD and then stake the recipient tokens here for GRO rewards.';
    }
    if (pool === TOKENS.UNI_50_50) {
        return 'Supply GRO and Gro Vault Tokens (GVT) into this Uniswap pool, then stake LP token here for GRO rewards.';
    }
    if (pool === TOKENS.UNI_USDC_50_50) {
        return 'Supply GRO and USDC into this Uniswap pool, then stake LP tokens here for GRO rewards.';
    }
    if (pool === TOKENS.GRO_SINGLE_SIDED) {
        return 'Get GRO tokens and then stake here for GRO rewards.';
    }
    if (pool === TOKENS.GVT_SINGLE_SIDED) {
        return 'Deposit stables into Vault and then stake the recipient tokens here for GRO rewards.';
    }
    if (pool === TOKENS.CRV_META) {
        return 'Supply PWRD into Curve metapool and stake LP tokens here. N.b. the Curve pool nullifies protection against stablecoin problems.';
    }
    if (pool === TOKENS.BAL_GRO_WETH) {
        return 'Wrap your ETH into WETH and get GRO. Then supply 80% GRO with 20% WETH into this Balancer pool and stake the LP token here.';
    }
    return '';
}

export function getPoolMultiplier(pool: PoolToken, deprecated: boolean): string {
    if (deprecated) {
        return 'Inactive';
    }
    if (pool === TOKENS.UNI_50_50) {
        return 'Pair';
    }
    if (pool === TOKENS.UNI_USDC_50_50) {
        return 'Pair';
    }
    if (pool === TOKENS.CRV_META) {
        return 'Multi';
    }
    if (pool === TOKENS.GRO_SINGLE_SIDED) {
        return 'Single';
    }
    if (pool === TOKENS.PWRD_SINGLE_SIDED) {
        return 'Single';
    }
    if (pool === TOKENS.GVT_SINGLE_SIDED) {
        return 'Single';
    }
    if (pool === TOKENS.BAL_GRO_WETH) {
        return 'Pair';
    }
    return 'Pool';
}

export function getTVLTitle(poolProvider: PoolProviders): string {
    if (poolProvider === PoolProviders.GRO) {
        return 'Staked TVL';
    }
    if (poolProvider === PoolProviders.UNISWAP) {
        return 'Uniswap TVL';
    }
    if (poolProvider === PoolProviders.CURVE) {
        return 'Curve TVL';
    }
    if (poolProvider === PoolProviders.BALANCER) {
        return 'Balancer TVL';
    }
    return 'Staked TVL';
}

export function getPoolVariantColors(
    pool: TpPool,
    theme: Theme,
    disabled?: boolean,
): {
    background?: string;
    border?: string;
} {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    const hasGVT = pool.tokens.indexOf(TOKENS.GVT) !== -1;
    const hasPWRD = pool.tokens.indexOf(TOKENS.PWRD) !== -1;
    const hasGRO = pool.tokens.indexOf(TOKENS.GRO) !== -1;
    const hasPWRD3CRV =
        pool.tokens.indexOf(TOKENS.UNI_USDC_50_50) !== -1 ||
        pool.tokens.indexOf(TOKENS.PWRD3CRV) !== -1;

    const groColor = theme.palette.gro.light as string;
    const gvtColor = theme.palette.gvt.light as string;
    const pwrdColor = theme.palette.pwrd.light as string;

    const backgroundOpacity = disabled ? 0.2 : 0.7;
    const borderOpacity = disabled ? 0.5 : 1;

    if ((hasPWRD3CRV || hasPWRD) && hasGRO) {
        return {
            background: `linear-gradient(0deg, ${alpha(pwrdColor, backgroundOpacity)}, ${alpha(
                groColor,
                backgroundOpacity,
            )}), ${theme.palette.common.black}`,
            border: `linear-gradient(180deg, ${alpha(groColor, borderOpacity)} 0%, ${alpha(
                pwrdColor,
                1,
            )} 100%)`,
        };
    }

    if (hasGVT && !hasPWRD && !hasGRO) {
        return {
            background: `linear-gradient(180deg, ${alpha(gvtColor, backgroundOpacity)} 0%, ${alpha(
                gvtColor,
                backgroundOpacity,
            )} 33.51%), ${theme.palette.common.black}`,
            border: `linear-gradient(180deg, ${alpha(gvtColor, borderOpacity)} 0%, ${alpha(
                gvtColor,
                1,
            )} 100%)`,
        };
    }

    if (!hasGVT && hasPWRD && !hasGRO) {
        return {
            background: `linear-gradient(0deg, ${alpha(pwrdColor, backgroundOpacity)}, ${alpha(
                pwrdColor,
                backgroundOpacity,
            )}), ${theme.palette.common.black}`,
            border: alpha(pwrdColor, borderOpacity),
        };
    }

    if (!hasGVT && !hasPWRD && hasGRO) {
        return {
            background: `linear-gradient(0deg, ${alpha(groColor, backgroundOpacity)}, ${alpha(
                groColor,
                backgroundOpacity,
            )}), ${theme.palette.common.black}`,
            border: alpha(groColor, borderOpacity),
        };
    }

    if (hasGVT && !hasPWRD && hasGRO) {
        return {
            background: `linear-gradient(180deg, ${alpha(groColor, backgroundOpacity)} 0%, ${alpha(
                gvtColor,
                backgroundOpacity,
            )} 33.51%), ${theme.palette.common.black}`,
            border: `linear-gradient(180deg, ${alpha(groColor, borderOpacity)} 0%, ${alpha(
                gvtColor,
                1,
            )} 100%)`,
        };
    }

    return {
        background: `linear-gradient(0deg, ${alpha(groColor, backgroundOpacity)}, ${alpha(
            groColor,
            0.3,
        )}), ${theme.palette.common.black}`,
        border: alpha(groColor, borderOpacity),
    };
}

export function unknownTokenPrice(pools: TpPool[] = []): boolean {
    for (let index = 0; index < pools.length; index += 1) {
        const element = pools[index];
        if (element.lp_usd_price === 'NA') {
            return true;
        }
    }
    return false;
}

export function handleErrorAndDisplay(
    // eslint-disable-next-line
    displayFunction: (...args: any[]) => string,
    value: string | BigNumber | undefined,
    lpTokenPrice?: string,
    isTvl?: boolean,
): string {
    if (value === 'NA' || lpTokenPrice === 'NA') {
        return '...';
    }
    if (value === typeof BigNumber) {
        return isTvl ? displayFunction(value, false, 0) : displayFunction(value);
    }
    if (!value) {
        return displayFunction(new BigNumber(0));
    }

    const isLesser = new BigNumber(value).isLessThan(0);

    return isTvl
        ? `${isLesser ? '-' : ''}${displayFunction(new BigNumber(value), 0, 0)}`
        : `${isLesser ? '-' : ''}${displayFunction(new BigNumber(value))}`;
}
