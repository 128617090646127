/* eslint-disable complexity */

import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TextOrSkeleton, TransactionCard } from '../../../components';
import { GroModalHeader, GroModalTxn } from '../../../components/GroModal';
import { getTokenInfo } from '../../../lib/contract-info';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../transaction/components/ConfirmationModal';
import { formatAsCurrency } from '../../utils/currency.helpers';
import { initWalletThunk } from '../../wallet/store/thunks/initWalletThunk';
import { selectWalletAccount, selectWalletTokens } from '../../wallet/store/wallet.selectors';
import { getLabsToken } from '../store/labs.helper';
import { setLabsToken, setLabsTransaction, setOpenAllowanceModal } from '../store/labs.reducer';
import {
    selectDepositLimitToken,
    selectIsLabsLoading,
    selectLabsToken,
    selectLabsTransactionDirection,
    selectMaxAllowanceHasBeenClaimed,
    selectOpenDepositModal,
    selectRemainingAllowanceByName,
    selectVaultBalance,
} from '../store/labs.selectors';
import { updateLabsValuesTHunk } from '../store/thunks/updateLabsValuesThunk';

export function Deposit(): React.ReactElement {
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState(new BigNumber(0));
    const isOpen = useAppSelector(selectOpenDepositModal);
    const actionType = useAppSelector(selectLabsTransactionDirection);
    const wallet = useAppSelector(selectWalletAccount);
    const [startTxn, setStartTxn] = useState(false);

    const name = useAppSelector(selectLabsToken);
    const token = getLabsToken(name);
    const { displayName } = getTokenInfo(token);
    const maxAllowanceClaimed = useAppSelector(selectMaxAllowanceHasBeenClaimed(name));
    const remainingAllowance = useAppSelector(selectRemainingAllowanceByName(name));

    // const userHasClaimed = useAppSelector(selectUserHasClaimed(name));

    const allowance = remainingAllowance;

    const showLink = !maxAllowanceClaimed;

    const walletTokens = useAppSelector(selectWalletTokens);

    const loading = useAppSelector(selectIsLabsLoading);

    const limit = useAppSelector(selectDepositLimitToken);
    const vaultBalance = useAppSelector(selectVaultBalance);

    function onClose(): void {
        dispatch(setLabsTransaction(undefined));
        setStartTxn(false);
    }

    const balance = useMemo(() => {
        const tokens = walletTokens[token];
        return (tokens || new BigNumber(0)).isLessThan(remainingAllowance)
            ? tokens || new BigNumber(0)
            : remainingAllowance;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletTokens]);

    const theme = useTheme();

    const balanceExceeded = useMemo(() => amount.isGreaterThan(balance), [amount, balance]);

    const capExceeded = useMemo(
        () =>
            amount
                .plus(vaultBalance)
                .isGreaterThanOrEqualTo(limit.multipliedBy(new BigNumber(0.98))),
        [amount, limit, vaultBalance],
    );

    const allowanceExceeded = useMemo(() => amount.isGreaterThan(allowance), [allowance, amount]);

    const styles = {
        activeAction: css`
            background-color: ${theme.palette.common.white};
            color: ${theme.palette.common.black};
            border-radius: 8px;
            cursor: pointer;
        `,
        buttonSubmit: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        buttonSwitch: css`
            background: ${theme.palette.grey[700]};
            border-radius: 8px;
        `,
        inputWrapper: css`
            background-color: ${theme.palette.common.black};
            border: 1px solid ${theme.palette.grey[700]};
            border-radius: 5px;
        `,
    };

    function handleAmountChange(newAmount: BigNumber): void {
        setAmount(newAmount);
    }

    function handleSumbit(): void {
        if (actionType) {
            dispatch(resetExchange());
            dispatch(setExchangeDirection(actionType));
            dispatch(setLabsToken(name));
            dispatch(setGrwthToken(token));
            dispatch(
                addTokenLhs({
                    [token]: amount,
                }),
            );
            dispatch(
                addTokenRhs({
                    [token]: amount,
                }),
            );
            dispatch(createNewTransactionThunk());
            setStartTxn(true);
        }
    }

    function onClaimAllowance(): void {
        onClose();
        dispatch(setOpenAllowanceModal(true));
    }

    useEffect(() => {
        // reset amount at when modal opens
        if (isOpen) {
            void dispatch(updateLabsValuesTHunk(wallet, name));
            void dispatch(initWalletThunk(wallet));
        }
        // eslint-disable-next-line
    }, [isOpen, dispatch]);

    useEffect(() => {
        if (isOpen && !Number.isNaN(balance.toNumber())) {
            setAmount(new BigNumber(balance));
        }
    }, [balance, isOpen]);

    return (
        <GroModalTxn isOpen={isOpen} overflow="visible" width="400px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={4} px={3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography mb={0.5} mt={1} variant="h1">
                            Deposit
                        </Typography>
                        <Typography
                            alignItems="center"
                            color="text.tertiary"
                            display="flex"
                            variant="body1"
                        >
                            {displayName} Leveraged Yield
                        </Typography>
                    </GroModalHeader>
                    {/* <Box display="flex" justifyContent="space-between" mb={0.5} mt={3.5}>
                            <Typography variant="body2">Your current allowance</Typography>
                            <Typography variant="body2">
                                <TextOrSkeleton loading={loading}>
                                    {formatAsCurrency(currentAllowance)}
                                </TextOrSkeleton>
                            </Typography>
                        </Box> */}
                    <Box display="flex" justifyContent="space-between" mt={2.5}>
                        <Typography
                            color={
                                allowanceExceeded ? theme.palette.warning.light : 'text.primary'
                            }
                            variant="body2"
                        >
                            Your remaining allowance
                        </Typography>
                        <Typography
                            color={
                                allowanceExceeded ? theme.palette.warning.light : 'text.primary'
                            }
                            variant="body2"
                        >
                            <TextOrSkeleton loading={loading}>
                                {formatAsCurrency(allowance)}
                            </TextOrSkeleton>
                        </Typography>
                    </Box>
                    {showLink && (
                        <Box
                            alignItems="center"
                            display="flex"
                            mt={0.5}
                            sx={{ cursor: 'pointer' }}
                            onClick={onClaimAllowance}
                        >
                            <Typography color={theme.palette.gro.superLight} variant="nav">
                                Unlock higher allowance
                            </Typography>
                            <ChevronRightIcon
                                sx={{
                                    color: theme.palette.gro.superLight,
                                    fontSize: '16px',
                                    marginBottom: '-2px',
                                }}
                            />
                        </Box>
                    )}
                    <Box mt={2}>
                        <TransactionCard
                            isEditable
                            selected
                            showIcon
                            amount={amount}
                            balance={balance}
                            balanceToDollars={amount}
                            text={displayName}
                            token={token}
                            onChange={handleAmountChange}
                        />
                    </Box>
                    <Box mt={2.5}>
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.buttonSubmit}
                            disabled={
                                amount.isZero() ||
                                allowanceExceeded ||
                                loading ||
                                balanceExceeded ||
                                capExceeded
                            }
                            variant="contained"
                            onClick={handleSumbit}
                        >
                            {allowanceExceeded && !capExceeded && 'Allowance exceeded'}
                            {!allowanceExceeded && !balanceExceeded && !capExceeded && 'Deposit'}
                            {balanceExceeded &&
                                !allowanceExceeded &&
                                !capExceeded &&
                                'Balance exceeded'}
                            {capExceeded && 'Strategy TVL limit exceeded'}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModalTxn>
    );
}

export const DepositModal = React.memo(Deposit);
