/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { curvePool } from '../contract-info';
import { CurvePool } from './types/curvePool';

let instance: CurvePool;

export function curvePoolContract(): CurvePool {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;

    instance = new Contract(curvePool.abi, curvePool.address) as any as CurvePool;
    return instance;
}
