/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { burnerContract } from '../abis';
import { convertToBN } from './web3.helpers';

export function reVestGRO(amount: BigNumber) {
    return burnerContract().methods.reVest(convertToBN(amount));
}
