/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import ETHIcon from '../../assets/images/ETHNETWORKDARK.svg';
import { ETH_NETWORK } from '../../constants';
import { useAppSelector } from '../../modules/app/hooks';
import { useChangeNetwork } from '../../modules/wallet/hooks/useChangeNetwork';
import { selectHardwareWalletLoading } from '../../modules/wallet/store/wallet.selectors';

export function ChangeNetworkButton(): React.ReactElement {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const hardwareWalletLoading = useAppSelector(selectHardwareWalletLoading);

    const { onChangeNetwork } = useChangeNetwork({ setIsLoading });

    const styles = {
        imageStyle: css`
            margin-right: ${theme.spacing(8)};
            vertical-align: middle;
            display: inline;
        `,
        spinner: css`
            vertical-align: middle;
            display: inline;
            margin-right: 57px;
        `,
    };

    async function onChangeNetworkBtn(): Promise<Promise<void>> {
        await onChangeNetwork({ data: ETH_NETWORK, icon: '', label: '' });
    }

    return (
        <Box display="flex" flexDirection="column" maxWidth="302px">
            <Button
                fullWidth
                color="secondary"
                sx={{ justifyContent: 'flex-start', padding: '10px 13px' }}
                variant="contained"
                onClick={onChangeNetworkBtn}
            >
                {!isLoading && !hardwareWalletLoading ? (
                    <img alt="" css={styles.imageStyle} src={ETHIcon} />
                ) : (
                    <CircularProgress color="secondary" css={styles.spinner} size={24} />
                )}
                Switch network
            </Button>

            <Typography mt={2} textAlign="center" variant="nav">
                Please switch your wallet network to the Ethereum Mainnet to use this feature.
            </Typography>
        </Box>
    );
}
