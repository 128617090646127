/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { investorPositions } from '../contract-info';
import { InvestorPositions } from './types/investorPositions';

let instance: InvestorPositions;

export function investorPositionsContract(): InvestorPositions {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(
        investorPositions.abi,
        investorPositions.address,
    ) as any as InvestorPositions;
    return instance;
}
