/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import { StableTokenAvax } from '../../../../constants';
import { getVaultAdaptor } from '../../../../lib/contract-info';
import { checkIsApproved } from '../../../../lib/web3/approve';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { selectExchangeState } from '../../../exchange/store/exchange.selectors';
import {
    addWalletTransaction,
    setTransactionStatus,
} from '../../../transaction/store/transactions.reducer';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { selectLabsToken, selectRemainingAllowance } from '../labs.selectors';

export const generateLabsTransactionThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const wallet = selectWalletAccount(getState());
        const exchange = selectExchangeState(getState());
        const allowance = selectRemainingAllowance(getState());
        const token = exchange.token as StableTokenAvax;
        const name = selectLabsToken(getState());
        const amountFrom = exchange.tokens.from[token];
        const amountToDeposit =
            exchange.direction === ExchangeDirection.investAvax &&
            amountFrom?.isGreaterThan(allowance)
                ? allowance
                : amountFrom;
        const amount = removeTokenDecimals(token, amountToDeposit) || new BigNumber(0);
        const vaultAdaptorInfo = getVaultAdaptor(name);
        const isApproved = await checkIsApproved(token, wallet, amount, vaultAdaptorInfo.address);

        if (
            exchange.direction === ExchangeDirection.investAvax &&
            isApproved.isLessThanOrEqualTo(0)
        ) {
            dispatch(
                addWalletTransaction({
                    // for pools we setup a huge amount instead of defaulting for LP supply
                    amount,
                    // by default undefined will generate an approval with total supply
                    // amount: undefined,
                    direction: exchange.direction,
                    meta: {
                        token,
                    },
                    mmTid: uuid(),
                    tid,
                    token,
                    type: TransactionType.approve,
                }),
            );
        }

        const mmTid = uuid();

        dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            addWalletTransaction({
                amount,
                direction: exchange.direction,
                meta: {
                    from: {
                        [token]: amount,
                    },
                    to: {
                        [token]: amount,
                    },
                },
                mmTid,
                status: TransactionStatus.idle,
                tid,
                token,
                type: TransactionType.exchange,
            }),
        );

        // Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
