/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { VaultAvaxNames } from '../../constants';
import { bouncerContract } from '../abis';
import { getVaultAdaptor } from '../contract-info';
import { convertToBN } from './web3.helpers';

type Claim = {
    amount?: BigNumber;
    merkleProof?: string[];
    name: VaultAvaxNames;
};

const merkleMock = [
    '0x897ac22c180ce81af2a0a4b42e157f72103462b28457010bc8f437ec30c2f10a',
    '0xad1192f12c2e45d557631eca0d8d521430610bb16d5a6438a65b21c490a3631e',
    '0x543c039bb87887bd891f3c28574c4d03dce8f4512c496fadc85b432e0a1a0b62',
    '0x2e9fa86e3cedcb2220326653e8b5c8b3d018943510aaaf1756e2e30267e28758',
];

export function claimAllowance({
    amount = new BigNumber(0),
    merkleProof = merkleMock,
    name,
}: Claim) {
    const vaultAdaptorInfo = getVaultAdaptor(name);
    return bouncerContract().methods.claim(
        convertToBN(amount),
        vaultAdaptorInfo.address,
        merkleProof,
    );
}
