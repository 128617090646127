import React from 'react';
import { css } from '@emotion/react';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { GroInfoIcon, TextOrSkeleton } from '../../../components';
import { ArrowDepositIcon } from '../../../components/icons';
import { ToolTipText } from '../../../components/typography/ToolTipText';
import { VaultAvaxNames } from '../../../constants';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoadingGroMcStats } from '../../stats/store/stats.selectors';
import { formatPct } from '../../utils/percentage.helpers';
import { ReactComponent as AssetsUpIcon } from '../assets/assets-up.svg';
import { ReactComponent as GasIcon } from '../assets/gas.svg';
import { ReactComponent as RobotIcon } from '../assets/robot.svg';
import { ReactComponent as ScaleIcon } from '../assets/scale.svg';
import { selectAllTimeApy } from '../store/labs.selectors';

export function LabsStrategy({
    name,
    smallDevices,
}: {
    name: VaultAvaxNames;
    smallDevices?: boolean;
}): React.ReactElement {
    const theme = useTheme();
    const allTimeAPY = useAppSelector(selectAllTimeApy(name));
    const loading = useAppSelector(selectIsLoadingGroMcStats);

    function checkIfActiveLab(): boolean {
        return name.includes('1_6');
    }

    const styles = {
        icon: css`
            & path {
                fill: ${theme.palette.grey[100]};
            }
        `,
    };

    return (
        <Box maxWidth={smallDevices ? '100%' : '296px'} mt={3} pr={2.5}>
            <Box>
                {!smallDevices && (
                    <Typography color="text.tertiary" variant="nav">
                        About strategy
                    </Typography>
                )}
                <Box display="flex" mt={2}>
                    <AssetsUpIcon
                        css={css`
                            width: 18px;
                            & path {
                                fill: ${theme.palette.grey[100]};
                            }
                        `}
                    />
                    <Typography color="text.secondary" ml={2} variant="nav">
                        Pure, compounding, stablecoin yield without vesting
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <ArrowDepositIcon css={styles.icon} />
                    <Typography
                        color="text.secondary"
                        ml={checkIfActiveLab() ? 1.5 : 1.75}
                        variant="nav"
                    >
                        {checkIfActiveLab()
                            ? 'Yield accrues continuously'
                            : 'Yield accrues automagically several times per day'}
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <GasIcon css={styles.icon} />
                    <Typography color="text.secondary" ml={1.75} variant="nav">
                        Crazy low gas fees on Avalanche network
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <RobotIcon css={styles.icon} />
                    <Typography color="text.secondary" ml={1.75} variant="nav">
                        Automates borrowing and liquidity provision
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <ScaleIcon css={styles.icon} />
                    <Typography color="text.secondary" ml={1.75} variant="nav">
                        Regularly rebalances to control impermanent loss
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <WarningAmberIcon sx={{ color: theme.palette.grey[100], fontSize: '16px' }} />
                    <Typography color="text.secondary" ml={1.75} variant="nav">
                        Strategy cycles will sometimes have negative returns
                    </Typography>
                </Box>
                <Box display="flex" mt={1.25}>
                    <EqualizerIcon sx={{ color: theme.palette.grey[100], fontSize: '16px' }} />
                    <Typography color="text.secondary" ml={1.75} variant="nav">
                        Average APY
                    </Typography>
                    <Tooltip
                        disableInteractive
                        title={<ToolTipText>Average historical realised returns</ToolTipText>}
                    >
                        <Typography color="text.secondary" ml={1.75} variant="nav">
                            <TextOrSkeleton loading={loading}>
                                {allTimeAPY.isLessThan(0) && '-'}
                                {formatPct(allTimeAPY)}
                            </TextOrSkeleton>
                            <GroInfoIcon
                                css={css`
                                    margin-left: 5px;
                                    margin-bottom: -2px;
                                `}
                                height={14}
                                stroke={theme.palette.text.tertiary}
                                width={14}
                            />
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box mt={3}>
                <a
                    css={css`
                        text-decoration: none;
                    `}
                    href="https://docs.gro.xyz/gro-docs/gro-products/labs"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                >
                    <Typography color="text.tertiary" variant="nav">
                        Read about leveraged yield with Labs{' '}
                        <OpenInNewRoundedIcon
                            sx={{ height: '14px', marginBottom: '-2px', width: '14px' }}
                        />
                    </Typography>
                </a>
            </Box>
        </Box>
    );
}
