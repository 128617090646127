import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { add, format } from 'date-fns';
import { Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { TeamVestingState } from './teamVesting.store';

export function selectTeamVestingState(state: RootState): TeamVestingState {
    return state.teamVesting;
}

export const selectPositionsAmount = createSelector(selectTeamVestingState, (teamVesting) =>
    teamVesting.positions
        .map((position) => ({
            available: position.available,
            cliff: format(add(position.startTime * 1000, { years: 1 }), 'd/MM/yy'),
            id: position.id,
            investor: position.investor,
            locked: position.balance.minus(position.vested),
            percentage: position.vested.dividedBy(position.balance).multipliedBy(100),
            startTime: format(position.startTime * 1000, 'd/MM/yy'),
            stopTime: position.stopTime === 0 ? '--' : format(position.stopTime * 1000, 'd/MM/yy'),
            vested: position.vested,
            withdrawn: position.withdrawn,
        }))
        .sort((a, b) => a.id - b.id)
        .filter((elem) => elem.locked.isGreaterThan(0) || elem.vested.isGreaterThan(0)),
);

export const selectTotalLockedTeamVestingAmount = createSelector(
    selectTeamVestingState,
    (teamVesting) =>
        teamVesting.positions.reduce(
            (curr, next) => curr.plus(next.balance.minus(next.available)),
            new BigNumber(0),
        ),
);

export const selectTotalWithdrawnTeamVestingAmount = createSelector(
    selectTeamVestingState,
    (teamVesting) =>
        teamVesting.positions.reduce((curr, next) => curr.plus(next.withdrawn), new BigNumber(0)),
);

export const selectTotalClaimableTeamVestingAmount = createSelector(
    selectTeamVestingState,
    (teamVesting) =>
        teamVesting.positions.reduce((curr, next) => curr.plus(next.available), new BigNumber(0)),
);

export const selectTeamVestingLoading = createSelector(
    selectTeamVestingState,
    (teamVesting) => teamVesting.status !== Status.ready && teamVesting.status !== Status.error,
);

export const selectOpenTeamClaimModal = createSelector(
    selectTeamVestingState,
    (teamVesting) => teamVesting.openClaimModal,
);

export const selectSelectedTeamPosition = createSelector(selectTeamVestingState, (state) => {
    const { positions, selectedId } = state;
    return positions.find((elem) => elem.id === selectedId);
});

export const selectBalancePosition = createSelector(
    selectSelectedTeamPosition,
    (position) => position?.available || new BigNumber(0),
);

export const selectedTeamPositionId = createSelector(
    selectTeamVestingState,
    (state) => state.selectedId,
);
