import BigNumber from 'bignumber.js';
import { VaultAvaxNames } from '../../../constants';
import { ExchangeDirection, Status, StatusState } from '../../app/app.types';

export type TokenValues = {
    assets: number;
    credit: number;
    currentAllowance: number;
    debt: number;
    depositLimit: number;
    deposited: number;
    pricePerShare: number;
    vaultBalance: number;
};

export type LabsState = {
    amount: BigNumber;
    deprecatedScreen: boolean;
    direction?: ExchangeDirection;
    openAllowance: boolean;
    openIncreasedAllowance: boolean;
    token?: VaultAvaxNames;
} & StatusState & {
        [key in VaultAvaxNames]: TokenValues;
    };

const emptyValues = {
    assets: 0,
    credit: 0,
    currentAllowance: 0,
    debt: 0,
    deposited: 0,
    depositLimit: 0,
    pricePerShare: 0,
    vaultBalance: 0,
};

export const initialLabsState: LabsState = {
    [VaultAvaxNames['groDAI.e_vault']]: emptyValues,
    [VaultAvaxNames['groDAI.e_vault_v1_5']]: emptyValues,
    [VaultAvaxNames['groDAI.e_vault_v1_5_1']]: emptyValues,
    [VaultAvaxNames['groDAI.e_vault_v1_6']]: emptyValues,
    [VaultAvaxNames['groDAI.e_vault_v1_7']]: emptyValues,
    [VaultAvaxNames['groUSDC.e_vault']]: emptyValues,
    [VaultAvaxNames['groUSDC.e_vault_v1_5']]: emptyValues,
    [VaultAvaxNames['groUSDC.e_vault_v1_5_1']]: emptyValues,
    [VaultAvaxNames['groUSDC.e_vault_v1_6']]: emptyValues,
    [VaultAvaxNames['groUSDC.e_vault_v1_7']]: emptyValues,
    [VaultAvaxNames['groUSDT.e_vault']]: emptyValues,
    [VaultAvaxNames['groUSDT.e_vault_v1_5']]: emptyValues,
    [VaultAvaxNames['groUSDT.e_vault_v1_5_1']]: emptyValues,
    [VaultAvaxNames['groUSDT.e_vault_v1_6']]: emptyValues,
    [VaultAvaxNames['groUSDT.e_vault_v1_7']]: emptyValues,
    amount: new BigNumber(0),
    deprecatedScreen: false,
    direction: undefined,
    error: undefined,
    openAllowance: false,
    openIncreasedAllowance: false,
    status: Status.idle,
    token: undefined,
};
