import React from 'react';
import { css, useTheme } from '@emotion/react';

export type SlippageOption = {
    label: React.ReactElement;
    name: string;
    value: string;
};

export function useSlippageOptions(): SlippageOption[] {
    const theme = useTheme();

    // Define styles inside to avoid infinite render loop
    const styles = {
        optionAmount: css`
            font-size: 14px;
            font-weight: 500;
            color: ${theme.palette.grey[200]};
            margin-right: 4px;
        `,

        optionText: css`
            font-size: 12px;
            text-transform: capitalize;
            color: ${theme.palette.grey[200]};
        `,
    };

    return [
        {
            label: <b css={styles.optionAmount}>1%</b>,
            name: 'high',
            value: '1',
        },
        {
            label: <b css={styles.optionAmount}>0.5%</b>,
            name: 'standard',
            value: '0.5',
        },
        {
            label: <b css={styles.optionAmount}>0.25%</b>,
            name: 'low',
            value: '0.25',
        },
        {
            label: <span css={styles.optionText}>Custom</span>,
            name: 'custom',
            value: 'custom',
        },
    ].reverse();
}
