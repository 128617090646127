import React from 'react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useFormatedAddress } from '../../../wallet/hooks/useFormattedAddress';

export function ConfirmedLink({
    hash,
    link,
    timestamp,
}: {
    hash?: string;
    link: string;
    timestamp: number;
}): React.ReactElement {
    const theme = useTheme();
    const formattedAddress = useFormatedAddress(hash || '');

    return (
        <a
            href={link}
            rel="noreferrer"
            style={{
                alignItems: 'flex-start',
                color: theme.palette.text.tertiary,
                display: 'flex',
                fontSize: '14px',
                textDecoration: 'none',
            }}
            target="_blank"
            title="Confirmed on-chain"
        >
            <Typography color="text.tertiary" variant="nav">
                {formattedAddress}
            </Typography>

            {formattedAddress && (
                <React.Fragment>
                    <OpenInNewRoundedIcon
                        fontSize="inherit"
                        style={{ marginLeft: '4px', marginTop: '1px' }}
                    />
                    <Typography color="text.tertiary" ml={1} variant="body2Small">
                        {format(timestamp, 'HH:mm')}
                    </Typography>
                </React.Fragment>
            )}
        </a>
    );
}
