/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { Token, TOKENS } from '../../../constants';
import { addTokenDecimals, addTokenObjectDecimals } from '../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../app/app.types';
import {
    CurrentSessionTransactions,
    initialTransactionsState,
    Transaction,
    TransactionStatus,
    TransactionType,
    WalletTransaction,
} from './transactions.store';

const transactionsSlice = createSlice({
    initialState: initialTransactionsState,
    name: 'transactions',
    reducers: {
        addTransaction: (state, { payload }: PayloadAction<Transaction>) => {
            state.currentSession[payload.id] = payload;
        },

        addWalletTransaction: (
            state,
            {
                payload,
            }: PayloadAction<{
                amount?: BigNumber;
                direction?: ExchangeDirection;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                meta: any;
                mmTid: string;
                status?: TransactionStatus;
                tid: string;
                token: Token;
                type: TransactionType;
                usdAmount?: BigNumber;
            }>,
        ) => {
            // If transactions have already been resolves/discarded, do nothing
            if (!state.currentSession[payload.tid]) {
                return;
            }

            // const queue = [...state.currentSession[tid].queue];

            // queue.push(<WalletTransaction>);

            state.currentSession[payload.tid].queue.push({
                amount: payload.amount,
                direction: payload.direction,
                error: payload.error,
                id: payload.mmTid,
                meta: {
                    ...payload.meta,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    from: addTokenObjectDecimals(payload.meta?.from || {}),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    to: addTokenObjectDecimals(payload.meta?.to || {}),
                },
                status: payload.status || TransactionStatus.idle,
                timestamp: Date.now(),
                token: payload.token,
                type: payload.type,
                updatedTimestamp: Date.now(),
                usdAmount: addTokenDecimals(TOKENS.USD, payload.usdAmount),
            });
        },
        removeTransaction: (state, { payload }: PayloadAction<string>) => {
            const transactions = { ...state.currentSession };
            delete transactions[payload];
            state.currentSession = transactions;
        },

        setTransactionConfirmed: (state, { payload }: PayloadAction<string>) => {
            // mark transaction as confirmed by user so we do not redirect to somewhere anymore
            state.currentSession[payload].confirmed = true;
            state.currentSession[payload].updatedTimestamp = Date.now();
        },
        setTransactions: (state, { payload }: PayloadAction<CurrentSessionTransactions>) => {
            state.currentSession = payload;
        },
        setTransactionStatus: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { payload }: PayloadAction<{ error?: any; status: TransactionStatus; tid: string }>,
        ) => {
            // If transactions have already been resolves/discarded, do nothing
            if (!state.currentSession[payload.tid]) {
                return state;
            }
            state.currentSession[payload.tid].status = payload.status;
            state.currentSession[payload.tid].error = payload.error;
            state.currentSession[payload.tid].updatedTimestamp = Date.now();
        },
        setWalletTransactionHash: (
            state,
            { payload }: PayloadAction<{ hash: string; mmTid: string; tid: string }>,
        ) => {
            const mmtIdx = state.currentSession[payload.tid].queue.findIndex(
                (t) => t.id === payload.mmTid,
            );
            const queue = [...state.currentSession[payload.tid].queue];
            queue[mmtIdx].transactionHash = payload.hash;
            state.currentSession[payload.tid].updatedTimestamp = Date.now();
            state.currentSession[payload.tid].queue = queue;
        },
        setWalletTransactionStatus: (
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                mmTid: string;
                status: TransactionStatus;
                tid: string;
            }>,
        ) => {
            // If transactions have already been resolves/discarded, do nothing
            if (
                !state.currentSession[payload.tid] ||
                !state.currentSession[payload.tid].queue.find((e) => e.id === payload.mmTid)
            ) {
                return state;
            }

            const queue = [...state.currentSession[payload.tid].queue] as WalletTransaction[];
            const idx = queue.findIndex((e: WalletTransaction) => e.id === payload.mmTid);

            queue[idx] = {
                ...queue[idx],
                error: payload.error,
                status: payload.status,
                updatedTimestamp: Date.now(),
            };

            state.currentSession[payload.tid].queue = queue;
        },
        updateTransactionSource: (
            state,
            { payload }: PayloadAction<{ amount?: BigNumber; tid: string; token: Token }>,
        ) => {
            state.currentSession[payload.tid].from[payload.token] =
                addTokenDecimals(payload.token, payload.amount) || new BigNumber(0);
        },
        updateTransactionTarget: (
            state,
            { payload }: PayloadAction<{ amount?: BigNumber; tid: string; token: Token }>,
        ) => {
            state.currentSession[payload.tid].to[payload.token] =
                addTokenDecimals(payload.token, payload.amount) || new BigNumber(0);
        },
    },
});

export const {
    addTransaction,
    addWalletTransaction,
    removeTransaction,
    setTransactionConfirmed,
    setTransactions,
    setTransactionStatus,
    setWalletTransactionHash,
    setWalletTransactionStatus,
    updateTransactionSource,
    updateTransactionTarget,
} = transactionsSlice.actions;

export const transactionsReducer = transactionsSlice.reducer;
