/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { exitRewards } from '../../../../lib/web3/vesting';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { initWalletThunk } from '../../../wallet/store/thunks/initWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { updateGROValuesThunk } from './updateGROValuesThunk';

export const executeExitTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());
        const { queue } = selectCurrentSessionTransaction(tid)(getState());

        const mmTransaction = queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const method: NonPayableTransactionObject<any> = exitRewards(mmTransaction.amount);

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(updateGROValuesThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
            void dispatch(initWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
