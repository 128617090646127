import React from 'react';
import { Box, BoxProps, Tooltip, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { GroInfoIcon } from '../../../components';
import { ToolTipText } from '../../../components/typography/ToolTipText';
import { useAppSelector } from '../../app/hooks';
import { formatPct } from '../../utils/percentage.helpers';
import diamondLogo from '../assets/diamond.png';
import { selectVestingBonusPct } from '../store/rewards.selectors';

type TpVestingPctInfo = {
    amount: BigNumber;
} & Pick<BoxProps, 'sx'>;

export function VestingPctInfo({ amount, sx }: TpVestingPctInfo): React.ReactElement {
    const vestingPct = useAppSelector(selectVestingBonusPct(amount));
    const theme = useTheme();

    const isZero = vestingPct.isZero();

    const color = isZero ? theme.palette.grey[200] : theme.palette.info.light;

    return (
        <Box display="flex" sx={sx}>
            <img alt="gro_logo" height="32px" src={diamondLogo} width="32px" />
            <Box display="flex" flexDirection="column" justifyContent="flex-start" ml={1.5}>
                <Typography color={color} variant="h3">
                    +{formatPct(vestingPct)} $GRO
                </Typography>
                <Tooltip
                    title={
                        <ToolTipText>
                            More locked GRO increases your claimable Vesting Bonus
                        </ToolTipText>
                    }
                >
                    <Box display="flex">
                        <Typography color={color} mr={0.5} variant="nav">
                            to your Vesting Bonus
                        </Typography>
                        <GroInfoIcon stroke={color} />
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    );
}
