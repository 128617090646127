/**
 * Web3 helper functions
 */
import BigNumber from 'bignumber.js';
import { Token } from '../../constants';
import { daiContract } from '../abis/daiContract';
import { tokenContract } from '../abis/tokenContract';

/**
 *
 * @param wallet - address of the selected account
 * @param token - token name for which to get the balance
 */
export async function fetchBalanceOf(wallet: string, token: Token): Promise<BigNumber> {
    if (!wallet) return new BigNumber(0);
    try {
        const wei = await tokenContract(token).methods.balanceOf(wallet).call();
        // if (token === TOKENS.USDT) return new BigNumber(98798733333)
        // if (token === TOKENS.DAI) return new BigNumber(11111100000000101010100)
        return new BigNumber(wei);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('fetchBalanceOf.error', token);
        return new BigNumber(0);
    }
}

export async function fetchNonce(wallet: string): Promise<string> {
    return daiContract().methods.nonces(wallet).call();
}
