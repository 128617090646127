/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React from 'react';
import { css } from '@emotion/react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TimerIcon from '@mui/icons-material/Timer';
import { Box, Button, Collapse, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { TokenProviderIcon } from '../../../../components';
import { TransactionCard } from '../../../../components/cards';
import { GroModalHeader, GroModalTxn } from '../../../../components/GroModal';
import { ArrowWithdrawIcon } from '../../../../components/icons';
import { TOKENS } from '../../../../constants';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { useAppSelector } from '../../../app/hooks';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { ConfirmationModal } from '../../../transaction/components/ConfirmationModal';
import { formatNumber } from '../../../utils/currency.helpers';
import { useClaimModal } from '../../hooks/useClaimModal';
import { setClaimToWallet } from '../../store/rewards.reducer';
import { NewDateInfo } from '../NewDateInfo';
import { VestingPctInfo } from '../VestingPctInfo';

export function ClaimModal(): React.ReactElement {
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);
    const groStats = useAppSelector(selectGroStatsMc);
    const dispatch = useDispatch();

    const {
        onBack,
        onClaim,
        onClose,
        open,
        rewardToClaim,
        selectedAmount,
        startTxn,
        vestingColor,
        vestingSelected,
        walletAmount,
        walletColor,
        walletColorWarning,
        walletSelected,
    } = useClaimModal(theme);

    const styles = {
        info: css`
            background: ${theme.palette.info.superDark};
            border-radius: 8px;
        `,
        label: css`
            & .MuiFormControlLabel-label {
                font-size: ${isXsSize ? '16px' : '20px'};
                font-weight: 500;
            }
        `,
        option: css`
            border: 1px solid ${theme.palette.grey[700]};
            padding: ${theme.spacing(0, 2)};
            border-radius: 8px;
            color: ${theme.palette.grey[200]};
            cursor: pointer;
        `,
        selected: css`
            background: ${theme.palette.common.black};
            color: ${theme.palette.common.white};
        `,
        submit: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        vestingIcon: css`
            & path {
                fill: ${vestingColor};
            }
        `,
        walletIcon: css`
            & path {
                fill: ${walletColor};
            }
        `,
        walletIconWarning: css`
            & path {
                fill: ${walletColorWarning};
            }
        `,
    };

    return (
        <GroModalTxn isOpen={open} width="432px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={4} px={isXsSize ? 1.5 : 3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography mt={1} variant="h1">
                            {rewardToClaim.vesting ? 'Claim to Vesting Contract' : 'Claim Rewards'}
                        </Typography>
                    </GroModalHeader>
                    <Typography color="text.tertiary" mb={2.5} mt={0.5} variant="body2">
                        Choose how you like to claim your rewards.
                    </Typography>
                    {/* <TransactionCard
                        amount={rewardToClaim?.amount}
                        balance={rewardToClaim?.amount}
                        balanceToDollars={new BigNumber(rewardToClaim?.amount || 0).multipliedBy(
                            new BigNumber(groStats.mainnet?.token_price_usd.gro),
                        )}
                        selected={vestingSelected}
                        text="Lock them"
                        token={TOKENS.GRO_SINGLE_SIDED}
                        onClick={(): void => {
                            dispatch(setClaimToWallet(false));
                        }}
                    /> */}
                    <Box mt={1}>
                        <TransactionCard
                            amount={walletAmount}
                            balance={walletAmount}
                            balanceToDollars={new BigNumber(walletAmount || 0).multipliedBy(
                                new BigNumber(groStats.mainnet?.token_price_usd.gro),
                            )}
                            selected={walletSelected}
                            text="Claim now"
                            token={TOKENS.GRO_SINGLE_SIDED}
                            onClick={(): void => {
                                dispatch(setClaimToWallet(true));
                            }}
                        />
                    </Box>
                    <Box css={styles.info} my={3} p={2}>
                        <Box alignItems="center" display="flex" justifyContent="space-between">
                            {/* <Box alignItems="center" display="flex">
                                <TimerIcon css={styles.vestingIcon} sx={{ fontSize: '13px' }} />
                                <Typography color={vestingColor} ml={1} variant="nav">
                                    Vest ({vestingSelected ? 100 : 0}%){' '}
                                </Typography>
                            </Box> */}
                            {/* <Box display="flex">
                                <TokenProviderIcon
                                    css={styles.vestingIcon}
                                    fill={vestingColor}
                                    tokenProvider={rewardToClaim?.token || ''}
                                    width="10px"
                                />
                                <Typography color={vestingColor} ml={0.5} variant="nav">
                                    {formatNumber(
                                        vestingSelected ? rewardToClaim?.amount : new BigNumber(0),
                                    )}
                                </Typography>
                            </Box> */}
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                            mt={0.5}
                        >
                            <Box alignItems="center" display="flex">
                                <AccountBalanceWalletIcon
                                    css={styles.walletIcon}
                                    sx={{ fontSize: '13px' }}
                                />
                                <Typography color={walletColor} ml={1} variant="nav">
                                    Claim to wallet ({walletSelected ? 100 : 0}%){' '}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <TokenProviderIcon
                                    css={styles.walletIcon}
                                    tokenProvider={rewardToClaim?.token || ''}
                                    width="10px"
                                />
                                <Typography color={walletColor} ml={0.5} variant="nav">
                                    {formatNumber(
                                        walletSelected ? walletAmount : new BigNumber(0),
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="space-between"
                            mt={0.5}
                        >
                            {/* <Box alignItems="center" display="flex">
                                <ArrowWithdrawIcon
                                    css={[
                                        styles.walletIconWarning,
                                        css`
                                            width: 15px;
                                        `,
                                    ]}
                                />
                                <Typography color={walletColorWarning} ml={1} variant="nav">
                                    You&apos;ll lose ({walletSelected ? 70 : 0}%){' '}
                                </Typography>
                            </Box> */}
                            {/* <Box display="flex">
                                <TokenProviderIcon
                                    css={styles.walletIconWarning}
                                    tokenProvider={rewardToClaim?.token || ''}
                                    width="10px"
                                />
                                <Typography color={walletColorWarning} ml={0.5} variant="nav">
                                    {formatNumber(
                                        walletSelected
                                            ? rewardToClaim?.amount.minus(walletAmount)
                                            : new BigNumber(0),
                                    )}
                                </Typography>
                            </Box> */}
                        </Box>
                        {/* <VestingPctInfo
                            amount={vestingSelected ? selectedAmount : new BigNumber(0)}
                            sx={{ mt: 2 }}
                        />
                        <Collapse in={vestingSelected}>
                            <NewDateInfo amount={selectedAmount} sx={{ mt: 2 }} />
                        </Collapse> */}
                    </Box>
                    <Button
                        fullWidth
                        color="secondary"
                        css={styles.submit}
                        variant="contained"
                        onClick={onClaim}
                    >
                        Claim rewards
                    </Button>
                </Box>
            ) : (
                <ConfirmationModal isOpen={startTxn} onBack={onBack} onClose={onClose} />
            )}
        </GroModalTxn>
    );
}
