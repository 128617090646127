/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BigNumber from 'bignumber.js';
import { teamVestingContract } from '../abis/teamVestingContract';
import { convertToBN } from './web3.helpers';

export async function fetchNumberOfPositions(wallet: string): Promise<number> {
    const positions = await teamVestingContract()
        .methods.numberOfContributorPositions(wallet)
        .call();
    return parseInt(positions, 10);
}

export async function fetchPositionBalance(wallet: string, id: number): Promise<BigNumber> {
    const balance = await teamVestingContract().methods.positionBalance(wallet, id).call();
    return new BigNumber(balance);
}

export async function fetchPositionVestedBalance(
    wallet: string,
    id: number,
): Promise<{ available: BigNumber; vested: BigNumber }> {
    const { available, vested } = await teamVestingContract()
        .methods.positionVestedBalance(wallet, id)
        .call();
    return { available: new BigNumber(available), vested: new BigNumber(vested) };
}

export async function fetchContributorPositions(
    wallet: string,
    id: number,
): Promise<{ startTime: number; stopTime: number; total: BigNumber; withdrawn: BigNumber }> {
    const { startTime, stopTime, total, withdrawn } = await teamVestingContract()
        .methods.contributorPositions(wallet, id)
        .call();
    return {
        startTime: parseInt(startTime, 10),
        stopTime: parseInt(stopTime, 10),
        total: new BigNumber(total),
        withdrawn: new BigNumber(withdrawn),
    };
}

export function claimTeamVesting(amount: BigNumber, id: number) {
    return teamVestingContract().methods.claim(convertToBN(amount), id);
}
