/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */

import React, { useState } from 'react';
import { css } from '@emotion/react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, BoxProps, Collapse, Divider, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { TextOrSkeleton } from '..';
import { TOKENS } from '../../constants';
import { useAppSelector } from '../../modules/app/hooks';
import { GroPriceCalculation } from '../../modules/rewards/components/GroPriceCalculation';
import { PwrdPriceCalculation } from '../../modules/rewards/components/PwrdPriceCalculation';
import { formatAsCurrency, formatNumber } from '../../modules/utils/currency.helpers';
import { selectIsCorrectNetwork } from '../../modules/wallet/store/wallet.selectors';
import { TokenIcon } from '../icons';
import { BalancePill } from './ProductCard/BalancePill';

type HeaderInfo = {
    actionComponent?: React.ReactElement;
    isGrey?: boolean;
    label: string;
    show?: boolean;
    showColor?: boolean;
    token?: string;
    value: BigNumber;
};

type PpMobileHeadersCard = {
    balance: BigNumber;
    dollarReturns?: boolean;
    headerInfo: HeaderInfo[];
    isLoading: boolean;
    returns?: BigNumber;
    sx: BoxProps;
    title: string;
};

export function MobileHeaderCard({
    balance,
    dollarReturns,
    headerInfo,
    isLoading,
    returns,
    sx,
    title,
}: PpMobileHeadersCard): React.ReactElement {
    const theme = useTheme();
    const [showDetails, setShowDetails] = useState(false);

    const isCorrectNetwork = useAppSelector(selectIsCorrectNetwork);

    function onToggleCollapse(): void {
        if (!headerInfo.length) return;
        setShowDetails(!showDetails);
    }

    const styles = {
        groIcon: css`
            margin-right: 4px;
            margin-bottom: -1px;
        `,
        wrapper: css`
            background: #0a1824;
            border-radius: 8px;
            cursor: pointer;
        `,
    };

    return (
        <Box css={styles.wrapper} p={1.5} sx={sx} onClick={onToggleCollapse}>
            <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box>
                    <Box alignItems="center" display="flex" mb={1}>
                        <Typography color="text.tertiary" variant="h3">
                            {title}
                        </Typography>
                        {!!returns && (
                            <BalancePill
                                isGro={!dollarReturns}
                                loading={false}
                                returns={returns}
                                tooltipText=""
                            />
                        )}
                    </Box>
                    <TextOrSkeleton loading={isLoading}>
                        <Typography variant="h1">
                            {isCorrectNetwork ? formatAsCurrency(balance) : '--'}
                        </Typography>
                    </TextOrSkeleton>
                </Box>
                {!!headerInfo.length && (
                    <Box>
                        {showDetails ? (
                            <KeyboardArrowUpIcon sx={{ verticalAlign: 'bottom' }} />
                        ) : (
                            <KeyboardArrowDownIcon sx={{ verticalAlign: 'bottom' }} />
                        )}
                    </Box>
                )}
            </Box>
            <Collapse in={showDetails}>
                <Box mt={3}>
                    {headerInfo.map(
                        ({ actionComponent, isGrey, label, show, showColor, token, value }, i) =>
                            show !== false ? (
                                <React.Fragment key={`header-card-${label}`}>
                                    {actionComponent && <Divider sx={{ mb: 2 }} />}
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        mt={i === 0 || isGrey ? 1 : 2}
                                    >
                                        <Box>
                                            <Typography
                                                color={isGrey ? 'text.tertiary' : 'text.primary'}
                                                mb={actionComponent ? 0.5 : 0}
                                                variant="h3"
                                            >
                                                {label}
                                            </Typography>
                                            {actionComponent || <Box />}
                                        </Box>
                                        <Box
                                            alignItems="flex-end"
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography
                                                color={
                                                    showColor && value.isGreaterThan(0)
                                                        ? theme.palette.success.light
                                                        : isGrey
                                                        ? 'text.tertiary'
                                                        : 'text.primary'
                                                }
                                                variant="h3"
                                            >
                                                {value.isGreaterThan(0) &&
                                                    isCorrectNetwork &&
                                                    showColor && (
                                                        <ArrowDropUpIcon
                                                            sx={{
                                                                fontSize: '16px',
                                                                marginBottom: '-2px',
                                                            }}
                                                        />
                                                    )}
                                                {isCorrectNetwork ? (
                                                    <TextOrSkeleton loading={isLoading}>
                                                        {token ? (
                                                            <TokenIcon
                                                                css={styles.groIcon}
                                                                fill="currentColor"
                                                                token={token}
                                                                width="12px"
                                                            />
                                                        ) : (
                                                            '$'
                                                        )}
                                                        {formatNumber(value)}
                                                    </TextOrSkeleton>
                                                ) : (
                                                    '--'
                                                )}
                                            </Typography>
                                            {token && isCorrectNetwork && token === TOKENS.GRO && (
                                                <GroPriceCalculation
                                                    amount={value}
                                                    isLoading={false}
                                                />
                                            )}
                                            {token &&
                                                isCorrectNetwork &&
                                                token === TOKENS.PWRD && (
                                                    <PwrdPriceCalculation
                                                        amount={value}
                                                        isLoading={false}
                                                    />
                                                )}
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <Box key={`header-card-${label}`} />
                            ),
                    )}
                </Box>
            </Collapse>
        </Box>
    );
}
