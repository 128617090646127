import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { TokenIcon } from '../../../components';
import { MigratePoolsModal } from '../../../components/MigratePoolsModal';
import { TOKENS } from '../../../constants';
import { ExchangeDirection } from '../../app/app.types';
import { useAppSelector } from '../../app/hooks';
import { useExchange } from '../../exchange/hooks/useExchange';
import { PoolLaunchTransactionModal } from '../../pools/components/PoolLaunchTransactionModal';
import { selectHasPoolsBalance, selectStakedPools } from '../../pools/store/pools.selectors';
import { ClaimAllBtn } from '../../rewards/components/ClaimAllBtn';
import { ExitGroModal } from '../../rewards/components/Modals/ExitGroModal';
import { setIsUstClaim } from '../../rewards/store/rewards.reducer';
import {
    selectLockedUst,
    selectUnvestedGRO,
    selectVestedGRO,
    selectVestedPWRD,
    selectVestingEndDateUst,
} from '../../rewards/store/rewards.selectors';
import { TpPool } from '../../stats/stats.types';
import { formatAsCurrency, formatNumber } from '../../utils/currency.helpers';
import { selectAllPoolsRewards, selectTokenInUSD } from '../../wallet/store/wallet.selectors';
import { ReactComponent as PwrdIcon } from '../assets/pwrd.svg';
import { ReactComponent as VaultIcon } from '../assets/vault.svg';
import { PortocolSection } from '../components/ProtocolSection';
import { useForceNetworkChange } from '../hooks/useForceNetworkChange';
import { useGetRedeemData } from '../hooks/useGetRedeemData';

export function Protocol(): React.ReactElement {
    const dispatch = useDispatch();
    const [openExit, setOpenExit] = useState(false);
    const [openPool, setOpenPool] = useState(false);
    const [pool, setPool] = useState<TpPool | undefined>();

    const { onExchange } = useExchange(TOKENS.GVT);
    const pwrdExchange = useExchange(TOKENS.PWRD);
    const allPoolsRewards = useAppSelector(selectAllPoolsRewards);

    const vestedGro = useAppSelector(selectVestedGRO);
    const unvestedGro = useAppSelector(selectUnvestedGRO);
    const hasPoolsBalance = useAppSelector(selectHasPoolsBalance);

    const gvtUSD = useAppSelector(selectTokenInUSD(TOKENS.GVT));
    const pwrdUSD = useAppSelector(selectTokenInUSD(TOKENS.PWRD));
    const lockedPwrd = useAppSelector(selectLockedUst);
    const vestedPwrd = useAppSelector(selectVestedPWRD);
    const pwrdEndDate = useAppSelector(selectVestingEndDateUst);

    const pools = useAppSelector(selectStakedPools);

    const showPwrd = useMemo(
        () =>
            pwrdUSD.isGreaterThanOrEqualTo(0.01) ||
            vestedPwrd.isGreaterThanOrEqualTo(0.01) ||
            lockedPwrd.isGreaterThanOrEqualTo(0.01),
        [pwrdUSD, vestedPwrd, lockedPwrd],
    );

    useGetRedeemData();

    useForceNetworkChange({ network: 'mainnet' });

    const styles = {
        button: css`
            width: 88px;
            height: 24px;
            padding: 0;
        `,
        grey: css`
            opacity: 0.7;
        `,
        link: css`
            text-decoration: none;
            color: #deb8fc;
        `,
    };

    function onSelectPool(p: TpPool): void {
        setPool(p);
        setOpenPool(true);
    }

    function onClaimUST(): void {
        dispatch(setIsUstClaim(true));
        setOpenExit(true);
    }

    return (
        <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center" mt={10} pb={2} px={2}>
            <Box maxWidth="444px">
                <Typography fontWeight={600} variant="h5">
                    Gro Products
                </Typography>
                <Typography mt={3} variant="body2">
                    Gro protocol is indefinitely available through{' '}
                    <a
                        css={styles.link}
                        href="https://etherscan.io/address/0xd4139e090e43ff77172d9dd8ba449d2a9683790d"
                        rel="noreferrer"
                        target="_blank"
                    >
                        block explorers
                    </a>{' '}
                    (see{' '}
                    <a
                        css={styles.link}
                        href="https://medium.com/gro-protocol/using-a-block-explorer-to-interact-with-gro-protocol-e30d739a90be"
                        rel="noreferrer"
                        target="_blank"
                    >
                        guide
                    </a>{' '}
                    for using block explorers) , but its strategies no longer generate yield. This
                    site is intended to simplify product interactions for wallets to easily
                    withdraw and claim any remaining assets. The protocol remains{' '}
                    <a
                        css={styles.link}
                        href="https://github.com/groLabs/ "
                        rel="noreferrer"
                        target="_blank"
                    >
                        open sourced
                    </a>{' '}
                    and is available as a multiple times audited and battle-tested DeFi building
                    block. Consider unstaking and withdrawing any assets that you still have in the
                    products.
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="444px">
                {(vestedGro.isGreaterThan(0.01) ||
                    allPoolsRewards.isGreaterThanOrEqualTo(0.01)) && (
                    <PortocolSection
                        background=" linear-gradient(0deg, rgba(180, 82, 255, 0.08) 0%, rgba(180, 82, 255, 0.08) 100%)"
                        color="#E8CBFF"
                        titleText="GRO Vesting"
                    >
                        <Box>
                            {allPoolsRewards.isGreaterThanOrEqualTo(0.01) && (
                                <Box>
                                    <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                        Unclaimed GRO
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box alignItems="center" display="flex">
                                            <TokenIcon
                                                fill="white"
                                                height="14px"
                                                token={TOKENS.GRO}
                                                width="14px"
                                            />
                                            <Typography fontWeight="500" ml={0.5}>
                                                {formatNumber(allPoolsRewards)}
                                            </Typography>
                                        </Box>
                                        <ClaimAllBtn />
                                    </Box>
                                </Box>
                            )}

                            {vestedGro.isGreaterThan(0.01) && (
                                <Box mt={allPoolsRewards.isGreaterThanOrEqualTo(0.01) ? 2.5 : 0}>
                                    <Box>
                                        <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                            Locked GRO
                                        </Typography>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box alignItems="center" display="flex">
                                                <TokenIcon
                                                    fill="white"
                                                    height="14px"
                                                    token={TOKENS.GRO}
                                                    width="14px"
                                                />
                                                <Typography fontWeight="500" ml={0.5}>
                                                    {formatNumber(unvestedGro)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box mt={2.5}>
                                        <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                            Vested GRO
                                        </Typography>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box alignItems="center" display="flex">
                                                <TokenIcon
                                                    fill="white"
                                                    height="14px"
                                                    token={TOKENS.GRO}
                                                    width="14px"
                                                />
                                                <Typography fontWeight="500" ml={0.5}>
                                                    {formatNumber(vestedGro)}
                                                </Typography>
                                            </Box>
                                            <Button
                                                color="secondary"
                                                css={styles.button}
                                                variant="contained"
                                                onClick={(): void => {
                                                    dispatch(setIsUstClaim(false));

                                                    setOpenExit(true);
                                                }}
                                            >
                                                Claim
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </PortocolSection>
                )}
                {gvtUSD.isGreaterThan(0.01) && (
                    <PortocolSection
                        background="linear-gradient(0deg, rgba(250, 119, 121, 0.12) 0%, rgba(250, 119, 121, 0.12) 100%)"
                        color="#FFC9C6"
                        title={
                            <Box alignItems="center" display="flex">
                                <VaultIcon />
                                <Typography color="#FFC9C6" fontWeight="500" ml={1}>
                                    (GVT)
                                </Typography>
                            </Box>
                        }
                    >
                        <Box>
                            <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                Balance
                            </Typography>
                            <Box display="flex" justifyContent="space-between">
                                <Typography fontWeight="500">
                                    {formatAsCurrency(gvtUSD)}
                                </Typography>
                                <Button
                                    color="secondary"
                                    css={styles.button}
                                    variant="contained"
                                    onClick={(): void => onExchange(ExchangeDirection.withdraw)}
                                >
                                    Withdraw
                                </Button>
                            </Box>
                        </Box>
                    </PortocolSection>
                )}
                {showPwrd && (
                    <PortocolSection
                        background="linear-gradient(0deg, rgba(150, 205, 255, 0.08) 0%, rgba(150, 205, 255, 0.08) 100%)"
                        color="#A4DEFF"
                        title={
                            <Box alignItems="center" display="flex">
                                <PwrdIcon />
                                <Typography color="#A4DEFF" fontWeight="500" ml={1}>
                                    (PWRD)
                                </Typography>
                            </Box>
                        }
                    >
                        <Box>
                            {pwrdUSD.isGreaterThan(0.01) && (
                                <Box>
                                    <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                        Balance
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography fontWeight="500">
                                            {formatAsCurrency(pwrdUSD)}
                                        </Typography>
                                        <Button
                                            color="secondary"
                                            css={styles.button}
                                            variant="contained"
                                            onClick={(): void =>
                                                pwrdExchange.onExchange(ExchangeDirection.withdraw)
                                            }
                                        >
                                            Withdraw
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {lockedPwrd.isGreaterThanOrEqualTo(0.01) && (
                                <Box mt={pwrdUSD.isGreaterThan(0.01) ? 2.5 : 0}>
                                    <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                        Vesting PWRD Locked
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography fontWeight="500">
                                            {formatAsCurrency(lockedPwrd)}{' '}
                                            <span css={styles.grey}>
                                                vesting until{' '}
                                                {format(
                                                    new Date(pwrdEndDate * 1000),
                                                    'HH:mm dd MMM yyyy',
                                                )}
                                            </span>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {vestedPwrd.isGreaterThanOrEqualTo(0.01) && (
                                <Box
                                    mt={
                                        pwrdUSD.isGreaterThan(0.01) ||
                                        lockedPwrd.isGreaterThanOrEqualTo(0.01)
                                            ? 2.5
                                            : 0
                                    }
                                >
                                    <Box>
                                        <Typography fontWeight="500" mb={1} sx={{ opacity: 0.7 }}>
                                            Vesting PWRD Unlocked
                                        </Typography>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography fontWeight="500">
                                                    {formatAsCurrency(vestedPwrd)}
                                                </Typography>
                                            </Box>

                                            <Button
                                                color="secondary"
                                                css={styles.button}
                                                variant="contained"
                                                onClick={onClaimUST}
                                            >
                                                Claim
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </PortocolSection>
                )}
                {hasPoolsBalance && (
                    <PortocolSection
                        background=" linear-gradient(0deg, rgba(180, 82, 255, 0.08) 0%, rgba(180, 82, 255, 0.08) 100%)"
                        color="#E8CBFF"
                        titleText="Pools"
                    >
                        <Box>
                            {pools.map((p, index) => (
                                <Box key={p.name}>
                                    <Typography
                                        fontWeight="500"
                                        mt={index !== 0 ? 2.5 : 0}
                                        sx={{ opacity: 0.7 }}
                                    >
                                        Staked - {p.display_name}
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography fontWeight="500">
                                            {formatAsCurrency(
                                                new BigNumber(
                                                    p.staked || new BigNumber(0),
                                                ).multipliedBy(p.lp_usd_price),
                                            )}
                                        </Typography>
                                        <Button
                                            color="secondary"
                                            css={styles.button}
                                            variant="contained"
                                            onClick={(): void => onSelectPool(p)}
                                        >
                                            Unstake
                                        </Button>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </PortocolSection>
                )}
            </Box>
            <ExitGroModal isOpen={openExit} onClose={(): void => setOpenExit(false)} />
            {pool ? (
                <PoolLaunchTransactionModal
                    actionType={ExchangeDirection.unstake}
                    isOpen={openPool}
                    pool={pool}
                    setIsOpen={setOpenPool}
                    onSubmit={(): void => {
                        setOpenPool(false);
                    }}
                />
            ) : null}
            <MigratePoolsModal />
        </Box>
    );
}
