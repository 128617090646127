/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { PoolToken } from '../../../../constants';
import { NonPayableTransactionObject } from '../../../../lib/abis/types/types';
import { stake, unStake, unstakePWRD } from '../../../../lib/web3/pools';
import { fetchBalanceOf } from '../../../../lib/web3/wallet';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import { initExchangeFlowsThunk } from '../../../exchange/store/thunks/initExchangeFlowsThunk';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { loadGroStatsMcThunk } from '../../../stats/store/thunks/loadGroStatsMcThunk';
import { loadUserStatsMcThunk } from '../../../stats/store/thunks/loadUserStatsMcThunk';
import { setWalletTransactionStatus } from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import {
    TransactionStatus,
    WalletTransaction,
} from '../../../transaction/store/transactions.store';
import { isPoolToken } from '../../../utils/token.helpers';
import { sendTransaction } from '../../../utils/transaction.helpers';
import { initPoolsWalletThunk } from '../../../wallet/store/thunks/initPoolsWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { getPoolPID } from '../../pools.helpers';

export const executePoolTransactionThunk: AppActionThunk =
    (tid: string, mmTid: string) => async (dispatch, getState) => {
        const wallet = selectWalletAccount(getState());

        const { queue } = selectCurrentSessionTransaction(tid)(getState());
        const mmTransaction = queue.find(
            (e: WalletTransaction) => e.id === mmTid,
        ) as WalletTransaction;

        if (!mmTransaction.token || !isPoolToken(mmTransaction.token)) {
            dispatch(
                setWalletTransactionStatus({
                    error: 'Internal Error: invalid Pool token',
                    mmTid,
                    status: TransactionStatus.error,
                    tid,
                }),
            );
            return;
        }

        dispatch(
            setWalletTransactionStatus({
                mmTid,
                status: TransactionStatus.pendingMmApproval,
                tid,
            }),
        );

        const sourceTokenAmount =
            removeTokenDecimals(
                mmTransaction.token,
                Object.values(mmTransaction.meta.from)[0] as BigNumber,
            ) || new BigNumber(0);

        const maxToDeposit = await fetchBalanceOf(wallet, mmTransaction.token);
        const groStats = selectGroStatsMc(getState());
        const pid = getPoolPID(mmTransaction.token as PoolToken, groStats.mainnet?.pools);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let method: NonPayableTransactionObject<any>;
        if (mmTransaction.direction === ExchangeDirection.stake) {
            const amount = sourceTokenAmount.isGreaterThan(maxToDeposit)
                ? maxToDeposit
                : sourceTokenAmount;
            method = stake(pid, amount);
        } else if (pid === '6') {
            method = unstakePWRD(pid);
        } else {
            method = unStake(pid, sourceTokenAmount);
        }

        function onTransactionDone(): void {
            dispatch(initExchangeFlowsThunk());
            void dispatch(loadGroStatsMcThunk());
            void dispatch(loadUserStatsMcThunk(wallet));
            void dispatch(initPoolsWalletThunk(wallet));
        }

        await sendTransaction(method, dispatch, mmTid, tid, getState, onTransactionDone);
    };
