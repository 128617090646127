import React from 'react';
import { css } from '@emotion/react';
import { Box, Skeleton, useTheme } from '@mui/material';

export function LoadingCard(): React.ReactElement {
    const theme = useTheme();
    const styles = {
        wrapper: css`
            background: ${theme.palette.grey[800]};
            border: 1px solid ${theme.palette.grey[600]};
            border-radius: 12px;
            cursor: cursor;
        `,
    };
    return (
        <Box css={styles.wrapper} height="79px" px={2} py={1.5} width="100%">
            <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box flex="1">
                    <Skeleton height="30px" width="60px" />
                </Box>

                <Box display="flex" flex="1" justifyContent="flex-end">
                    <Skeleton height="30px" />
                </Box>
            </Box>
            <Box display="flex">
                <Skeleton height="30px" width="120px" />
            </Box>
        </Box>
    );
}
