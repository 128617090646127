/* eslint-disable func-style */

import BigNumber from 'bignumber.js';
import { v4 as uuid } from 'uuid';
import { GrowthToken, Token, TOKENS } from '../../../../constants';
import { checkIsApproved } from '../../../../lib/web3/approve';
import { growthToStables, tokenToUsd } from '../../../../lib/web3/convert';
import { fetchBalanceOf } from '../../../../lib/web3/wallet';
import { removeTokenDecimals } from '../../../../lib/web3/web3.helpers';
import { ExchangeDirection } from '../../../app/app.types';
import { AppActionThunk } from '../../../app/store';
import {
    addWalletTransaction,
    setTransactionStatus,
    updateTransactionSource,
    updateTransactionTarget,
} from '../../../transaction/store/transactions.reducer';
import { selectCurrentSessionTransaction } from '../../../transaction/store/transactions.selectors';
import { TransactionStatus, TransactionType } from '../../../transaction/store/transactions.store';
import { fetchMaxWalletTokens } from '../../../wallet/services/wallet.services';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { getFromTokens } from '../../helpers/exchange.helpers';
import { createApproval } from '../exchange.helpers';
import {
    selectExchangeConversions,
    selectExchangeState,
    selectUnstakeAmount,
} from '../exchange.selectors';

export const generateWithdrawTransactionThunk: AppActionThunk =
    (tid: string) =>
    async (dispatch, getState): Promise<void> => {
        const wallet = selectWalletAccount(getState());
        const exchange = selectExchangeState(getState());
        const conversions = selectExchangeConversions(getState());
        const transaction = selectCurrentSessionTransaction(tid)(getState());
        const unstakeAmount = selectUnstakeAmount(getState());
        const grwthToken = exchange.token as GrowthToken;
        const grwthAmount =
            removeTokenDecimals(grwthToken, exchange.tokens.from[grwthToken]) || new BigNumber(0);

        const fromTokens = await getFromTokens({
            conversions: exchange.conversions,
            tokens: transaction.from,
            tokensFrom: exchange.tokens.from,
            wallet,
        });

        const promises = fromTokens
            // Launch approvals
            .map((item) =>
                checkIsApproved(item[0], wallet, item[1]).then((approval): [string, BigNumber] => [
                    item[0],
                    approval,
                ]),
            );

        const approvalMap = await Promise.all(promises);

        approvalMap.forEach(([token, isApproved]) => {
            createApproval(
                dispatch,
                isApproved,
                ExchangeDirection.withdraw,
                grwthAmount,
                tid,
                token as Token,
            );
        });

        // Check if we are withdrawing all the tokens, and remove all dust
        const isWithdrawAllTransaction = await fetchMaxWalletTokens(
            wallet,
            grwthToken,
            tokenToUsd(grwthToken, grwthAmount, conversions),
            conversions,
        );

        const totalGrwthAmount = isWithdrawAllTransaction
            ? await fetchBalanceOf(wallet, grwthToken)
            : grwthAmount;

        // 1. Calculate conversion amount
        const mmTid = uuid();

        const toToken = Object.keys(exchange.tokens.to)[0] as Token;
        const toTokenValue = Object.values(exchange.tokens.to)[0];

        const stableAmount = removeTokenDecimals(toToken, toTokenValue);

        const coins = await growthToStables(
            grwthToken,
            totalGrwthAmount,
            Object.keys(transaction.to) as Token[],
            conversions,
        );

        Object.entries(coins).forEach(([coin, amount]) => {
            dispatch(updateTransactionTarget({ amount, tid, token: coin as Token }));
        });

        dispatch(updateTransactionSource({ amount: grwthAmount, tid, token: grwthToken }));

        // Check if user wants to unstake
        if (unstakeAmount.isGreaterThan(0)) {
            const unstakeToken =
                grwthToken === TOKENS.GVT ? TOKENS.GVT_SINGLE_SIDED : TOKENS.PWRD_SINGLE_SIDED;
            const amount = removeTokenDecimals(unstakeToken, unstakeAmount) || new BigNumber(0);
            dispatch(
                addWalletTransaction({
                    direction: ExchangeDirection.unstake,
                    meta: {
                        from: {
                            [unstakeToken]: amount,
                        },
                        to: {
                            [grwthToken]: amount,
                        },
                    },
                    mmTid: uuid(),
                    status: TransactionStatus.idle,
                    tid,
                    token: unstakeToken,
                    type: TransactionType.exchange,
                }),
            );
        }

        dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            addWalletTransaction({
                direction: ExchangeDirection.withdraw,
                meta: {
                    from: {
                        [grwthToken]: grwthAmount,
                    },
                    to: {
                        [toToken]: stableAmount,
                    },
                },
                mmTid,
                status: TransactionStatus.idle,
                tid,
                token: grwthToken,
                type: TransactionType.exchange,
                usdAmount: tokenToUsd(grwthToken, grwthAmount, conversions),
            }),
        );

        // 3. Mark transactions state as ready
        dispatch(setTransactionStatus({ status: TransactionStatus.ready, tid }));
    };
