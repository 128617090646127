/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Approve a transaction being done the first time
 */

import { BigNumber } from 'bignumber.js';
import { Token } from '../../constants';
import { tokenContract } from '../abis/tokenContract';
import { g2Router } from '../contract-info';
import { convertToBN } from './web3.helpers';

/**
 * Check whether the user has approved us to make transactions on their behalf.
 *
 * N.B. This checks whether the allowance is at the order of the total token supply i.e. a lot
 * greater than the amount the user is ever likely to transact
 *
 *
 * @param token
 * @param targetToken
 * @param wallet
 * @return BigNumber return > 0 if allowed, 0 not allowed and not approved, -1 approved but not allowed because not engough amount
 */
export async function checkIsApproved(
    token: Token,
    wallet: string,
    amount: BigNumber,
    // some cases we need to pass LPStaker address here
    spenderAddress?: string,
): Promise<BigNumber> {
    const contract = tokenContract(token);
    const address = spenderAddress || g2Router.address;

    const allowance = new BigNumber(await contract.methods.allowance(wallet, address).call());

    if (allowance.isZero()) {
        return allowance;
    }

    const isAllowed = allowance.minus(amount);
    // plus 1 for (amount = allowance) cases
    return isAllowed.isLessThan(0) ? isAllowed : isAllowed.plus(1);
}

/**
 * Generates the method which when called `send` upon, sends the transaction for confirmation to
 * MetaMask.
 *
 * In the ideal world, we would do all web3 related actions (calling `send`) in this function and
 * return a promise which can then be awaited to process the results. Unfortunately the world
 * isn't ideal. Web3 return a PromiEvent when you call `send`. If you try to return a PromiEvent
 * from an async method or call `then` on it, it automatically gets converted to a native Promise
 * and you loose the ability to handle the generated Web3 events (like `once`, `on` etc).
 *
 * @param sourceToken
 * @param targetToken
 */

export async function generateApproveMethod(
    sourceToken: Token,
    _targetToken: Token,
    spenderAddress?: string,
    amount?: BigNumber,
    // some cases we need to pass LPStaker address here
) {
    const supply = await tokenContract(sourceToken).methods.totalSupply().call();
    const supplyBN = new BigNumber(supply);

    const address = spenderAddress || g2Router.address;

    return tokenContract(sourceToken).methods.approve(address, convertToBN(amount || supplyBN));
}
