/* eslint-disable @typescript-eslint/no-unused-vars */

import BigNumber from 'bignumber.js';
import { priceOracleContract } from '../abis/priceOracleContract';
import { convertToBN } from './web3.helpers';

export async function calculateDeposit(
    amount: BigNumber,
    index: number,
    slippage: BigNumber,
    tranche: boolean,
): Promise<BigNumber> {
    const result = await priceOracleContract()
        .methods.deposit(convertToBN(amount), convertToBN(slippage), index, tranche)
        .call();
    const [tokenAmounts, minAmounts, oracleAmount] = Object.values(result);
    return new BigNumber(oracleAmount);
}

export async function calculateWithdraw(
    amount: BigNumber,
    index: number,
    slippage: BigNumber,
    tranche: boolean,
): Promise<{ oracleAmount: BigNumber; tokenAmount: BigNumber }> {
    const result = await priceOracleContract()
        .methods.withdraw(convertToBN(amount), convertToBN(slippage), index, tranche)
        .call();
    const [tokenAmounts, minAmounts, oracleAmount] = Object.values(result);
    return {
        oracleAmount: new BigNumber(oracleAmount),
        tokenAmount: new BigNumber(tokenAmounts),
    };
}
