import { format, utcToZonedTime } from 'date-fns-tz';

// duration in millis
export function getCountdownValues(duration: number): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
} {
    let days = duration / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let seconds = (minutes % 1) * 60;
    [days, hours, minutes, seconds] = [
        Math.floor(days),
        Math.floor(hours),
        Math.floor(minutes),
        Math.floor(seconds),
    ];
    return {
        days,
        hours,
        minutes,
        seconds,
    };
}

// unix timestamp, in seconds
export function getUnixTimestamp(timestamp?: number): number {
    if (timestamp) {
        return Math.floor(timestamp / 1000);
    }
    return Math.floor(Date.now() / 1000);
}

// by default in UTC without timezones, timestamp in millis
export function formatInTimeZone(date: number, formatString: string, tz = 'UTC'): string {
    if (!date) {
        return '';
    }
    return format(utcToZonedTime(date * 1000, tz), formatString, { timeZone: tz });
}
