/* eslint-disable react/hook-use-state */

import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { GroIcon, TransactionCard } from '../../../../components';
import { GroModalHeader, GroModalTxn } from '../../../../components/GroModal';
import { ExchangeArrowIcon } from '../../../../components/icons';
import { TOKENS } from '../../../../constants';
import { useGetSizes } from '../../../../theme/hooks/useGetSizes';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../../exchange/store/thunks/createNewTransactionThunk';
import { ReactComponent as LockIcon } from '../../../labs/assets/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../labs/assets/unlock.svg';
import { selectGroStatsMc } from '../../../stats/store/stats.selectors';
import { ConfirmationModal } from '../../../transaction/components/ConfirmationModal';
import { formatNumber } from '../../../utils/currency.helpers';
import { setExitToWallet } from '../../store/rewards.reducer';
import {
    selectIsUstClaim,
    selectTotalVesting,
    selectVestedPWRD,
} from '../../store/rewards.selectors';

function ExitGro({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}): React.ReactElement {
    const theme = useTheme();
    const { isXsSize } = useGetSizes(theme);
    const [startTxn, setStartTxn] = useState(false);
    const isUstClaim = useAppSelector(selectIsUstClaim);

    const text = isUstClaim ? 'Claim PWRD' : 'Exit & get GRO';

    const dispatch = useDispatch();
    const [sliderValue, setValue] = useState(50);

    function handleChange(event: Event, value: number | number[]): void {
        if (Array.isArray(value)) return;
        setValue(value);
    }

    const totalGro = useAppSelector(selectTotalVesting);
    const groStats = useAppSelector(selectGroStatsMc);
    const vestedPWRD = useAppSelector(selectVestedPWRD);
    const selectedGro = useMemo(
        () => totalGro.multipliedBy(sliderValue / 100),
        [totalGro, sliderValue],
    );

    const pct = useMemo(() => {
        const toWallet = new BigNumber(sliderValue).multipliedBy(100 / 100);
        const toForfeit = new BigNumber(sliderValue).multipliedBy((100 - 100) / 100);
        const remaining = new BigNumber(100).minus(sliderValue);
        const toUnlocked = remaining.multipliedBy(100 / 100);
        const toLocked = remaining.multipliedBy((100 - 100) / 100);
        return {
            forfeit: { gro: totalGro.multipliedBy(toForfeit.dividedBy(100)), pct: toForfeit },
            locked: { gro: totalGro.multipliedBy(toLocked.dividedBy(100)), pct: toLocked },
            unlocked: {
                gro: totalGro.multipliedBy(toUnlocked.dividedBy(100)),
                pct: toUnlocked,
            },
            wallet: { gro: totalGro.multipliedBy(toWallet.dividedBy(100)), pct: toWallet },
        };
    }, [sliderValue, totalGro]);

    const balanceExceeded = useMemo(
        () => selectedGro.isGreaterThan(totalGro),
        [totalGro, selectedGro],
    );

    const styles = {
        arrowIcon: css`
            transform: rotate(90deg);
            margin-left: 40%;
        `,
        buttonSubmit: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        fromWrapper: css`
            border: 1px solid ${theme.palette.grey[200]};
            border-radius: 8px;
            background: ${theme.palette.common.black};
        `,
        groForfeitWrapper: css`
            border: 1.5px solid ${theme.palette.warning.light};
            border-radius: 5px;
        `,
        groGetWrapper: css`
            border: 1.5px solid ${theme.palette.gro.superLight};
            border-radius: 5px;
        `,
        icon: css`
            & path {
                fill: ${theme.palette.grey[100]};
            }
            height: 12px;
            width: 12px;
        `,
        inputWrapper: css`
            & div {
                border-bottom: none;
            }
            & input {
                padding: 0;
            }
        `,
        resultWrapper: css`
            background: ${theme.palette.info.superDark};
        `,
        toWrapper: css`
            border: 1.5px solid ${theme.palette.grey[400]};
            background: ${theme.palette.grey[800]};
            border-radius: 5px;
        `,
    };

    function onExit(): void {
        dispatch(setExitToWallet(pct.wallet.gro));
        dispatch(resetExchange());
        dispatch(
            setExchangeDirection(isUstClaim ? ExchangeDirection.claimUst : ExchangeDirection.exit),
        );
        dispatch(setGrwthToken(isUstClaim ? TOKENS.PWRD : TOKENS.GRO));
        dispatch(
            addTokenLhs({
                [isUstClaim ? TOKENS.PWRD : TOKENS.GRO]: isUstClaim
                    ? vestedPWRD
                    : new BigNumber(selectedGro),
            }),
        );
        dispatch(
            addTokenRhs({
                [isUstClaim ? TOKENS.PWRD : TOKENS.GRO]: isUstClaim
                    ? vestedPWRD
                    : new BigNumber(selectedGro),
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    useEffect(() => {
        if (isOpen) {
            setStartTxn(false);
            setValue(50);
        }
    }, [isOpen]);

    return (
        <GroModalTxn isOpen={isOpen} width="400px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={4} px={isXsSize ? 1.5 : 3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography variant="h1">
                            {isUstClaim ? 'Claim PWRD' : 'Exit GRO'} rewards
                        </Typography>
                    </GroModalHeader>
                    <Box mt={3}>
                        {isUstClaim ? (
                            <Box mb={3}>
                                <TransactionCard
                                    selected
                                    showIcon
                                    amount={vestedPWRD}
                                    balance={vestedPWRD}
                                    balanceToDollars={vestedPWRD}
                                    text="PWRD"
                                    token={TOKENS.PWRD}
                                />
                            </Box>
                        ) : (
                            <React.Fragment>
                                <Typography color="text.tertiary" mb={2} variant="body2">
                                    From
                                </Typography>
                                <TransactionCard
                                    selected
                                    amount={selectedGro}
                                    balance={selectedGro}
                                    balanceToDollars={selectedGro}
                                    sliderValue={sliderValue}
                                    text="veGRO"
                                    token={TOKENS.GRO_SINGLE_SIDED}
                                    onSlide={handleChange}
                                />
                                <Box alignItems="center" display="flex">
                                    <Typography
                                        color="text.secondary"
                                        mb={2}
                                        mt={2.5}
                                        variant="body2"
                                    >
                                        To
                                    </Typography>
                                    <ExchangeArrowIcon css={styles.arrowIcon} />
                                </Box>
                                <Box css={styles.resultWrapper} mb={4}>
                                    <TransactionCard
                                        bottomCard
                                        selected
                                        amount={pct.wallet.gro}
                                        balance={pct.wallet.gro}
                                        balanceToDollars={pct.wallet.gro.multipliedBy(
                                            new BigNumber(groStats.mainnet?.token_price_usd.gro),
                                        )}
                                        text="GRO"
                                        token={TOKENS.GRO_SINGLE_SIDED}
                                    />
                                    <Box p={2}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box alignItems="center" display="flex">
                                                <AccountBalanceWalletIcon
                                                    sx={{
                                                        color: 'text.secondary',
                                                        fontSize: '14px',
                                                    }}
                                                />
                                                <Typography
                                                    color="text.secondary"
                                                    ml={1}
                                                    mr={0.3}
                                                    variant="nav"
                                                >
                                                    To your wallet{' '}
                                                    {!isXsSize &&
                                                        `(${formatNumber(pct.wallet.pct)}%)`}
                                                </Typography>
                                                {/* <GroInfoIcon height="14px" width="14px" /> */}
                                            </Box>
                                            <Box alignItems="center" display="flex">
                                                <GroIcon
                                                    fill={theme.palette.grey[100]}
                                                    height="11px"
                                                    width="11px"
                                                />
                                                <Typography
                                                    color="text.secondary"
                                                    ml={0.3}
                                                    variant="nav"
                                                >
                                                    {formatNumber(pct.wallet.gro)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            mt={0.5}
                                        >
                                            <Box alignItems="center" display="flex">
                                                <UnlockIcon css={styles.icon} />
                                                <Typography
                                                    color="text.secondary"
                                                    ml={1}
                                                    mr={0.3}
                                                    variant="nav"
                                                >
                                                    Remain unlocked{' '}
                                                    {!isXsSize &&
                                                        `(${formatNumber(pct.unlocked.pct)}%)`}
                                                </Typography>
                                                {/* <GroInfoIcon height="14px" width="14px" /> */}
                                            </Box>
                                            <Box alignItems="center" display="flex">
                                                <GroIcon
                                                    fill={theme.palette.grey[100]}
                                                    height="11px"
                                                    width="11px"
                                                />
                                                <Typography
                                                    color="text.secondary"
                                                    ml={0.3}
                                                    variant="nav"
                                                >
                                                    {formatNumber(pct.unlocked.gro)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </React.Fragment>
                        )}
                        <Button
                            fullWidth
                            color="secondary"
                            css={styles.buttonSubmit}
                            disabled={balanceExceeded}
                            variant="contained"
                            onClick={onExit}
                        >
                            {balanceExceeded ? 'Balance exceeded' : text}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModalTxn>
    );
}

export const ExitGroModal = React.memo(ExitGro);
