import React from 'react';
import { css } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { GroIcon, SmallButton } from '../../../components';
import { ReactComponent as GroLockIcon } from '../../rewards/assets/gro-lock.svg';
import groLogo from '../../rewards/assets/gro_logo.png';
import { ReactComponent as WalletIcon } from '../../rewards/assets/wallet.svg';
import { formatNumber } from '../../utils/currency.helpers';
import { formatPct } from '../../utils/percentage.helpers';
import { setOpenTeamClaimModal, setSelectedId } from '../store/teamVesting.reducer';

type TpVestingPosition = {
    available: BigNumber;
    cliff: string;
    id: number;
    locked: BigNumber;
    percentage: BigNumber;
    startTime: string;
    stopTime: string;
    withdrawn: BigNumber;
};

export function VestingPosition({
    available,
    cliff,
    id,
    locked,
    percentage,
    startTime,
    stopTime,
    withdrawn,
}: TpVestingPosition): React.ReactElement {
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = {
        groLock: css`
            width: 16px;
            height: 16px;
            margin-top: -7px;
            & path {
                fill: ${theme.palette.common.white};
            }
        `,
        iconWrapper: css`
            width: 34px;
        `,
        imgWrapper: css`
            width: 52px;
            height: 52px;
            background: linear-gradient(149.21deg, #8737cd 8.3%, #0ba7fe 183.43%);
            border: 1px solid #5466e2;
            border-radius: 4px;
        `,
        innerCircle: css`
            background: #071623;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            position: absolute;
            border-radius: 50%;
            top: 3px;
            left: 3px;
            align-items: center;
        `,
        line: css`
            position: absolute;
            background: ${theme.palette.grey[600]};
            width: 1px;
            height: 64px;
            top: -32px;
            left: 16px;
        `,
        progressBase: css`
            color: ${theme.palette.grey[600]};
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -2;
                width: 64px;
                height: 1px;
                background: ${theme.palette.grey[600]};
                right: -37%;
                bottom: 48%;
            }
        `,
        progressFilled: css`
            position: absolute;
            top: -0.95%;
            right: -1%;
            color: white;
        `,
        uppercase: css`
            text-transform: uppercase;
            font-size: 10px;
        `,
        walletWrapper: css`
            position: absolute;
            top: -18px;
            background: #071623;
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            align-items: center;
            border-radius: 50%;
            border: 1px solid ${theme.palette.grey[600]};
        `,
        wrapper: css`
            background: #071623;
            border-radius: 16px;
            width: 1008px;
        `,
    };

    function onClaim(): void {
        dispatch(setSelectedId(id));
        dispatch(setOpenTeamClaimModal(true));
    }

    return (
        <Box css={styles.wrapper} display="flex" gap="35px" mb={2} px={2.5} py={2}>
            <Box alignItems="center" display="flex">
                <Box alignItems="center" css={styles.imgWrapper} display="flex" mr={2}>
                    <img alt="positon" src={groLogo} width="100%" />
                </Box>
                <Box>
                    <Typography color="text.tertiary" mb={1.5} variant="body2">
                        Position {id}
                    </Typography>
                    <Box alignItems="center" display="flex">
                        <GroLockIcon css={styles.groLock} />
                        <Typography
                            color="text.primary"
                            lineHeight="8px"
                            mb={0.4}
                            ml={0.5}
                            variant="h3"
                        >
                            {formatNumber(locked, 0)}
                        </Typography>
                    </Box>
                    <Typography
                        color="text.tertiary"
                        css={styles.uppercase}
                        lineHeight="0px"
                        variant="labsSmall"
                    >
                        vesting {startTime}-{stopTime}
                    </Typography>
                </Box>
            </Box>
            <Box alignItems="center" display="flex">
                <Box>
                    <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                    <ChevronRightIcon
                        sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                    />
                </Box>
                <Box position="relative">
                    <CircularProgress
                        color="secondary"
                        css={styles.progressBase}
                        size="36px"
                        thickness={3}
                        value={100}
                        variant="determinate"
                    />
                    <CircularProgress
                        css={styles.progressFilled}
                        size="36px"
                        thickness={3}
                        value={percentage.toNumber()}
                        variant="determinate"
                    />
                    <Box css={styles.innerCircle}>
                        <Typography fontSize="10px" variant="body2">
                            {formatPct(percentage.dividedBy(100), false, 0)}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                    <ChevronRightIcon
                        sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                    />
                </Box>
            </Box>
            <Box alignItems="center" display="flex">
                <Box mr={10}>
                    <Box>
                        <Typography color="text.tertiary" mb={1} variant="body2">
                            Claimable
                        </Typography>
                    </Box>
                    <Box alignItems="center" display="flex">
                        <GroIcon
                            fill={
                                available.isGreaterThan(0)
                                    ? theme.palette.gro.superLight
                                    : theme.palette.grey[200]
                            }
                            width="14px"
                        />
                        <Typography
                            color={
                                available.isGreaterThan(0)
                                    ? theme.palette.gro.superLight
                                    : 'text.tertiary'
                            }
                            ml={0.5}
                            variant="h3"
                        >
                            {formatNumber(available, available.isGreaterThan(0) ? 2 : 0)}
                        </Typography>
                    </Box>
                    <Typography color="text.tertiary" css={styles.uppercase} variant="labsSmall">
                        cliff {cliff}
                    </Typography>
                </Box>
                <SmallButton
                    tiny
                    disabled={available.isEqualTo(0)}
                    text="Claim"
                    variant="contained"
                    onClick={onClaim}
                />
            </Box>
            <Box alignItems="center" display="flex">
                <Box>
                    <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                    <ChevronRightIcon
                        sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                    />
                </Box>
                <Box css={styles.iconWrapper} position="relative">
                    <Box css={styles.line} />
                    <Box css={styles.walletWrapper}>
                        <WalletIcon
                            css={css`
                                width: 16px;
                            `}
                        />
                    </Box>
                </Box>
                <Box>
                    <ChevronRightIcon sx={{ color: theme.palette.grey[600] }} />
                    <ChevronRightIcon
                        sx={{ color: theme.palette.grey[600], marginLeft: '-14px' }}
                    />
                </Box>
            </Box>
            <Box>
                <Box>
                    <Typography color="text.tertiary" mb={1} variant="body2">
                        Total claimed
                    </Typography>
                </Box>
                <Box alignItems="center" display="flex">
                    <GroIcon
                        fill={
                            withdrawn.isGreaterThan(0)
                                ? theme.palette.common.white
                                : theme.palette.grey[200]
                        }
                        width="14px"
                    />
                    <Typography
                        color={
                            withdrawn.isGreaterThan(0)
                                ? theme.palette.common.white
                                : 'text.tertiary'
                        }
                        ml={0.5}
                        variant="h3"
                    >
                        {formatNumber(withdrawn, withdrawn.isGreaterThan(0) ? 2 : 0)}
                    </Typography>
                </Box>
            </Box>

            {/* <Box display="flex" flexDirection="column" justifyContent="center" marginLeft="auto">
                <Typography color="text.tertiary" css={styles.uppercase} variant="labsSmall">
                    cliff {cliff}
                </Typography>
                <Typography color="text.tertiary" css={styles.uppercase} variant="labsSmall">
                    end {stopTime}
                </Typography>
            </Box> */}
        </Box>
    );
}
