/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { airdropsContract } from '../abis';

type Claim = {
    amount?: string;
    merkleProof?: string[];
    trancheId?: string;
    walletClaim?: boolean;
};

export function claimAirdrop({
    amount = '',
    merkleProof = [],
    trancheId = '',
    walletClaim = false,
}: Claim) {
    return airdropsContract().methods.claim(!walletClaim, trancheId, amount, merkleProof);
}
