import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { TokenIcon } from '../../../components';
import { getLabsToken } from '../store/labs.helper';

export function LabsCoinIcon({ name }: { name: string }): React.ReactElement {
    const theme = useTheme();
    const token = getLabsToken(name);
    return (
        <button
            css={css`
                width: 40px;
                left: 16px;
                position: absolute;
                border: none;
                background: none;
            `}
            type="button"
        >
            <TokenIcon
                colored
                css={css`
                    top: -20px;
                    position: absolute;
                    left: 0px;
                    color: ${theme.palette.common.white};
                    width: 40px !important;
                    height: 40px !important;
                    transition: top 0.3s;
                    &:hover {
                        top: -26px;
                    }
                `}
                height="40px"
                token={token}
                width="40px"
            />
        </button>
    );
}
