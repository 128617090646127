import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { Token, TokenObject } from '../../constants';
import { getTokenInfo } from '../contract-info';

export function convertToBN(value: BigNumber = new BigNumber(0)): string {
    return Web3.utils.toBN(value.toFormat(0, {})).toString();
}

/**
 * Transform token amount without decimals to decimals defined by its contract info
 * @param token
 * @param amount
 * @returns
 */
export function removeTokenDecimals(
    token: Token,
    amount: BigNumber = new BigNumber(0),
): BigNumber {
    const tokenInfo = getTokenInfo(token);
    return tokenInfo ? amount.shiftedBy(tokenInfo.decimals) : amount;
}

/**
 * Transform token amount with decimals to BigNumber without decimals defined by its contract info
 * @param token
 * @param amount
 * @returns
 */
export function addTokenDecimals(token: Token, amount: BigNumber = new BigNumber(0)): BigNumber {
    const tokenInfo = getTokenInfo(token);
    return tokenInfo ? amount.shiftedBy(-tokenInfo.decimals) : amount;
}

export function removeTokenObjectDecimals(coins: TokenObject): TokenObject {
    return Object.fromEntries(
        Object.entries(coins).map((entry) => [
            entry[0] as Token,
            removeTokenDecimals(entry[0] as Token, entry[1] || new BigNumber(0)),
        ]),
    );
}

export function addTokenObjectDecimals(coins: TokenObject): TokenObject {
    return Object.fromEntries(
        Object.entries(coins).map((entry) => [
            entry[0] as Token,
            addTokenDecimals(entry[0] as Token, entry[1] || new BigNumber(0)),
        ]),
    );
}
