/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, useTheme } from '@mui/material';
import { ExchangeDirection } from '../../../app/app.types';
import {
    TransactionStatus,
    TransactionType,
    WalletTransaction,
} from '../../store/transactions.store';
import { ConfirmedLink } from '../ConfirmationModal/ConfirmedLink';
import { ErrorInfo } from './ErrorInfo';
import { TokenAmount } from './TokenAmount';
import { TransactionStatusDisplay } from './TransactionStatusDisplay';
import { TransactionTitle } from './TransactionTitle';

type TpSentTranction = {
    isLast: boolean;
    link: string;
    onRetry: (tx: WalletTransaction) => void;
    transaction: WalletTransaction;
};

export function SentTransaction({
    isLast,
    link,
    onRetry,
    transaction,
}: TpSentTranction): React.ReactElement {
    const { error, status, transactionHash, updatedTimestamp } = transaction;
    const theme = useTheme();

    const isClaim = [
        ExchangeDirection.claimAirdrop,
        ExchangeDirection.claimBonus,
        ExchangeDirection.claimReward,
        ExchangeDirection.exit,
        ExchangeDirection.teamClaim,
    ].includes(transaction.direction || ExchangeDirection.invest);

    const borderColor = useMemo(() => {
        switch (status) {
            case TransactionStatus.idle:
                return theme.palette.grey[300];
            case TransactionStatus.pendingApproval:
            case TransactionStatus.pendingConfirmation:
            case TransactionStatus.pendingMmApproval:
            case TransactionStatus.timeout:
                return 'rgba(121, 165, 251, 1)';
            case TransactionStatus.confirmed:
                return theme.palette.success.light;
            case TransactionStatus.error:
                return theme.palette.error.light;
            default:
                return theme.palette.grey[300];
        }
    }, [status, theme]);

    const styles = {
        circle: css`
            border-radius: 50%;
            background: ${borderColor};
            height: 8px;
            width: 8px;
            position: absolute;
            left: -4.5px;
            bottom: -3px;
        `,
        wrapper: css`
            border-left: 2px solid ${borderColor};
            position: relative;
        `,
    };

    return (
        <Box css={styles.wrapper} mb="2px" pl={2} py={2}>
            {!!isLast && <Box css={styles.circle} />}
            <Box display="flex" justifyContent="space-between" mb={1}>
                <TransactionTitle transaction={transaction} />
                {status !== TransactionStatus.idle && <TransactionStatusDisplay status={status} />}
            </Box>
            <Box alignItems="flex-end" display="flex" justifyContent="space-between">
                <Box>
                    <TokenAmount isClaim={isClaim} transaction={transaction} />
                    {transaction.type !== TransactionType.approve &&
                        transaction.direction !== ExchangeDirection.claimUst &&
                        !isClaim && (
                            <TokenAmount toToken isClaim={isClaim} transaction={transaction} />
                        )}
                    {status === TransactionStatus.error && (
                        <ErrorInfo
                            error={error || ''}
                            onRetry={(): void => onRetry(transaction)}
                        />
                    )}
                </Box>
                {transactionHash && (
                    <Box alignItems="center" display="flex">
                        <ConfirmedLink
                            hash={transactionHash}
                            link={link}
                            timestamp={updatedTimestamp}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
