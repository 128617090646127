import React from 'react';
import { css } from '@emotion/react';
import { Box, Typography, useTheme } from '@mui/material';
import { DISCORD_LINK } from '../../../constants';
import { ReactComponent as BuildIcon } from '../../icons/build.svg';

type TpBasicAlert = {
    hide?: boolean;
    text: string;
    title: string;
};

export function BasicAlert({ hide, text, title }: TpBasicAlert): React.ReactElement | null {
    const theme = useTheme();
    const styles = {
        warning: css`
            & h2 {
                white-space: nowrap;
            }
            & svg {
                margin-right: ${theme.spacing(1)};
            }
            background: ${theme.palette.info.light};
            border-radius: 15px;
            color: ${theme.palette.common.black};
            ${theme.breakpoints.down('md')} {
                flex-wrap: wrap;
            }
            display: flex;
            padding: ${theme.spacing(2, 2)};
        `,
    };

    return (
        <Box css={styles.warning} mb={2.5}>
            <Typography mb={1} mr={4} variant="h2">
                <BuildIcon />
                {title}
            </Typography>
            <Typography>
                {text}{' '}
                {!hide && (
                    <a
                        css={css`
                            color: ${theme.palette.common.black};
                        `}
                        href={DISCORD_LINK}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                    >
                        See Discord for more details or support
                    </a>
                )}
                .
            </Typography>
        </Box>
    );
}
