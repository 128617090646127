import React from 'react'
import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'

type PortocolSectionProps = {
    background: string,
    children: React.ReactElement,
    color: string,
    title?: React.ReactElement;
    titleText?: string;
}

export function PortocolSection({ background, children, color, title, titleText }: PortocolSectionProps): React.ReactElement {
    
    const styles = {
        top: css`
        border-radius: 16px 16px 0 0;
        background: ${background}
        
    `,
        wrapper: css`
        border-radius: 16px;
        border: 1px solid rgba(138, 213, 255, 0.20);
    `
    }

    return (
        <Box css={styles.wrapper}>
            <Box css={styles.top} p={2}>
                {titleText ? <Typography color={color} fontWeight="900" variant="h2">{titleText}</Typography> : title}
            </Box>
            <Box p={2}>
                {children}
            </Box>
        </Box>
    )

}