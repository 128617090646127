import BigNumber from 'bignumber.js';
import { Token } from '../../constants';

export enum HistoryTransactionType {
    approve = 'approval',
    deposit = 'deposit',
    failure = 'failure',
    stakerDeposits = 'staker_deposits',
    stakerWithdrawals = 'staker_withdrawals',
    transferIn = 'transfer_in',
    transferOut = 'transfer_out',
    withdraw = 'withdrawal',
}

export enum HistoryTransactionStatus {
    error = 'error',
    pending = 'pending',
    success = 'success',
    timeout = 'timeout',
}

export type HistoryTransaction = {
    coinAmount?: BigNumber;
    hash: string;
    status: HistoryTransactionStatus;
    timestamp: BigNumber;
    token?: Token;
    type: HistoryTransactionType;
    usdAmount?: BigNumber;
};
