/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import BigNumber from 'bignumber.js';
import { ustClaimContract } from '../abis/ustClaimContract';
import { convertToBN } from './web3.helpers';

type TpGetVestedAmount = {
    proof: string[];
    totalAmount: BigNumber;
    wallet: string;
};

type TpInitialClaim = {
    proof: string[];
    totalClaim: BigNumber;
};

type TpGetUserInfo = {
    wallet: string;
};

export async function getVestedAmount({ proof, totalAmount, wallet }: TpGetVestedAmount) {
    const value = await ustClaimContract()
        .methods.getVestedAmount(proof, convertToBN(totalAmount), wallet)
        .call();
    return new BigNumber(value || 0);
}

export function initialClaim({ proof, totalClaim }: TpInitialClaim) {
    return ustClaimContract().methods.initialClaim(proof, convertToBN(totalClaim));
}

export function ustClaim() {
    return ustClaimContract().methods.claim();
}

export async function getUSTUserInfo({ wallet }: TpGetUserInfo) {
    const value = await ustClaimContract().methods.usersInfo(wallet).call();
    return new BigNumber(value.claimedAmount);
}

export async function getVestingEndDateUst() {
    const value = await ustClaimContract().methods.vestingEndTime().call();
    return parseInt(value, 10);
}

export async function hasClaimed(wallet: string) {
    const value = await ustClaimContract().methods.usersInfo(wallet).call();
    const claimedAmount = Object.entries(value).find((elem) => elem[0] === 'claimedAmount');
    const hasUserClaimed = new BigNumber(claimedAmount ? claimedAmount[1] : 0).isGreaterThan(0);
    return hasUserClaimed;
}

export async function getLockedUst(wallet: string, proof: string[], totalAmount: BigNumber) {
    const value = await ustClaimContract().methods.usersInfo(wallet).call();
    const claimedAmount = Object.entries(value).find((elem) => elem[0] === 'claimedAmount');
    const vested = await ustClaimContract()
        .methods.getVestedAmount(proof, convertToBN(new BigNumber(totalAmount)), wallet)
        .call();

    const result = new BigNumber(totalAmount)
        .minus(vested)
        .minus(new BigNumber(claimedAmount ? claimedAmount[1] : 0));

    return result;
}
