/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UniToken } from '../../constants';
import { web3Instance } from '../../modules/app/services';
import { getUniTokenInfo } from '../contract-info';
import { Uniswap } from './types/uniswap';

const instance: { [key in UniToken]?: any } = {};

export function uniTokenContract<T = Uniswap>(token: UniToken): T {
    if (instance[token]) return instance[token];

    const tokenInfo = getUniTokenInfo(token);

    const { Contract } = web3Instance().eth;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    instance[token] = new Contract(tokenInfo.abi, tokenInfo.address) as any as T;
    return instance[token];
}
