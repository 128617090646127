/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { ustClaim } from '../contract-info';
import { UstClaim } from './types/ustClaim';

let instance: UstClaim;

export function ustClaimContract(): UstClaim {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(ustClaim.abi, ustClaim.address) as any as UstClaim;
    return instance;
}
