/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../app/app.types';
import { initialTeamVestingState, Position } from './teamVesting.store';

const teamVestingSlice = createSlice({
    initialState: initialTeamVestingState,
    name: 'gro',
    reducers: {
        setOpenTeamClaimModal: (state, { payload }: PayloadAction<boolean>) => {
            state.openClaimModal = payload;
        },
        setPositions: (state, { payload }: PayloadAction<Position[]>) => {
            state.positions = payload;
        },
        setSelectedId: (state, { payload }: PayloadAction<number>) => {
            state.selectedId = payload;
        },
        setTeamVestingStatus(
            state,
            {
                payload,
            }: PayloadAction<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                error?: any;
                status: Status;
            }>,
        ) {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },
    },
});

export const { setOpenTeamClaimModal, setPositions, setSelectedId, setTeamVestingStatus } =
    teamVestingSlice.actions;

export const teamVestingReducer = teamVestingSlice.reducer;
