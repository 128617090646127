import BigNumber from 'bignumber.js';
import { GrowthToken, PoolToken, StableTokenAvax, TokenObject, TOKENS } from '../../../constants';
import { ExchangeDirection, Status, StatusState } from '../../app/app.types';
import { ExchangeTokens, TpLimitsExchange } from '../exchange.types';

export type ExchangeState = {
    conversions: TokenObject;
    direction: ExchangeDirection;
    isStake: boolean;
    limits: TpLimitsExchange;
    showExchangeModal: boolean;
    // last transaction modal
    showModal: boolean;
    signature?: {
        deadline: number;
        nonce: string;
        r: string;
        s: string;
        v: number;
    };
    slippage: BigNumber;
    stablePriceLoading?: boolean;
    token: GrowthToken | PoolToken | StableTokenAvax | TOKENS.CUSDC | TOKENS.DURA | TOKENS.USDC;
    tokens: ExchangeTokens;
    unstakeAmount: BigNumber;
    volatilityStatus: boolean;
} & StatusState;

export const initialExchangeState: ExchangeState = {
    // conversion rates to LP token (approximate)
    conversions: {
        dai: new BigNumber(0),
        gvt: new BigNumber(0),
        pwrd: new BigNumber(0),
        usdc: new BigNumber(0),
        usdt: new BigNumber(0),
    },

    direction: ExchangeDirection.invest,
    error: undefined,
    isStake: false,
    limits: {
        availableDepositPWRD: new BigNumber(0),
        availableWithdrawGVT: new BigNumber(0),
    },
    showExchangeModal: false,
    showModal: false,
    signature: undefined,
    slippage: new BigNumber(25),
    stablePriceLoading: false,

    status: Status.idle,
    token: TOKENS.GVT,
    tokens: {
        from: {},
        to: {},
    },
    unstakeAmount: new BigNumber(0),
    volatilityStatus: false,
};
