import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import packageJson from '../../../package.json';
import { DISCORD_LINK, MAINTENANCE_STATUS } from '../../constants';

type VersionResponse = {
    version: string;
};

type PpError500 = {
    fullScreen?: boolean;
    minHeight?: string;
};

export function Error500({
    fullScreen = false,
    minHeight = '0px',
}: PpError500): React.ReactElement {
    const [versionMissMatch, setVersionMissMatch] = useState(false);
    const theme = useTheme();
    const styles = {
        description: css`
            text-align: center;
        `,
        title: css`
            max-width: 400px;
            text-align: center;
        `,
        wrapper: css`
            ${theme.cssMixins.colCentered};
            padding: ${theme.spacing(fullScreen ? 5 : 2)};
            min-height: ${fullScreen ? '100vh' : minHeight};
        `,
    };

    function onClickReload(): void {
        window.location.reload();
    }

    function fetchVersion(): void {
        void axios.get<VersionResponse>('/manifest.json').then((resp) => {
            const versionManifest = resp.data.version;

            setVersionMissMatch(versionManifest !== packageJson.version);
        });
    }

    useEffect(() => {
        fetchVersion();
    }, []);

    const errorTitle = MAINTENANCE_STATUS ? 'Planned maintenance' : 'Oops!';
    const title = versionMissMatch ? 'Good News!' : errorTitle;

    return (
        <div css={styles.wrapper}>
            <Typography css={styles.title} variant="h5">
                {title}
            </Typography>
            <Box sx={{ maxWidth: '400px', mt: 2 }}>
                {versionMissMatch && (
                    <Typography
                        gutterBottom
                        paragraph
                        color="text.tertiary"
                        css={styles.description}
                    >
                        We now have a new version of the dApp live, please reload the page to get
                        access.
                    </Typography>
                )}
                {MAINTENANCE_STATUS && !versionMissMatch && (
                    <Typography
                        gutterBottom
                        paragraph
                        color="text.tertiary"
                        css={styles.description}
                    >
                        Gro Protocol is currently undergoing a planned upgrade and will be
                        available again soon.
                    </Typography>
                )}
                {!MAINTENANCE_STATUS && !versionMissMatch && (
                    <Typography
                        gutterBottom
                        paragraph
                        color="text.tertiary"
                        css={styles.description}
                    >
                        Something isn&apos;t working quite right, please reload page. If that
                        doesn’t let the Gro team know on{' '}
                        <a
                            href={DISCORD_LINK}
                            style={{ color: theme.palette.text.tertiary }}
                            title="Join our discord"
                        >
                            {' '}
                            discord.
                        </a>
                    </Typography>
                )}
            </Box>

            <Box sx={{ maxWidth: '300px ', mb: 4, mt: 6 }}>
                {MAINTENANCE_STATUS && (
                    <a
                        href={DISCORD_LINK}
                        style={{ textDecoration: 'none' }}
                        title="Join our discord"
                    >
                        <Button fullWidth color="secondary" size="large" variant="contained">
                            Check discord for updates
                        </Button>
                    </a>
                )}
                {!MAINTENANCE_STATUS && (
                    <Button
                        fullWidth
                        color="secondary"
                        size="large"
                        variant="contained"
                        onClick={onClickReload}
                    >
                        Reload page
                    </Button>
                )}
            </Box>
        </div>
    );
}
