import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GroModalHeader, GroModalTxn } from '../../../../components/GroModal';
import { ExchangeArrowIcon } from '../../../../components/icons';
import { getTokenInfo } from '../../../../lib/contract-info';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    setExchangeDirection,
    setGrwthToken,
} from '../../../exchange/store/exchange.reducers';
import { createNewTransactionThunk } from '../../../exchange/store/thunks/createNewTransactionThunk';
import { ConfirmationModal } from '../../../transaction/components/ConfirmationModal';
import { initWalletThunk } from '../../../wallet/store/thunks/initWalletThunk';
import { selectWalletAccount } from '../../../wallet/store/wallet.selectors';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { getLabsToken } from '../../store/labs.helper';
import { setLabsToken, setLabsTransaction } from '../../store/labs.reducer';
import {
    selectLabsToken,
    selectShouldOpenWithdrawal,
    selectTriggerWarning,
    selectUserCantWithdraw,
    selectWithdrawAmount,
} from '../../store/labs.selectors';
import { updateLabsValuesTHunk } from '../../store/thunks/updateLabsValuesThunk';
import { ToCard } from './ToCard';
import { WithdrawCard } from './WithdrawCard';

export function WithdrawModal(): React.ReactElement {
    const dispatch = useDispatch();
    const [manuallyEnabled, setManuallyEnabled] = useState(false);
    const theme = useTheme();
    const isOpen = useAppSelector(selectShouldOpenWithdrawal);
    const warning = useAppSelector(selectTriggerWarning);
    const name = useAppSelector(selectLabsToken);
    const token = getLabsToken(name);
    const amount = useAppSelector(selectWithdrawAmount);
    const wallet = useAppSelector(selectWalletAccount);
    const { displayName } = getTokenInfo(token);
    const [startTxn, setStartTxn] = useState(false);

    const warningWithdraw = useMemo(() => warning && !manuallyEnabled, [warning, manuallyEnabled]);
    const dontAllowWithdraw = useAppSelector(selectUserCantWithdraw);

    function onClose(): void {
        setManuallyEnabled(false);
        dispatch(setLabsTransaction(undefined));
        setStartTxn(false);
        dispatch(resetExchange());
    }

    useEffect(() => {
        // reset amount at when modal opens
        if (isOpen) {
            void dispatch(updateLabsValuesTHunk(wallet, name));
            void dispatch(initWalletThunk(wallet));
        }
        // eslint-disable-next-line
    }, [isOpen, dispatch]);

    useEffect(() => {
        setManuallyEnabled(false);
    }, [warning]);

    const styles = {
        arrowIcon: css`
            transform: rotate(90deg);
            margin-left: 40%;
        `,
        buttonSubmit: css`
            padding-top: 14px;
            padding-bottom: 14px;
            &.Mui-disabled {
                background: ${theme.palette.grey[400]};
                color: ${theme.palette.common.black};
            }
        `,
        lockIcon: css`
            margin-right: ${theme.spacing(16.5)};
            vertical-align: middle;
            display: inline;
        `,
        warning: css`
            background: ${theme.palette.grey[700]};
            border: 1px solid ${theme.palette.warning.light};
            border-radius: 5px;
        `,
    };

    function onWithdraw(): void {
        dispatch(resetExchange());
        dispatch(setExchangeDirection(ExchangeDirection.withdrawAvax));
        dispatch(setLabsToken(name));
        dispatch(setGrwthToken(token));
        dispatch(
            addTokenLhs({
                [token]: amount,
            }),
        );
        dispatch(
            addTokenRhs({
                [token]: amount,
            }),
        );
        dispatch(createNewTransactionThunk());
        setStartTxn(true);
    }

    return (
        <GroModalTxn isOpen={isOpen} width="400px" onClose={onClose}>
            {!startTxn ? (
                <Box pb={4} px={3}>
                    <GroModalHeader onClose={onClose}>
                        <Typography mb={0.5} mt={1} variant="h1">
                            Withdrawal
                        </Typography>
                        <Typography
                            alignItems="center"
                            color="text.tertiary"
                            display="flex"
                            variant="body1"
                        >
                            {displayName} Leveraged Yield
                        </Typography>
                    </GroModalHeader>
                    <Box mt={2.5}>
                        <Typography color="text.tertiary" mb={2}>
                            From
                        </Typography>
                        <WithdrawCard isOpen={isOpen} />
                    </Box>
                    <Box mb={2.5}>
                        <Box alignItems="center" display="flex">
                            <Typography color="text.secondary" mb={2} mt={2.5} variant="body2">
                                To
                            </Typography>
                            <ExchangeArrowIcon css={styles.arrowIcon} />
                        </Box>
                        <ToCard />
                    </Box>
                    {warning && (
                        <Box css={styles.warning} display="flex" mb={4} p={2}>
                            <Box mr={2}>
                                <WarningAmberIcon sx={{ color: theme.palette.warning.light }} />
                            </Box>
                            <Box>
                                <Typography
                                    color={theme.palette.warning.light}
                                    mb={1}
                                    variant="h3"
                                >
                                    Volatility spike
                                </Typography>
                                <Typography variant="body2">
                                    You are currently withdrawing at a loss. It&apos;s recommended
                                    you wait until the next harvest event when losses/gains will be
                                    realised.
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Button
                        fullWidth
                        color="secondary"
                        css={styles.buttonSubmit}
                        disabled={warningWithdraw || dontAllowWithdraw || amount.isZero()}
                        sx={{
                            justifyContent:
                                warningWithdraw && !dontAllowWithdraw ? 'flex-start' : 'center',
                            padding: '10px 13px',
                        }}
                        variant="contained"
                        onClick={onWithdraw}
                    >
                        {warningWithdraw && !dontAllowWithdraw && (
                            <LockIcon css={styles.lockIcon} />
                        )}
                        {dontAllowWithdraw ? 'Balance exceeded' : 'Withdraw'}
                    </Button>
                    {warning && !manuallyEnabled && (
                        <Box mt={1.5}>
                            <Typography
                                color={
                                    amount.isZero()
                                        ? theme.palette.grey[400]
                                        : theme.palette.common.white
                                }
                                sx={{ cursor: 'pointer', textDecorationLine: 'underline' }}
                                textAlign="center"
                                variant="h3"
                                onClick={(): void => setManuallyEnabled(!amount.isZero() && true)}
                            >
                                Overide to withdraw
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <ConfirmationModal
                    isOpen={startTxn}
                    onBack={(): void => setStartTxn(false)}
                    onClose={onClose}
                />
            )}
        </GroModalTxn>
    );
}
