/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';
import {
    GrowthToken,
    PoolToken,
    StableTokenAvax,
    Token,
    TokenObject,
    TOKENS,
} from '../../../constants';
import { addTokenDecimals } from '../../../lib/web3/web3.helpers';
import { ExchangeDirection, Status } from '../../app/app.types';
import { TpTvl } from '../../stats/stats.types';
import { calculateExchangeLimits } from '../helpers/limits';
import { initialExchangeState } from './exchange.store';

const exchangeSlice = createSlice({
    initialState: initialExchangeState,
    name: 'exchange',
    reducers: {
        addTokenLhs: (state, { payload }: PayloadAction<TokenObject>) => {
            state.tokens.from = payload;
        },
        addTokenRhs: (state, { payload }: PayloadAction<TokenObject>) => {
            state.tokens.to = payload;
        },
        resetExchange: (state) => {
            state = {
                ...initialExchangeState,
                conversions: state.conversions,
                direction: state.direction,
                showExchangeModal: state.showExchangeModal,
                token: state.token,
            };
            // Return the state to overwrite
            return state;
        },

        resetFullExchange: (state) => {
            state = { ...initialExchangeState };
            return state;
        },
        resetSignature: (state) => {
            state.signature = undefined;
        },

        setConversionRate: (
            state,
            { payload }: PayloadAction<{ token: Token; value: BigNumber }>,
        ) => {
            state.conversions[payload.token] = addTokenDecimals(TOKENS.USD, payload.value);
        },

        setExchangeDirection: (state, { payload }: PayloadAction<ExchangeDirection>) => {
            state.direction = payload;
        },

        setExchangeLimits: (state, { payload }: PayloadAction<TpTvl>) => {
            const limits = calculateExchangeLimits(payload);
            state.limits = limits;
        },

        setExchangeStatus: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { payload }: PayloadAction<{ error?: any; status: Status }>,
        ) => {
            state.status = payload.status;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.error = payload.error;
        },

        setGrwthToken: (
            state,
            {
                payload,
            }: PayloadAction<
                | GrowthToken
                | PoolToken
                | StableTokenAvax
                | TOKENS.CUSDC
                | TOKENS.DURA
                | TOKENS.USDC
            >,
        ) => {
            state.token = payload;
        },

        setIsStake: (state, { payload }: PayloadAction<boolean>) => {
            state.isStake = payload;
        },

        setSignature: (
            state,
            {
                payload,
            }: PayloadAction<{ deadline: number; nonce: string; r: string; s: string; v: number }>,
        ) => {
            state.signature = payload;
        },
        setSlippage: (state, { payload }: PayloadAction<BigNumber>) => {
            state.slippage = payload;
        },
        setStablePriceLoading(state, { payload }: PayloadAction<boolean | undefined>) {
            state.stablePriceLoading = payload;
        },
        setUnstakeAmount: (state, { payload }: PayloadAction<BigNumber>) => {
            state.unstakeAmount = payload;
        },
        setVolatilityStatus: (state, { payload }: PayloadAction<boolean>) => {
            state.volatilityStatus = payload;
        },
        showExchangeModal: (state, { payload }: PayloadAction<boolean | undefined>) => {
            state.showExchangeModal = !!payload;
        },
        showTransactionModal: (state, { payload }: PayloadAction<boolean | undefined>) => {
            state.showModal = !!payload;
        },
        updateLhsInvest: (
            state,
            { payload }: PayloadAction<{ price: BigNumber; token: Token; value: BigNumber }>,
        ) => {
            state.tokens = {
                from: { ...state.tokens.from, [payload.token]: payload.value },
                to: {
                    [state.token]: payload.price.dividedBy(state.conversions[state.token] || 1),
                },
            };
        },
        updateLhsWithdraw: (
            state,
            {
                payload,
            }: PayloadAction<{
                toToken: Token;
                toTokenAmount: BigNumber;
                token: Token;
                value: BigNumber;
            }>,
        ) => {
            state.tokens = {
                from: { [payload.token]: payload.value },
                to: { [payload.toToken]: payload.toTokenAmount },
            };
        },
        updateTokenLhsInvest: (
            state,
            { payload }: PayloadAction<{ token: Token; value: BigNumber }>,
        ) => {
            state.tokens = {
                from: { [payload.token]: payload.value },
                to: { [state.token]: new BigNumber(0) },
            };
        },
    },
});

export const {
    addTokenLhs,
    addTokenRhs,
    resetExchange,
    resetFullExchange,
    resetSignature,
    setConversionRate,
    setExchangeDirection,
    setExchangeLimits,
    setExchangeStatus,
    setGrwthToken,
    setIsStake,
    setSignature,
    setSlippage,
    setStablePriceLoading,
    setUnstakeAmount,
    setVolatilityStatus,
    showExchangeModal,
    showTransactionModal,
    updateLhsInvest,
    updateLhsWithdraw,
    updateTokenLhsInvest,
} = exchangeSlice.actions;

export const exchangeReducer = exchangeSlice.reducer;
