/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { dai } from '../contract-info';
import { Dai } from './types/dai';

let instance: Dai;

export function daiContract(): Dai {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(dai.abi, dai.address) as any as Dai;
    return instance;
}
