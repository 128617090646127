/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { redeemAlloy } from '../contract-info';
import { RedeemAlloy } from './types/redeemAlloy';

let instance: RedeemAlloy;

export function redeemAlloyContract(): RedeemAlloy {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(redeemAlloy.abi, redeemAlloy.address) as any as RedeemAlloy;
    return instance;
}
