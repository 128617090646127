/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import BigNumber from 'bignumber.js';
import { GrowthToken } from '../../../constants';
import { useAppSelector } from '../../app/hooks/useAppSelector';
import { TpGroStatsMc } from '../stats.types';
import {
    selectDefaultPeriodApy,
    selectGroStatsError,
    selectGroStatsMc,
    selectIsLoadingGroMcStats,
    selectMaxApy,
} from '../store/stats.selectors';

export function useGroStats(): {
    apyDefault: {
        [key in GrowthToken]: BigNumber;
    };
    error: boolean;
    groStats: TpGroStatsMc;
    isLoading: boolean;
    maxApy: BigNumber;
} {
    const groStats = useAppSelector(selectGroStatsMc);
    const maxApy = useAppSelector(selectMaxApy);
    const isLoading = useAppSelector(selectIsLoadingGroMcStats);
    const apyDefault = useAppSelector(selectDefaultPeriodApy);
    const error = useAppSelector(selectGroStatsError);

    return {
        apyDefault,
        error,
        groStats,
        isLoading,
        maxApy,
    };
}
