/* eslint-disable @typescript-eslint/no-explicit-any */
import { web3Instance } from '../../modules/app/services';
import { g2Router } from '../contract-info';
import { G2Router } from './types/g2Router';

let instance: G2Router;

export function gRouterContract(): G2Router {
    if (instance) return instance;
    const { Contract } = web3Instance().eth;
    instance = new Contract(g2Router.abi, g2Router.address) as any as G2Router;
    return instance;
}
