/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { alpha, Box, Input, Typography, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Token, TOKENS } from '../../constants';
import { tokenToUsd, usdToToken } from '../../lib/web3/convert';
import { useAppSelector } from '../../modules/app/hooks';
import {
    selectExchangeConversions,
    selectStablesLoading,
} from '../../modules/exchange/store/exchange.selectors';
import { formatAsCurrency, formatNumber } from '../../modules/utils/currency.helpers';
import { isStableToken } from '../../modules/utils/token.helpers';
import { coreThemeConstants } from '../../theme/coreThemeConstants';
import { useGetSizes } from '../../theme/hooks/useGetSizes';
import { MaskedTextField } from '../form';
import { TextOrSkeleton } from '../utils';
import { PercentInput } from './PercentInput';

type InputPropTypes = {
    amount: BigNumber;
    decimals?: number;
    format: (val: BigNumber) => string;
    isEditable?: boolean;
    isFocused?: boolean;
    onChange?: (val: BigNumber) => void;
    prefix?: string;
    reverse?: boolean;
    selected?: boolean;
    setIsFocused: (val: boolean) => void;
};

function getFormattedValue(num: BigNumber, limitBigNumber: boolean): string {
    // If number is greater than 9999999.99 hide decimals if limitBigNumber is enabled
    return num.isGreaterThan(999999.99) && limitBigNumber
        ? num.toFixed(0, BigNumber.ROUND_FLOOR)
        : num.decimalPlaces(4).toString();
}

function InputComponent({
    amount,
    decimals = 2,
    format,
    isEditable = false,
    isFocused = false,
    onChange = (_val: BigNumber) => {},
    prefix = '',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    reverse,
    selected,
    setIsFocused,
}: InputPropTypes): React.ReactElement {
    const amountTwoDp = getFormattedValue(amount, true);
    const [value, setValue] = useState<string>(amount.isEqualTo(0) ? '' : amountTwoDp);
    const [pl, setPl] = useState(value);
    const [isEditing, setIsEditing] = useState(false);
    const ref = useRef(null);
    const stablesLoading = useAppSelector(selectStablesLoading);

    const theme = useTheme();
    const { isMobileDisplay } = useGetSizes(theme);

    useEffect(() => {
        const shouldBeEmpty = amount.isEqualTo(0) && isEditing;
        setValue(shouldBeEmpty ? '' : getFormattedValue(amount, !isEditing));
    }, [amount, isEditing]);

    function handleChange(
        event: ChangeEvent<HTMLInputElement> & { target: { rawValue: string } },
    ): void {
        let newValue = event.target.rawValue.replace(prefix, '') || '0';
        if (newValue === '.') {
            newValue = '0.';
        }

        onChange(new BigNumber(newValue));
    }

    function handleFocus(): void {
        setPl(getFormattedValue(amount, false));
        setIsEditing(true);
    }

    function handleBlur(): void {
        setPl('0');
        setIsEditing(false);
        setIsFocused(false);
    }

    const styles = {
        // from typography h2
        input: css`
            font-size: ${isMobileDisplay ? '1rem' : '1.25rem'};
            font-weight: 500;
            letter-spacing: ${coreThemeConstants.letterSpacing.sm};
            line-height: ${coreThemeConstants.lineHeight.md};
            &.MuiInput-root::after {
                display: none;
            }
            &.MuiInput-root::before {
                display: none;
            }
            background-color: inherit;
            border: none;
            color: ${amount.isZero() || !selected ? theme.palette.grey[200] : 'inherit'};
            outline: none;
            text-align: ${reverse ? 'left' : 'right'};
            width: 100%;
        `,
    };

    return isEditable ? (
        <Input
            ref={ref}
            autoFocus={isEditable}
            css={styles.input}
            inputComponent={MaskedTextField}
            inputProps={{
                maxLength: 16,
                options: {
                    numeral: true,
                    numeralDecimalScale: decimals,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix,
                },
                style: {
                    padding: '0px',
                    textAlign: reverse ? 'left' : 'right',
                },
            }}
            placeholder={pl}
            readOnly={!(isEditable && isFocused)}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
        />
    ) : (
        <Typography
            style={{ color: !selected ? theme.palette.grey[200] : 'white', width: '100%' }}
            variant={isMobileDisplay ? 'h3' : 'h2'}
        >
            <TextOrSkeleton loading={stablesLoading}>
                {format(new BigNumber(amount))}
            </TextOrSkeleton>
        </Typography>
    );
}

type TokenCardInputTypes = {
    amount?: BigNumber;
    decimals?: number;
    fraction?: number;
    hidePercentage?: boolean;
    isActive?: boolean;
    isEditable?: boolean;
    isFocused?: boolean;
    onChange?: (val: BigNumber) => void;
    onFractionChange?: (val: number) => void;
    reverse?: boolean;
    selected?: boolean;
    setIsFocused?: (val: boolean) => void;
    token: Token;
};

export function TokenCardInput({
    amount = new BigNumber(0),
    decimals,
    fraction = 0,
    hidePercentage,
    isActive,
    isEditable,
    isFocused,
    onChange = (_val: BigNumber): void => {},
    onFractionChange = (_val: number): void => {},
    reverse,
    selected,
    setIsFocused = (_val: boolean) => null,
    // eslint-disable-next-line
    token,
}: TokenCardInputTypes): React.ReactElement {
    const conversions = useAppSelector(selectExchangeConversions);

    const dollarTokens = [
        TOKENS.GVT,
        TOKENS.BAL_GRO_WETH,
        TOKENS.GVT_SINGLE_SIDED,
        TOKENS.UNI_50_50,
        TOKENS.UNI_USDC_50_50,
        TOKENS.CRV_META,
        TOKENS.PWRD3CRV,
        TOKENS.GRO,
        TOKENS.PWRD_SINGLE_SIDED,
    ];

    // Used to display GVT tokens with the dollar symbol
    const formatFunc = dollarTokens.includes(token) ? formatAsCurrency : formatNumber;
    // USD to token display functions for GVT token, which will be interacted always as USD
    const value = dollarTokens.includes(token) ? tokenToUsd(token, amount, conversions) : amount;

    function handleAmountChange(val: BigNumber): void {
        if (dollarTokens.includes(token)) {
            onChange(usdToToken(token, val, conversions));
        } else {
            onChange(val);
        }
    }

    const prefix = `${dollarTokens.includes(token) && isEditable ? '$' : ''}`;

    const isStable = isStableToken(token);
    const theme = useTheme();

    const inputColor = theme.palette.common.white;

    const styles = {
        wrapper: css`
            align-items: center;
            display: flex;
            /* margin-left: ${theme.spacing(0.5)}; */
            /* margin-bottom: -4px; */
            border-color: ${isActive && isEditable
                ? isStable
                    ? alpha(theme.palette.common.black, 0.3)
                    : 'hsla(0,0%,100%,.3)'
                : 'transparent'};
            color: ${inputColor};

            flex-direction: ${reverse ? 'row-reverse' : 'inherit'};
            width: 100%;
        `,
    };

    return (
        <Box css={styles.wrapper}>
            {isEditable && !hidePercentage && (
                <PercentInput
                    dropdownBackground={
                        isStable && isActive
                            ? theme.palette.common.white
                            : theme.palette.common.black
                    }
                    dropdownColor={
                        isStable && isActive
                            ? theme.palette.common.black
                            : theme.palette.common.white
                    }
                    fraction={fraction}
                    setIsInputFocused={setIsFocused}
                    onChange={onFractionChange}
                />
            )}
            <InputComponent
                amount={value}
                decimals={decimals}
                format={formatFunc}
                isEditable={isEditable}
                isFocused={isFocused}
                prefix={prefix}
                reverse={reverse}
                selected={selected}
                setIsFocused={setIsFocused}
                onChange={handleAmountChange}
            />
        </Box>
    );
}
