/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { Token, TOKENS, TOKENS_DEPOSIT } from '../../../../constants';
import { ExchangeDirection } from '../../../app/app.types';
import { useAppSelector } from '../../../app/hooks';
import {
    selectExchangeDirection,
    selectExchangeState,
    selectIsExchangeLoading,
    selectStablesInOrder,
} from '../../store/exchange.selectors';
import { LoadingCard } from './LoadingCard';
import { TokenCardWrapper } from './TokenCardWrapper';

export function ExchangeTo({ grwthToken }: { grwthToken: Token }): React.ReactElement {
    const dispatch = useDispatch();
    const [selectedToken, setSelectedToken] = useState(TOKENS.DAI);
    const [displayTokens, setDisplayTokens] = useState<Token[]>([...TOKENS_DEPOSIT]);
    const direction = useAppSelector(selectExchangeDirection);
    const isInvest = direction === ExchangeDirection.invest;
    const exchange = useAppSelector(selectExchangeState);
    const stables = useAppSelector(selectStablesInOrder);
    const exchangeLoading = useAppSelector(selectIsExchangeLoading);

    const tokensNotAdded = useMemo(
        () => [...TOKENS_DEPOSIT].filter((elem) => elem !== selectedToken),
        [selectedToken],
    );

    const stableTk = Object.keys(exchange.tokens.to)[0] as Token;
    const tokens = (isInvest ? [] : tokensNotAdded) as [TOKENS.DAI, TOKENS.USDC];

    const onSelectToken = useCallback(
        (tk: Token) => {
            setSelectedToken(tk);
            setDisplayTokens([tk]);
        },
        [dispatch, exchange],
    );

    useEffect(() => {
        if (!isInvest) {
            onSelectToken(stableTk || stables[0]);
            setDisplayTokens([stableTk || stables[0]]);
        }
    }, [dispatch, isInvest]);

    return (
        <Box mt={3}>
            <Box alignItems="center" display="flex" justifyContent="space-between" mb={1.5}>
                <Typography color="text.tertiary">To</Typography>
            </Box>
            <TransitionGroup>
                {!exchangeLoading &&
                    (isInvest ? [grwthToken] : displayTokens).map((token) => (
                        <Collapse key={token}>
                            <Box mb={1}>
                                <TokenCardWrapper
                                    bottomCard
                                    token={token}
                                    tokensNotAdded={tokens}
                                    onSelectToken={isInvest ? (): void => {} : onSelectToken}
                                />
                            </Box>
                        </Collapse>
                    ))}
                {exchangeLoading && (
                    <Collapse>
                        <Box mb={1}>
                            <LoadingCard />
                        </Box>
                    </Collapse>
                )}
            </TransitionGroup>
        </Box>
    );
}
