/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable func-style */
import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../../constants';
import { RootState } from '../../app/store';
import { AirdropItem } from '../../rewards/airdrop.types';
import {
    selectHasUserStakedWallet,
    selectHasWalletConnected,
    selectIsAVAXNetwork,
    selectIsCorrectNetwork,
    selectWalletTokens,
} from '../../wallet/store/wallet.selectors';
import { DEFAULT_APY_PERIOD } from '../stats.types';
import { StatsState } from './stats.store';

export function selectStatsState(state: RootState): StatsState {
    return state.stats;
}

export const selectUserStatsMc = createSelector(selectStatsState, (state) => state.userStatsMc);

export const selectTotalGro = createSelector(
    selectUserStatsMc,
    (state) => new BigNumber(state?.ethereum.gro_balance_combined || 0),
);

export const selectIsLoadingUserStatsMc = createSelector(
    selectStatsState,
    (state) => state.loadingUserStatsMc,
);

export const selectGroStatsMc = createSelector(selectStatsState, (state) => state.groStatsMc);

export const selectIsLoadingGroMcStats = createSelector(
    selectStatsState,
    (state) => state.loadingGroMcStats,
);

export const selectDefaultPeriodApy = createSelector(selectGroStatsMc, (stats) => ({
    gvt: new BigNumber(0),
    pwrd: new BigNumber(0),
}));

export const selectMaxApy = createSelector(selectDefaultPeriodApy, (apyDefault) =>
    BigNumber.maximum(apyDefault.gvt, apyDefault.pwrd),
);

export const selectUserAirdrops = createSelector(selectUserStatsMc, (state) => {
    // We need to merge the intersection of the default airdrops + new airdrops introduced through the API.
    const userStatsAirdrops = state && state.ethereum.airdrops ? state.ethereum.airdrops : [];

    const userStatsTransformedAirdrops = userStatsAirdrops
        .map(
            (airdrop): AirdropItem => ({
                ...airdrop,
                amount: new BigNumber(airdrop.amount || 0),
                claimed: airdrop.claimed === 'true',
                expired: airdrop.expired === 'true',
                expiry_ts: airdrop.expiry_ts ? parseInt(airdrop.expiry_ts, 10) : undefined,
                participated: airdrop.participated === 'true',
                timestamp: new BigNumber(airdrop.timestamp).multipliedBy(1000),
                unlocked: airdrop.claimable === 'true',
            }),
        )
        .filter(
            (airdrop) =>
                !airdrop.amount.isZero() && airdrop.participated && airdrop.name !== 'gas_pwrd',
        );

    return userStatsTransformedAirdrops;
});

export const selectTotalUnclaimedGroFromAirdrops = createSelector(
    selectUserAirdrops,
    (airdrops): BigNumber =>
        airdrops
            // Get unclaimed GRO tokens, from participated airdrops
            .filter(
                (airdrop) =>
                    airdrop.participated &&
                    !airdrop.claimed &&
                    !airdrop.expired &&
                    airdrop.unlocked,
            )
            // And add the total values
            .reduce(
                (prev: BigNumber, next: AirdropItem): BigNumber => prev.plus(next.amount),
                new BigNumber(0),
            ),
);

export const selectTotalClaimedGroFromAirdrops = createSelector(
    selectUserAirdrops,
    (airdrops): BigNumber =>
        airdrops
            // Get unclaimed GRO tokens, from participated airdrops
            .filter((airdrop) => airdrop.participated && airdrop.claimed)
            // And add the total values
            .reduce(
                (prev: BigNumber, next: AirdropItem): BigNumber => prev.plus(next.amount),
                new BigNumber(0),
            ),
);

export const selectPoolsApyRanges = createSelector(selectGroStatsMc, (stats) => {
    const groStats = stats;
    const apys = groStats.mainnet?.pools
        .filter((elem) => elem.apy.current.total !== 'NA')
        .map((pool) => new BigNumber(pool.apy.current.total).toNumber())
        .sort((a, b) => a - b);

    if (!apys) {
        return {
            bigger: 0,
            smaller: 0,
        };
    }
    return {
        bigger: apys[apys.length - 1],
        smaller: apys[0],
    };
});

export const selectLockedAirdrops = createSelector(selectUserAirdrops, (airdrops) =>
    airdrops.filter((airdrop) => !airdrop.unlocked && !airdrop.expired && !airdrop.claimed),
);

export const selectClaimableAirdrops = createSelector(selectUserAirdrops, (airdrops) =>
    airdrops
        .filter((airdrop) => !airdrop.claimed && airdrop.unlocked && !airdrop.expired)
        .sort((a, b) => b.amount.minus(a.amount).toNumber())
        .map((elem) => ({ ...elem })),
);

export const selectClaimedAirdrops = createSelector(selectUserAirdrops, (airdrops) =>
    airdrops.filter((airdrop) => airdrop.claimed),
);

export const selectExpiredAirdrops = createSelector(selectUserAirdrops, (airdrops) =>
    airdrops.filter((airdrop) => airdrop.expired && !airdrop.claimed),
);

export const selectTokenPricesInUsd = createSelector(
    selectGroStatsMc,
    (stats) => stats.mainnet?.token_price_usd,
);

export const selectPoolsStats = createSelector(selectGroStatsMc, (stats) => stats?.mainnet?.pools);

export const selectStatsTVL = createSelector(selectGroStatsMc, (stats) => stats.mainnet?.tvl);

export const selectNetVestingBonusReturns = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum.vest_bonus?.net_reward || 0),
);

export const selectNetAllRewardsReturns = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum.pools.all?.net_reward || 0),
);

export const selectTotalNetReturns = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum.net_returns.total || 0),
);

export const selectPoolsAndBonusReturns = createSelector(
    selectNetVestingBonusReturns,
    selectNetAllRewardsReturns,
    (vesting, rewards) => vesting.plus(rewards),
);

export const selectNetGVTRewardsReturns = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum.pools.single_staking_100_gvt_3?.net_reward || 0),
);

export const selectNetPWRDRewardsReturns = createSelector(
    selectUserStatsMc,
    (stats) => new BigNumber(stats?.ethereum.pools.single_staking_100_pwrd_6?.net_reward || 0),
);

export const selectGROPrice = createSelector(
    selectTokenPricesInUsd,
    (prices) => new BigNumber(prices.gro || 0),
);

export const selectExposureProtocols = createSelector(
    selectGroStatsMc,
    (stats) => stats.mainnet?.exposure.protocols || [],
);

export const selectExposureStablecoins = createSelector(selectGroStatsMc, (stats) => {
    const stablecoins = stats.mainnet?.exposure.stablecoins || [];
    return stablecoins;
});

export const selectPwrdProtectionStablecoins = createSelector(
    selectExposureStablecoins,
    selectStatsTVL,
    (stablecoins, tvl) =>
        stablecoins
            .map((elem) => {
                const assetTVL = new BigNumber(tvl.gvt)
                    .plus(new BigNumber(tvl.pwrd))
                    .multipliedBy(elem.concentration);
                const concentration = new BigNumber(tvl.gvt)
                    .dividedBy(assetTVL)
                    .plus(Math.min(assetTVL.dividedBy(tvl.pwrd).toNumber(), 1));
                return {
                    ...elem,
                    concentration: assetTVL.isGreaterThan(0) ? concentration : new BigNumber(0),
                };
            })
            .sort((a, b) => b.concentration.toNumber() - a.concentration.toNumber()),
);

export const selectPwrdProtectionProtocols = createSelector(
    selectExposureProtocols,
    selectStatsTVL,
    (protocols, tvl) =>
        protocols
            .map((elem) => {
                const assetTVL = new BigNumber(tvl.gvt)
                    .plus(new BigNumber(tvl.pwrd))
                    .multipliedBy(elem.concentration);
                const concentration = new BigNumber(tvl.gvt)
                    .dividedBy(assetTVL)
                    .plus(Math.min(assetTVL.dividedBy(tvl.pwrd).toNumber(), 1));
                return {
                    ...elem,
                    concentration: assetTVL.isGreaterThan(0) ? concentration : new BigNumber(0),
                };
            })
            .sort((a, b) => b.concentration.toNumber() - a.concentration.toNumber()),
);

export const selectUserStatsError = createSelector(
    selectStatsState,
    (stats) => stats.userStatsError,
);

export const selectUserStatsWarning = createSelector(
    selectStatsState,
    (stats) => stats.userStatsWarning,
);

export const selectGroStatsError = createSelector(
    selectStatsState,
    (stats) => stats.groStatsError,
);

export const selectGroStatsWarning = createSelector(
    selectStatsState,
    (stats) => stats.groStatsWarning,
);

export const selectShowPosition = createSelector(
    selectIsCorrectNetwork,
    selectIsAVAXNetwork,
    (isCorrectNetwork, isAvax) => isCorrectNetwork && !isAvax,
);

export const selectLabsBalanceOnChain = createSelector(
    selectStatsState,
    (state) => state.labsBalance,
);

export const selectCapitalExposureNames = createSelector(
    selectExposureProtocols,
    selectExposureStablecoins,
    (protocol, coins) => [
        ...protocol.map((elem) => elem.display_name),
        ...coins.map((elem) => elem.display_name),
    ],
);

export const selectShowOnboarding = createSelector(
    selectHasWalletConnected,
    selectWalletTokens,
    selectHasUserStakedWallet,
    selectTotalNetReturns,
    (connected, tokens, hasStaked, netReturns) =>
        !connected ||
        (new BigNumber(tokens[TOKENS.GVT] || 0).plus(tokens[TOKENS.PWRD] || 0).isZero() &&
            !hasStaked &&
            netReturns.isEqualTo(0)),
);
