/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { ETHEREUM_NETWORK, NETWORK, Token, TokenObject, TOKENS } from '../../../constants';
import { isAVAXNetwork, isCorrectNetwork } from '../../app/app.helpers';
import { Status } from '../../app/app.types';
import { RootState } from '../../app/store';
import { WalletState } from './wallet.store';

export function selectWalletState(state: RootState): WalletState {
    return state.wallet;
}

export const selectWalletAccount = createSelector(
    selectWalletState,
    (state) => state.account || '',
);

export const selectHasWalletConnected = createSelector(
    selectWalletAccount,
    (account) => !!account,
);

export const selectWalletTokens = createSelector(
    selectWalletState,
    (state: WalletState) => state.tokens,
);

export const selectWalletGro = createSelector(
    selectWalletTokens,
    (tokens) => new BigNumber(tokens.gro || 0),
);

export const selectStakedWalletTokens = createSelector(
    selectWalletState,
    (state: WalletState): TokenObject => state.staked,
);

export const selectHasUserStakedWallet = createSelector(
    selectStakedWalletTokens,
    (staked) => !!Object.values(staked).filter((val) => !val.isEqualTo(0)).length,
);

export const selectClaimableRewardsTokens = createSelector(
    selectWalletState,
    (state: WalletState) => state.claimableRewards,
);

export const selectWalletTokensInUsd = createSelector(
    selectWalletState,
    (state: WalletState) => state.tokensInUsd,
);

export const selectWalletStatus = createSelector(selectWalletState, (state) => state.status);

export const selectWalletLoading = createSelector(
    selectWalletState,
    (state) =>
        state.status !== Status.ready &&
        state.status !== Status.error &&
        state.status !== Status.idle,
);

export const selectNetworkId = createSelector(
    selectWalletState,
    (state: WalletState) => state.networkId,
);

export const selectIsAvalancheNetwork = createSelector(
    selectNetworkId,
    (networkId: number) => networkId === 43114,
);

export const selectIsMainnet = createSelector(
    selectNetworkId,
    (networkId: number) => networkId === 1,
);

export const selectIsAVAXNetwork = createSelector(selectWalletState, (state: WalletState) =>
    isAVAXNetwork(state.networkId),
);

export const selectNetworkParam = createSelector(
    selectIsMainnet,
    selectIsAVAXNetwork,
    (isMainnet, isAvax) => {
        if (isMainnet) return NETWORK.MAINNET;
        if (isAvax) return NETWORK.MAINNET;
        return ETHEREUM_NETWORK;
    },
);

export const selectIsCorrectNetwork = createSelector(selectWalletState, (state: WalletState) =>
    isCorrectNetwork(state.networkId),
);

export const selectProviderName = createSelector(
    selectWalletState,
    (state: WalletState) => state.providerName,
);

export const selectStableTokenBalance = (token: Token) =>
    createSelector(selectWalletTokens, (tokens) => tokens[token] || new BigNumber(0));

export const selectTokenInUSD = (token: Token) =>
    createSelector(selectWalletTokensInUsd, (tokens) => tokens[token] || new BigNumber(0));

export const selectGvtRewards = createSelector(
    selectClaimableRewardsTokens,
    (claimableRewards) => new BigNumber(claimableRewards.single_staking_100_gvt_3 || 0),
);

export const selectPWRDRewards = createSelector(
    selectClaimableRewardsTokens,
    (claimableRewards) => new BigNumber(claimableRewards.single_staking_100_pwrd_6 || 0),
);

export const selectAllPoolsRewards = createSelector(
    selectClaimableRewardsTokens,
    (claimableRewards) => {
        const values = Object.values(claimableRewards);
        return values.reduce((curr, next) => curr.plus(new BigNumber(next)), new BigNumber(0));
    },
);

export const selectStakedToken = (token: Token) =>
    createSelector(selectStakedWalletTokens, (tokens) => {
        switch (token) {
            case TOKENS.GVT:
                return new BigNumber(tokens.single_staking_100_gvt_3 || 0);
            case TOKENS.PWRD:
                return new BigNumber(tokens.single_staking_100_pwrd_6 || 0);
            default:
                return new BigNumber(0);
        }
    });

export const selectShowNotification = createSelector(
    selectWalletState,
    (state) => state.showNotification,
);

export const selectNotificationText = createSelector(
    selectWalletState,
    (state) => state.notification,
);

export const selectShowVolatility = createSelector(
    selectWalletState,
    (state) => state.showVolatility,
);

export const selectOpenBlockModal = createSelector(
    selectWalletState,
    (state) => state.openBlockModal,
);

export const selectWalletBlocked = createSelector(
    selectWalletState,
    (state) => state.walletBlocked,
);

export const selectWalletError = createSelector(selectWalletState, (state) => state.error);

export const selectHardwareWalletLoading = createSelector(
    selectWalletState,
    (state) => state.hardwareWalletLoading,
);

export const selectLoadingProvider = createSelector(
    selectWalletState,
    (state) => state.loadingProvider,
);

export const selectIsUS = createSelector(selectWalletState, (state) => state.country === 'US');
